import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/account/auth.service';
import { IUser } from 'src/app/account/model/user.model';
import { getAuthUserDashboardLabel } from 'src/app/shared/utils/dashboard.utils';
import { config } from 'src/config';

@Component({
  selector: 'app-dashboard-validation-sidebar',
  templateUrl: './dashboard-validation-sidebar.component.html',
  styleUrls: ['./dashboard-validation-sidebar.component.scss'],
})
export class DashboardValidationSidebarComponent implements OnInit {
  authUser?: IUser;
  dashboardLabel = '';
  userRoles = config.authRoles;
  dobUserRole = this.userRoles.DOB.filter((role) => role.includes('USER'));
  dobAdminRole = this.userRoles.DOB.filter((role) => role.includes('ADMIN'));

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.getAuthUser().subscribe((user) => {
      this.authUser = user;
      this.dashboardLabel = getAuthUserDashboardLabel(this.authUser);
    });
  }
}
