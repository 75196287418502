<div class="examen-section">
  <div class="mb-8 examen-section__header">
    <app-examen-breadcrumb [items]="items"></app-examen-breadcrumb>
  </div>

  <div class="examen-section__content full">
    <h3>Information candidat</h3>

    <div style="text-align: center">
      <a
        class="link link-main"
        href=""
        style="text-align: center"
        [routerLink]="['/']"
        *ngIf="!studentFounded && !loaded"
      >
        <i class="pi pi-arrow-left"></i>
        Retour
      </a>
    </div>
    <p *ngIf="studentFounded">
      Veuillez remplir le formulaire en tenant compte des champs obligatoire
    </p>

    <p-messages [(value)]="msgs"></p-messages>

    <div *ngIf="studentFounded">
      <form [formGroup]="editForm">
        <p-toast key="studentInfoEdited"></p-toast>
        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_lastname"
              >Nom
              <span class="color-danger">{{
                fieldIsRequired(editForm.controls["lastName"])
              }}</span>
            </label>
            <input
              type="text"
              pInputText
              formControlName="lastName"
              id="field_lastname"
              placeholder="Votre nom"
            />
            <small
              *ngIf="
                editForm.get('lastName')?.errors &&
                (editForm.get('lastName')?.errors)!['required']
              "
              class="p-field-help"
              id="field_lastName-help"
              >Ce champ est obligatore.</small
            >
          </div>

          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_firstname"
                >Prénoms
                <span class="color-danger">{{
                  fieldIsRequired(editForm.controls["firstName"])
                }}</span>
              </label>
              <input
                type="text"
                pInputText
                formControlName="firstName"
                id="field_firstname"
                placeholder="Votre prénom"
              />
              <small
                *ngIf="
                  editForm.get('firstName')?.errors &&
                  (editForm.get('firstName')?.errors)!['required']
                "
                class="p-field-help"
                id="field_firstName-help"
                >Ce champ est obligatore.</small
              >
            </div>
          </div>
        </div>

        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_birthDate"
              >Date de naissance
              <span class="color-danger">{{
                fieldIsRequired(editForm.controls["birthDate"])
              }}</span>
            </label>
            <input
              type="date"
              pInputText
              formControlName="birthDate"
              id="field_birthDate"
              placeholder="Votre date de naissance"
            />
            <small
              *ngIf="
                editForm.get('birthDate')?.errors &&
                (editForm.get('birthDate')?.errors)!['required']
              "
              class="p-field-help"
              id="field_birthDate-help"
              >Ce champ est obligatore.</small
            >
          </div>

          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_birthPlace"
                >Lieu de naissance
                <span class="color-danger">{{
                  fieldIsRequired(editForm.controls["birthPlace"])
                }}</span>
              </label>
              <input
                type="text"
                pInputText
                formControlName="birthPlace"
                id="field_birthPlace"
                placeholder="Votre lieu de naissance"
              />
              <small
                *ngIf="
                  editForm.get('birthPlace')?.errors &&
                  (editForm.get('birthPlace')?.errors)!['required']
                "
                class="p-field-help"
                id="field_birthPlace-help"
                >Ce champ est obligatore.</small
              >
            </div>
          </div>
        </div>

        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_gender"
              >Sexe
              <span class="color-danger">{{
                fieldIsRequired(editForm.controls["gender"])
              }}</span>
            </label>
            <p-dropdown
              id="field_gender"
              [options]="genders"
              [(ngModel)]="selectedGender"
              optionLabel="name"
              optionValue="code"
              formControlName="gender"
              placeholder="Selectionner sexe"
            >
            </p-dropdown>
            <small
              *ngIf="
                editForm.get('gender')?.errors &&
                (editForm.get('gender')?.errors)!['required']
              "
              class="p-field-help"
              id="field_gender-help"
              >Ce champ est obligatore.</small
            >
          </div>

          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_nationality"
                >Nationalité
                <span class="color-danger">{{
                  fieldIsRequired(editForm.controls["nationality"])
                }}</span>
              </label>
              <!-- <input
                type="text"
                pInputText
                formControlName="nationality"
                id="field_nationality"
                placeholder="Votre nationalité"
              /> -->
              <p-dropdown
                id="field_nationality"
                [options]="nationalities"
                [(ngModel)]="selectedNationality"
                optionLabel="nat_label"
                formControlName="nationality"
                placeholder="Selectionner votre nationalité"
              >
              </p-dropdown>
              <small
                *ngIf="
                  editForm.get('nationality')?.errors &&
                  (editForm.get('nationality')?.errors)!['required']
                "
                class="p-field-help"
                id="field_nationality-help"
                >Ce champ est obligatore.</small
              >
            </div>
          </div>
        </div>

        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_email"
                >Email
                <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
              </label>
              <input
                type="text"
                pInputText
                formControlName="email"
                id="field_email"
                placeholder="Votre email"
              />
            </div>
          </div>

          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_phone1"
                >Téléphone 1
                <span class="color-danger">{{
                  fieldIsRequired(editForm.controls["phone1"])
                }}</span>
              </label>
              <!-- <input
                type="text"
                pInputText
                formControlName="phone1"
                id="field_phone1"
                placeholder="Téléphone 1"
              /> -->
              <p-inputMask
                mask="+225 99 99 99 99 99"
                formControlName="phone1"
                id="field_phone1"
                placeholder="+225 99 99 99 99 99"
                [autoClear]="false"
              >
              </p-inputMask>
            </div>
            <small
              *ngIf="
                editForm.get('phone1')?.errors &&
                (editForm.get('phone1')?.errors)!['required']
              "
              class="p-field-help"
              id="field_phone1-help"
              >Ce champ est obligatore.</small
            >
          </div>

          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_phone2"
                >Téléphone 2
              </label>
              <!-- <input
                type="text"
                pInputText
                formControlName="phone2"
                id="field_phone1"
                placeholder="Téléphone 2"
              /> -->
              <p-inputMask
                mask="+225 99 99 99 99 99"
                formControlName="phone2"
                id="field_phone1"
                placeholder="+225 99 99 99 99 99"
                [autoClear]="false"
                [size]="19"
                [minlength]="10"
              >
              </p-inputMask>
            </div>
          </div>
        </div>

        <div class="p-fielf p-field-image">
          <div>
            <p-image
              *ngIf="student?.std_picture!; else defaultpicture"
              [src]="studentPictureURL"
              alt="Image"
              width="200"
              height="200"
              [style]="{ 'object-fit': 'cover' }"
            ></p-image>

            <ng-template #defaultpicture>
              <p-image
                src="/assets/default_picture.png"
                alt="Image"
                width="200"
                height="200"
                [style]="{ 'object-fit': 'cover' }"
              ></p-image>
            </ng-template>
          </div>

          <div class="p-field-image__description">
            <div style="display: flex; align-items: center">
              <input
                type="file"
                name="myfile[]"
                (change)="onFileChange($event)"
                #fileInput
                style="display: none"
              />
              <button
                pButton
                pRipple
                type="button"
                label="Charger votre photo"
                class="p-button p-button-sm"
                (click)="fileInput.click()"
                style="margin-right: 1rem"
              ></button>

              <span class="color-danger">{{
                fieldIsRequired(editForm.controls["phone1"])
              }}</span>

              <!-- <button
                pButton
                pRipple
                [disabled]="!selectedFile || !isFileValid"
                class="p-button p-button-sm"
                (click)="onFileUpload()"
              >
                Sauvegarder
              </button> -->
              <img src="/assets/loader.gif" alt="Loadder" *ngIf="isUploaded" />
            </div>
            <div
              *ngIf="selectedFile"
              style="
                display: flex;
                text-align: left;
                align-items: center;
                margin-top: 10px;
              "
            >
              <p>{{ selectedFile?.name }}</p>
              <!-- <span
                class="p-link"
                (click)="removeSelectedFile(fileInput)"
                style="margin-left: 2rem"
                >X</span
              > -->
            </div>

            <ul>
              <li>- Assurez-vouz que le fichier ne dépase pas 2Mb</li>
              <li>
                - Le fichier doit faire {{ imgWidth }}x{{ imgHeight }} de taile
              </li>
            </ul>
          </div>
        </div>

        <div class="p-field p-field-actions">
          <!-- <button
            pButton
            pRipple
            type="button"
            label="Enregistrer"
            icon="pi pi-save"
            class="p-button-outlined"
            style="margin-right: auto"
            [disabled]="
              !editForm.dirty || (editForm.touched && !editForm.dirty) || loaded
            "
            (click)="edit()"
          ></button> -->

          <button
            pButton
            pRipple
            type="button"
            label="Annuler"
            icon="pi pi-arrow-left"
            class="p-button-secondary p-button-text"
            (click)="goPrev()"
            [disabled]="isSaving"
          ></button>

          <button
            [disabled]="isSaving || editForm.invalid || loaded"
            pButton
            type="button"
            label="Enregistrer et continuer"
            (click)="openConfirmDialog()"
          ></button>
        </div>
      </form>
    </div>

    <!-- confirm dialog -->
    <p-dialog
      header="Comfirmation"
      [(visible)]="showDialog"
      [style]="{ width: '500px' }"
      [resizable]="false"
      appendTo="body"
      [modal]="true"
    >
      <p style="display: flex; align-items: center">
        <i
          style="font-size: 2rem; color: red; margin-right: 1rem"
          class="pi pi-question-circle"
        ></i>
        Êtes-vous sure des informations renseignées ?
      </p>

      <ng-template pTemplate="footer">
        <p-button
          (click)="showDialog = false"
          icon="pi pi-times"
          label="Annuler"
          styleClass="p-button-secondary p-button-text"
        ></p-button>
        <p-button
          (click)="goToNext()"
          label="Comfirmer"
          styleClass="p-button"
        ></p-button>
      </ng-template>
    </p-dialog>

    <div class="student-info-loader" *ngIf="loaded">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
