<!-- Creation dialog -->
<!-- <p-dialog
  header="Création d'un Nouvel utilisateur"
  [(visible)]="displayCreateUser"
  [style]="{ width: '500px' }"
  [modal]="true"
> -->
<p-messages key="USER_CREATION_ALERT_MESSAGE" [(value)]="msgs"></p-messages>

<form [formGroup]="editForm">
  <!-- EMAIL -->
  <div class="p-field p-field-column" style="margin-bottom: 0rem">
    <label class="p-field-label" for="field_email"
      >Email
      <span class="color-danger">{{
        fieldIsRequired(editForm.controls["email"])
      }}</span>
    </label>
    <input
      type="email"
      pInputText
      formControlName="email"
      id="field_email"
      placeholder="Votre email"
    />
    <small
      *ngIf="
        editForm.get('email')?.errors &&
        (editForm.get('email')?.errors)!['required'] &&
        (editForm.controls['email'].dirty || editForm.controls['email'].touched)
      "
      class="p-field-help"
      id="field_email-help"
      >Ce champ est obligatore.</small
    >
    <small
      *ngIf="
        editForm.get('email')?.errors &&
        (editForm.get('email')?.errors)!['email'] &&
        (editForm.controls['email'].dirty || editForm.controls['email'].touched)
      "
      class="p-field-help"
      id="field_email-help"
      >Ce champ est doit être un email valide</small
    >
    <small class="p-field-help" id="field_email-help">&nbsp;</small>
  </div>

  <!-- ROLE -->
  <div class="p-field p-field-column" style="margin-bottom: 0rem">
    <label class="p-field-label" for="field_role"
      >Role
      <span class="color-danger">{{
        fieldIsRequired(editForm.controls["role"])
      }}</span>
    </label>
    <p-dropdown
      id="field_role"
      [options]="roles"
      [(ngModel)]="selectedRole"
      optionLabel="name"
      optionValue="code"
      formControlName="role"
      placeholder="Selectionner le rôle"
      appendTo="body"
      (onChange)="handleLoadUserTypeData(selectedRole!)"
    >
    </p-dropdown>
    <small
      *ngIf="
        editForm.get('role')?.errors &&
        (editForm.get('role')?.errors)!['required'] &&
        (editForm.controls['role'].dirty || editForm.controls['role'].touched)
      "
      class="p-field-help"
      id="field_role-help"
      >Ce champ est obligatore.</small
    >
    <small class="p-field-help" id="field_role-help">&nbsp;</small>
  </div>

  <!-- SCHOOL -->
  <div
    class="p-field p-field-column"
    *ngIf="
      selectedRole! && isUserSchoolType(selectedRole) && schools.length !== 0
    "
    style="margin-bottom: 0rem"
  >
    <label class="p-field-label" for="field_school"
      >Etablissement
      <span class="color-danger">{{
        fieldIsRequired(editForm.controls["school"])
      }}</span>
    </label>
    <p-dropdown
      id="field_school"
      [filter]="true"
      [options]="schools"
      [(ngModel)]="selectedSchool"
      optionLabel="sch_longlabel"
      optionValue="sch_id"
      formControlName="school"
      emptyMessage="Aucun résultat"
      emptyFilterMessage="Aucun résultat"
      placeholder="Selectionner l'établissement"
      appendTo="body"
      [showClear]="true"
    >
    </p-dropdown>
    <small
      *ngIf="
        editForm.get('school')?.errors &&
        (editForm.get('school')?.errors)!['required'] &&
        (editForm.controls['school'].dirty ||
          editForm.controls['school'].touched)
      "
      class="p-field-help"
      id="field_school-help"
      >Ce champ est obligatore.</small
    >
    <small class="p-field-help" id="field_school-help">&nbsp;</small>
  </div>

  <!-- REGIONAL -->
  <div
    class="p-field p-field-column"
    *ngIf="selectedRole! && isUserRegionalType(selectedRole)"
    style="margin-bottom: 0rem"
  >
    <label class="p-field-label" for="field_regionalbranch"
      >Direction régionale
      <span class="color-danger">{{
        fieldIsRequired(editForm.controls["regionalbranch"])
      }}</span>
    </label>
    <p-dropdown
      id="field_regionalbranch"
      [options]="regionals"
      [filter]="true"
      [(ngModel)]="selectedRegional"
      optionLabel="bch_label"
      optionValue="bch_id"
      formControlName="regionalbranch"
      emptyMessage="Aucun résultat"
      emptyFilterMessage="Aucun résultat"
      placeholder="Selectionner la direction régional"
      appendTo="body"
      [showClear]="true"
    >
    </p-dropdown>
    <small
      *ngIf="
        editForm.get('regionalbranch')?.errors &&
        (editForm.get('regionalbranch')?.errors)!['required'] &&
        (editForm.controls['regionalbranch'].dirty ||
          editForm.controls['regionalbranch'].touched)
      "
      class="p-field-help"
      id="field_regionalbranch-help"
      >Ce champ est obligatore.</small
    >
    <small class="p-field-help" id="field_regionalbranch-help">&nbsp;</small>
  </div>

  <!-- BANK -->
  <div
    class="p-field p-field-column"
    *ngIf="selectedRole! && isUserBankType(selectedRole)"
    style="margin-bottom: 0rem"
  >
    <label class="p-field-label" for="field_bank"
      >Banque
      <span class="color-danger">{{
        fieldIsRequired(editForm.controls["bank"])
      }}</span>
    </label>
    <p-dropdown
      id="field_bank"
      [options]="banks"
      [filter]="true"
      [(ngModel)]="selectedBank"
      optionLabel="bnk_shortlabel"
      optionValue="bnk_id"
      formControlName="bank"
      emptyMessage="Aucun résultat"
      emptyFilterMessage="Aucun résultat"
      placeholder="Selectionner la banque"
      appendTo="body"
      [showClear]="true"
    >
    </p-dropdown>
    <small
      *ngIf="
        editForm.get('bank')?.errors &&
        (editForm.get('bank')?.errors)!['required'] &&
        (editForm.controls['bank'].dirty || editForm.controls['bank'].touched)
      "
      class="p-field-help"
      id="field_bank-help"
      >Ce champ est obligatore.</small
    >
    <small class="p-field-help" id="field_bank-help">&nbsp;</small>
  </div>

  <div class="p-field p-field-actions loader">
    <div></div>
    <div>
      <button
        pButton
        pRipple
        type="button"
        label="Annuler"
        icon="pi pi-times"
        class="p-button-secondary p-button-text"
        (click)="closeDialog()"
        [disabled]="isSaving"
      ></button>

      <button
        [disabled]="isSaving || editForm.invalid"
        pButton
        (click)="save()"
      >
        <span *ngIf="isSaving"
          ><i style="font-size: 2rem" class="pi pi-spin pi-spinner"></i
        ></span>
        <span *ngIf="!isSaving">Enregistrer</span>
      </button>
    </div>
  </div>
</form>
<!-- </p-dialog> -->
