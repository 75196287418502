import { RouterModule, Routes } from '@angular/router';
import { DasboardValidationLayoutComponent } from './dasboard-validation-layout/dasboard-validation-layout.component';
import { DashboardValidationCandidatureComponent } from './dashboard-validation-candidature/dashboard-validation-candidature.component';
import { DashboardValidationListingComponent } from './dashboard-validation-listing/dashboard-validation-listing.component';
import { AuthGuard } from '../helpers/auth.guard';
import { DashboardRegionalComponent } from './dashboard-regional/dashboard-regional.component';
import { DashboardValidationComponent } from './dashboard-validation/dashboard-validation.component';
import { DashboardSchoolsComponent } from './dashboard-schools/dashboard-schools.component';
import { config } from '../../config';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { UserRoleGuard } from '../helpers/user-role.guard';
import { DashboardSupervisorComponent } from './dashboard-supervisor/dashboard-supervisor.component';
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
import { DashboardSchoolsStaffComponent } from './dashboard-schools-staff/dashboard-schools-staff.component';
import { DashboardBankComponent } from './dashboard-bank/dashboard-bank.component';
import { DashbordFreeCandidateComponent } from './dashbord-free-candidate/dashbord-free-candidate.component';
import { DashboardBankValidationComponent } from './dashboard-bank-validation/dashboard-bank-validation.component';
import { DashboardAdminAccountConfigureComponent } from './dashboard-admin-account-configure/dashboard-admin-account-configure.component';
import { DashboardDobComponent } from './dashboard-dob/dashboard-dob.component';
import { DashboardDobApplicantsComponent } from './dashboard-dob-applicants/dashboard-dob-applicants.component';
import { DashboardDexcUpdateComponent } from './dashboard-dexc-update/dashboard-dexc-update.component';
import { DashboardDexcUpdateStudentComponent } from './dashboard-dexc-update-student/dashboard-dexc-update-student.component';
import { DashboardDexcUpdateClassComponent } from './dashboard-dexc-update-class/dashboard-dexc-update-class.component';
import { DashboardDexcUpdateFinishComponent } from './dashboard-dexc-update-finish/dashboard-dexc-update-finish.component';
import { DashboardRegionalOrientationValidationComponent } from './dashboard-regional-orientation-validation/dashboard-regional-orientation-validation.component';
import { DashboardRegionalOrientationValidationListingComponent } from './dashboard-regional-orientation-validation-listing/dashboard-regional-orientation-validation-listing.component';
import { DashboardRegionalSettingsApplicantCodeComponent } from './dashboard-regional-settings-applicant-code/dashboard-regional-settings-applicant-code.component';
import { DashboardDobUserRegistrationComponent } from './dashboard-dob-user-registration/dashboard-dob-user-registration.component';
import { DashboardDobUserRegistrationEditComponent } from './dashboard-dob-user-registration-edit/dashboard-dob-user-registration-edit.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DasboardValidationLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'regional',
        // component: DasboardValidationLayoutComponent,
        children: [
          {
            path: 'home',
            component: DashboardRegionalComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.REGIONAL },
          },
          {
            path: 'schools',
            component: DashboardSchoolsComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.REGIONAL },
          },
          {
            path: 'orientation/validation',
            component: DashboardRegionalOrientationValidationComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.REGIONAL },
          },
          {
            path: 'orientation/listing-validation',
            component: DashboardRegionalOrientationValidationListingComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.REGIONAL },
          },
          {
            path: 'candidate-libre',
            component: DashbordFreeCandidateComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.REGIONAL },
          },
          // {
          //   path: 'settings/applicant-code',
          //   component: DashboardRegionalSettingsApplicantCodeComponent,
          //   canActivate: [AuthGuard, UserRoleGuard],
          //   data: { roles: config.authRoles.REGIONAL },
          // },
        ],
      },
      {
        path: 'validators',
        // component: DasboardValidationLayoutComponent,
        children: [
          {
            path: 'home',
            component: DashboardValidationComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.SCHOOL },
          },
          {
            path: 'candidatures',
            component: DashboardValidationCandidatureComponent,
            canActivate: [AuthGuard],
            data: { roles: config.authRoles.SCHOOL },
          },
          {
            path: 'listing',
            component: DashboardValidationListingComponent,
            canActivate: [AuthGuard],
            data: { roles: config.authRoles.SCHOOL },
          },
          {
            path: 'staff',
            component: DashboardSchoolsStaffComponent,
            canActivate: [AuthGuard],
            data: { roles: config.authRoles.SCHOOL },
          },
        ],
      },
      {
        path: 'supervisor',
        children: [
          {
            path: 'home',
            component: DashboardSupervisorComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.SUPERVISOR },
          },
          {
            path: 'validation-bank',
            component: DashboardBankValidationComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.SUPERVISOR },
          },
        ],
      },
      {
        path: 'admin',
        children: [
          {
            path: 'home',
            component: DashboardAdminComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.ADMIN },
          },
          {
            path: 'configuration/user-account',
            component: DashboardAdminAccountConfigureComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.ADMIN },
          },
        ],
      },
      {
        path: 'dob',
        children: [
          {
            path: 'home',
            component: DashboardDobComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.DOB },
          },
          {
            path: 'applicants',
            component: DashboardDobApplicantsComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.DOB },
          },
          {
            path: 'user-choices-registrations/edit',
            component: DashboardDobUserRegistrationEditComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: {
              roles: config.authRoles.DOB.filter((role) =>
                role.toLowerCase().includes('user')
              ),
            },
          },
          {
            path: 'user-choices-registrations',
            component: DashboardDobUserRegistrationComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: {
              roles: config.authRoles.DOB.filter((role) =>
                role.toLowerCase().includes('user')
              ),
            },
          },
        ],
      },
      {
        path: 'bank',
        children: [
          {
            path: 'home',
            component: DashboardBankComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.BANK },
          },
          {
            path: 'validation',
            component: DashboardBankValidationComponent,
            canActivate: [AuthGuard, UserRoleGuard],
            data: { roles: config.authRoles.BANK },
          },
        ],
      },
      // {
      //   path: 'dexc-update',
      //   children: [
      //     {
      //       path: 'home',
      //       component: DashboardDexcUpdateComponent,
      //       canActivate: [AuthGuard, UserRoleGuard],
      //       data: { roles: config.authRoles.DEXCUPDATE },
      //     },
      //     {
      //       path: 'search',
      //       component: DashboardDexcUpdateComponent,
      //       canActivate: [AuthGuard, UserRoleGuard],
      //       data: { roles: config.authRoles.DEXCUPDATE },
      //     },
      //     {
      //       path: 'update-student',
      //       component: DashboardDexcUpdateStudentComponent,
      //       canActivate: [AuthGuard, UserRoleGuard],
      //       data: { roles: config.authRoles.DEXCUPDATE },
      //     },
      //     {
      //       path: 'update-class',
      //       component: DashboardDexcUpdateClassComponent,
      //       canActivate: [AuthGuard, UserRoleGuard],
      //       data: { roles: config.authRoles.DEXCUPDATE },
      //     },
      //     {
      //       path: 'update-finish',
      //       component: DashboardDexcUpdateFinishComponent,
      //       canActivate: [AuthGuard, UserRoleGuard],
      //       data: { roles: config.authRoles.DEXCUPDATE },
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   path: 'dashboard/regional',
  //   component: DasboardValidationLayoutComponent,
  //   canActivate: [AuthGuard],
  // },
];

@NgModule({
  declarations: [],
  imports: [SharedModule, RouterModule.forChild(routes)],
})
export class DashboardRoutingModule {}
