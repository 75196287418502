<app-navbar></app-navbar>


<div class="container concours-wrapper">
    <div id="svg_wrap"></div>

    <h1>Inscription Concours</h1>

    <section>
        <p>Entrez votre matricule</p>
        <input type="text" id="meana_id"  placeholder="********A" />
    </section>

    <section>
        <p>Quel Diplôme Preparez vous?</p>
        <select>
            <option *ngFor="let item of diplomas" [value]="item.dip_id" >{{item.dip_shortlabel}}</option>
        </select>
    </section>
   
   <section class="filiereFlex">
     <p>Choix de filiere</p>
     <div class="flexit" style="margin-left: 20%;">
        <div class="choice">
            <p>1er Choix</p>
            <select>
                <option *ngFor="let item of school" [value]="item.sch_id" data-attr="">{{item.sch_longlabel}}</option>
            </select>
            <select>
                <option>Filiere</option>
            </select>
        </div>
        <div class="choice">
            <p>2eme Choix</p>
            <select>
                <option *ngFor="let item of school" [value]="item.sch_id" data-attr="">{{item.sch_longlabel}}</option>
            </select>
            <select>
                <option>Filiere</option>
            </select>
        </div>
        <div class="choice">
            <p>3eme Choix</p>
            <select>
                <option *ngFor="let item of school" [value]="item.sch_id" data-attr="">{{item.sch_longlabel}}</option>
            </select>
            <select>
                <option>Filiere</option>
            </select>
        </div>
    </div>
   </section>
   
   <section class="filiereFlex">
     <app-user-info></app-user-info>
   </section>
   
   <section>
    <p>Frais D'inscription</p>
    <br>
    <p>12 000 FCFA</p>
    <input type="hidden" value="12000" readonly placeholder="12000" />
   </section>
   
   <div class="button" id="prev">&larr; Previous</div>
   <div class="button" id="next">Next &rarr;</div>
   <div class="button" id="submit">Payer et valider</div>
   
</div>