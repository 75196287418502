import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import {
  IApplicant,
  IPaginateApplicantWithStatus,
} from './models/applicant.model';
import { Choice, IChoice } from './models/choice.model';
import { SessionStorageService } from '../session-storage.service';
import { IAPIResponse } from '../shared/model/apiresponse.model';
import {
  IOATValidation,
  IValidationStatistics,
} from './models/validation.model';
import { IApplicantByRegional } from '../dashboard/dashboard-dob/dashboard-dob.component';
import { IDobRegistrationLine } from './models/registrationline.model';
import { DOB_USER_ITEM_KEY } from './constant/storage-items-key.constant';

@Injectable({
  providedIn: 'root',
})
export class DobService {
  applicantResourceURL = environment.apiURL + 'api/v1/applicants';
  choiceResourceURL = environment.apiURL + 'api/v1/choices';
  studyFieldResourceURL = environment.apiURL + 'api/v1/studyfield';
  orientationResourceURL = environment.apiURL + 'api/v1/orientations';

  private _choices = new BehaviorSubject<Choice[]>([]);
  public choices$ = this._choices.asObservable();

  constructor(
    private http: HttpClient,
    private storage: SessionStorageService
  ) {}

  getDobStatistics(): Observable<any> {
    return this.http.get<any>(`${this.applicantResourceURL}/statistics/`, {
      observe: 'body',
    });
  }

  getDobUserStatistics(user_id: string): Observable<any> {
    return this.http.get<any>(
      `${this.applicantResourceURL}/statistics/by-user/${user_id}/`,
      {
        observe: 'body',
      }
    );
  }

  checkApplicantID(body: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.applicantResourceURL}/check-matricule`,
      body,
      {
        observe: 'response',
      }
    );
  }

  checkAuthCode(body: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.applicantResourceURL}/check-authcode`,
      body,
      {
        observe: 'response',
      }
    );
  }

  getAllApplicant(
    url: string,
    params?: any
  ): Observable<HttpResponse<IPaginateApplicantWithStatus>> {
    let paramsObj = new HttpParams();
    if (params && typeof params['page'] !== 'undefined')
      paramsObj = paramsObj.append('page', params['page']);
    if (params && typeof params['status'] !== 'undefined')
      paramsObj = paramsObj.append('status', params['status']);
    return this.http.get<IPaginateApplicantWithStatus>(url, {
      observe: 'response',
      params: paramsObj,
    });
  }

  // getAllApplicant(
  //   page?: string,
  //   status?: string
  // ): Observable<HttpResponse<IPaginateApplicantWithStatus>> {
  //   let params = new HttpParams();
  //   if (typeof page !== 'undefined') params = params.append('page', page);
  //   if (typeof status !== 'undefined') params = params.append('status', status);
  //   return (page && page.toString() != '/') || status
  //     ? this.http.get<IPaginateApplicantWithStatus>(
  //         `${this.applicantResourceURL}/`,
  //         {
  //           observe: 'response',
  //           params: params,
  //         }
  //       )
  //     : this.http.get<IPaginateApplicantWithStatus>(
  //         `${this.applicantResourceURL}/`,
  //         {
  //           observe: 'response',
  //         }
  //       );
  // }

  geApplicantByRef(ref: string): Observable<HttpResponse<IApplicant | any>> {
    return this.http.get<IApplicant | any>(
      `${this.applicantResourceURL}/${ref}/`,
      {
        observe: 'response',
      }
    );
  }

  // geApplicantByRef(
  //   ref: string,
  //   isNew: number
  // ): Observable<HttpResponse<IApplicant | any>> {
  //   return this.http.get<IApplicant | any>(
  //     `${this.applicantResourceURL}/${ref}/${isNew}/`,
  //     {
  //       observe: 'response',
  //     }
  //   );
  // }

  saveApplicant(body: IApplicant): Observable<HttpResponse<IApplicant>> {
    return this.http.post<IApplicant>(`${this.applicantResourceURL}/`, body, {
      observe: 'response',
    });
  }

  updateApplicant(
    matricule: string,
    body: IApplicant
  ): Observable<HttpResponse<IApplicant>> {
    return this.http.patch<IApplicant>(
      `${this.applicantResourceURL}/${matricule}/`,
      body,
      {
        observe: 'response',
      }
    );
  }

  validationEditApplicantNationality(
    matricule: string,
    body: any
  ): Observable<IAPIResponse> {
    return this.http.patch<IAPIResponse>(
      `${this.applicantResourceURL}/${matricule}/edit-nationality/`,
      body,
      {
        observe: 'body',
      }
    );
  }

  validationEditApplicantBirthDate(
    matricule: string,
    body: any
  ): Observable<IAPIResponse> {
    return this.http.patch<IAPIResponse>(
      `${this.applicantResourceURL}/${matricule}/edit-birthdate/`,
      body,
      {
        observe: 'body',
      }
    );
  }

  validationSave(matricule: string, body: any): Observable<IAPIResponse> {
    return this.http.post<IAPIResponse>(
      `${this.orientationResourceURL}/validation/${matricule}/validate/`,
      body,
      {
        observe: 'body',
      }
    );
  }

  validationUpdate(validation_id: string, body: any): Observable<IAPIResponse> {
    return this.http.patch<IAPIResponse>(
      `${this.orientationResourceURL}/validation/${validation_id}/update/`,
      body,
      {
        observe: 'body',
      }
    );
  }

  getValidations(): Observable<IOATValidation[]> {
    return this.http.get<IOATValidation[]>(
      `${this.orientationResourceURL}/validations/`,
      {
        observe: 'body',
      }
    );
  }

  getValidationStatistics(): Observable<IValidationStatistics> {
    return this.http.get<IValidationStatistics>(
      `${this.orientationResourceURL}/validations/statistics/`,
      {
        observe: 'body',
      }
    );
  }

  getChoiceByApplicant(
    applicant_id: string
  ): Observable<HttpResponse<IChoice[]>> {
    return this.http.get<IChoice[]>(
      `${this.choiceResourceURL}/${applicant_id}/`,
      {
        observe: 'response',
      }
    );
  }

  addChoices(body: any): Observable<HttpResponse<IChoice[]>> {
    return this.http.post<IChoice[]>(`${this.choiceResourceURL}/`, body, {
      observe: 'response',
    });
  }

  editChoices(
    choice: Choice[],
    applicant_id: string
  ): Observable<HttpResponse<IChoice[]>> {
    const body = {
      applicant_id: applicant_id,
      choice: choice,
    };
    return this.http.post<IChoice[]>(`${this.choiceResourceURL}/`, body, {
      observe: 'response',
    });
  }

  getChoices(): Observable<Choice[]> {
    const choices = JSON.parse(this.storage.getItem('DOB_APPLICANT_CHOICES')!);
    this._choices.next(choices);
    return this.choices$;
  }

  setChoices(choices: Choice[]): void {
    this.storage.setItem('DOB_APPLICANT_CHOICES', JSON.stringify(choices));
    this._choices.next(choices);
  }

  validateChoiceStudyField(body: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.studyFieldResourceURL}/validate-dob-studyfield/`,
      body,
      { observe: 'response' }
    );
  }

  getDocument(body: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.orientationResourceURL}/document/`,
      body,
      {
        observe: 'response',
      }
    );
  }

  getApplicationByRegional(): Observable<HttpResponse<IApplicantByRegional[]>> {
    return this.http.get<IApplicantByRegional[]>(
      `${this.applicantResourceURL}/regional-statistics/`,
      { observe: 'response' }
    );
  }

  getUserRegistration(): Observable<HttpResponse<IDobRegistrationLine[]>> {
    return this.http.get<IDobRegistrationLine[]>(
      `${this.choiceResourceURL}/by-user/`,
      { observe: 'response' }
    );
  }

  addDobUserRegistration(
    body: any
  ): Observable<HttpResponse<IDobRegistrationLine>> {
    return this.http.post<IDobRegistrationLine>(
      `${this.choiceResourceURL}/by-user/`,
      body,
      { observe: 'response' }
    );
  }

  clearDobUserRegistrationStorage(): void {
    this.storage.removeItem(DOB_USER_ITEM_KEY);
  }
}
