import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { IApplicant } from 'src/app/dob/models/applicant.model';
import { INationality } from 'src/app/shared/model/nationality.model';
import {
  hasRequiredField,
  MustMatch,
} from 'src/app/shared/utils/validator.utils';
import { genders } from 'src/app/shared/constant/gender.contanst';
import { InscriptionService } from 'src/app/inscription/inscription.service';
import { SessionStorageService } from 'src/app/session-storage.service';
import { IChoice } from 'src/app/dob/models/choice.model';
import { Choice } from 'src/app/eexams/models/choice.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  MAX_ALLOWED_AGE,
  MAX_CHOICE_NUMBER,
  MIN_CHOICE_NUMBER,
} from 'src/app/dob/constant/validator.constant';
import { getPreferenceLabel } from 'src/app/dob/utils/preference.utils';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ISelectedChoiceData } from 'src/app/dob/components/dob-choice-form/dob-choice-form.component';
import { DOB_USER_ITEM_KEY } from 'src/app/dob/constant/storage-items-key.constant';
import { IYear } from 'src/app/shared/model/year.model';
import { YearService } from 'src/app/services/year.service';
import { DobService } from 'src/app/dob/dob.service';
import { AuthService } from 'src/app/account/auth.service';
import { IUser } from 'src/app/account/model/user.model';
import { Router } from '@angular/router';
import * as moment from 'moment';

export interface INavigationItem {
  label: string;
  active: boolean;
  validate: boolean;
  step: number;
}

export interface IUserRegistrationChoice {
  applicant: IApplicant;
  choice: IChoice[];
  step: number;
  validateStep?: number[];
  steps?: INavigationItem[];
}

enum DIRECTION {
  right = 'right',
  left = 'left',
}

@Component({
  selector: 'app-dashboard-dob-user-registration-edit',
  templateUrl: './dashboard-dob-user-registration-edit.component.html',
  styleUrls: ['./dashboard-dob-user-registration-edit.component.scss'],
})
export class DashboardDobUserRegistrationEditComponent implements OnInit {
  currentStep$: Observable<number> = new Observable<number>();
  currentStep = 0;
  items$: Observable<INavigationItem[]> = new Observable<INavigationItem[]>();
  items = [
    {
      label: 'Indentification',
      active: true,
      validate: false,
      step: 1,
    },
    {
      label: 'Choix',
      active: false,
      validate: false,
      step: 2,
    },
  ];
  editForm = this.fb.group(
    {
      app_matricule: [
        [],
        [Validators.required, Validators.pattern(/^\d{8}[A-Z]{1}$/)],
      ],
      app_lastname: [null, [Validators.required]],
      app_firstname: [null, [Validators.required]],
      app_birthdate: [null, [Validators.required]],
      app_birthplace: ['', [Validators.required]],
      app_gender: [null, [Validators.required]],
      app_nationality: [null, [Validators.required]],
      app_email: [null, [Validators.email]],
      app_phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      // app_authcode: [
      //   null,
      //   [Validators.required, Validators.pattern(/^[0-9]{4}$/)],
      // ],
      // app_authcode_confirm: [
      //   null,
      //   [Validators.required, Validators.pattern(/^[0-9]{4}$/)],
      // ],
    }
    // {
    //   validator: MustMatch('app_authcode', 'app_authcode_confirm'),
    // }
  );

  msgs: Message[] = [];
  isFirstTime = false;
  studentLastName = '';
  studentFirstName = '';
  studentBirthPlace = '';
  studentBirthDocPlace = '';
  minBirthDateAllowed = false;
  isLoading = false;
  isSaving = false;
  applicant?: IApplicant;
  applicantFounded = false;
  maxBirthDateAllowed = false;
  nationalities: INationality[] = [];
  selectedNationality?: INationality;
  isNationalityInvalid = false;
  matriculeValue = '';

  genders: any[] = [];
  selectedGender = '';

  registration?: IUserRegistrationChoice;

  // CHOICES DATA
  displayDialog = false;
  loaded = false;
  choices: IChoice[] = [];
  tempChoices: IChoice[] = [];
  minChoiceNumber = MIN_CHOICE_NUMBER;
  maxChoiceNumber = MAX_CHOICE_NUMBER;
  selectedChoice?: Choice;
  selectedChoices?: ISelectedChoiceData;
  activeYear?: IYear;
  year?: IYear;
  activeApplicant?: IApplicant;
  selectedPreferences: number[] = [];
  selectedStudyfields: string[] = [];
  selectedSchools: string[] = [];
  authUser?: IUser;

  constructor(
    private fb: FormBuilder,
    private inscriptionService: InscriptionService,
    private storage: SessionStorageService,
    private yearService: YearService,
    private dobService: DobService,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.genders = genders;
    this.setDefault();
    this.inscriptionService.getNationalities().subscribe((res) => {
      this.nationalities = res.body || [];
    });
    this.yearService.geActiveYear().subscribe((year) => (this.year = year));
  }

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }

  // Birth date validation
  handleDateChange(): void {
    const studentBirthDateValue = this.editForm.controls['app_birthdate'].value;
    const nowDate = new Date();
    const studentBirthDate = moment(studentBirthDateValue).format('MM-DD-YYYY');

    const dateDiff =
      nowDate.getFullYear() - new Date(studentBirthDate).getFullYear();

    if (dateDiff > MAX_ALLOWED_AGE) {
      this.editForm.controls['app_birthdate'].setErrors({ incorrect: true });
      this.maxBirthDateAllowed = true;
    } else {
      this.maxBirthDateAllowed = false;
    }
  }

  goNext(): void {
    this.addApplicantDataToStorage();
  }
  goPrev(): void {
    this.currentStep$.subscribe((step) => {
      if (step === 2) {
        this.goToIdentificationStep();
      } else if (step === 1) {
        this.router.navigate([
          '/',
          'dashboard',
          'dob',
          'user-choices-registrations',
        ]);
        this.storage.removeItem(DOB_USER_ITEM_KEY);
        this.loadData();
      }
    });
  }

  goToIdentificationStep(): void {
    let data = this.getRegistrationData();
    data.step = 1;
    data.steps = this.updateStep(DIRECTION.left);
    this.currentStep$ = this.setStep(1);
    this.storage.setItem(DOB_USER_ITEM_KEY, JSON.stringify(data));
    this.loadData();
  }

  setStep(step: number): Observable<number> {
    this.currentStep = step;
    return of(this.currentStep);
  }

  validateNationality(): void {}

  handleKeyUp(): void {}

  getRegistrationData(): IUserRegistrationChoice {
    return JSON.parse(this.storage.getItem(DOB_USER_ITEM_KEY)!);
  }

  addApplicantDataToStorage(): void {
    const data = this.getRegistrationData();
    let registrationValidatedStepData = data.validateStep;
    this.tempChoices = data.choice;
    if (!registrationValidatedStepData?.includes(1))
      registrationValidatedStepData?.push(1);
    this.currentStep = 2;
    this.registration = {
      applicant: this.editForm.value,
      choice: this.tempChoices ? this.tempChoices : [],
      step: this.currentStep,
      validateStep: registrationValidatedStepData,
      steps: this.updateStep(DIRECTION.right),
    };
    this.storage.setItem(DOB_USER_ITEM_KEY, JSON.stringify(this.registration));
    this.loadData();
  }

  setDefault(): void {
    if (this.getRegistrationData() === null) {
      this.currentStep = 1;
      this.currentStep$.subscribe((step) => console.log('IN =>', step));
      this.registration = {
        applicant: {},
        choice: [],
        step: this.currentStep,
        validateStep: [],
        steps: this.items,
      };
      this.storage.setItem(
        DOB_USER_ITEM_KEY,
        JSON.stringify(this.registration)
      );
    }
    this.loadData();
    this.updateStepForm();
  }

  updateStepForm(): void {
    this.currentStep$.subscribe((step) => {
      if (step === 1) {
        let { applicant } = this.getRegistrationData();
        if (Object.keys(applicant).length !== 0)
          this.updateApplicantForm(applicant);
      } else if (step === 2) this.loadStep2();
    });
  }

  updateStep(direction: DIRECTION): INavigationItem[] {
    let data = this.getRegistrationData();
    let result: INavigationItem[] = [];
    let activeElement: INavigationItem;
    this.currentStep$.subscribe((step) => {
      result =
        data?.steps?.map((item, index) => {
          if (data.validateStep?.includes(item.step)) item.validate = true;
          if (item.step === step) {
            item.active = false;
            activeElement =
              direction == DIRECTION.left
                ? data?.steps![index - 1]
                : data?.steps![index + 1];
          }

          return item;
        }) || [];

      result = result?.map((item) => {
        if (item.step == activeElement?.step) {
          item.active = true;
          item.validate = false;
        }

        return item;
      });
    });
    return result;
  }

  getStepItems(): Observable<INavigationItem[]> {
    return of(this.getRegistrationData()?.steps! || []);
  }
  getCurrentStep(): Observable<number> {
    return of(this.getRegistrationData()?.step! || 1);
  }

  loadData(): void {
    this.items$ = this.getStepItems();
    this.currentStep$ = this.getCurrentStep();
  }

  updateApplicantForm(applicant: IApplicant): void {
    this.selectedNationality = applicant.app_nationality;
    this.selectedGender = applicant.app_gender!;
    this.applicant = applicant;
    this.matriculeValue = applicant.app_matricule!;
    this.studentBirthPlace = applicant.app_birthplace!;
    this.studentLastName = applicant.app_lastname!;
    this.studentFirstName = applicant.app_firstname!;

    const dateFormted = new Date(applicant.app_birthdate?.toString()!);

    this.editForm.patchValue({
      app_matricule: applicant.app_matricule,
      app_lastname: applicant.app_lastname,
      app_firstname: applicant.app_firstname,
      app_birthdate: dateFormted,
      app_birthplace: applicant.app_birthplace,
      app_gender: applicant.app_gender,
      app_nationality: applicant.app_nationality,
      app_email: applicant.app_email,
      app_phone: applicant.app_phone,
      app_authcode: applicant.app_authcode,
      app_authcode_confirm: applicant.app_authcode,
    });

    if (applicant.app_birthdate) this.handleDateChange();
  }

  // =========================================

  loadStep2(): void {
    this.getActiveApplicant();
    this.getApplicantChoices();
    this.getActiveYear();

    // if (
    //   choice.length <= 0 &&
    //   this.tempChoices?.length <= 0
    // ) {
    //   this.setDobUserDataToStorage([]);
    // } else {
    //   this.setDobUserDataToStorage();
    // }
  }

  getActiveApplicant(): void {
    this.applicant = this.getRegistrationData().applicant;
  }

  getApplicantChoices(): void {
    const foundChoices = this.getRegistrationData().choice;
    this.choices = [...foundChoices];
    this.tempChoices = [...foundChoices];
  }

  getApplicantChoices1(applicant_id: string): void {
    this.isLoading = true;
    this.dobService.getChoiceByApplicant(applicant_id).subscribe((res) => {
      const foundChoices = res.body || [];
      this.choices = [...foundChoices];
      this.tempChoices = [...foundChoices];
      this.isLoading = false;
      if (
        foundChoices.length !== 0 &&
        this.getRegistrationData().choice &&
        this.getRegistrationData().choice.length === 0
      ) {
        this.choices = foundChoices;
        this.tempChoices = this.choices;

        this.setDobUserDataToStorage();
      } else {
        this.loadChoicesFromSS();
      }
    });
  }

  loadChoicesFromSS(): void {
    this.dobService.getChoices().subscribe((choices) => {
      this.choices = choices;
      this.tempChoices = choices;

      if (
        this.tempChoices == null ||
        this.tempChoices.length === 0 ||
        (this.tempChoices &&
          this.tempChoices[0]?.chx_applicant?.app_matricule !==
            this.getRegistrationData().applicant.app_matricule)
      ) {
        this.setDobUserDataToStorage([]);
      }

      this.selectedPreferences = this.choices?.map(
        (choice) => choice.chx_preference!
      );

      this.selectedSchools = this.choices?.map(
        (choice) => choice.chx_school?.sch_id!
      );

      this.selectedStudyfields = this.choices?.map(
        (choice) => choice.chx_studyfield?.sfd_id!
      );

      this.selectedChoices = {
        preference: this.selectedPreferences,
        school: this.selectedSchools,
        studyfield: this.selectedStudyfields,
      };
    });
  }

  getActiveYear(): void {
    this.yearService.geActiveYear().subscribe((year) => (this.year = year));
  }

  showDialog(): void {
    this.displayDialog = true;
  }

  save(): void {
    this.isSaving = true;
    this.displayDialog = false;
    let data = this.getRegistrationData();
    let applicantBody = data.applicant;
    // applicantBody.app_birthdate = moment(applicantBody.app_birthdate).utc();

    this.dobService.saveApplicant(applicantBody).subscribe(
      (next) => {
        const createdApplicant = next.body;
        if (createdApplicant) {
          const body = {
            choices: data.choice,
            applicant_id: createdApplicant?.app_id!,
            session_id: this.year?.yrs_id!,
          };
          this.dobService.addChoices(body).subscribe((res) => {
            this.dobService
              .addDobUserRegistration({
                applicant_ref: createdApplicant?.app_matricule!,
              })
              .subscribe((res) => {
                this.showSuccessResponse();
              });
          });
        }
      },
      (err) => {
        this.isSaving = false;
      }
    );
  }

  showSuccessResponse(): void {
    this.messageService.add({
      key: 'DOB_CHOICE_ALERT',
      severity: 'success',
      detail: "Vos choix d'orientation ont été enregistrés avec succès",
    });
    setTimeout(() => {
      this.goToHomePage();
      this.storage.removeItem(DOB_USER_ITEM_KEY);
    }, 2000);
  }

  goToHomePage(): void {
    this.isSaving = false;
    this.router.navigate([
      '/',
      'dashboard',
      'dob',
      'user-choices-registrations',
    ]);
  }

  isChoicesMinOk(): boolean {
    return this.tempChoices?.length >= MIN_CHOICE_NUMBER;
  }

  getPreferenceOrderLabel(preference: number): string {
    return getPreferenceLabel(preference);
  }

  addChoice(event: IChoice): void {
    this.tempChoices = [...this.tempChoices, event];
    this.loadData();
    this.setDobUserDataToStorage();
  }

  removeChoice(choice: Choice): void {
    this.tempChoices = this.getRegistrationData().choice;
    const temp = this.tempChoices?.filter(
      (item) => item.chx_preference !== choice.chx_preference
    );

    const newTempChoices: Choice[] = [];

    for (let index = 0; index < temp.length; index++) {
      const element = temp[index];
      element.chx_preference = index + 1;
      newTempChoices.push(element);
    }

    // this.tempChoices = [...newTempChoices];
    this.setDobUserDataToStorage(newTempChoices);
    this.loadPage();
  }

  setDobUserDataToStorage(choices?: IChoice[]) {
    let data = this.getRegistrationData();
    data.choice = choices ? choices : this.tempChoices;
    this.storage.setItem(DOB_USER_ITEM_KEY, JSON.stringify(data));
  }

  reorderIndex(choices: Choice[]): void {
    for (let index = 0; index < choices.length; index++) {
      const element = choices[index];
      const preference = index + 1;
      element.chx_preference = preference;
    }
    this.setDobUserDataToStorage();
  }

  reorderChoice(event: CdkDragDrop<string[]>) {
    this.tempChoices = this.getRegistrationData().choice;
    moveItemInArray(this.tempChoices, event.previousIndex, event.currentIndex);
    this.reorderIndex(this.tempChoices);
  }
}
