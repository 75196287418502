import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FreeCandidateService } from 'src/app/services/free-candidate.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-bank-confirmation',
  templateUrl: './dashboard-bank-confirmation.component.html',
  styleUrls: ['./dashboard-bank-confirmation.component.scss']
})
export class DashboardBankConfirmationComponent implements OnInit {

  studentBankToLocalStorage:any;
  @Output("prevStep") prevStep : EventEmitter<boolean> = new EventEmitter();
  urlDocument: any;
  base64String: any;
  pdfReceived!: string;
  isLoader: boolean = false;
  displayDialog: boolean = false;

  constructor(
    private freeCandidateService: FreeCandidateService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.studentBankToLocalStorage = JSON.parse(localStorage.getItem("bankStudent") || '{}');
    // let student :any = this.student;
    console.table("getStudentBankToLocalStorage", this.studentBankToLocalStorage);
  }

  onPrevStep(){
    this.prevStep.emit();
  }

  finishInscriptionBank(){
    localStorage.removeItem("InfoBankStudent");
    localStorage.removeItem("bankStudent");
    this.router.navigate(['/dashboard', 'bank', 'home']);

    // Reload page
    this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/dashboard', 'bank', 'home']);
    }); 
  }

  handleSuccess(): void {
    this.isLoader = true;
    this.displayDialog = false;
    this.messageService.add({
      severity: 'success',
      detail: 'Informations enregistrées avec succèss',
    });
    // this.onClickDownloadPdf();
    setTimeout(() => {
      this.finishInscriptionBank();
    }, 1500);
  }

  showDialog() {
    this.displayDialog = true;
  }


  // get register received free candidate
  onGetRegisterReceived(){
    const idBankCandidate = this.studentBankToLocalStorage.fcr_id
    this.freeCandidateService.getRegisterReceived(idBankCandidate).subscribe(
      (res) => {
        this.urlDocument = res.body.url_fichier;
        this.base64String = res.body.fichier_base64;
        // this.pdfReceived = `data:application/pdf;base64,${this.base64String}`;
      },
      (err) => {
        console.log(err);
      }
    )
  }

  // download received document pdf
  downloadPdf(base64Strings:any, fileName:any) {
    this.pdfReceived = `data:application/pdf;base64,${this.base64String}`;
    const link = document.createElement("a");
    link.href = this.pdfReceived;
    link.download = `${fileName}_${this.studentBankToLocalStorage?.fcr_bankcandidate.bkc_lastname}_${this.studentBankToLocalStorage?.fcr_bankcandidate.bkc_firstname}.pdf`
    link.target = "_blank"
    link.click();
  }

  onClickDownloadPdf(){
    let base64Strings = this.base64String;
    this.downloadPdf(base64Strings,"reçu_preinscription");
  }

}
