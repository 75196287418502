import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/account/auth.service';
import { RegionalService } from 'src/app/services/regional.service';
import { SchoolService } from 'src/app/services/school.service';
import { USER_ROLES } from 'src/app/shared/constant/roles.constant';
import { IRegionalBranch } from 'src/app/shared/model/regionalbranch.model';
import { ISchool } from 'src/app/shared/model/school.model';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';
import { IRole } from '../dashboard-admin-account-configure/dashboard-admin-account-configure.component';
import { Message } from 'primeng/api';
import { IBank } from 'src/app/shared/model/bank.model';
import { BankService } from 'src/app/services/bank.service';

@Component({
  selector: 'app-dashboard-admin-account-configure-edit',
  templateUrl: './dashboard-admin-account-configure-edit.component.html',
  styleUrls: ['./dashboard-admin-account-configure-edit.component.scss'],
})
export class DashboardAdminAccountConfigureEditComponent implements OnInit {
  editForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    role: [null, [Validators.required]],
    school: [null, []],
    regionalbranch: [null, []],
    bank: [null, []],
  });

  isSaving = false;

  roles: IRole[] = USER_ROLES;
  selectedRole?: string;

  schools: ISchool[] = [];
  selectedSchool?: string;

  banks: IBank[] = [];
  selectedBank?: string;

  regionals: IRegionalBranch[] = [];
  selectedRegional?: string;

  msgs: Message[] = [];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private schoolService: SchoolService,
    private regionalService: RegionalService,
    private dialogService: DialogService,
    private bankService: BankService,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.loadEntityData();
  }

  save() {
    let school = '';
    let regional = '';
    let bank = '';
    const body: any = {};
    const email = this.editForm.controls['email'].value;
    const role = this.editForm.controls['role'].value;
    body['email'] = email;
    body['role'] = role;
    body['admin'] = true;
    body['password'] = 'password';

    if (this.isUserSchoolType(role)) {
      school = this.editForm.controls['school'].value;
      body['usr_school'] = school;
    }

    if (this.isUserRegionalType(role)) {
      regional = this.editForm.controls['regionalbranch'].value;
      body['usr_regionalbranch'] = regional;
    }

    if (this.isUserBankType(role)) {
      bank = this.editForm.controls['bank'].value;
      body['usr_bank'] = bank;
    }

    this.isSaving = true;

    this.authService.registerUser(body).subscribe((res) => {
      const result = res.body;
      this.isSaving = false;

      if (result.status === 'success') {
        this.ref.close(result.data);
      } else {
        if (result.field === 'email') {
          this.editForm.controls['email'].setErrors({ incorrect: true });
        }
        this.msgs = [
          {
            key: 'USER_CREATION_ALERT_MESSAGE',
            severity: 'error',
            detail: result?.message,
          },
        ];
      }
    });
  }

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }

  isUserSchoolType(role: string): boolean {
    return role?.toUpperCase().split('_').includes('SCHOOL');
  }

  isUserRegionalType(role: string): boolean {
    return role?.toUpperCase().split('_').includes('REGIONAL');
  }

  isUserBankType(role: string): boolean {
    return role?.toUpperCase().split('_').includes('BANK');
  }

  handleLoadUserTypeData(role: string): void {
    if (this.isUserSchoolType(role)) {
    } else if (this.isUserRegionalType(role)) {
    }
  }

  loadEntityData(): void {
    this.schoolService
      .loadSchools()
      .subscribe((res) => (this.schools = res.body || []));

    this.regionalService
      .getAllRegional()
      .subscribe((res) => (this.regionals = res.body || []));

    this.bankService
      .getBanks()
      .subscribe((res) => (this.banks = res.body || []));
  }

  closeDialog(): void {
    // this.dialogService.
    this.ref.close();
  }

  // dismissDialog(): void {
  //   this.dialog?.close((result: any) => {
  //     console.log(result);
  //   });
  // }
}
