import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { IUser } from 'src/app/account/model/user.model';
import { UserService } from 'src/app/services/user.service';
import { DashboardAdminAccountConfigureEditComponent } from '../dashboard-admin-account-configure-edit/dashboard-admin-account-configure-edit.component';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/account/auth.service';

export interface IRole {
  name: string;
  code: string;
}
@Component({
  selector: 'app-dashboard-admin-account-configure',
  templateUrl: './dashboard-admin-account-configure.component.html',
  styleUrls: ['./dashboard-admin-account-configure.component.scss'],
})
export class DashboardAdminAccountConfigureComponent implements OnInit {
  items: MenuItem[] = [];
  home: MenuItem = {};
  filteredUserAccount: any[] = [];

  modelFormRegister: boolean = false;
  isLoading: boolean = false;
  isSaving = false;
  authUser?: IUser;
  users: IUser[] = [];

  searchUser = '';

  displayCreateUser = true;

  constructor(
    private userService: UserService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  allUserAccount() {
    this.isLoading = true;
    this.userService.getAllUsers().subscribe((res) => {
      this.isLoading = false;
      this.users = res.body || [];
      this.filteredUserAccount = [...this.users];
    });
  }

  loadPage(): void {
    this.allUserAccount();
    this.authService.getAuthUser().subscribe((user) => (this.authUser = user));
  }

  // toggle modal form register
  toggleModalFormRegister() {
    this.displayCreateUser = !this.displayCreateUser;
  }

  // filter table
  handleFilter(): void {
    if (this.searchUser) {
      this.filteredUserAccount = this.users.filter(
        (data) =>
          data?.usr_school?.sch_longlabel
            ?.toLowerCase()
            .includes(this.searchUser?.toLowerCase()) ||
          data?.usr_regionalbranch?.bch_label
            ?.toLowerCase()
            .includes(this.searchUser.toLowerCase()) ||
          data?.email?.toLowerCase().includes(this.searchUser.toLowerCase()) ||
          data?.first_name
            ?.toLowerCase()
            ?.includes(this.searchUser?.toLowerCase()) ||
          data?.last_name
            ?.toLowerCase()
            ?.includes(this.searchUser?.toLowerCase())
      );
      // this.isPaginatorVisible();
    } else {
      this.filteredUserAccount = [...this.users];
    }
  }

  // .length !== 0 ? true : false
  isPaginatorVisible(): boolean {
    return !!this.filteredUserAccount;
  }

  openDialog(): void {
    const options: DynamicDialogConfig = {
      data: {},
      header: 'Nouveau utilisateur',
      modal: true,
      width: '500px',
    };
    this.dialogService
      .open(DashboardAdminAccountConfigureEditComponent, options)
      .onClose.subscribe((res: IUser) => {
        if (res.id) {
          this.messageService.add({
            key: 'USER_TOAST_MESSAGE',
            severity: 'success',
            detail: 'Enregistrement effectué avec succèes',
          });
          this.loadPage();
        }
      });
  }

  handleActivationState(user: string, event: any): void {
    const status = event.checked ? false : true;
    this.authService
      .toggleActivationState(user, { is_active: status })
      .subscribe((res) => {
        this.messageService.add({
          key: 'USER_TOAST_MESSAGE',
          severity: 'success',
          detail: 'Status mus à jour',
        });
        this.loadPage();
      });
  }
}
