import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { IUser } from 'src/app/account/model/user.model';
import { ExamregistrationService } from 'src/app/services/examregistration.service';
import { IExamRegistration } from 'src/app/shared/model/examregistration.model';
import { RegistrationService } from '../../services/registration.service';
import { IRegistration } from '../../shared/model/registration.model';
import { AuthService } from 'src/app/account/auth.service';
import { ACCESS_TOKEN_NAME } from 'src/app/shared/constant/token.constant';

@Component({
  selector: 'app-dashboard-validation',
  templateUrl: './dashboard-validation.component.html',
  styleUrls: ['./dashboard-validation.component.scss'],
})
export class DashboardValidationComponent implements OnInit {
  items: MenuItem[] = [];
  home: MenuItem = {};
  totalRegistred = 0;
  totalValidated = 0;
  totalRejected = 0;
  totalPending = 0;

  registrationTotalRegistred = 0;
  registrationTotalRejected = 0;
  registrationTotalValidated = 0;
  registrationTotalPending = 0;

  examregistrations: IExamRegistration[] = [];
  registrations: IRegistration[] = [];

  currentUser?: IUser;
  resJwt: any;
  isLoading = false;

  constructor(
    private examRegistrationService: ExamregistrationService,
    private registrationService: RegistrationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.isLoading = true;
    // this.loadExamRegistrations();

    this.resJwt = this.authService.getAuthUser();
    this.authService.getAuthUser().subscribe((user) => {
      this.currentUser = user;
      // this.loadRegistrations();
      this.loadRegistrationStatistics();
      // this.loadRegistrationStatistics();
    });
  }

  // loadExamRegistrations(): void {
  //   this.examRegistrationService.getAll().subscribe((res) => {
  //     this.examregistrations = res.body || [];
  //     this.totalRegistred = this.examregistrations.length;
  //     this.totalPending = this.examregistrations.filter(
  //       (exam) => Number(exam?.exa_validation_status) === 1
  //     ).length;
  //     this.totalRejected = this.examregistrations.filter(
  //       (exam) => Number(exam?.exa_validation_status) === 3
  //     ).length;
  //     this.totalValidated = this.examregistrations.filter(
  //       (exam) => Number(exam?.exa_validation_status) === 2
  //     ).length;
  //   });
  // }

  // loadRegistrations(): void {
  //   this.registrationService
  //     .findSchoolRegistrations(this.currentUser?.usr_school?.sch_id!)
  //     .subscribe((res) => {
  //       this.registrations = res.body || [];
  //       this.isLoading = false;
  //       this.registrationTotalRegistred = this.registrations.length;
  //       this.registrationTotalValidated = this.registrations.filter(
  //         (registration) => registration?.reg_isvalidated
  //       ).length;
  //       this.registrationTotalPending = this.registrations.filter(
  //         (registration) => registration?.reg_validationstatus == '0'
  //       ).length;
  //       this.registrationTotalRejected = this.registrations.filter(
  //         (registration) => registration?.reg_validationstatus == '2'
  //       ).length;
  //       this.registrationTotalValidated = this.registrations.filter(
  //         (registration) => registration?.reg_validationstatus == '1'
  //       ).length;
  //     });
  // }

  loadRegistrationStatistics(): void {
    this.registrationService
      .getSchoolRegistrationStatistics(this.currentUser?.usr_school?.sch_id!)
      .subscribe((res) => {
        this.isLoading = false;
        const data = res.body?.data!;
        this.registrationTotalRegistred = data['registrated'];
        this.registrationTotalValidated = data['validate'];
        this.registrationTotalPending = data['pending'];
        this.registrationTotalRejected = data['rejected'];
      });
  }
}
