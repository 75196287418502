import { IRole } from 'src/app/dashboard/dashboard-admin-account-configure/dashboard-admin-account-configure.component';

export const USER_ROLES: IRole[] = [
  {
    name: 'ROLE ADMIN',
    code: 'ROLE_ADMIN',
  },
  {
    name: 'ROLE USER',
    code: 'ROLE_USER',
  },
  {
    name: 'ROLE SCHOOL ADMIN',
    code: 'ROLE_SCHOOL_ADMIN',
  },
  {
    name: 'ROLE SCHOOL USER',
    code: 'ROLE_SCHOOL_USER',
  },
  {
    name: 'ROLE SUPERVISOR ADMIN',
    code: 'ROLE_SUPERVISOR_ADMIN',
  },
  {
    name: 'ROLE SUPERVISOR USER',
    code: 'ROLE_SUPERVISOR_USER',
  },
  {
    name: 'ROLE REGIONAL ADMIN',
    code: 'ROLE_REGIONAL_ADMIN',
  },
  {
    name: 'ROLE REGIONAL USER',
    code: 'ROLE_REGIONAL_USER',
  },
  {
    name: 'ROLE DOB ADMIN',
    code: 'ROLE_DOB_ADMIN',
  },
  {
    name: 'ROLE DOB USER',
    code: 'ROLE_DOB_USER',
  },
  {
    name: 'ROLE BANK ADMIN',
    code: 'ROLE_BANK_USER',
  },
  {
    name: 'ROLE BANK USER',
    code: 'ROLE_BANK_USER',
  },
];
