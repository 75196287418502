<div class="dob-main-content-wrapper">
    <div class="dob-main-content-header">
      <p class="dob-main-content-title">Vérification</p>
      <p class="dob-main-content-sub-title">
        Veuillez saisir votre numéro matricule MENA
      </p>
  
      <small>
        <span class="color-danger">(*) </span>
        Champ obligatoire
      </small>
    </div>
  
    <p-messages
      [(value)]="orientationMSG"
      [closable]="false"
      [escape]="false"
    ></p-messages>
  
    <form [formGroup]="editForm" style="margin-top: 2rem">
      <!-- Session-->
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_session">Session </label>
        <input
          type="text"
          pInputText
          [(ngModel)]="session"
          formControlName="session"
          [readonly]="true"
          id="field_session"
        />
      </div>
  
      <!-- matricule -->
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_matricule"
          >Matricule
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["matricule"])
          }}</span>
        </label>
        <p-inputMask
          mask="99999999a"
          formControlName="matricule"
          [ngModel]="matriculeValue"
          id="field_matricule"
          placeholder="_________"
          [maxlength]="9"
          (ngModelChange)="matriculeValue = $event?.toUpperCase()"
          [autoClear]="false"
          (keydown.enter)="handleKeyUp()"
        >
        </p-inputMask>
        <small
          *ngIf="
            editForm.get('matricule')?.errors &&
            (editForm.get('matricule')?.errors)!['required'] &&
            (editForm.controls['matricule'].dirty ||
              editForm.controls['matricule'].touched)
          "
          class="p-field-help"
          id="field_matricule-help"
          >Ce champ est obligatoire.</small
        >
        <small
          *ngIf="
            editForm.get('matricule')?.errors &&
            (editForm.get('matricule')?.errors)!['pattern']
          "
          class="p-field-help"
          id="field_matricule-help"
          >Format du matricule non reconnu: (Ex: 0000000X)</small
        >
      </div>
  
      <div class="p-field p-field-actions loader">
        <div class="search-loader">
          <!-- <img src="/assets/loader.gif" alt="Loader" *ngIf="isLoading" /> -->
        </div>
  
        <div></div>
        <div style="float: right">
          <button
            pButton
            pRipple
            type="button"
            label="Annuler"
            icon="pi pi-arrow-left"
            [disabled]="isLoading"
            class="p-button-secondary p-button-text"
            [routerLink]="['/']"
          ></button>
          <button
            [disabled]="
              editForm.invalid ||
              editForm.controls['matricule']?.value! == null ||
              isSaving ||
              isLoading
            "
            pButton
            type="button"
            (click)="searchApplicant()"
          >
            <span *ngIf="isLoading"
              ><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i
            ></span>
            <span *ngIf="!isLoading">Vérifier et Continuer</span>
          </button>
        </div>
      </div>
    </form>
  </div>
  
  <p-dialog
    header="Code d'autorisation"
    [(visible)]="displayAuthCode"
    [style]="{ width: '400px' }"
    [modal]="true"
  >
    <div>
      <p-messages [(value)]="authCodeMSG"></p-messages>
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_authcode"
          >Code confidentiel
        </label>
        <input
          type="password"
          pInputText
          [(ngModel)]="authCode"
          id="field_authcode"
          placeholder="Entre votre code confidentiel"
          size="4"
          inputmode="numeric"
          pattern="[0-9]{4}"
          maxlength="4"
          (keydown.enter)="handleCodeKeyUp()"
        />
      </div>
  
      <div class="p-field p-field-column">
        <!-- <button
          [disabled]="isAuthChecked || authCode == ''"
          (click)="checkAuthCode()"
          pButton
          icon
          type="button"
          label="Vérifier"
        ></button> -->
  
        <button
          [disabled]="isAuthChecked || authCode == ''"
          (click)="checkAuthCode()"
          pButton
          class="p-button"
        >
          <span *ngIf="isAuthChecked"
            ><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i
          ></span>
          <span *ngIf="!isAuthChecked">Vérifier</span>
        </button>
      </div>
    </div>
  </p-dialog>
  