<div class="registration-content-wrapper">
    <div class="registration-content-item">
      <div class="registration-content-item-header">
        <h3>Informations de l'apprenant</h3>
        <p>Veuillez remplir tous les champs</p>
        <p-messages [(value)]="msgs" key="alertMessage"></p-messages>
      </div>
  
      <form [formGroup]="editForm">
        <p-toast key="studentInfoEdited"></p-toast>
  
        <h4>
          <span class="color-danger">(*) </span>
          Champs obligatoires
        </h4>
  
        <div class="form-section">
          <p-divider align="right">
            <span class="p-tag">
              <i class="pi pi-user"></i>
              Information apprenant
            </span>
          </p-divider>
  
          <div class="p-fielf p-field-image">
            <div>
              <p-image
                *ngIf="
                  student?.std_picture! || studentPictureURL;
                  else defaultpicture
                "
                [src]="studentPictureURL"
                alt="Image"
                width="160"
                height="210"
                [style]="{ 'object-fit': 'cover' }"
              ></p-image>
  
              <ng-template #defaultpicture>
                <p-image
                  src="/assets/default_picture.png"
                  alt="Image"
                  width="160"
                  height="210"
                  [style]="{ 'object-fit': 'cover' }"
                ></p-image>
              </ng-template>
            </div>
  
            <div class="p-field-image__description">
              <div style="display: flex; align-items: center">
                <input
                  type="file"
                  name="myfile[]"
                  (change)="onFileChange($event)"
                  #fileInput
                  style="display: none"
                />
                <button
                  *ngIf="studentData?.founded!"
                  pButton
                  pRipple
                  type="button"
                  label="Charger votre photo"
                  class="p-button p-button-sm"
                  (click)="fileInput.click()"
                  style="margin-right: 1rem"
                ></button>
  
                <button
                  *ngIf="!studentData?.founded!"
                  pButton
                  pRipple
                  type="button"
                  label="Charger votre photo"
                  class="p-button p-button-sm"
                  (click)="fileInput.click()"
                  style="margin-right: 1rem"
                ></button>
  
                <span class="color-danger">{{
                  fieldIsRequired(editForm.controls["std_picture"])
                }}</span>
  
                <!-- <button
                  pButton
                  pRipple
                  [disabled]="!selectedFile || !isFileValid"
                  class="p-button p-button-sm"
                  (click)="onFileUpload()"
                >
                  Sauvegarder
                </button> -->
                <img src="/assets/loader.gif" alt="Loadder" *ngIf="isUploaded" />
              </div>
              <div
                *ngIf="selectedFile"
                style="
                  display: flex;
                  text-align: left;
                  align-items: center;
                  margin-top: 10px;
                "
              >
                <p style="word-break: break-all">{{ selectedFile?.name }}</p>
                <!-- <span
                  class="p-link"
                  (click)="removeSelectedFile(fileInput)"
                  style="margin-left: 2rem"
                  >X</span
                > -->
              </div>
  
              <ul>
                <li>
                  <small
                    *ngIf="
                      editForm.get('std_picture')?.errors &&
                      (editForm.get('std_picture')?.errors)!['required'] &&
                      (editForm.controls['std_picture'].dirty ||
                        editForm.controls['std_picture'].touched)
                    "
                    class="p-field-help"
                    id="field_std_picture-help"
                    >Ce champ est obligatore.</small
                  >
                </li>
                <li>
                  - Assurez-vous que le fichier ne dépasse pas {{ imgSize }} Mb
                  <span *ngIf="isImageSizeOK">
                    -
                    <i
                      class="pi pi-check"
                      style="color: #519259; font-size: 1.7rem"
                    ></i
                  ></span>
  
                  <span
                    *ngIf="
                      !isImageSizeOK &&
                      (student?.std_picture! || studentPictureURL)
                    "
                  >
                    - <i class="pi pi-minus" style="color: #e63946"></i
                  ></span>
                </li>
                <li>
                  - Le fichier doit faire {{ imgWidth }} x {{ imgHeight }} pixels
                  <span *ngIf="isImageDimOK">
                    -
                    <i
                      class="pi pi-check"
                      style="color: #519259; font-size: 1.7rem"
                    ></i
                  ></span>
  
                  <span
                    *ngIf="
                      !isImageDimOK &&
                      (student?.std_picture! || studentPictureURL)
                    "
                  >
                    - <i class="pi pi-minus" style="color: #e63946"></i
                  ></span>
                </li>
              </ul>
            </div>
          </div>
  
          <div class="p-flex-between">
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_menaref"
                  >Matricule MENA
                  <span class="color-danger">{{
                    fieldIsRequired(editForm.controls["std_menaref"])
                  }}</span>
                </label>
                <input
                  type="text"
                  pInputText
                  formControlName="std_menaref"
                  id="field_std_menaref"
                  placeholder="Matricule"
                  [readonly]="true"
                />
                <small class="p-field-help" id="field_std_menaref-help"
                  >&nbsp;</small
                >
              </div>
            </div>
          </div>
  
          <div class="p-flex-between">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_lastname"
                >Nom
                <span class="color-danger">{{
                  fieldIsRequired(editForm.controls["std_lastname"])
                }}</span>
              </label>
              <input
                type="text"
                pInputText
                formControlName="std_lastname"
                id="field_lastname"
                placeholder="Votre nom"
                [ngModel]="studentLastName"
                (ngModelChange)="studentLastName = $event?.toUpperCase()"
              />
              <small
                *ngIf="
                  editForm.get('std_lastname')?.errors &&
                  (editForm.get('std_lastname')?.errors)!['required'] &&
                  (editForm.controls['std_lastname'].dirty ||
                    editForm.controls['std_lastname'].touched)
                "
                class="p-field-help"
                id="field_std_lastname-help"
                >Ce champ est obligatore.</small
              >
              <small class="p-field-help" id="field_std_lastname-help"
                >&nbsp;</small
              >
            </div>
  
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_firstname"
                  >Prénoms
                  <span class="color-danger">{{
                    fieldIsRequired(editForm.controls["std_firstname"])
                  }}</span>
                </label>
                <input
                  type="text"
                  pInputText
                  formControlName="std_firstname"
                  id="field_std_firstname"
                  placeholder="Votre prénom"
                  [ngModel]="studentFirstName"
                  (ngModelChange)="studentFirstName = $event?.toUpperCase()"
                />
                <small
                  *ngIf="
                    editForm.get('std_firstname')?.errors &&
                    (editForm.get('std_firstname')?.errors)!['required'] &&
                    (editForm.controls['std_firstname'].dirty ||
                      editForm.controls['std_firstname'].touched)
                  "
                  class="p-field-help"
                  id="field_std_firstname-help"
                  >Ce champ est obligatore.</small
                >
                <small class="p-field-help" id="field_std_firstname-help"
                  >&nbsp;</small
                >
              </div>
            </div>
          </div>
  
          <div class="p-flex-between">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_std_birthdate"
                >Date de naissance
                <span class="color-danger">{{
                  fieldIsRequired(editForm.controls["std_birthdate"])
                }}</span>
              </label>
              <input
                type="date"
                pInputText
                formControlName="std_birthdate"
                id="field_std_birthdate"
                placeholder="Votre date de naissance"
                min=""
                (change)="handleDateChange()"
              />
              <!-- <p-calendar
                formControlName="std_birthdate"
                [showIcon]="true"
                inputId="field_std_birthdate"
                dateFormat="dd/mm/yy"
                placeholder="Date de naissance"
              >
              </p-calendar> -->
              <small
                *ngIf="
                  editForm.get('std_birthdate')?.errors &&
                  (editForm.get('std_birthdate')?.errors)!['required'] &&
                  (editForm.controls['std_birthdate'].dirty ||
                    editForm.controls['std_birthdate'].touched)
                "
                class="p-field-help"
                id="field_std_birthdate-help"
                >Ce champ est obligatore.</small
              >
              <small
                *ngIf="
                  !minBirthDateAllowed && editForm.controls['std_birthdate'].value
                "
                class="p-field-help"
                id="field_std_birthdate-help"
                >L'age minimum pour rentrer à l'ETFP est de 10 ans</small
              >
              <small class="p-field-help" id="field_std_birthdate-help"
                >&nbsp;</small
              >
            </div>
  
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_birthplace"
                  >Lieu de naissance
                  <span class="color-danger">{{
                    fieldIsRequired(editForm.controls["std_birthplace"])
                  }}</span>
                </label>
                <input
                  type="text"
                  pInputText
                  formControlName="std_birthplace"
                  id="field_std_birthplace"
                  placeholder="Votre lieu de naissance"
                  [ngModel]="studentBirthPlace"
                  (ngModelChange)="studentBirthPlace = $event?.toUpperCase()"
                />
                <small
                  *ngIf="
                    editForm.get('std_birthplace')?.errors &&
                    (editForm.get('std_birthplace')?.errors)!['required'] &&
                    (editForm.controls['std_birthplace'].dirty ||
                      editForm.controls['std_birthplace'].touched)
                  "
                  class="p-field-help"
                  id="field_std_birthplace-help"
                  >Ce champ est obligatore.</small
                >
                <small class="p-field-help" id="field_std_birthplace-help"
                  >&nbsp;</small
                >
              </div>
            </div>
          </div>
  
          <div class="p-flex-between">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_std_gender"
                >Genre
                <span class="color-danger">{{
                  fieldIsRequired(editForm.controls["std_gender"])
                }}</span>
              </label>
              <p-dropdown
                id="field_std_gender"
                [options]="genders"
                [(ngModel)]="selectedGender"
                optionLabel="name"
                optionValue="code"
                formControlName="std_gender"
                placeholder="Selectionner votre genre"
              >
              </p-dropdown>
              <small
                *ngIf="
                  editForm.get('std_gender')?.errors &&
                  (editForm.get('std_gender')?.errors)!['required'] &&
                  (editForm.controls['std_gender'].dirty ||
                    editForm.controls['std_gender'].touched)
                "
                class="p-field-help"
                id="field_std_gender-help"
                >Ce champ est obligatore.</small
              >
              <small class="p-field-help" id="field_std_gender-help"
                >&nbsp;</small
              >
            </div>
  
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_nationality"
                  >Nationalité
                  <span class="color-danger">{{
                    fieldIsRequired(editForm.controls["std_nationality"])
                  }}</span>
                </label>
                <!-- <input
                  type="text"
                  pInputText
                  formControlName="nationality"
                  id="field_nationality"
                  placeholder="Votre nationalité"
                /> -->
                <p-dropdown
                  id="field_std_nationality"
                  [options]="nationalities"
                  [(ngModel)]="selectedNationality"
                  optionLabel="nat_label"
                  formControlName="std_nationality"
                  placeholder="Selectionner votre nationalité"
                >
                </p-dropdown>
                <small
                  *ngIf="
                    editForm.get('std_nationality')?.errors &&
                    (editForm.get('std_nationality')?.errors)!['required'] &&
                    (editForm.controls['std_nationality'].dirty ||
                      editForm.controls['std_nationality'].touched)
                  "
                  class="p-field-help"
                  id="field_std_nationality-help"
                  >Ce champ est obligatore.</small
                >
                <small
                  *ngIf="!editForm.get('std_nationality')?.errors"
                  class="p-field-help"
                  id="field_std_nationality-help"
                  >&nbsp;</small
                >
              </div>
            </div>
          </div>
  
          <div class="p-flex-between">
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_birthdocnumber"
                  >Numéro de l'acte de naissance
                  <span class="color-danger">{{
                    fieldIsRequired(editForm.controls["std_birthdocnumber"])
                  }}</span>
                </label>
                <input
                  type="text"
                  pInputText
                  formControlName="std_birthdocnumber"
                  id="field_std_birthdocnumber"
                  placeholder="Votre extrait d'acte de naissance"
                />
                <small
                  *ngIf="
                    editForm.get('std_birthdocnumber')?.errors &&
                    (editForm.get('std_birthdocnumber')?.errors)!['required'] &&
                    (editForm.controls['std_birthdocnumber'].dirty ||
                      editForm.controls['std_birthdocnumber'].touched)
                  "
                  class="p-field-help"
                  id="field_std_birthdocnumber-help"
                  >Ce champ est obligatore.</small
                >
                <small class="p-field-help" id="field_std_birthdocnumber-help"
                  >&nbsp;</small
                >
              </div>
            </div>
  
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_birthdoclocation"
                  >Lieu d'établissement de l'acte
                  <span class="color-danger">{{
                    fieldIsRequired(editForm.controls["std_birthdoclocation"])
                  }}</span>
                </label>
                <input
                  type="text"
                  pInputText
                  formControlName="std_birthdoclocation"
                  id="field_std_birthdoclocation"
                  placeholder="Lieu d'établissement de l'acte"
                  [ngModel]="studentBirthDocPlace"
                  (ngModelChange)="studentBirthDocPlace = $event?.toUpperCase()"
                />
                <small
                  *ngIf="
                    editForm.get('std_birthdoclocation')?.errors &&
                    (editForm.get('std_birthdoclocation')?.errors)!['required'] &&
                    (editForm.controls['std_birthdoclocation'].dirty ||
                      editForm.controls['std_birthdoclocation'].touched)
                  "
                  class="p-field-help"
                  id="field_std_birthdoclocation-help"
                  >Ce champ est obligatore.</small
                >
                <small class="p-field-help" id="field_std_birthdoclocation-help"
                  >&nbsp;</small
                >
              </div>
            </div>
  
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_birthdocdate"
                  >Date d'établissement de l'acte
                  <span class="color-danger">{{
                    fieldIsRequired(editForm.controls["std_birthdocdate"])
                  }}</span>
                </label>
                <input
                  type="date"
                  pInputText
                  formControlName="std_birthdocdate"
                  inputId="field_std_birthdocdate"
                  placeholder="Date d'établissement"
                />
                <!-- <p-calendar
                  formControlName="std_birthdocdate"
                  [showIcon]="true"
                  inputId="field_std_birthdocdate"
                  dateFormat="dd/mm/yy"
                  placeholder="Date d'établissement"
                >
                </p-calendar> -->
                <small
                  *ngIf="
                    editForm.get('std_birthdocdate')?.errors &&
                    (editForm.get('std_birthdocdate')?.errors)!['required'] &&
                    (editForm.controls['std_birthdocdate'].dirty ||
                      editForm.controls['std_birthdocdate'].touched)
                  "
                  class="p-field-help"
                  id="field_std_birthdocdate-help"
                  >Ce champ est obligatore.</small
                >
                <small class="p-field-help" id="field_std_birthdocdate-help"
                  >&nbsp;</small
                >
              </div>
            </div>
          </div>
  
          <div class="p-flex-between">
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_email"
                  >Votre email ou celui d'un parent
                  <span class="color-danger">{{
                    fieldIsRequired(editForm.controls["std_email"])
                  }}</span>
                </label>
                <input
                  type="email"
                  pInputText
                  formControlName="std_email"
                  id="field_std_email"
                  placeholder="Votre email"
                />
                <small
                  *ngIf="
                    editForm.get('std_email')?.errors &&
                    (editForm.get('std_email')?.errors)!['required'] &&
                    (editForm.controls['std_email'].dirty ||
                      editForm.controls['std_email'].touched)
                  "
                  class="p-field-help"
                  id="field_std_email-help"
                  >Ce champ est obligatore.</small
                >
                <small
                  *ngIf="
                    editForm.get('std_email')?.errors &&
                    (editForm.get('std_email')?.errors)!['email'] &&
                    (editForm.controls['std_email'].dirty ||
                      editForm.controls['std_email'].touched)
                  "
                  class="p-field-help"
                  id="field_std_email-help"
                  >Ce champ est doit être un email valide</small
                >
                <small class="p-field-help" id="field_std_email-help"
                  >&nbsp;</small
                >
              </div>
            </div>
  
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_phone1"
                  >Téléphone 1
                  <span class="color-danger">{{
                    fieldIsRequired(editForm.controls["std_phone1"])
                  }}</span>
                </label>
                <!-- <input
                  type="text"
                  pInputText
                  formControlName="std_phone1"
                  id="field_std_phone1"
                  placeholder="Téléphone 1"
                /> -->
                <p-inputMask
                  mask="9999999999"
                  formControlName="std_phone1"
                  id="field_std_phone1"
                  placeholder="__________"
                  [autoClear]="false"
                >
                </p-inputMask>
                <small
                  *ngIf="
                    editForm.get('std_phone1')?.errors &&
                    (editForm.get('std_phone1')?.errors)!['required'] &&
                    (editForm.controls['std_phone1'].dirty ||
                      editForm.controls['std_phone1'].touched)
                  "
                  class="p-field-help"
                  id="field_std_phone1-help"
                  >Ce champ est obligatore.</small
                >
                <small
                  *ngIf="
                    editForm.get('std_phone1')?.errors &&
                    (editForm.get('std_phone1')?.errors)!['pattern'] &&
                    (editForm.controls['std_phone1'].dirty ||
                      editForm.controls['std_phone1'].touched)
                  "
                  class="p-field-help"
                  id="field_std_phone1-help"
                  >Le numéro de téléphone incorrecte.</small
                >
                <small class="p-field-help" id="field_std_phone1-help"
                  >&nbsp;</small
                >
              </div>
            </div>
  
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_phone2"
                  >Téléphone 2
                </label>
                <!-- <input
                  type="text"
                  pInputText
                  formControlName="std_phone2"
                  id="field_std_phone2"
                  placeholder="Téléphone 2"
                /> -->
                <p-inputMask
                  mask="9999999999"
                  formControlName="std_phone2"
                  id="field_std_phone2"
                  placeholder="__________"
                  [autoClear]="false"
                >
                </p-inputMask>
                <small
                  *ngIf="
                    editForm.get('std_phone2')?.errors &&
                    (editForm.get('std_phone2')?.errors)!['pattern'] &&
                    (editForm.controls['std_phone2'].dirty ||
                      editForm.controls['std_phone2'].touched)
                  "
                  class="p-field-help"
                  id="field_std_phone1-help"
                  >Le numéro de téléphone incorrecte.</small
                >
                <!-- <p-inputMask
                  mask="+225 99 99 99 99 99"
                  formControlName="std_phone2"
                  id="field_phone1"
                  placeholder="+225 99 99 99 99 99"
                  [autoClear]="false"
                  [size]="19"
                  [minlength]="10"
                >
                </p-inputMask> -->
                <small class="p-field-help" id="field_std_phone2-help"
                  >&nbsp;</small
                >
              </div>
            </div>
          </div>
        </div>
  
        <!-- Parent info -->
  
        <div class="form-section">
          <p-divider align="right">
            <span class="p-tag">
              <i class="pi pi-user"></i>
              Informations parents
            </span>
          </p-divider>
  
          <div class="p-flex-between">
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_fatherfullname"
                  >Nom et prénoms du père
                  <span class="color-danger">{{
                    fieldIsRequired(editForm.controls["std_fatherfullname"])
                  }}</span>
                </label>
                <input
                  type="text"
                  pInputText
                  formControlName="std_fatherfullname"
                  id="field_std_fatherfullname"
                  placeholder="Nom et prénoms du père"
                  [ngModel]="studentFatherName"
                  (ngModelChange)="studentFatherName = $event?.toUpperCase()"
                />
                <small
                  *ngIf="
                    editForm.get('std_fatherfullname')?.errors &&
                    (editForm.get('std_fatherfullname')?.errors)!['required'] &&
                    (editForm.controls['std_fatherfullname'].dirty ||
                      editForm.controls['std_fatherfullname'].touched)
                  "
                  class="p-field-help"
                  id="field_std_fatherfullname-help"
                  >Ce champ est obligatore.</small
                >
                <small class="p-field-help" id="field_std_fatherfullname-help"
                  >&nbsp;</small
                >
              </div>
            </div>
  
            <div class="p-field p-field-column">
              <div class="p-field p-field-column">
                <label class="p-field-label" for="field_std_motherfullname"
                  >Nom et prénoms de la mère
                  <span class="color-danger">{{
                    fieldIsRequired(editForm.controls["std_motherfullname"])
                  }}</span>
                </label>
                <input
                  type="text"
                  pInputText
                  formControlName="std_motherfullname"
                  id="field_std_motherfullname"
                  placeholder="Nom et prénoms de la mère"
                  [ngModel]="studentMotherName"
                  (ngModelChange)="studentMotherName = $event?.toUpperCase()"
                />
                <small
                  *ngIf="
                    editForm.get('std_motherfullname')?.errors &&
                    (editForm.get('std_motherfullname')?.errors)!['required'] &&
                    (editForm.controls['std_motherfullname'].dirty ||
                      editForm.controls['std_motherfullname'].touched)
                  "
                  class="p-field-help"
                  id="field_std_motherfullname-help"
                  >Ce champ est obligatore.</small
                >
                <small class="p-field-help" id="field_std_motherfullname-help"
                  >&nbsp;</small
                >
              </div>
            </div>
          </div>
        </div>
  
        <div class="p-field p-field-actions loader">
          <!-- <div class="search-loader">
            <img src="/assets/loader.gif" alt="Loader" *ngIf="isSaving" />
          </div> -->
          <div></div>
          <!-- <button
              pButton
              pRipple
              type="button"
              label="Enregistrer"
              icon="pi pi-save"
              class="p-button-outlined"
              style="margin-right: auto"
              [disabled]="
                !editForm.dirty || (editForm.touched && !editForm.dirty) || loaded
              "
              (click)="edit()"
            ></button> -->
  
          <div>
            <button
              pButton
              pRipple
              type="button"
              label="Précédent"
              icon="pi pi-arrow-left"
              class="p-button-secondary p-button-text"
              (click)="goPrev()"
              [disabled]="isSaving"
            ></button>
  
            <button
              [disabled]="isSaving || editForm.invalid || loaded"
              pButton
              type="button"
              label="Enregistrer et continuer"
              (click)="openConfirmDialog()"
            ></button>
          </div>
        </div>
      </form>
  
      <!-- confirm dialog -->
      <p-dialog
        header="Confirmation"
        [(visible)]="showDialog"
        [style]="{ width: '500px' }"
        [resizable]="false"
        appendTo="body"
        [modal]="true"
      >
        <p style="display: flex; align-items: center">
          <i
            style="font-size: 2rem; color: red; margin-right: 1rem"
            class="pi pi-question-circle"
          ></i>
          Êtes-vous sûr des informations renseignées ?
        </p>
  
        <ng-template pTemplate="footer">
          <p-button
            (click)="showDialog = false"
            icon="pi pi-times"
            label="Annuler"
            styleClass="p-button-secondary p-button-text"
          ></p-button>
          <p-button
            (click)="goToNext()"
            label="Confirmer"
            styleClass="p-button"
          ></p-button>
        </ng-template>
      </p-dialog>
  
      <div class="student-info-loader" *ngIf="loaded">
        <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="registration-loader" *ngIf="isSaving">
    <p-progressSpinner></p-progressSpinner>
  </div>
  