import { Component, OnInit } from '@angular/core';
import { DobService } from 'src/app/dob/dob.service';
import { IOATValidation } from 'src/app/dob/models/validation.model';

@Component({
  selector: 'app-dashboard-regional-orientation-validation-listing',
  templateUrl:
    './dashboard-regional-orientation-validation-listing.component.html',
  styleUrls: [
    './dashboard-regional-orientation-validation-listing.component.scss',
  ],
})
export class DashboardRegionalOrientationValidationListingComponent
  implements OnInit
{
  isLoading = false;
  searchText = '';

  validations: IOATValidation[] = [];
  filterValidations: IOATValidation[] = [];
  selectedValidation?: IOATValidation;
  constructor(private dobService: DobService) {}

  ngOnInit(): void {
    this.loadPage();
  }

  refreshPage(): void {
    this.loadValidations();
  }

  loadPage(): void {
    this.loadValidations();
  }

  loadValidations(): void {
    this.isLoading = true;
    this.dobService.getValidations().subscribe((validations) => {
      this.isLoading = false;
      this.validations = validations;
      this.filterValidations = this.validations;
    });
  }

  handleSearch(): void {
    if (this.searchText) {
      const search = this.searchText.toLowerCase();
      this.filterValidations = this.validations.filter(
        (validation) =>
          validation?.oval_applicant?.app_matricule
            ?.toLowerCase()
            .includes(search) ||
          validation?.oval_applicant?.app_firstname
            ?.toLowerCase()
            .includes(search) ||
          validation?.oval_applicant?.app_lastname
            ?.toLowerCase()
            .includes(search) ||
          validation?.oval_applicant?.app_email?.toLowerCase().includes(search)
      );
    } else {
      this.filterValidations = [...this.validations];
    }
  }

  showDetail(event: any, panel: any, validation: IOATValidation): void {
    panel.toggle(event);
    this.selectedValidation = validation;
  }
}
