import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  recaptchaURL = environment.apiURL + 'api/v1/recaptcha-token-verify';

  constructor(private http: HttpClient) {}

  sendToken(body: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.recaptchaURL}/`, body, {
      observe: 'response',
    });
  }
}
