<!-- <div class="dashboard-wrapper-grid"> -->
<!-- <div class="dashboard-wrapper-grid-main"> -->
<!-- STATISTICS -->
<div class="dashboard-section">
  <p class="dashboard-title">
    Bienvenue, {{ authUser?.last_name + " " + authUser?.first_name }}
  </p>
  <!-- <div class="dashboard-validation__statistics">
    <div class="dashboard-validation__statistics-item">
      <span class="dashboard-validation__statistics-value">{{
        totalRegional
      }}</span>

      <div class="dashboard-validation__statistics-text">
        <span class="dashboard-validation__statistics-label"
          >Total direction régionale <span>enregistrés</span>
        </span>
      </div>
    </div>

    <div class="dashboard-validation__statistics-item">
      <span class="dashboard-validation__statistics-value warning">{{
        totalSchool
      }}</span>

      <div class="dashboard-validation__statistics-text">
        <span class="dashboard-validation__statistics-label"
          >Total établissement <span>enregistrés</span>
        </span>
      </div>
    </div>

    <div class="dashboard-validation__statistics-item">
      <span class="dashboard-validation__statistics-value danger">{{
        totalRegistrationForExam
      }}</span>
      <div class="dashboard-validation__statistics-text">
        <span class="dashboard-validation__statistics-label"
          >Total candidats <span>attendus</span>
        </span>
      </div>
    </div>

    <div class="dashboard-validation__statistics-item">
      <span class="dashboard-validation__statistics-value success">{{
        totalExamRegistration
      }}</span>
      <div class="dashboard-validation__statistics-text">
        <span class="dashboard-validation__statistics-label"
          >Total candidats <span>validé pour examen</span>
        </span>
      </div>
    </div>
  </div>
</div> -->

  <!-- CHART -->
  <!-- <div class="dashboard-section">
  <p class="dashboard-title">Statistics des apprenant attendus par diplôme</p>
  <ng-container *ngIf="chartOptions">
    <apx-chart
      [series]="chartOptions?.series!"
      [chart]="chartOptions?.chart!"
      [xaxis]="chartOptions?.xaxis!"
      [title]="chartOptions?.title!"
      [colors]="chartOptions?.colors!"
      type="bar"
    ></apx-chart>
  </ng-container>
  <div>
    <p-chart
      type="bar"
      [data]="registrationsStatisticData"
      [options]="basicOptions"
      width=""
      height="400px"
      [responsive]="true"
    >
    </p-chart>
  </div>
</div> -->

  <!-- <div class="dashboard-section">
  <p class="dashboard-title">Status des paiements</p>

  <div class="dashboard-section-block">
    <div class="dashboard-section-block-item">
      <p class="dashboard-section-block-item-title">
        Candidats validés
        <span class="chip chip-info">{{ paymentStatusValues[0] }}</span>
      </p>
      <ng-container *ngIf="validatedExamStudentChartOptions">
        <apx-chart
          [series]="validatedExamStudentChartOptions?.series!"
          [chart]="validatedExamStudentChartOptions?.chart!"
          [labels]="validatedExamStudentChartOptions?.labels!"
          [colors]="validatedExamStudentChartOptions?.colors!"
        ></apx-chart>
      </ng-container>
    </div>

    <div class="dashboard-section-block-item">
      <p class="dashboard-section-block-item-title">
        Paiement en cours d'initiation
        <span class="chip chip-main">{{ paymentStatusValues[1] }}</span>
      </p>
      <ng-container *ngIf="initiatedPaymentChartOptions">
        <apx-chart
          [series]="initiatedPaymentChartOptions?.series!"
          [chart]="initiatedPaymentChartOptions?.chart!"
          [labels]="initiatedPaymentChartOptions?.labels!"
          [colors]="initiatedPaymentChartOptions?.colors!"
        ></apx-chart>
      </ng-container>
    </div>

    <div class="dashboard-section-block-item">
      <p class="dashboard-section-block-item-title">
        Paiement en attente
        <span class="chip chip-warning">{{ paymentStatusValues[2] }}</span>
      </p>
      <ng-container *ngIf="pendingPaymentChartOptions">
        <apx-chart
          [series]="pendingPaymentChartOptions?.series!"
          [chart]="pendingPaymentChartOptions?.chart!"
          [labels]="pendingPaymentChartOptions?.labels!"
          [colors]="pendingPaymentChartOptions?.colors!"
        ></apx-chart>
      </ng-container>
    </div>

    <div class="dashboard-section-block-item">
      <p class="dashboard-section-block-item-title">
        Paiement effectué
        <span class="chip chip-success">{{ paymentStatusValues[3] }}</span>
      </p>
      <ng-container *ngIf="validatedPaymentChartOptions">
        <apx-chart
          [series]="validatedPaymentChartOptions?.series!"
          [chart]="validatedPaymentChartOptions?.chart!"
          [labels]="validatedPaymentChartOptions?.labels!"
          [colors]="validatedPaymentChartOptions?.colors!"
        ></apx-chart>
      </ng-container>
    </div>
  </div>
</div> -->
  <!-- </div> -->
  <!-- <div class="dashboard-section-sidebar dashboard-wrapper-grid-sidebar">
    <div class="dashboard-section">
      <p class="dashboard-title">Détails</p>
    </div>
  </div> -->
  <!-- </div> -->

  <div class="dashboard-loader" *ngIf="isLoading">
    <p-progressSpinner
      [style]="{ width: '50px', height: '50px' }"
    ></p-progressSpinner>
  </div>
</div>
