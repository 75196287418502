import {
  FormGroup,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';

// export function isRequired(form: FormGroup, key: string): void {
//   console.log(form.controls[key].validator)
// }

export const hasRequiredField = (abstractControl: AbstractControl): boolean => {
  if (abstractControl?.validator!) {
    const validator = abstractControl?.validator({} as AbstractControl);
    if (validator && validator['required']) {
      return true;
    }
  }
  return false;
};

export function humanFileSize(sizeInBytes: number): number {
  return parseFloat((sizeInBytes / (1024 * 1024)).toFixed(2));
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function matchValidator(
  matchTo: string,
  reverse?: boolean
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.parent && reverse) {
      const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
      if (c) {
        c.updateValueAndValidity();
      }
      return null;
    }
    return !!control.parent &&
      !!control.parent.value &&
      control.value === (control.parent?.controls as any)[matchTo].value
      ? null
      : { matching: true };
  };
}

// export const checkImageSizeAndDim = (selectedFile: File[]) => {
//   const _URL = window.URL || window.webkitURL;
//   let file: File, img: any;
//   const imgWidth = 332;
//   const imgHeight = 452;
//   if ((file = selectedFile[0])) {
//     img = new Image();
//     img.onload =  () => {
//       if (humanFileSize(file.size) > 2.5) {
//         // result = {
//         //   valid: false,
//         //   message: 'Le fichier ne doit pas dépasser 2.5Mb',
//         // };
//       }

//       if (img.width > imgWidth || img.height > imgHeight) {
//         // result = {
//         //   valid: false,
//         //   message: `Dimensions autorisées ${imgWidth} par ${imgHeight}`,
//         // };
//       }
//     };
//       img.onerror = () => {
//       // result = {
//       //     valid: false,
//       //     message: 'Le fichier doit être une image avec pour extension possible (jpg, png. jpeg)',
//       // };
//     };
//     img.src = _URL.createObjectURL(file);
//   }
// }
