import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IYear, Year } from '../shared/model/year.model';
import { find, map, take, takeWhile, toArray } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class YearService {
  private yearResourceURL = environment.apiURL + 'api/v1/years';
  private curYear = new BehaviorSubject<IYear>({});

  constructor(private http: HttpClient) {}

  get(): Observable<HttpResponse<IYear[]>> {
    return this.http.get<IYear[]>(`${this.yearResourceURL}/`, {
      observe: 'response',
    });
  }

  findBySession(session: string): Observable<HttpResponse<IYear>> {
    return this.http.get<IYear>(`${this.yearResourceURL}/${session}/`, {
      observe: 'response',
    });
  }

  geActiveYear(): Observable<IYear> {
    return this.get().pipe(
      map((value) => value.body?.filter((item) => item.yrs_is_active)),
      map((value) => value![0])
    );
  }
}
