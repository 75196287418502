export const statusList = [
  {
    name: 'EN ATTENTE',
    code: 0,
  },
  {
    name: 'VALIDÉ',
    code: 1,
  },
  {
    name: 'REJETÉ',
    code: 2,
  },
];
