import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { YearService } from 'src/app/services/year.service';
import { SessionStorageService } from 'src/app/session-storage.service';
import { IYear } from 'src/app/shared/model/year.model';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';
import { DobService } from '../../dob.service';
import { IApplicant } from '../../models/applicant.model';
import { StepsService } from '../../services/steps.service';

@Component({
  selector: 'app-dob-check-matricule',
  templateUrl: './dob-check-matricule.component.html',
  styleUrls: ['./dob-check-matricule.component.scss'],
})
export class DobCheckMatriculeComponent implements OnInit {
  isLoading = false;
  isContentLoaded = false;
  isSaving = false;
  isAuthChecked = false;
  matriculeValue = '';
  session = 0;
  orientationMSG: Message[] = [];
  authCodeMSG: Message[] = [];
  displayAuthCode = false;
  authCode = '';
  editForm = this.fb.group({
    matricule: [
      [],
      [Validators.required, Validators.pattern(/^\d{8}[A-Z]{1}$/)],
    ],
    session: ['', []],
  });

  year?: IYear;

  applicant?: IApplicant;
  base64File: any;
  document: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dobStepService: StepsService,
    private sessionStorage: SessionStorageService,
    private yearService: YearService,
    private dobService: DobService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.isContentLoaded = true;
    this.getApplicantRef(sessionStorage);
    this.yearService.geActiveYear().subscribe((year) => {
      this.year = year;
      this.session = this.year?.yrs_session!;
      this.isContentLoaded = false;
    });
  }

  showMessage(type: string, message: string): void {
    this.orientationMSG = [
      {
        severity: type,
        detail: message,
      },
    ];
  }

  searchApplicant(): void {
    this.isLoading = true;
    this.orientationMSG = [];
    this.dobService.checkApplicantID(this.editForm.value).subscribe((res) => {
      this.isLoading = false;
      const data = res.body;

      if (data.result === 3) {
        this.applicant = data.data;
        this.generateApplicantDocument(data.message);
      } else if (data.result === -1) {
        this.showMessage('warn', data.message);
      } else if (data.result === 2) {
        this.saveApplicantToSS(this.sessionStorage, data.result);
        this.goNext();
      } else {
        this.displayAuthCode = true;
      }
    });
  }

  handleKeyUp(): void {
    this.searchApplicant();
  }

  saveApplicantToSS(storage: Storage, state?: number): void {
    const ref = this.editForm?.controls['matricule']?.value!;
    storage.setItem('DOB_APPLICANT_REF', ref);
    if (state && state === 1) {
      storage.setItem(
        'DOB_APPLICANT_FIRST_REGISTRATION',
        JSON.stringify(false)
      );
    } else if (state && (state === -1 || state === 2)) {
      storage.setItem('DOB_APPLICANT_FIRST_REGISTRATION', JSON.stringify(true));
    }
  }

  setFoundedApplicantInfo(storage: Storage): void {
    storage.setItem('DOB_APPLICANT_FIRST_REGISTRATION', JSON.stringify(false));
  }

  fieldIsRequired(control: AbstractControl): string {
    if (hasRequiredField(control)) {
      return '*';
    }

    return '';
  }

  getApplicantRef(storage: Storage): void {
    const ref = storage.getItem('DOB_APPLICANT_REF');
    if (ref) this.matriculeValue = ref;
  }

  goNext(): void {
    this.router.navigate(['/orientation', 'applicant']);
    this.dobStepService.setNextStep(this.sessionStorage);
  }

  checkAuthCode(): void {
    this.isAuthChecked = true;
    this.dobService
      .checkAuthCode({
        app_authcode: this.authCode,
        app_matricule: this.matriculeValue,
      })
      .subscribe((res) => {
        const data = res.body;
        if (data?.result === 1) {
          setTimeout(() => {
            this.isAuthChecked = false;
            this.saveApplicantToSS(this.sessionStorage, data?.result);
            this.goNext();
          }, 1000);
        } else if (data?.result === -1) {
          this.isAuthChecked = false;
          this.authCodeMSG = [
            {
              severity: 'error',
              detail: data?.message,
            },
          ];
        }
      });
  }

  handleCodeKeyUp(): void {
    if (this.authCode) this.checkAuthCode();
  }

  generateApplicantDocument(message: string): void {
    this.isLoading = true;
    const body = {
      applicant: this.applicant?.app_id,
    };
    this.dobService.getDocument(body).subscribe((res) => {
      this.isLoading = false;
      const doc = res.body;
      this.base64File = doc;
      this.showMessage('warn', message);

      // this.orientationMSG = [
      //   {
      //     severity: 'warn',
      //     detail:
      //       message +
      //       ' ' +
      //       `Veuillez <a href="#" style="cursor: pointer; font-weight:bold" (click)="downloadDocument()">Télécharger</a> votre document de candidature`,
      //   },
      // ];
    });
  }

  downloadDocument(): void {
    this.downloadPdf(this.base64File, 'fiche_orientation');
  }

  downloadPdf(base64String: any, fileName: any) {
    const doc = `data:application/pdf;base64, ${base64String}`;
    const link = document.createElement('a');
    link.href = doc;
    link.download = `${fileName.toUpperCase()}_${this.applicant?.app_lastname?.toUpperCase()}_${this.applicant?.app_firstname?.toUpperCase()}_SESSION_${
      this.year?.yrs_session
    }.pdf`;
    link.target = '_blank';
    link.click();
  }
}
