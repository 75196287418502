<div class="validation-navbar">
  <!-- <div class="validation-navbar__search p-input-icon-left">
    <i class="pi pi-search"></i>
    <input type="text" pInputText placeholder="Recherche" />
  </div> -->
  <!-- <div>&nbsp;</div> -->
  <div
    style="
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      color: #1c2e82;
    "
  >
    <i class="pi pi-bars" style="font-size: 1.5rem; margin-right: 1rem"></i>
    {{ dashboardLabel }}
  </div>
  <ul class="validation-navbar__menu">
    <!-- <li class="validation-navbar__item">
      <a href="" class="validation-navbar__link">
        <i class="pi pi-question-circle"></i>
      </a>
    </li>
    <li class="validation-navbar__item">
      <a href="" class="validation-navbar__link">
        <i class="pi pi-bell"></i>
        <span>3</span>
      </a>
    </li> -->

    <li class="validation-navbar__dropdown">
      <span class="validation-navbar__dropdown-label">
        <span class="validation-navbar__dropdown-label-text">
          {{ currentUser?.first_name! | uppercase }} .
          <span>{{ currentUser?.role?.replace("/_/", " ") }}</span>
        </span>

        <i class="pi pi-angle-down"></i>
      </span>

      <ul class="validation-navbar__dropdown-menu">
        <!-- <li class="validation-navbar__dropdown-item">
          <a href="" class="validation-navbar__dropdown-link">
            <i class="pi pi-user"></i>
            Profile
          </a>
        </li>
        <li class="validation-navbar__dropdown-item">
          <a href="" class="validation-navbar__dropdown-link">
            <i class="pi pi-cog"></i>
            Paramètres
          </a>
        </li> -->

        <li class="validation-navbar__dropdown-item">
          <span class="validation-navbar__dropdown-link" (click)="logout()">
            <i class="pi pi-sign-out"></i>
            Déconnexion
          </span>
        </li>
      </ul>
    </li>
  </ul>
</div>
