import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FREE_CANDIDATE_ITEMS_KEY } from '../shared/constant/step.constant';
import { freeCandidateSteps } from '../shared/constant/steps.constant';
import { IExamenBreadcrumb } from '../shared/model/examen-breadcrumb.model';

interface IStep {
  step: number;
  data: IExamenBreadcrumb[];
}

@Injectable({
  providedIn: 'root',
})
export class FreeCandidateStepService {
  private steps$ = new BehaviorSubject<IExamenBreadcrumb[]>([]);
  steps = freeCandidateSteps;

  constructor() {}

  init(storage: Storage): void {
    if (!storage.getItem(FREE_CANDIDATE_ITEMS_KEY)) {
      const stepData = freeCandidateSteps;
      const currentStep = this.steps.filter((element) => element.active)[0]
        ?.step;

      storage.setItem(
        FREE_CANDIDATE_ITEMS_KEY,
        JSON.stringify({ step: currentStep, data: stepData })
      );
      this.steps$.next(stepData);
    }
  }

  getStepItems(storage: Storage): Observable<IExamenBreadcrumb[]> {
    const stepData = this.getStepData(storage);
    this.steps$.next(stepData?.data);
    return this.steps$.asObservable();
  }

  getCurrentStep(storage: Storage): number {
    return this.getStepData(storage).step;
  }

  private setItems(storage: Storage): void {
    const data =
      JSON.parse(storage.getItem(FREE_CANDIDATE_ITEMS_KEY)!).data || [];
    this.steps$.next(data);
  }

  private getStepData(storage: Storage): IStep {
    return JSON.parse(storage.getItem(FREE_CANDIDATE_ITEMS_KEY)!);
  }

  setActiveStep(storage: Storage, items: IExamenBreadcrumb[]): void {
    const currentStep = JSON.parse(
      storage.getItem(FREE_CANDIDATE_ITEMS_KEY)!
    ).step;
    items.forEach((item) => {
      if (item.step === Number(currentStep)) {
        item.active = true;
      }
    });
  }

  private nextStep(
    storage: Storage,
    step: number,
    data: IExamenBreadcrumb[]
  ): void {
    storage.setItem(
      FREE_CANDIDATE_ITEMS_KEY,
      JSON.stringify({ step: step + 1, data })
    );
  }

  private prevStep(
    storage: Storage,
    step: number,
    data: IExamenBreadcrumb[]
  ): void {
    storage.setItem(
      FREE_CANDIDATE_ITEMS_KEY,
      JSON.stringify({ step: step - 1, data })
    );
  }

  private validateStep(storage: Storage, direction: string): void {
    const { step, data } = this.getStepData(storage);
    data.forEach((item) => {
      if (item.step === Number(step) && direction === 'next') {
        item.active = false;
        item.validated = true;
      } else if (item.step === Number(step) && direction === 'prev') {
        item.active = false;
        item.validated = false;
      }
    });

    if (direction === 'next') {
      this.nextStep(storage, step, data);
    } else if (direction === 'prev') {
      this.prevStep(storage, step, data);
    }
  }

  private activeStep(storage: Storage): void {
    const { step, data } = this.getStepData(storage);
    data.forEach((item) => {
      if (item.step === Number(step)) item.active = true;
    });
    storage.setItem(FREE_CANDIDATE_ITEMS_KEY, JSON.stringify({ step, data }));
  }

  setNextStep(storage: Storage): void {
    this.validateStep(storage, 'next');
    this.activeStep(storage);
    this.setItems(storage);
  }

  setPrevStep(storage: Storage): void {
    this.validateStep(storage, 'prev');
    this.activeStep(storage);
    this.setItems(storage);
  }
}
