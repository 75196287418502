import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../account/auth.service';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class UserRoleGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let user_role = jwt_decode<any>(this.authService.token)?.role;
    if (
      user_role &&
      route.data &&
      route.data['roles'] &&
      route.data['roles'].includes(user_role?.toUpperCase())
    ) {
      return true;
    } else {
      this.authService.logout();
      // console.log("Vous n'avez pas accès");
      return false;
    }
  }
}
