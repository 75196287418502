import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExamPaymentCheckComponent } from './exam-payment-check/exam-payment-check.component';

const routes: Routes = [
  {
    path: 'payment',
    children: [
      {
        path: 'exam-registration-check',
        component: ExamPaymentCheckComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingRoutingModule {}
