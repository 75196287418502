<app-navbar></app-navbar>

<div class="registration-wrapper" #sidebar>
  <app-registration-sidebar></app-registration-sidebar>

  <div class="registration-content">
    <!-- <app-registration-header></app-registration-header> -->
    <router-outlet></router-outlet>
  </div>

  <!-- <div
    [ngClass]="{ 'registration-content': !isRegistrationFinished }"
    [style.width]="isRegistrationFinished ? '100%' : ''"
  >
    <router-outlet></router-outlet>
  </div> -->
</div>
