import { Component, OnInit } from '@angular/core';
import { SchoolService } from '../../services/school.service';
import { AuthService } from 'src/app/account/auth.service';
import { IUser } from 'src/app/account/model/user.model';
import { ILocation } from '../../shared/model/location.model';

@Component({
  selector: 'app-dashboard-schools',
  templateUrl: './dashboard-schools.component.html',
  styleUrls: ['./dashboard-schools.component.scss'],
})
export class DashboardSchoolsComponent implements OnInit {
  isLoading = false;
  locations: ILocation[] = [];
  selectedLocation: any[] = [];
  regionalSchoolsData: any[] = [];
  filteredRegionalSchoolsData: any[] = [];
  user?: IUser;
  searchSchool = '';
  constructor(
    private schoolService: SchoolService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.authService.getAuthUser().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.schoolService
          .retrieveRegionalSchoolsListData(
            this.user.usr_regionalbranch?.bch_id!
          )
          .subscribe((data) => {
            this.isLoading = false;
            this.regionalSchoolsData = data.body || [];
            this.handleFilterBySchoolNameOrDSPSCODE();

            this.getRegionalAllSchoolsLocationsList(this.regionalSchoolsData);
          });
      }
    });
  }

  handleFilterBySchoolNameOrDSPSCODE(): void {
    if (this.searchSchool) {
      this.filteredRegionalSchoolsData = this.regionalSchoolsData.filter(
        (data) => {
          if (
            data?.school?.sch_longlabel
              ?.toLowerCase()
              .includes(this.searchSchool?.toLowerCase()) ||
            data?.school?.sch_dspscode
              ?.toLowerCase()
              .includes(this.searchSchool?.toLowerCase())
          ) {
            return data;
          }
        }
      );
      this.isPaginatorVisible();
    } else {
      this.filteredRegionalSchoolsData = [...this.regionalSchoolsData];
    }
  }

  getRegionalAllSchoolsLocationsList(data: any[]): void {
    data.forEach((item) => {
      const location: ILocation = item?.school?.sch_location;

      if (!this.locations.find((loc) => loc?.loc_id === location?.loc_id)) {
        this.locations.push(location);
      }
    });
  }

  handleLocationChange(): void {
    if (this.selectedLocation.length !== 0) {
      this.filteredRegionalSchoolsData = this.regionalSchoolsData.filter(
        (data) => {
          const schoolLocation = data?.school?.sch_location;
          if (this.selectedLocation.includes(schoolLocation?.loc_id)) {
            return data;
          }
        }
      );
    } else {
      this.filteredRegionalSchoolsData = [...this.regionalSchoolsData];
    }
    this.isPaginatorVisible();
  }

  // .length !== 0 ? true : false
  isPaginatorVisible(): boolean {
    return !!this.filteredRegionalSchoolsData;
  }
}
