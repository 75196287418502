import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DobCheckMatriculeComponent } from './components/dob-check-matricule/dob-check-matricule.component';
import { DobApplicantComponent } from './components/dob-applicant/dob-applicant.component';
import { DobChoiceComponent } from './components/dob-choice/dob-choice.component';
import { DobSummaryComponent } from './components/dob-summary/dob-summary.component';
import { SharedModule } from '../shared/shared.module';
import { DobRoutingModule } from './dob-routing.module';
import { DobLayoutComponent } from './components/dob-layout/dob-layout.component';
import { DobChoiceFormComponent } from './components/dob-choice-form/dob-choice-form.component';
import { DobFinishComponent } from './components/dob-finish/dob-finish.component';

@NgModule({
  declarations: [
    DobCheckMatriculeComponent,
    DobApplicantComponent,
    DobChoiceComponent,
    DobSummaryComponent,
    DobLayoutComponent,
    DobChoiceFormComponent,
    DobFinishComponent,
  ],
  imports: [SharedModule, DobRoutingModule],
})
export class DobModule {}
