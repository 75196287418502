import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { StepService } from '../step.service';

@Component({
  selector: 'app-registration-layout',
  templateUrl: './registration-layout.component.html',
  styleUrls: ['./registration-layout.component.scss'],
})
export class RegistrationLayoutComponent implements OnInit {
  isRegistrationFinished = true;
  // processStatus$ = new Observable<boolean>();

  // @ViewChild('sidebar') sidebar: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private stepService: StepService
  ) {}
  // ngAfterViewInit(): void {
  //   Promise.resolve().then(() => {
  //     if (this.sidebar)
  //       this.processStatus$ = this.stepService.getProcessStatus();
  //     this.processStatus$.subscribe(
  //       (status) => (this.isRegistrationFinished = status)
  //     );
  //   });
  // }

  ngOnInit(): void {}

  // checkRegistrationState(): void {
  //   const status =
  //     this.router.routerState.snapshot.url
  //       .split('/')
  //       .reverse()[0]
  //       ?.toLowerCase() === 'finish';

  //   this.stepService.setProcessStatus(status);
  // }
}
