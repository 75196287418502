import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISchool } from '../shared/model/school.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map, toArray } from 'rxjs/operators';
import { getOrderType } from '../shared/utils/schooltype.utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  private schoolResourceURL = environment.apiURL + 'api/v1/schools';

  private schools$ = new BehaviorSubject<ISchool[]>([]);

  constructor(private http: HttpClient) {}

  getSchools(schoolType?: number, schoolOrder?: string): Observable<ISchool[]> {
    return this.http
      .get<ISchool[]>(`${this.schoolResourceURL}/`, {
        observe: 'response',
      })
      .pipe(
        map((value) =>
          value.body!.filter(
            (item) =>
              getOrderType(schoolOrder!, item) &&
              item.sch_type?.scty_id! == schoolType
          )
        )
      );
  }

  loadSchools(): Observable<HttpResponse<ISchool[]>> {
    return this.http.get<ISchool[]>(`${this.schoolResourceURL}/`, {
      observe: 'response',
    });
  }

  retrieveRegionalSchoolsListData(
    region_id: string
  ): Observable<HttpResponse<any[]>> {
    return this.http.get<any[]>(
      `${this.schoolResourceURL}/${region_id}/schools-data/`,
      {
        observe: 'response',
      }
    );
  }

  getSchoolsByLocation(
    studyfield_id: string,
    location_label: string
  ): Observable<ISchool[]> {
    return this.http.get<ISchool[]>(
      `${this.schoolResourceURL}/${studyfield_id}/${location_label}/`,
      { observe: 'body' }
    );
  }
}
