<p-toast key="VALIDATION_TOAST"></p-toast>

<div class="dashboard-section">
  <p class="dashboard-title">Validation des applicants</p>

  <div></div>

  <div class="validation-wrapper">
    <div class="validation-search">
      <div class="validation-search-selected">
        <button
          class="p-button p-button-icon rounded p-button-outlined"
          (click)="handleNewValidation()"
          pButton
        >
          <i class="pi pi-refresh"></i>
          Nouvelle validation
        </button>

        <p>&nbsp;</p>
      </div>

      <div class="validation-search-block">
        <p-inputMask
          mask="99999999a"
          [ngModel]="search"
          id="field_matricule"
          placeholder="_________"
          [maxlength]="9"
          (ngModelChange)="search = $event?.toUpperCase()"
          [autoClear]="false"
          [disabled]="isApplicantSelected"
          (keydown)="onKeyDown($event)"
        >
        </p-inputMask>
        <button
          [disabled]="isApplicantSelected"
          (click)="searchStudent()"
          pButton
          class="p-button"
        >
          Rechercher
        </button>
      </div>
    </div>
  </div>

  <div class="validation-content">
    <!-- Validation list -->
    <div
      class="validation-content-detail"
      *ngIf="isApplicantSelected; else noselection"
    >
      <div
        class="validation-content-detail-wrapper validation-content-detail-wrapper-right"
      >
        <div class="validation-content-detail-box">
          <div
            *ngIf="
              !validation || (validation && !validation?.oval_validationstatus);
              else novalidation
            "
          >
            <div class="validation-content-detail-box-checklist">
              <div class="validation-content-detail-box-checklist-item">
                <label for="validationSignature">Fiche de demande signée</label>
                <div class="validation-content-detail-box-checklist-item-value">
                  <p-checkbox
                    inputId="validationSignature"
                    name="validationSignature"
                    value="isSign"
                    [(ngModel)]="isSign"
                    [binary]="true"
                  ></p-checkbox>
                </div>
              </div>

              <div class="validation-content-detail-box-checklist-item">
                <label for="validationIvorian"
                  >Certificat de nationalité ou CNI d’un parents</label
                >
                <div class="validation-content-detail-box-checklist-item-value">
                  <p-checkbox
                    inputId="validationIvorian"
                    name="validationIvorian"
                    value="isIvorian"
                    [(ngModel)]="isIvorian"
                    [binary]="true"
                  ></p-checkbox>
                </div>
              </div>

              <div class="validation-content-detail-box-checklist-item">
                <label for="validationBirthDate"
                  >Extrait d’acte de naissance</label
                >
                <div class="validation-content-detail-box-checklist-item-value">
                  <p-checkbox
                    inputId="validationBirthDate"
                    name="validationBirthDate"
                    value="isBirthDateOK"
                    [(ngModel)]="isBirthDateOK"
                    [binary]="true"
                  ></p-checkbox>
                </div>
              </div>
            </div>

            <div class="validation-content-detail-box-checklist-action">
              <button
                pButton
                class="p-button rounded p-button-success"
                [disabled]="!isSign || !isIvorian || !isBirthDateOK"
                (click)="confirm($event)"
              >
                Valider
                <i class="pi pi-check"></i>
              </button>
              <p-confirmPopup></p-confirmPopup>
            </div>
          </div>

          <ng-template #novalidation>
            <div class="validation-content-detail-box-checklist-status">
              <div class="validation-content-detail-box-checklist-status-ok">
                <i class="pi pi-check-circle color-success"></i>
                <span>Validé</span>
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <div
        class="validation-content-detail-wrapper validation-content-detail-wrapper-left"
      >
        <div
          class="validation-content-detail-box validation-content-detail-form"
        >
          <p class="validation-content-detail-box-title">
            Informations candidat
          </p>
          <app-dashboard-regional-orientation-validation-applicant-form
            [applicant]="selectedApplicant"
            [validation]="validation"
            (editNationality)="editNationality($event)"
            (onEditBirthDate)="editBirthDate($event)"
          ></app-dashboard-regional-orientation-validation-applicant-form>
        </div>

        <!-- <p-divider></p-divider> -->

        <div class="validation-content-detail-box">
          <p class="validation-content-detail-box-title">Liste des choix</p>
          <app-dashboard-regional-orientation-validation-choices-list
            [choices]="choices"
          ></app-dashboard-regional-orientation-validation-choices-list>
        </div>
      </div>
    </div>

    <!-- Search icon -->
    <ng-template #noselection>
      <div class="validation-content-search" *ngIf="!isFailOrError">
        <div class="validation-content-search-icon">
          <i *ngIf="!isSearch" class="pi pi-search"></i>
          <i *ngIf="isSearch" class="pi pi-spin pi-spinner"></i>
        </div>
        <div class="validation-content-serach-text">
          <p>
            {{ isSearch ? "Recherche en cours..." : "Aucun appplicant trouvé" }}
          </p>
        </div>
      </div>
      <p-messages
        *ngIf="isFailOrError"
        [(value)]="msgs"
        [closable]="false"
      ></p-messages>
    </ng-template>
  </div>
</div>
