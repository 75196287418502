<p class="dashboard-title">Liste des validations</p>

<div class="dashboard-section-filter-transparent">
  <div class="dashboard-section-filter-actions">
    <div class="dashboard-section-filter-search">
      <input
        type="text"
        pInputText
        placeholder="Recherche"
        [(ngModel)]="searchText"
        (ngModelChange)="handleSearch()"
        [style]="{ width: '400px' }"
        class="rounded"
      />
    </div>
  </div>

  <div class="dashboard-section-action">
    <button
      class="p-button p-button-outlined p-button-secondary rounded"
      style="margin-left: 4rem"
      (click)="refreshPage()"
    >
      <i class="pi pi-refresh" style="margin-right: 1rem"></i>
      Rafraichir
    </button>
  </div>
</div>

<div class="dashboard-section">
  <p-table
    #registrationtable
    [value]="filterValidations"
    [paginator]="true"
    [rows]="10"
    [alwaysShowPaginator]="false"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    currentPageReportTemplate="Affichage de {first} à {last} des {totalRecords} enregistrements"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Identification</th>
        <th>Nationalité</th>
        <th>Date de naissance</th>
        <th>Date de validation</th>
        <th>Session</th>
        <th>Status</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-validation>
      <tr *ngIf="!isLoading">
        <td
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            border: none;
          "
        >
          <span style="opacity: 0.5">{{
            validation?.oval_applicant?.app_matricule
          }}</span>
          <span
            >{{ validation?.oval_applicant?.app_lastname }}
            {{ validation?.oval_applicant?.app_firstname }}</span
          >
        </td>

        <td>{{ validation?.oval_applicant?.app_nationality?.nat_label }}</td>

        <td>
          {{
            validation?.oval_applicant?.app_birthdate | date: "longDate":"fr"
          }}
        </td>

        <td>
          {{ validation?.updated_at | date: "shortDate":"fr" }}
          &nbsp; à &nbsp; {{ validation?.updated_at | date: "shortTime":"fr" }}
        </td>

        <td>
          {{ validation?.oval_session?.yrs_session }}
        </td>

        <td>
          <span
            class="chip chip-success"
            *ngIf="validation?.oval_validationstatus"
            >Validé</span
          >
          <span
            class="chip chip-danger"
            *ngIf="!validation?.oval_validationstatus"
            >Non validé</span
          >
        </td>

        <td style="text-align: right">
          <button
            *ngIf="!validation?.oval_validationstatus"
            class="p-button p-button-sm p-button-outlined p-button-secondary rounded"
            (click)="showDetail($event, op, validation)"
          >
            Détails
          </button>

          <span *ngIf="validation?.oval_validationstatus">-</span>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div
    class="empty-table-message-block"
    *ngIf="filterValidations.length === 0 && !isLoading"
  >
    <p>Aucune validation trouvée</p>
  </div>
  <div
    *ngIf="isLoading"
    class="loader"
    style="display: flex; justify-content: center"
  >
    <img src="/assets/loader.gif" />
  </div>
</div>

<p-overlayPanel
  [style]="{ width: '500px' }"
  #op
  appendTo="body"
  [dismissable]="true"
  [showCloseIcon]="true"
>
  <p class="validation-checklist-title">Detail des validations</p>

  <div class="validation-checklist-list">
    <div class="validation-checklist-item">
      <span>Document signé ?</span>
      <span
        class="chip chip-{{
          selectedValidation?.oval_documentsign ? 'success' : 'danger'
        }}"
        >{{ selectedValidation?.oval_documentsign ? "Oui" : "Non" }}</span
      >
    </div>
    <div class="validation-checklist-item">
      <span>Candidat Ivoirien ?</span>
      <span
        class="chip chip-{{
          selectedValidation?.oval_nationality ? 'success' : 'danger'
        }}"
        >{{ selectedValidation?.oval_nationality ? "Oui" : "Non" }}</span
      >
    </div>
    <div class="validation-checklist-item">
      <span>Date de naissance valide ?</span>
      <span
        class="chip chip-{{
          selectedValidation?.oval_birthdate ? 'success' : 'danger'
        }}"
        >{{ selectedValidation?.oval_birthdate ? "Oui" : "Non" }}</span
      >
    </div>
  </div>
</p-overlayPanel>
