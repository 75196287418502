<p-toast></p-toast>
<form class="form-steps">
    <div class="steps steps-four">
        <div class="steps-title">
            <h2>Récapitulatif des informations</h2>
            <p>
                Veuillez vérifier l'exactitude de vos informations et cliquez ensuite 
                sur le bouton <span class="indication">Terminer l'enregistrement</span> pour finaliser votre enregistrement. 
                En cas d'erreur dans la saisie, cliquer sur le bouton <span class="indication">Précédent</span> pour retourner 
                aux étapes précédentes et apporter les corrections nécessaires.
            </p>
        </div>

        <div class="body">

            <!-- header -->
            <header class="header">
                <img src="../assets/logo_eftpa_blue.png" alt="metfpa">
                <h1>MINISTERE DE L'ENSEIGNEMENT TECHNIQUE ET DE LA FORMATION PROFESSIONNELLE</h1>
                <img src="https://imgs.search.brave.com/yCj3Ty6RQEqM5GZuLX4idF2--7Casr_7eVki2s1yxkc/rs:fit:1160:1024:1/g:ce/aHR0cHM6Ly92b2l0/dXJlc2NpLnMzLmFt/YXpvbmF3cy5jb20v/dXBsb2Fkcy9wb3N0/L2ZlYXR1cmVkX2lt/YWdlLzQxL0NvYXRf/b2ZfYXJtc19vZl9D/JUMzJUI0dGVfZF9J/dm9pcmVfXzE5OTct/MjAwMV92YXJpYW50/Xy5zdmcucG5n" alt="embleme">
            </header>

            <!-- title -->
            <div class="big-title">
                <span>FICHE DE PREINSCRIPTION CANDIDAT BANQUE</span>
            </div>

            <!-- divider -->
            <div class="divider">
                <span>Information Apprenant</span>
            </div>

            <div class="first-info">
                <div class="first-info__text">
                    <p><span>Nom:</span> <small>{{studentBankToLocalStorage?.fcr_bankcandidate.bkc_lastname}}</small></p>
                    <p><span>Prénom(s):</span> <small>{{studentBankToLocalStorage?.fcr_bankcandidate.bkc_firstname}}</small></p>
                    <!-- <p><span>Matricule:</span> <small>----------</small></p> -->
                    <p><span>Date de Nais:</span> <small>{{studentBankToLocalStorage?.fcr_bankcandidate.bkc_birthdate}}</small></p>
                    <p><span>Nationalité:</span> <small>{{studentBankToLocalStorage?.fcr_bankcandidate.bkc_nationality.nat_label}}</small></p>
                </div>
                <div class="first-info__img">
                    <img src="{{'http://127.0.0.1:8000' + studentBankToLocalStorage?.fcr_bankcandidate.bkc_picture}}" alt="photo">
                </div>
            </div>

            <!-- box card -->
            <div class="second-info">
                <div class="box-card">
                    <div class="box-card__item">
                        <small>Lieu de Naissance</small>
                        <p class="bold">{{studentBankToLocalStorage?.fcr_bankcandidate.bkc_birthplace}}</p>
                    </div>
                    <div class="box-card__item">
                        <small>Genre</small>
                        <p class="bold">{{studentBankToLocalStorage?.fcr_bankcandidate.bkc_gender}}</p>
                    </div>
                </div>
    
                <!-- <div class="box-card">
                    <div class="box-card__item">
                        <small>Numéro de l'acte de naissance</small>
                        <p class="bold">----------</p>
                    </div>
                    <div class="box-card__item">
                        <small>Lieu d'établissement de l'acte</small>
                        <p class="bold">----------</p>
                    </div>
                </div> -->
    
                <div class="box-card">
                    <!-- <div class="box-card__item">
                        <small>Date d'établissement de l'acte</small>
                        <p class="bold">----------</p>
                    </div> -->
                    <div class="box-card__item">
                        <small>Téléphone 1</small>
                        <p class="bold">{{studentBankToLocalStorage?.fcr_bankcandidate.bkc_phone1}}</p>
                    </div>
                    <div class="box-card__item">
                        <small>Téléphone 2</small>
                        <p class="bold">{{studentBankToLocalStorage?.fcr_bankcandidate.bkc_phone2}}</p>
                    </div>
                </div>

                <div class="box-card">
                    <div class="box-card__item">
                        <small>E-mail</small>
                        <p class="bold">{{studentBankToLocalStorage?.fcr_bankcandidate.bkc_email}}</p>
                    </div>
                </div>
    
                <!-- <div class="box-card">
                    <div class="box-card__item">
                        <small>Nom et prénom(s) du père </small>
                        <p class="bold">----------</p>
                    </div>
                    <div class="box-card__item">
                        <small>Nom et prénom(s) de la mere</small>
                        <p class="bold">----------</p>
                    </div>
                </div> -->
            </div>

            <!-- divider -->
            <div class="divider">
                <span>Information Insciption</span>
            </div>

            <div class="third-info">
                <div class="box-card">
                    <div class="box-card__item">
                        <small>Session</small>
                        <p class="bold">{{studentBankToLocalStorage?.fcr_session.yrs_academic}}</p>
                    </div>
                    <div class="box-card__item">
                        <small>Type Candidature</small>
                        <!-- <p class="bold">{{studentSearchtoLocalStorage?.pfc_status}}</p> -->
                        <p class="bold">Candidat Banque</p>
                    </div>
                </div>

                <div class="box-card">
                    <div class="box-card__item">
                        <small>Etablissement</small>
                        <p class="bold">{{studentBankToLocalStorage?.fcr_bank.bnk_longlabel}}</p>
                    </div>
                    <div class="box-card__item">
                        <small>Diplome preparé</small>
                        <p class="bold">{{studentBankToLocalStorage?.fcr_diploma.dip_longlabel}}</p>
                    </div>
                </div>

                <div class="box-card">
                    <div class="box-card__item">
                        <small>Filiere</small>
                        <p class="bold">{{studentBankToLocalStorage?.fcr_studyfield.sfd_longlabel}}</p>
                    </div>
                    <div class="box-card__item">
                        <small>Unité d'enseignement à presenter</small>
                        <p class="bold">{{studentBankToLocalStorage?.fcr_examtotake}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-right box-btn">
            <a (click)="onPrevStep()">
                <i class="pi pi-arrow-left"></i>
                RETOUR
            </a>

            <button type="button" class="btn-download" (click)="onClickDownloadPdf()">
                Telecharger Mon Reçu
            </button>

            <button type="button" class="p-button" (click)="showDialog()">
                TERMINER
            </button>
        </div>
        
    </div>

</form>


<!-- confirm dialog -->
<p-dialog
  header="Comfirmation"
  [(visible)]="displayDialog"
  [style]="{ width: '500px' }"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
>
  <p style="display: flex; align-items: center">
    <i
      style="font-size: 2rem; color: red; margin-right: 1rem"
      class="pi pi-question-circle"
    ></i>
    Êtes-vous sûr de vouloir terminer l'enregistrement ?
  </p>

  <ng-template pTemplate="footer">
    <p-button
      (click)="displayDialog = false"
      icon="pi pi-times"
      label="Annuler"
      styleClass="p-button-secondary p-button-text"
    ></p-button>
    <p-button
      (click)="handleSuccess()"
      label="Confirmer"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>