<nav class="nav">
  <div class="nav__left">
    <div class="nav__logo">
      <img
        style="cursor: pointer"
        [routerLink]="['/']"
        src="/assets/logo_blue.png"
        alt="ETFPA Logo"
      />
    </div>
    <!-- <ul class="account__menu">
      <li class="account__item">
        <a class="account__link active" [routerLink]="['/']">
          <i class="pi pi-home"></i>
          Dashboard
        </a>
      </li>

      <li class="account__item">
        <a class="account__link" [routerLink]="['/']">
          <i class="pi pi-list"></i>
          Candidature
        </a>
      </li>
    </ul> -->
  </div>

  <ul class="nav__menu">
    <li class="nav__item">
      <a class="nav__link" [routerLink]="['/']">Accueil</a>
    </li>
    <!-- <li class="nav__item">
      <a href="" class="nav__link">Actualité</a>
    </li>
    <li class="nav__item">
      <a href="" class="nav__link">Contact</a>
    </li> -->

    <li class="nav__item">
      <a [routerLink]="['/account', 'login']" class="nav__link button"
        >Mon espace</a
      >
      <!-- <a [routerLink]="['/']" class="nav__link button"
        >Mon espace</a
      > -->
    </li>
  </ul>
</nav>
