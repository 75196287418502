<div class="registration-content-wrapper">
  <div class="registration-content-item">
    <div class="registration-content-item-header">
      <!-- <p-toast></p-toast> -->
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2rem;
        "
      >
        <div>
          <h3>Recherche</h3>
          <p>Veuillez saisir votre numéro matricule MENA</p>
        </div>

        <img
          class="animate__animated animate__jello animate__infinite animate__fast"
          src="/assets/icons/message.svg"
          alt="Icone information "
          style="width: 50px"
          pTooltip="Le matricule du MENA est le matricule délivré à l’apprenant par l’Education Nationale"
          tooltipPosition="top"
        />
      </div>

      <h4>
        <span class="color-danger">(*) </span>
        Champs obligatoire
      </h4>
    </div>

    <p-messages [(value)]="registrationMSG"></p-messages>

    <form [formGroup]="editForm" style="margin-top: 2rem">
      <!-- Année académicque -->
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_academic"
          >Année académique
        </label>
        <input
          type="text"
          pInputText
          [(ngModel)]="yearAcademic"
          formControlName="academicYear"
          [readonly]="true"
          id="field_academic"
        />
      </div>

      <!-- matricule -->
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_codeMena"
          >Numéro matricule MENA
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["codeMena"])
          }}</span>
        </label>
        <!-- <input
          type="text"
          pInputText
          formControlName="codeMena"
          [ngModel]="codeMenaValue"
          id="field_codeMena"
          placeholder="Entrer votre numéro matricule"
          (ngModelChange)="codeMenaValue = $event?.toUpperCase()"
          maxlength="9"
        /> -->
        <p-inputMask
          mask="99999999a"
          formControlName="codeMena"
          [ngModel]="codeMenaValue"
          id="field_codeMena"
          placeholder="_________"
          [maxlength]="9"
          (ngModelChange)="codeMenaValue = $event?.toUpperCase()"
          [autoClear]="false"
          (keydown.enter)="handleKeyUp()"
        >
        </p-inputMask>
        <small
          *ngIf="
            editForm.get('codeMena')?.errors &&
            (editForm.get('codeMena')?.errors)!['required'] &&
            (editForm.controls['codeMena'].dirty ||
              editForm.controls['codeMena'].touched)
          "
          class="p-field-help"
          id="field_codeMena-help"
          >Ce champ est obligatoire.</small
        >
        <small
          *ngIf="
            editForm.get('codeMena')?.errors &&
            (editForm.get('codeMena')?.errors)!['pattern']
          "
          class="p-field-help"
          id="field_codeMena-help"
          >Format du matricule non reconnu: (Ex: 0000000X)</small
        >
      </div>

      <div class="p-field p-field-actions loader">
        <!-- <button
          [disabled]="editForm.invalid || isSaving || isLoading"
          pButton
          type="button"
          label="Rechercher"
          (click)=""
        ></button> -->
        <div class="search-loader">
          <img src="/assets/loader.gif" alt="Loader" *ngIf="isLoading" />
        </div>

        <div></div>
        <div style="float: right">
          <button
            pButton
            pRipple
            type="button"
            label="Annuler"
            icon="pi pi-arrow-left"
            [disabled]="isLoading"
            class="p-button-secondary p-button-text"
            [routerLink]="['/']"
          ></button>

          <button
            [disabled]="
              (editForm.invalid &&
                editForm.controls['matricule']?.value! == null) ||
              isSaving ||
              isLoading
            "
            pButton
            type="button"
            label="Rechercher et Continuer"
            (click)="searchStudent()"
          ></button>
        </div>
      </div>
    </form>
  </div>
</div>
