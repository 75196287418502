import { Component, Input, OnInit } from '@angular/core';
import { IChoice } from 'src/app/dob/models/choice.model';
import { getPreferenceLabel } from 'src/app/dob/utils/preference.utils';

@Component({
  selector: 'app-dashboard-regional-orientation-validation-choices-list',
  templateUrl:
    './dashboard-regional-orientation-validation-choices-list.component.html',
  styleUrls: [
    './dashboard-regional-orientation-validation-choices-list.component.scss',
  ],
})
export class DashboardRegionalOrientationValidationChoicesListComponent
  implements OnInit
{
  @Input() choices?: IChoice[];

  constructor() {}

  ngOnInit(): void {}

  getPreferenceOrderLabel(preference: number): string {
    return getPreferenceLabel(preference);
  }
}
