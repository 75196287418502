import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { IExamenBreadcrumb } from 'src/app/shared/model/examen-breadcrumb.model';
import {
  activateStep,
  setActiveStep,
  setPrevStep,
} from 'src/app/shared/utils/step.utils';
import { InscriptionService } from '../inscription.service';
import { IStudent } from 'src/app/shared/model/student.model';
import { Message, MessageService } from 'primeng/api';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import {
  hasRequiredField,
  humanFileSize,
} from 'src/app/shared/utils/validator.utils';
import { INationality } from '../../shared/model/nationality.model';
import { BREADCRUMB_ITEMS_KEY } from 'src/app/shared/constant/step.constant';
import { SessionStorageService } from 'src/app/session-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-examen-enregistrement',
  templateUrl: './examen-enregistrement.component.html',
  styleUrls: ['./examen-enregistrement.component.scss'],
})
export class ExamenEnregistrementComponent implements OnInit, OnDestroy {
  items: IExamenBreadcrumb[] = [];

  editForm = this.fb.group({
    lastName: [null, [Validators.required]],
    firstName: [null, [Validators.required]],
    birthDate: [null, [Validators.required]],
    birthPlace: [null, [Validators.required]],
    gender: [null, [Validators.required]],
    nationality: [null, [Validators.required]],
    email: [null, []],
    phone1: [null, [Validators.required, Validators.minLength(14)]],
    phone2: [null, [Validators.minLength(14)]],
    picture: [null, [Validators.required]],
  });

  genders = [
    { name: 'Masculin', code: 'M' },
    { name: 'Féminin', code: 'Fß' },
  ];
  selectedGender = '';
  currentExameRef = '';
  studentPictureURL = '';
  isSaving = false;
  showDialog = false;
  loaded = false;
  student?: IStudent;
  studentFounded = false;

  msgs: Message[] = [];
  studentPictureFile: FormData = new FormData();
  selectedFile?: File;
  isFileValid = false;

  nationalities: INationality[] = [];
  selectedNationality?: INationality;

  imgWidth = environment.imageWidthAllowed;
  imgHeight = environment.imageHeightAllowed;

  isUploaded = false;

  constructor(
    protected router: Router,
    private inscriptionService: InscriptionService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.loaded = true;
    const currentStep = this.getStepDataFromSS()?.step;
    this.items = this.getStepDataFromSS()?.data;
    setActiveStep(this.items, currentStep);
    this.loadNationalites();

    const studentRef = JSON.parse(sessionStorage.getItem('exam')!)?.matricule;

    this.inscriptionService.findStudentByRef(studentRef).subscribe({
      next: (res) => {
        this.student = res.body!;
        this.updateForm(this.student!);
        this.studentFounded = true;
        this.loaded = false;
      },
      error: (err: any) => {
        this.studentFounded = false;
        this.loaded = false;
        this.msgs = [
          {
            severity: 'warn',
            summary: 'Recherche',
            detail: `Le numéro matricule ${studentRef} n'a pas été trouvé, vous ne pouvez donc pas continuer le processus de préinscription veuillez vérifier et réessayer!`,
          },
        ];
      },
      complete: () => (this.loaded = false),
    });
  }

  loadNationalites(): void {
    this.inscriptionService.getNationalities().subscribe((res) => {
      this.nationalities = res.body || [];
    });
  }

  getStepDataFromSS(): any {
    return JSON.parse(this.sessionStorage.getItem(BREADCRUMB_ITEMS_KEY)!);
  }

  goToNext(): void {
    if (this.editForm.dirty) this.edit();
    activateStep(this.getStepDataFromSS(), this.sessionStorage);
    this.router.navigate(['/inscription', 'examen', 'info-examen']);
  }

  goPrev(): void {
    setPrevStep(this.getStepDataFromSS(), this.sessionStorage);
    this.router.navigate(['/inscription', 'examen', 'recherche']);
  }

  edit(): void {
    this.isSaving = true;
    let phone1 = this.editForm.value['phone1'];
    let phone2 = this.editForm.value['phone2'];

    if (phone1.length > 10) {
      phone1 = phone1.slice(4).trim().split(' ').join('');
    }

    if (phone2.length > 10) {
      phone2 = phone2.slice(4).trim().split(' ').join('');
    }
    this.editForm.patchValue({
      phone1: phone1,
      phone2: phone2,
    });
    this.inscriptionService
      .update(this.student?.std_menaref!, this.editForm.value)
      .subscribe((event) => {
        this.isSaving = false;
      });
  }

  openConfirmDialog(): void {
    this.showDialog = true;
  }

  updateForm(student: IStudent): void {
    this.selectedGender = student?.std_gender!;
    this.studentPictureURL = environment.mediaURL + student?.std_picture!;
    this.selectedNationality = student.std_nationality;
    this.editForm.patchValue({
      lastName: student?.std_lastname,
      firstName: student?.std_firstname,
      birthDate: student?.std_birthdate,
      birthPlace: student?.std_birthplace,
      gender: student?.std_gender,
      nationality: student?.std_nationality?.nat_label,
      email: student?.std_email,
      phone1: `+225${student?.std_phone1}`,
      phone2: `+225${student?.std_phone2}`,
      picture: student?.std_picture,
    });
  }

  // File upload
  onFileUpload(): void {
    this.isUploaded = true;
    this.studentPictureFile.append(
      'picture',
      this.selectedFile!,
      this.selectedFile?.name
    );
    this.inscriptionService
      .uploadStudentPicture(this.student?.std_menaref!, this.studentPictureFile)
      .subscribe((data) => {
        this.student = data.body;
        this.studentPictureURL =
          environment.mediaURL + this.student?.std_picture;
        this.isUploaded = false;
        this.editForm.patchValue({
          picture: this.student?.std_picture,
        });
      });
  }

  onFileChange(event: any): void {
    const _URL = window.URL || window.webkitURL;
    this.messageService.clear();
    this.selectedFile = event.target.files[0];
    let file: File;

    if ((file = event.target.files[0])) {
      const img = new Image();

      img.onload = () => {
        if (humanFileSize(file.size) > 2.5) {
          this.isFileValid = false;
          this.messageService.add({
            severity: 'error',
            detail: 'Le fichier ne doit pas dépasser 2.5Mb',
          });
        } else if (img.width > this.imgWidth || img.height > this.imgHeight) {
          this.isFileValid = false;
          this.messageService.add({
            severity: 'error',
            detail: `Dimensions maximales autorisées ${this.imgWidth} par ${this.imgHeight}`,
          });
        } else {
          this.onFileUpload();
          event.target.files = [];
          this.isFileValid = true;
        }
      };
      img.onerror = () => {
        this.isFileValid = false;
        this.messageService.add({
          severity: 'error',
          detail:
            'Le fichier doit être une image avec pour extension possible (jpg, png. jpeg)',
        });
      };
      img.src = _URL.createObjectURL(file);
    }
  }

  removeSelectedFile(file: HTMLInputElement): void {
    file.value = '';
  }

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }
}
