<app-navbar></app-navbar>
<!-- <div class="examen-header">

  <div class="examen-header__text">
    <h3 class="examen-header__title">Insciption Examen</h3>
    <p class="examen-header__subtitle">
      Processus d’inscription aux examens de l’année académique 2021-2022
    </p>
  </div>
</div> -->
