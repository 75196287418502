<div class="validation-sidebar">
  <div class="validation-sidebar__logo">
    <img src="/assets/logo_ers.png" alt="ETFPA logo" />
    <!-- <small>{{ dashboardLabel }}</small> -->
  </div>

  <ul class="validation-sidebar__menu">
    <!-- ############# REGIONAL USER NAVIGATION ############# -->
    <ng-container *ngIf="userRoles?.REGIONAL?.includes(authUser?.role!)">
      <li class="validation-sidebar__item">
        <a
          [routerLink]="['/dashboard', 'regional', 'home']"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-home"></i>
          <span>Accueil</span>
        </a>
      </li>

      <li class="validation-sidebar__item">
        <a
          [routerLink]="['/dashboard', 'regional', 'schools']"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-sitemap"></i>
          <span>Etablissement</span>
        </a>
      </li>

      <div class="validation-sidebar__separator"></div>

      <div class="validation-sidebar__group">
        <span class="validation-sidebar__group-title">
          <span> Orientation </span>
          <i class="pi pi-chevron-down"></i>
        </span>

        <div class="validation-sidebar__group-list">
          <li class="validation-sidebar__item">
            <a
              [routerLink]="[
                '/dashboard',
                'regional',
                'orientation',
                'listing-validation'
              ]"
              routerLinkActive="active"
              class="validation-sidebar__link"
            >
              <i class="pi pi-list"></i>
              <span>Listing des validations</span>
            </a>
          </li>

          <li class="validation-sidebar__item">
            <a
              [routerLink]="[
                '/dashboard',
                'regional',
                'orientation',
                'validation'
              ]"
              routerLinkActive="active"
              class="validation-sidebar__link"
            >
              <i class="pi pi-check-square"></i>
              <span>Validation</span>
            </a>
          </li>
        </div>
      </div>

      <!-- <div class="validation-sidebar__separator"></div> -->

      <!-- Parameters menu -->
      <!-- <div class="validation-sidebar__group">
        <span class="validation-sidebar__group-title">
          <span> Paramètres </span>
          <i class="pi pi-chevron-down"></i>
        </span>

        <div class="validation-sidebar__group-list">
          <li class="validation-sidebar__item">
            <a
              [routerLink]="[
                '/dashboard',
                'regional',
                'settings',
                'applicant-code'
              ]"
              routerLinkActive="active"
              class="validation-sidebar__link"
            >
              <i class="pi pi-unlock"></i>
              <span>Code applicant</span>
            </a>
          </li>
        </div>
      </div> -->
    </ng-container>

    <!-- ############# SCHOOL USER NAVIGATION ############# -->
    <ng-container *ngIf="userRoles?.SCHOOL?.includes(authUser?.role!)">
      <li class="validation-sidebar__item">
        <a
          [routerLink]="['/dashboard', 'validators', 'home']"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-home"></i>
          <span>Accueil</span>
        </a>
      </li>
      <!-- <li class="validation-sidebar__item">
      <a
        [routerLink]="['/dashboard', 'validators', 'candidature']"
        routerLinkActive="active"
        href=""
        class="validation-sidebar__link"
      >
        <i class="pi pi-list"></i>
        <span>Candidatures</span>
      </a>
    </li> -->
      <li class="validation-sidebar__item">
        <a
          [routerLink]="['/dashboard', 'validators', 'listing']"
          routerLinkActive="active"
          href=""
          class="validation-sidebar__link"
        >
          <i class="pi pi-users"></i>
          <span>Validation apprenants</span>
        </a>
      </li>
      <li class="validation-sidebar__item">
        <a
          [routerLink]="['/dashboard', 'validators', 'candidatures']"
          routerLinkActive="active"
          href=""
          class="validation-sidebar__link"
        >
          <i class="pi pi-wallet"></i>
          <span>Paiement examens</span>
        </a>
      </li>

      <!-- <div class="validation-sidebar__separator"></div> -->

      <div class="validation-sidebar__separator"></div>

      <div class="validation-sidebar__group">
        <span class="validation-sidebar__group-title">
          <span> Paramètres </span>
          <i class="pi pi-chevron-down"></i>
        </span>

        <div class="validation-sidebar__group-list">
          <li class="validation-sidebar__item">
            <a
              [routerLink]="['/dashboard', 'validators', 'staff']"
              routerLinkActive="active"
              href=""
              class="validation-sidebar__link"
            >
              <i class="pi pi-sort-numeric-down"></i>
              <span>Effectif attendu</span>
            </a>
          </li>
        </div>
      </div>
    </ng-container>

    <!-- ############# SUPERVISOR USER NAVIGATION ############# -->
    <ng-container *ngIf="userRoles?.SUPERVISOR?.includes(authUser?.role!)">
      <li class="validation-sidebar__item">
        <a
          [routerLink]="['/dashboard', 'supervisor', 'home']"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-home"></i>
          <span>Accueil</span>
        </a>
      </li>

      <li class="validation-sidebar__item">
        <a
          [routerLink]="['/dashboard', 'supervisor', 'validation-bank']"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-check-circle"></i>
          <span>Validation banque</span>
        </a>
      </li>

      <!-- <li
      class="validation-sidebar__item"
    >
      <a
        [routerLink]="['/dashboard', 'supervisor', 'registration-status']"
        routerLinkActive="active"
        class="validation-sidebar__link"
      >
        <i class="pi pi-chart-line"></i>
        <span>Statut des enregistrements</span>
      </a>
    </li> -->

      <!-- <li
      class="validation-sidebar__item"
    >
      <a
        [routerLink]="['/dashboard', 'admin', 'home']"
        routerLinkActive="active"
        class="validation-sidebar__link"
      >
        <i class="pi pi-chart-line"></i>
        <span>Statistiques examen</span>
      </a>
    </li> -->
    </ng-container>

    <!-- ############ DOB ################ -->
    <ng-container *ngIf="userRoles?.DOB?.includes(authUser?.role!)">
      <li class="validation-sidebar__item">
        <a
          [routerLink]="['/dashboard', 'dob', 'home']"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-home"></i>
          <span>Accueil</span>
        </a>
      </li>

      <li
        class="validation-sidebar__item"
        *ngIf="dobAdminRole?.includes(authUser?.role!)"
      >
        <a
          [routerLink]="['/dashboard', 'dob', 'applicants']"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-users"></i>
          <span>Listing Candidats</span>
        </a>
      </li>
      <li
        class="validation-sidebar__item"
        *ngIf="dobUserRole?.includes(authUser?.role!)"
      >
        <a
          [routerLink]="['/dashboard', 'dob', 'user-choices-registrations']"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-users"></i>
          <span>Inscrtiptions</span>
        </a>
      </li>
    </ng-container>

    <!-- <ng-container *ngIf="userRoles?.DOB?.includes('ROLE_DOB_USER')">

    </ng-container> -->

    <!-- ############# ADMIN USER NAVIGATION #############  -->
    <ng-container *ngIf="userRoles?.ADMIN?.includes(authUser?.role!)">
      <li>
        <a
          [routerLink]="['/dashboard', 'admin', 'home']"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-home"></i>
          <span>Accueil</span>
        </a>
      </li>
      <li
        class="validation-sidebar__item"
        *ngIf="authUser?.role === 'ROLE_ADMIN'"
      >
        <a
          [routerLink]="[
            '/dashboard',
            'admin',
            'configuration',
            'user-account'
          ]"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-users"></i>
          <span>Compte utilisateur</span>
        </a>
      </li>
    </ng-container>

    <!-- ############# ADMIN BANK NAVIGATION #############  -->
    <ng-container *ngIf="userRoles?.BANK?.includes(authUser?.role!)">
      <li class="validation-sidebar__item">
        <a
          [routerLink]="['/dashboard', 'bank', 'home']"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-home"></i>
          <span>Accueil</span>
        </a>
      </li>
    </ng-container>

    <!-- ############ DEXC UPDATE STUDENT AND EXAM REGISTRATION ################ -->
    <!-- <ng-container *ngIf="userRoles?.DEXCUPDATE?.includes(authUser?.role!)">
      <li class="validation-sidebar__item">
        <a
          [routerLink]="['/dashboard', 'dexc-update', 'home']"
          routerLinkActive="active"
          class="validation-sidebar__link"
        >
          <i class="pi pi-user-edit"></i>
          <span>Modification apprenant</span>
        </a>
      </li>
    </ng-container> -->
  </ul>
</div>
