import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { AuthService } from 'src/app/account/auth.service';
import { IUser } from 'src/app/account/model/user.model';
import { ExamregistrationService } from 'src/app/services/examregistration.service';
import { IDiploma } from 'src/app/shared/model/diploma.model';
import { IExamRegistration } from 'src/app/shared/model/examregistration.model';
import { IStudent } from 'src/app/shared/model/student.model';
import { environment } from 'src/environments/environment';
import { RegistrationService } from '../../services/registration.service';
import * as R from 'ramda';
import * as moment from 'moment';

interface IPaymentStatus {
  name: string;
  code: number;
}
@Component({
  selector: 'app-dashboard-validation-candidature',
  templateUrl: './dashboard-validation-candidature.component.html',
  styleUrls: ['./dashboard-validation-candidature.component.scss'],
})
export class DashboardValidationCandidatureComponent implements OnInit {
  items: MenuItem[] = [];
  home: MenuItem = {};
  isLoading = false;

  selectedStudent: string[] = [];

  searchText = '';
  displayDetail = false;
  selectedExamRegistration?: IExamRegistration;
  examregistrations: IExamRegistration[] = [];

  filteredExamRegistrations: IExamRegistration[] = [];
  examRegistrationFilteredByNameOrCode: IExamRegistration[] = [];
  examRegistrationFilteredByDiplomas: IExamRegistration[] = [];
  examRegistrationFilteredByStatus: IExamRegistration[] = [];

  diplomas: IDiploma[] = [];
  selectedDiploma: any[] = [];

  paymentStatusList: IPaymentStatus[] = [];
  selectedPaymentStatus: number[] = [];

  authUser?: IUser;

  constructor(
    private examRegistrationService: ExamregistrationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private registrationService: RegistrationService,
    private authService: AuthService
  ) {
    this.isExamregistratiionRowSelectable =
      this.isExamregistratiionRowSelectable.bind(this);
  }

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.authService.getAuthUser().subscribe((user) => {
      this.authUser = user;
      this.loadDiplomas();
      // this.loadExamRegistrations();
      this.loadSchoolExamRegistrations();
    });

    this.paymentStatusList = [
      {
        name: 'Initiation en cours',
        code: 1,
      },
      {
        name: 'En attente',
        code: 2,
      },
      {
        name: 'Paiement effectué',
        code: 3,
      },
      {
        name: 'Paiement réfusé',
        code: 4,
      },
    ];
  }

  loadSchoolExamRegistrations(): void {
    this.isLoading = true;
    this.examRegistrationService
      .findExamRegistrationBySchool(this.authUser?.usr_school?.sch_id!)
      .subscribe((res) => {
        this.examregistrations = res.body || [];
        this.filteredExamRegistrations = [...this.examregistrations];
        this.isLoading = false;
      });
  }

  loadDiplomas(): void {
    const school_id = this.authUser?.usr_school?.sch_id!;
    this.registrationService
      .findSchoolDiplomas(school_id)
      .subscribe((res) => (this.diplomas = res.body || []));
  }

  showDetail(exam: IExamRegistration): void {
    this.selectedExamRegistration = exam;
    this.displayDetail = true;
  }

  getPicture(image: string): string {
    return environment.mediaURL + image;
  }

  updateStatus(status: number): Observable<HttpResponse<IExamRegistration>> {
    return this.examRegistrationService.updateStatus(
      JSON.stringify({
        status: status,
        uuid: this.selectedExamRegistration?.exa_id!,
      })
    );
  }

  reject(): void {
    this.updateStatus(3).subscribe((data) =>
      this.loadSchoolExamRegistrations()
    );
  }

  validate(): void {
    this.updateStatus(2).subscribe((data) =>
      this.loadSchoolExamRegistrations()
    );
  }

  confirm() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to perform this action?',
      accept: () => {
        //Actual logic to perform a confirmation
      },
    });
  }

  confirmInitiatePayment(event: any, examRegistration: IExamRegistration) {
    this.confirmationService.confirm({
      target: event.target,
      message: "Confirmez-vous l'initiation du paiement?",
      icon: 'pi pi-exclamation-triangle',
      key: 'PAYMENT_POPUP',
      acceptLabel: 'Confirmer',
      rejectLabel: 'Annuler',
      rejectIcon: 'pi pi-times',
      acceptButtonStyleClass: 'rounded p-button p-button-sm',

      accept: () => {
        this.handleInitiatePayment(examRegistration);
      },
      reject: () => {
        //reject action
      },
    });
  }

  handleInitiatePayment(exam: IExamRegistration): void {
    this.examRegistrationService.initiatePayment(exam?.exa_id!).subscribe({
      next: (res) => {
        const data = res.body;
        this.messageService.add({
          key: 'PAYEMENT_RESULT',
          severity: 'success',
          detail: ` Paiement initié pour l\'apprenant ${data?.exa_student?.std_lastname?.toUpperCase()} ${data?.exa_student?.std_firstname?.toUpperCase()}`,
        });
        this.loadPage();
      },
      error: (err) => {
        this.messageService.add({
          key: 'PAYEMENT_RESULT',
          severity: 'error',
          detail: `Erreur d\initiation, veuillez réessayè!`,
        });
        console.log(err);
      },
    });
  }

  examregistrationTrackBy(
    index: number,
    examregistration: IExamRegistration
  ): string {
    return examregistration?.exa_id!;
  }

  isPaymentInitiatedPendingOrValidated(exam: IExamRegistration): boolean {
    return ![0, 4].includes(exam.exa_paymentstatus!);
  }

  isExamregistratiionRowSelectable(event: any): boolean {
    return !this.isPaymentInitiatedPendingOrValidated(event.data);
  }

  // ################# FILTER ####################
  // Search by name or matricule
  handleSearchByNameOrStudentCode(): void {
    this.searchText = this.searchText.toLowerCase();

    if (this.searchText) {
      this.examRegistrationFilteredByNameOrCode = this.examregistrations.filter(
        (exam) =>
          exam.exa_student?.std_lastname
            ?.toLowerCase()
            .includes(this.searchText) ||
          exam.exa_student?.std_firstname
            ?.toLowerCase()
            .includes(this.searchText) ||
          exam.exa_student?.std_menaref
            ?.toLowerCase()
            .includes(this.searchText) ||
          exam.exa_student?.std_etfparef
            ?.toLowerCase()
            .includes(this.searchText)
      );
    } else {
      this.examRegistrationFilteredByNameOrCode = [];
    }
    this.filteredExamRegistrations = this.loadAllExamRegistration();
  }

  handleSearchByDiploma(): void {
    if (this.selectedDiploma.length !== 0) {
      this.examRegistrationFilteredByDiplomas = this.examregistrations.filter(
        (exam) => this.selectedDiploma.includes(exam.exa_diploma?.dip_id!)
      );
    } else {
      this.examRegistrationFilteredByDiplomas = [];
    }
    this.filteredExamRegistrations = this.loadAllExamRegistration();
  }

  handleSearchByStatus(): void {
    if (this.selectedPaymentStatus.length !== 0) {
      this.examRegistrationFilteredByStatus = this.examregistrations.filter(
        (exam) => this.selectedPaymentStatus.includes(exam.exa_paymentstatus!)
      );
    } else {
      this.examRegistrationFilteredByStatus = [];
    }
    this.filteredExamRegistrations = this.loadAllExamRegistration();
  }

  loadAllExamRegistration(): IExamRegistration[] {
    if (
      this.examRegistrationFilteredByDiplomas.length === 0 &&
      this.examRegistrationFilteredByNameOrCode.length === 0 &&
      this.examRegistrationFilteredByStatus.length === 0
    ) {
      return this.examregistrations;
    }
    return R.uniqBy(
      R.propEq('exa_id'),
      R.concat(
        this.examRegistrationFilteredByDiplomas,
        this.examRegistrationFilteredByNameOrCode
      ).concat(this.examRegistrationFilteredByStatus)
    );
  }

  refreshExamRegistrations(): void {
    this.filteredExamRegistrations = [];
    this.searchText = '';
    this.selectedDiploma = [];
    this.selectedPaymentStatus = [];
    this.loadSchoolExamRegistrations();
  }

  showPaymentDetail(event: any, overlay: any, exam: IExamRegistration) {
    overlay?.toggle(event);
    this.selectedExamRegistration = exam;
  }

  momentToDate(date: any): any {
    return moment(date).toDate();
  }
}
