import { Component, OnInit, OnDestroy } from '@angular/core';
import { IExamenBreadcrumb } from 'src/app/shared/model/examen-breadcrumb.model';
import {
  setActiveStep,
  setPrevStep,
  finishStep,
  isStepFinished,
} from 'src/app/shared/utils/step.utils';
import { Router } from '@angular/router';
import { StepService } from '../step.service';
import { Observable } from 'rxjs';
import { InscriptionService } from '../inscription.service';
import { IExamRegistration } from '../../shared/model/examregistration.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IStudent } from 'src/app/shared/model/student.model';
import { BREADCRUMB_ITEMS_KEY } from 'src/app/shared/constant/step.constant';
import { SessionStorageService } from 'src/app/session-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-examen-validation',
  templateUrl: './examen-validation.component.html',
  styleUrls: ['./examen-validation.component.scss'],
})
export class ExamenValidationComponent implements OnInit, OnDestroy {
  items$: Observable<IExamenBreadcrumb[]> | undefined;
  items: IExamenBreadcrumb[] = [];
  isRegistrationFinished = false;
  examregistration$?: Observable<IExamRegistration>;

  student$?: Observable<IStudent>;
  examRegistration?: IExamRegistration;
  documentURL?: SafeResourceUrl;

  constructor(
    private router: Router,
    private stepService: StepService,
    private inscriptionService: InscriptionService,
    private sanitazer: DomSanitizer,
    private sessionStorage: SessionStorageService
  ) {}
  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    const currentStep = this.getStepDataFromSS()?.step;
    this.isRegistrationFinished = isStepFinished(
      this.getStepDataFromSS()?.data
    );
    this.stepService.getSteps(this.sessionStorage).subscribe((items) => {
      this.items = items;
      setActiveStep(this.items, currentStep);
    });

    const documennt = JSON.parse(
      this.sessionStorage.getItem('examregistration')!
    );
    this.documentURL = this.sanitazer.bypassSecurityTrustResourceUrl(
      `${environment.mediaURL}${documennt}` as string
    );
  }

  download(): void {
    const documennt = JSON.parse(
      this.sessionStorage.getItem('examregistration')!
    );
    console.log(documennt);
    this.inscriptionService
      .downloadExamDocument(documennt)
      .subscribe((data) => console.log(data));
  }

  loadPreRegistratedDocument(body: any): void {
    this.inscriptionService
      .loadExamPreRegistrationDocument(body)
      .subscribe((data) => console.log(data.body?.size));
  }

  goPrev(): void {
    setPrevStep(this.getStepDataFromSS(), this.sessionStorage);
    this.router.navigate(['/inscription', 'examen', 'info-examen']);
  }

  getStepDataFromSS(): any {
    return JSON.parse(this.sessionStorage.getItem(BREADCRUMB_ITEMS_KEY)!);
  }

  finish(): void {
    finishStep(this.getStepDataFromSS(), this.sessionStorage);
    this.stepService.getSteps(this.sessionStorage).subscribe((items) => {
      this.items = items;
      this.isRegistrationFinished = isStepFinished(this.items);
    });
  }
}
