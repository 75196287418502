import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from 'src/app/account/model/user.model';
import { DobService } from 'src/app/dob/dob.service';
import { IChoice } from 'src/app/dob/models/choice.model';
import { IDobRegistrationLine } from '../../dob/models/registrationline.model';
import { IApplicant } from 'src/app/dob/models/applicant.model';
import { downloadPdf } from '../../shared/utils/downloader.utils';
import { IYear } from 'src/app/shared/model/year.model';
import { YearService } from 'src/app/services/year.service';

export interface IDobUserChoiceRegistration {
  user: IUser;
  applicant: IChoice;
}

@Component({
  selector: 'app-dashboard-dob-user-registration',
  templateUrl: './dashboard-dob-user-registration.component.html',
  styleUrls: ['./dashboard-dob-user-registration.component.scss'],
})
export class DashboardDobUserRegistrationComponent implements OnInit {
  choicesRegistrations: IDobRegistrationLine[] = [];
  isLoading = false;
  isChoiceLoading = false;
  selectedApplicant?: IApplicant;
  applicantChoices: IChoice[] = [];
  base64File: any;
  year?: IYear;

  constructor(
    private router: Router,
    private dobService: DobService,
    private yearService: YearService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.isLoading = true;
    this.dobService.getUserRegistration().subscribe((res) => {
      this.isLoading = false;
      this.choicesRegistrations = res.body || [];
    });
    this.yearService.geActiveYear().subscribe((year) => (this.year = year));
  }

  refreshPage(): void {
    this.loadPage();
  }

  addRegistration(): void {
    this.dobService.clearDobUserRegistrationStorage();
    this.router.navigate([
      '/dashboard',
      'dob',
      'user-choices-registrations',
      'edit',
    ]);
  }

  showDetail(event: any, panel: any, applicant: IApplicant): void {
    this.isChoiceLoading = true;
    panel.toggle(event);
    this.selectedApplicant = applicant;
    this.dobService
      .getChoiceByApplicant(this.selectedApplicant?.app_id!)
      .subscribe((res) => {
        this.isChoiceLoading = false;
        this.applicantChoices = res.body || [];
      });
  }

  downloadRegistrationFile(applicant: IApplicant): void {
    const body = {
      applicant: applicant?.app_id,
    };
    this.dobService.getDocument(body).subscribe((res) => {
      const doc = res.body;
      const fileLabel = 'fiche_orientation';
      this.base64File = doc;
      const fileName = `${fileLabel.toUpperCase()}_${applicant?.app_lastname?.toUpperCase()}_${applicant?.app_firstname?.toUpperCase()}_SESSION_${
        this.year?.yrs_session
      }.pdf`;
      downloadPdf(this.base64File, fileName);
    });
  }
}
