import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SessionStorageService } from '../session-storage.service';

@Injectable()
export class StepInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const step = JSON.parse(
      this.sessionStorage.getItem('registrationStepItems')!
    )?.step;
    const registrationStatus = JSON.parse(
      this.sessionStorage.getItem('registrationStarted')!
    );
    const registrationRouteList = ['finish', 'infos-student', 'infos-class'];
    const searchPath = this.getPathText();
    const routeChecker = {
      search: 1,
      'infos-student': 2,
      'infos-class': 3,
      validation: 4,
    };

    if (
      registrationStatus &&
      (this.getRoute(step, registrationRouteList, this.router) ||
        this.checkRegistrationRouteWithStep(step, searchPath, routeChecker))
    ) {
      this.router.navigate(['/']);
    }

    return next.handle(request);
  }

  getRoute(step: number, registrationRoute: string[], route: Router): boolean {
    return registrationRoute.includes(this.getPathText()) && step === 1;
  }
  checkRegistrationRouteWithStep(
    step: string,
    search: string,
    routeChecker: any
  ): boolean {
    return routeChecker[search] !== step ? true : false;
  }

  getPathText(): string {
    return this.router.url.split('/').reverse()[0]?.toLowerCase();
  }
}
