import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBank } from '../shared/model/bank.model';
import { INationality } from '../shared/model/nationality.model';
import { IStudent } from '../shared/model/student.model';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  private baseUrl = environment.apiURL + 'api/v1';
  private bankResourceURL = environment.apiURL + 'api/v1/banks';

  constructor(private http: HttpClient) {}

  // step one post
  postInfoBankCandidate(body: FormData): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/bank-candidat/`, body);
  }

  // bank candidate id

  // step one update
  updateInfoBankCandidate(
    idBankCandidate: string,
    body: FormData
  ): Observable<IStudent> {
    return this.http.put<any>(
      `${this.baseUrl}/bank-candidat/${idBankCandidate}/`,
      body
    );
  }

  // step two post
  postSecondInfoBank(body: any) {
    return this.http.post<any>(
      `${this.baseUrl}/candidate-registration-list/`,
      body
    );
  }

  // step two update
  updateSecondInfoBank(id: string, body: FormData) {
    return this.http.put<any>(
      `${this.baseUrl}/candidate-registration-list/${id}/`,
      body
    );
  }

  // get bank list
  getBankList(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.baseUrl}/bank-list/`, {
      observe: 'response',
    });
  }

  getBanks(): Observable<HttpResponse<IBank[]>> {
    return this.http.get<IBank[]>(`${this.bankResourceURL}/`, {
      observe: 'response',
    });
  }

  // get nationality
  getNationalities(): Observable<HttpResponse<INationality[]>> {
    return this.http.get<INationality[]>(`${this.baseUrl}/nationalities/`, {
      observe: 'response',
    });
  }

  // dashboard: get all bank candidate student
  getAllBankCandidate(): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/candidate-registration-list/bank/`,
      { observe: 'response' }
    );
  }

  // status bank candidate
  updateStatusBankCanidate(
    idFcr: any,
    body: FormData
  ): Observable<HttpResponse<any>> {
    return this.http.patch<any>(
      `${this.baseUrl}/validation-candidat/${idFcr}/`,
      body,
      { observe: 'response' }
    );
  }

  // dashboard: get all bank candidate student
  getBankCandidateByBank(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.baseUrl}/bank-candidat/`, {
      observe: 'response',
    });
  }

  // Get bank detail
  // dashboard: get all bank candidate student
  getUserBankDetail(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.baseUrl}/bank-user-detail/`, {
      observe: 'response',
    });
  }

  // candidate validate by bank admin
  bankCandidateValidateByBankAdmin(idFCR: any, body: any): Observable<any> {
    return this.http.patch<any>(
      `${this.baseUrl}/validation-candidat/${idFCR}/`,
      body
    );
  }

  // download register received freecandidate
  getRegisterReceived(idFcr: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/bank/${idFcr}/download-document/`,
      { observe: 'response' }
    );
  }
}
