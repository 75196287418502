import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '../account/auth.service';
import { catchError } from 'rxjs/operators';
import { redirectTo } from '../shared/utils/http.utils';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = request;
    const token = localStorage.getItem('ACCESS_TOKEN');
    if (token) {
      // const cloneReq = request.clone({
      //   headers: request.headers.set('Authorization', 'Bearer ' + token),
      // });
      authReq = this.authService.addTokenHeader(request, token);
      // console.log(this.router.routerState.snapshot)

      // if (request.url.includes('/account/login')) {
      //   alert('login');
      //   const userRole = this.authService.authenticateUserRole();
      //   console.log('userRole', userRole);
      //   redirectTo(userRole, this.router);
      // }

      return next.handle(authReq).pipe(
        catchError((error) => {
          if (
            error instanceof HttpErrorResponse &&
            !request.url.includes('account/login') &&
            error.status === 401
          ) {
            return this.handle401Error(request, next);
          }
          return throwError(this.handleError);
        })
      );
    } else {
      return next.handle(request);
    }
  }

  handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.updateToken(request, next);
  }

  handleError(error: any): any {
    return error;
  }
}
