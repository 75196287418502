import { ISchoolType } from '../model/schooltype.model';

export const SCHOOLTYPE: ISchoolType[] = [
  {
    scty_label: 'PRIVE',
    scty_id: 1,
  },
  {
    scty_label: 'PUBLIC',
    scty_id: 2,
  },
];

export const SCHOOLSTUDYORDER = [
  {
    name: 'ENSEIGNEMENT TECHNIQUE',
    code: 1,
  },
  {
    name: 'FORMATION PROFESSIONNELLE',
    code: 2,
  },
];

export const SCHOOLSTUDYORDERCONCOURS = [
  {
    name: 'ENSEIGNEMENT TECHNIQUE',
    code: 1,
  }
];

export const FILTER_SCHOOL_TYPE = {
  PRI: [
    {
      scty_label: 'PRIVE',
      scty_id: 1,
    },
  ],
  PUB: [
    {
      scty_label: 'PUBLIC',
      scty_id: 2,
    },
  ],
  BOTH: SCHOOLTYPE,
};
