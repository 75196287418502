import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { InscriptionRoutingModule } from './inscription-routing.module';
import { ExamenComponent } from './examen/examen.component';
import { ExamenRechercheComponent } from './examen-recherche/examen-recherche.component';
import { ExamenHeaderComponent } from './examen-header/examen-header.component';
import { ExamenBreadcrumbComponent } from './examen-breadcrumb/examen-breadcrumb.component';
import { ExamenEnregistrementComponent } from './examen-enregistrement/examen-enregistrement.component';
import { ExamenValidationComponent } from './examen-validation/examen-validation.component';
import { ExamenInfoComponent } from './examen-info/examen-info.component';
import { ExamenStudentFormComponent } from './examen-student-form/examen-student-form.component';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ExamenRegistredDetailComponent } from './examen-registred-detail/examen-registred-detail.component';
import { ExamenFreeCandidateComponent } from './examen-free-candidate/examen-free-candidate.component';
import { ExamenFreeSearchComponent } from './examen-free-search/examen-free-search.component';
import { ExamenFreeInfoComponent } from './examen-free-info/examen-free-info.component';
import { ExamenFreeFiliereComponent } from './examen-free-filiere/examen-free-filiere.component';
import { ExamenFreeConfirmationComponent } from './examen-free-confirmation/examen-free-confirmation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ExamenFreeFinishComponent } from './examen-free-finish/examen-free-finish.component';




@NgModule({
  declarations: [
    ExamenComponent,
    ExamenRechercheComponent,
    ExamenHeaderComponent,
    ExamenBreadcrumbComponent,
    ExamenEnregistrementComponent,
    ExamenValidationComponent,
    ExamenInfoComponent,
    ExamenStudentFormComponent,
    ExamenRegistredDetailComponent,
    ExamenFreeCandidateComponent,
    ExamenFreeSearchComponent,
    ExamenFreeInfoComponent,
    ExamenFreeFiliereComponent,
    ExamenFreeConfirmationComponent,
    ExamenFreeFinishComponent,
  ],
  imports: [SharedModule, InscriptionRoutingModule, ReactiveFormsModule, FormsModule, RouterModule],
  providers: [MessageService, ConfirmationService],
})
export class InscriptionModule {}
