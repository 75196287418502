import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOB_ITEMS_KEY } from '../../shared/constant/step.constant';
import { IDOBStep } from '../components/dob-layout/dob-layout.component';

interface IStepData {
  step: number;
  data: IDOBStep[];
}
@Injectable({
  providedIn: 'root',
})
export class StepsService {
  private steps$ = new BehaviorSubject<IDOBStep[]>([]);

  steps: IDOBStep[] = [
    {
      label: 'Vérification',
      index: 1,
      detail: 'Vérifiction du matricule MENA',
      last: false,
      active: true,
      validate: false,
    },
    {
      label: 'Identification',
      index: 2,
      detail: 'Enregistrement informations candidat',
      last: false,
      active: false,
      validate: false,
    },
    {
      label: 'Choix',
      index: 3,
      detail: "Selection des choix d'orientation",
      last: false,
      active: false,
      validate: false,
    },
    {
      label: 'Impression fiche d’orientation',
      index: 4,
      detail: 'Récapitulatif et document',
      last: true,
      active: false,
      validate: false,
    },
  ].sort((a: any, b: any) => {
    return a.index! < b.index! ? -1 : 1;
  });
  constructor() {}

  init(storage: Storage): void {
    if (!storage.getItem(DOB_ITEMS_KEY)) {
      const stepData = this.steps;
      const currentStep = this.steps.filter((element) => element.active)[0]
        ?.index;

      storage.setItem(
        DOB_ITEMS_KEY,
        JSON.stringify({ step: currentStep, data: stepData })
      );
      this.steps$.next(stepData);
    }
  }

  getStepItems(storage: Storage): Observable<IDOBStep[]> {
    const stepData = this.getStepData(storage);
    this.steps$.next(stepData?.data);
    return this.steps$.asObservable();
  }

  setItems(storage: Storage): void {
    const data = JSON.parse(storage.getItem(DOB_ITEMS_KEY)!).data || [];
    this.steps$.next(data);
  }

  getStepData(storage: Storage): IStepData {
    return JSON.parse(storage.getItem(DOB_ITEMS_KEY)!);
  }

  setActiveStep(storage: Storage, items: IDOBStep[]): void {
    const currentStep = JSON.parse(storage.getItem(DOB_ITEMS_KEY)!).step;
    items.forEach((item) => {
      if (item.index === Number(currentStep)) {
        item.active = true;
      }
    });
  }

  nextStep(storage: Storage, step: number, data: IDOBStep[]): void {
    storage.setItem(DOB_ITEMS_KEY, JSON.stringify({ step: step + 1, data }));
  }

  prevStep(storage: Storage, step: number, data: IDOBStep[]): void {
    storage.setItem(DOB_ITEMS_KEY, JSON.stringify({ step: step - 1, data }));
  }

  validateStep(storage: Storage, direction: string): void {
    const { step, data } = this.getStepData(storage);
    data.forEach((item) => {
      if (item.index === Number(step) && direction === 'next') {
        item.active = false;
        item.validate = true;
      } else if (item.index === Number(step) && direction === 'prev') {
        item.active = false;
        item.validate = false;
      }
    });

    if (direction === 'next') {
      this.nextStep(storage, step, data);
    } else if (direction === 'prev') {
      this.prevStep(storage, step, data);
    }
  }

  activeStep(storage: Storage): void {
    const { step, data } = this.getStepData(storage);
    data.forEach((item) => {
      if (item.index === Number(step)) item.active = true;
    });
  }

  setNextStep(storage: Storage): void {
    this.validateStep(storage, 'next');
    this.activeStep(storage);
    this.setItems(storage);
  }

  setPrevStep(storage: Storage): void {
    this.validateStep(storage, 'prev');
    this.activeStep(storage);
    this.setItems(storage);
  }
}
