import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-examen-free-finish',
  templateUrl: './examen-free-finish.component.html',
  styleUrls: ['./examen-free-finish.component.scss']
})
export class ExamenFreeFinishComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
