import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { IExamenBreadcrumb } from 'src/app/shared/model/examen-breadcrumb.model';
import {
  activateStep,
  setActiveStep,
  setPrevStep,
} from 'src/app/shared/utils/step.utils';
import { SchoolService } from '../../services/school.service';
import { ISchool } from '../../shared/model/school.model';
import { Observable, Subscription } from 'rxjs';
import { IDiploma } from '../../shared/model/diploma.model';
import { DiplomaService } from '../../services/diploma.service';
import { IStudyField } from 'src/app/shared/model/studyfield.model';
import { StudyfieldService } from '../../services/studyfield.service';
import { IRegistration } from '../../shared/model/registration.model';
import { RegistrationService } from '../../services/registration.service';
import { ExamregistrationService } from '../../services/examregistration.service';
import { ExamRegistration } from 'src/app/shared/model/examregistration.model';
import { IStudent } from '../../shared/model/student.model';
import { InscriptionService } from '../inscription.service';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { IExamUnit } from 'src/app/shared/model/examunit.model';
import { BREADCRUMB_ITEMS_KEY } from 'src/app/shared/constant/step.constant';
import { SessionStorageService } from 'src/app/session-storage.service';
@Component({
  selector: 'app-examen-info',
  templateUrl: './examen-info.component.html',
  styleUrls: ['./examen-info.component.scss'],
})
export class ExamenInfoComponent implements OnInit, OnDestroy {
  items: IExamenBreadcrumb[] = [];

  editForm = this.fb.group({
    school: [null, []],
    diploma: [null, []],
    sector: [null, []],
    unity: [null, []],
  });

  // schools = [];
  schools$?: Observable<ISchool[]>;
  schools: ISchool[] = [];
  selectedSchool?: ISchool;

  diplomas$?: Observable<IDiploma[]>;
  diplomas: IDiploma[] = [];
  selectedDiploma?: IDiploma;

  sectors$?: Observable<IStudyField[]>;
  selectedSector?: IStudyField;

  registratedStudent$?: Observable<IRegistration>;

  student$?: Observable<IStudent>;

  curStudent?: IStudent;

  examunits: IExamUnit[] = [];

  selectedUnity: IExamUnit[] = [];

  subcription?: Subscription;

  typeCandidature = 1;

  loaded = false;

  displayDialog = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private schoolService: SchoolService,
    private diplomaService: DiplomaService,
    private studyfieldSercice: StudyfieldService,
    private registrationService: RegistrationService,
    private examregistrationService: ExamregistrationService,
    private inscriptionService: InscriptionService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnDestroy(): void {
    this.messageService.clear();
    this.confirmationService.close();
  }

  ngOnInit(): void {
    const currentStep = this.getStepDataFromSS()?.step;
    this.items = this.getStepDataFromSS()?.data;
    setActiveStep(this.items, currentStep);
    const ref = JSON.parse(sessionStorage.getItem('exam')!)?.matricule;
    const year = JSON.parse(sessionStorage.getItem('activeYear')!);

    this.typeCandidature = JSON.parse(
      sessionStorage.getItem('exam')!
    )?.typeCandidature;

    this.schoolService
      .getSchools()
      .subscribe((schools) => (this.schools = schools || []));
    this.diplomas$ = this.diplomaService.getDiplomas();
    this.sectors$ = this.studyfieldSercice.getStudyField();
    this.student$ = this.inscriptionService.get(ref);

    this.student$.subscribe((student) => (this.curStudent = student));

    this.diplomas$.subscribe((diplomas) => (this.diplomas = diplomas || []));

    this.registratedStudent$ = this.registrationService.getRegistratedStudent(
      ref,
      year
    );

    this.registratedStudent$.subscribe((regisration) => {
      if (regisration) {
        this.selectedDiploma = regisration?.reg_diploma;
        this.selectedSector = regisration?.reg_studyfield;
        this.selectedSchool = regisration?.reg_school;

        this.editForm.patchValue({
          school: regisration?.reg_school,
          diploma: regisration?.reg_diploma,
          sector: regisration?.reg_studyfield,
        });
      }

      if (regisration?.reg_diploma && regisration?.reg_student) {
        this.examunits = regisration?.reg_diploma?.dip_examunits!;
        if (
          this.typeCandidature &&
          (this.typeCandidature === 1 || this.typeCandidature === 2)
        ) {
          this.selectedUnity = this.examunits;
        }

        this.examunits = this.examunits.sort((exam_1, exam_2) =>
          exam_1.uni_shortlabel! > exam_2.uni_shortlabel! ? 1 : -1
        );
      }
    });
  }

  saveStudentPreregistration(): void {
    // const ref = JSON.parse(sessionStorage.getItem('exam')!)?.matricule;
    this.loaded = true;

    this.registratedStudent$?.subscribe((registration) => {
      if (registration && registration?.reg_student && registration?.reg_year) {
        const examRegistration = new ExamRegistration(
          registration?.reg_student,
          registration?.reg_year
        );

        this.subcription = this.examregistrationService
          .save({
            exam: examRegistration,
            typeCandidature: this.typeCandidature,
          })
          .subscribe({
            next: (data) => {
              sessionStorage.setItem(
                'examregistration',
                JSON.stringify(data.body?.exa_fiche)
              );
              this.loaded = false;
              this.goToNext();
            },
            error: () => {
              this.loaded = false;
              this.messageService.add({
                severity: 'error',
                summary: 'Pré-enregistrement',
                sticky: true,
                detail: 'Votre pré-enregistrement a échoué',
              });
            },

            complete: () => {
              this.loaded = false;
              this.subcription?.unsubscribe();
            },
          });
      }
    });
  }

  showDialog() {
    this.displayDialog = true;
  }

  confirm(): void {
    if (this.editForm.dirty) {
      this.edit();
    }

    this.displayDialog = false;
    this.saveStudentPreregistration();
  }

  edit(): void {
    const year = JSON.parse(sessionStorage.getItem('activeYear')!);
    const ref = JSON.parse(sessionStorage.getItem('exam')!)?.matricule;
    this.registrationService
      .editRegistrationSchoolOrStudyFieldOrDiploma(
        ref,
        year,
        this.editForm.value
      )
      .subscribe((res) => {
        const registration = res.body;
        this.messageService.add({
          severity: 'success',
          detail: 'Informations modifiées avec succèss',
        });
      });
  }

  // confirm(event: Event) {
  //   this.confirmationService.confirm({
  //     target: event.target!,
  //     message: 'Etes-vous sure de continuer?',
  //     icon: 'pi pi-question-circle',
  //     acceptLabel: 'Continuer',
  //     rejectLabel: 'Annuler',
  //     accept: () => {
  //       if (this.editForm.dirty) {
  //         this.edit();
  //       }
  //       this.saveStudentPreregistration();
  //     },
  //     reject: () => {
  //       this.messageService.add({
  //         severity: 'warn',
  //         summary: 'Annulé',
  //         detail: "Vous avez annuler l'action",
  //       });
  //     },
  //   });
  // }

  loadExamunit(): void {
    this.diplomaService
      .getDiplomaExamunit(this.selectedDiploma!)
      .subscribe((res) => {
        const examunits =
          res.body?.sort((exam_1, exam_2) =>
            exam_1.uni_shortlabel! > exam_2.uni_shortlabel! ? 1 : -1
          ) || [];

        if (examunits) {
          this.selectedUnity = examunits;
          this.examunits = examunits;
          this.editForm.patchValue({
            unity: examunits,
          });
        }
      });
  }

  goToNext(): void {
    activateStep(this.getStepDataFromSS(), this.sessionStorage);
    this.router.navigate(['/inscription', 'examen', 'validation']);
  }

  goPrev(): void {
    setPrevStep(this.getStepDataFromSS(), this.sessionStorage);
    this.router.navigate(['/inscription', 'examen', 'info-candidat']);
  }

  getStepDataFromSS(): any {
    return JSON.parse(sessionStorage.getItem(BREADCRUMB_ITEMS_KEY)!);
  }
}
