<app-navbar></app-navbar>

<div class="dob-wrapper">
  <div class="dob-header">
    <h1 class="dob-header-title">
      Concours d'entrée dans les établissements de la Formation Professionelle
    </h1>

    <!-- <p>
      Add a payment details and we will send you the evaluation package within
      24 hrs. 9
    </p> -->
  </div>

  <div class="dob-steps">
    <ul class="dob-step-list">
      <li
        class="dob-step-item"
        [ngClass]="{ active: item?.active!, validate: item?.validate! }"
        *ngFor="let item of items$ | async"
      >
        <span class="dob-step-item-index">
          <span *ngIf="!item?.validate! || (item?.validate && item?.active)">
            {{ item?.index! }}</span
          >
          <span *ngIf="item?.validate! && !item?.active"
            ><i class="pi pi-check"></i
          ></span>
        </span>
        <div class="dob-step-item-content">
          <span class="dob-step-item-label">{{ item?.label }}</span>
          <!-- <span class="dob-step-item-detail">{{ item?.detail }}</span> -->
        </div>
      </li>
    </ul>
  </div>

  <div class="dob-main-content">
    <router-outlet></router-outlet>
  </div>
</div>
