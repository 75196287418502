<div class="dashboard-validation-grid">
  <div class="dashboard-validation-navbar">
    <app-dashboard-validation-navbar></app-dashboard-validation-navbar>
  </div>

  <div class="dashboard-validation-sidebar">
    <app-dashboard-validation-sidebar></app-dashboard-validation-sidebar>
  </div>

  <div class="dashboard-validation-content">
    <router-outlet></router-outlet>
  </div>
</div>
