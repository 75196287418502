import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  BehaviorSubject,
  Observable,
  of,
  ReplaySubject,
  throwError,
} from 'rxjs';
import { catchError, shareReplay, switchMap, tap } from 'rxjs/operators';
import { IUser } from './model/user.model';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import {
  ACCESS_TOKEN_NAME,
  REFRESH_TOKEN_NAME,
  TOKEN_HEADER_KEY,
} from '../shared/constant/token.constant';
import { ISchool } from '../shared/model/school.model';
import { environment } from 'src/environments/environment';
import { map } from 'ramda';
import { SessionStorageService } from 'src/app/session-storage.service';

export interface IToken {
  access: string;
  refresh: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authenticationResourceURL = environment.apiURL + 'auth';
  private userResourceURL = environment.apiURL + 'api/v1/users';
  private tokenResourceURL = environment.apiURL + 'api/v1/token';
  private schoolResourceURL = environment.apiURL + 'api/v1/schools';
  private updateUserResourceURL = environment.apiURL + 'account/update-user';
  private registerResourceURL = environment.apiURL + 'api/v1/account/register';

  private _isUserLoggedIn$ = new BehaviorSubject<boolean>(false);
  isLogginIn$ = this._isUserLoggedIn$.asObservable();

  private _currentUserSubject$: BehaviorSubject<IUser> =
    new BehaviorSubject<IUser>({});
  private _authUser = new ReplaySubject<IUser | null>(1);
  public user?: IUser;
  public currentUser?: IUser;
  // public user

  private TOKEN_NAME = ACCESS_TOKEN_NAME;

  constructor(
    private http: HttpClient,
    private jwtHelperService: JwtHelperService,
    private router: Router,
    private storage: SessionStorageService
  ) {
    this._isUserLoggedIn$.next(!!this.token);
    // this.user = this._currentUserSubject$.asObservable();
  }

  authenticate(account: IUser) {
    this.user = account;
    this._authUser.next(this.user);
  }

  getUserById(user_id: number): Observable<IUser> {
    return this.http.get<IUser>(`${this.userResourceURL}/${user_id}/`);
  }

  get userID(): string {
    const tokenDecode = this.getAuthenticatedUser(this.token);
    return tokenDecode?.user_id;
  }

  getAuthUser(): Observable<IUser> {
    const tokenDecode = this.getAuthenticatedUser(this.token);
    const authUserId = tokenDecode?.user_id;
    return this.getUserById(authUserId);
  }

  // getAuthRelatedModel(): Observable<HttpResponse<any>> {
  // return this.http.get(userResourceURL);
  // }

  getAuthSchool(): Observable<HttpResponse<ISchool>> {
    return this.http.get<ISchool>(
      `${this.schoolResourceURL}/${this.userID}/user-school/`,
      {
        observe: 'response',
      }
    );
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(`${this.tokenResourceURL}/`, {
        email,
        password,
      })
      .pipe(
        catchError(this.handleError),
        tap((response: any) => {
          if (response) {
            this.setToken(response);
            this.getAuthenticatedUser(response.access);
          }
        })
      );
  }

  handleError(error: HttpErrorResponse): any {
    console.log('Error => ', error, error.status);
    return error;
  }

  get token(): any {
    return localStorage.getItem(ACCESS_TOKEN_NAME);
  }

  get accessToken(): string {
    return JSON.parse(localStorage.getItem(ACCESS_TOKEN_NAME)!);
  }

  getAuthenticatedUser(token: any): any {
    return jwt_decode(token);
  }

  setToken(token: IToken) {
    localStorage.setItem(ACCESS_TOKEN_NAME, token.access);
    localStorage.setItem(REFRESH_TOKEN_NAME, token.access);
  }

  get refreshToken(): any {
    return localStorage.getItem(REFRESH_TOKEN_NAME)!;
  }

  updateToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const body = { refresh: this.refreshToken };

    console.log('Expired Refresh Token =>', this.refreshToken);

    return this.http
      .post<any>(`${this.tokenResourceURL}/refresh/`, body, {
        observe: 'response',
      })
      .pipe(
        switchMap((token: any) => {
          this.setToken(token);
          return next.handle(this.addTokenHeader(request, token));
        }),
        catchError((error) => {
          this.logout();
          console.log(request.url);
          return throwError(error);
        })
      );
  }

  logout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(REFRESH_TOKEN_NAME);
    this.storage.clear();
    this._currentUserSubject$.next({});
    this.router.navigate(['/account', 'login']);
  }

  addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token),
    });
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem(ACCESS_TOKEN_NAME);
    return !this.jwtHelperService.isTokenExpired(token!);
  }

  authenticateUserRole(): string {
    return jwt_decode<any>(this.token).role!;
  }

  authenticatedUserFirstConnection(): boolean {
    return jwt_decode<any>(this.token).is_first_connection!;
  }

  getAuthenticationState(): Observable<IUser> {
    return this.getAuthUser();
  }

  updateRegistratedUser(
    uid: string,
    token: string,
    body: any
  ): Observable<HttpResponse<IUser>> {
    return this.http.patch<IUser>(
      `${this.updateUserResourceURL}/${uid}/${token}`,
      body,
      {
        observe: 'response',
      }
    );
  }

  registerUser(body: any): Observable<HttpResponse<IUser | any>> {
    return this.http.post<IUser | any>(`${this.registerResourceURL}/`, body, {
      observe: 'response',
    });
  }

  toggleActivationState(
    user_id: string,
    body: any
  ): Observable<HttpResponse<IUser | any>> {
    return this.http.patch<IUser | any>(
      `${this.userResourceURL}/${user_id}/update-status/`,
      body,
      {
        observe: 'response',
      }
    );
  }
}
