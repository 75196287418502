import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { includes, values } from 'ramda';
import { map, take } from 'rxjs';
import { SchoolService } from 'src/app/services/school.service';
import { StudyfieldService } from 'src/app/services/studyfield.service';
import { YearService } from 'src/app/services/year.service';
import { SessionStorageService } from 'src/app/session-storage.service';
import { ISchool } from 'src/app/shared/model/school.model';
import { IStudyField } from 'src/app/shared/model/studyfield.model';
import { IYear } from 'src/app/shared/model/year.model';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';
import { IApplicant } from '../../models/applicant.model';
import { Choice, IChoice } from '../../models/choice.model';
import {
  MAX_CHOICE_NUMBER,
  MIN_CHOICE_NUMBER,
} from '../../constant/choice.constant';
import { Message } from 'primeng/api';
import { Messages } from 'primeng/messages';
import {
  SCHOOLSTUDYORDERCONCOURS,
  SCHOOLTYPE,
  FILTER_SCHOOL_TYPE,
} from 'src/app/shared/constant/schooltype.constant';
import { ILocation } from '../../../shared/model/location.model';
import { LocationService } from '../../../services/location.service';
import { ISchoolType } from 'src/app/shared/model/schooltype.model';
import { EexamsService } from '../../eexams.service';

interface IPriorityChoice {
  name: string;
  code: number;
}

export interface ISelectedChoiceData {
  preference: number[];
  school: string[];
  studyfield: string[];
}

@Component({
  selector: 'app-ee-choice-form',
  templateUrl: './ee-choice-form.component.html',
  styleUrls: ['./ee-choice-form.component.scss']
})
export class EeChoiceFormComponent implements OnInit {

  loaded = false;
  searching = false;

  @Output()
  choice: EventEmitter<IChoice> = new EventEmitter();

  @Input()
  selectedChoicesData?: ISelectedChoiceData;

  studyfields: IStudyField[] = [];
  selectedStudyfield: any;

  filterSchools: ISchool[] = [];
  schools: ISchool[] = [];
  selectedSchool: any;

  preferences: IPriorityChoice[] = [];
  selectedPriority?: number;

  editForm = this.fb.group({
    chx_studyfield: [[], [Validators.required]],
    chx_school: [[], [Validators.required]],
    location: [[], [Validators.required]],
    studyFieldType: [[], [Validators.required]],
    schoolType: [[], [Validators.required]],
  });

  activeYear?: IYear;
  activeApplicant?: IApplicant;

  choices: IChoice[] = [];
  selectedPreferences: number[] = [];
  selectedStudyfields: string[] = [];
  selectedSchools: string[] = [];

  tempChoices: Choice[] = [];
  choiceIndex = 1;

  msgs: Message[] = [];

  schooltypeList = SCHOOLTYPE;
  filterSchoolTypeList: ISchoolType[] = [];
  selectedSchoolType = 0;

  schoolstudyorderList = SCHOOLSTUDYORDERCONCOURS;
  selectedSchoolStudyOrder = 1;

  locations: ILocation[] = [];
  selectedLocation: any;

  constructor(
    private fb: FormBuilder,
    private studyFieldService: StudyfieldService,
    private yearService: YearService,
    private eexamsService: EexamsService,
    private storageService: SessionStorageService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.loadPage();
    this.handleLoadStudyfield()
  }

  loadPage(): void {
    this.resetForm();
    // this.loadSchools()
    // this.loadStudyfieldByDiplomas();
    this.loadLocations();
    this.getActiveYear();
    this.geApplicant(this.storageService);
  }

  loadSchools(): void {
    // this.schoolService.getSchools()
  }

  loadLocations(): void {
    this.locationService
      .getLocations()
      .subscribe((res) => (this.locations = res.body || []));
  }

  fieldIsRequired(control: AbstractControl): string {
    if (hasRequiredField(control)) {
      return '*';
    }

    return '';
  }

  handleLoadSelectedStudyfieldSchool(): void {
    this.selectedSchool = null;
    this.selectedLocation = null;
    this.selectedSchoolType = 0;

    if (this.selectedStudyfield) {
      this.handleValidateChoice(this.selectedStudyfield);
    }
  }

  loadStudyfieldByDiplomas(): void {
    this.searching = true;
    this.studyFieldService.loadStudyFieldByDiplomas().subscribe((res) => {
      this.searching = false;
      this.studyfields = res.body || [];
    });
  }

  getActiveYear(): void {
    this.yearService
      .geActiveYear()
      .subscribe((year) => (this.activeYear = year!));
  }

  geApplicant(storage: Storage): void {
    const ref = storage.getItem('DOB_APPLICANT_REF');
    this.eexamsService.geApplicantByRef(ref!).subscribe((res) => {
      this.activeApplicant = res.body.data;
      this.getApplicantChoices(this.activeApplicant?.app_id!);
    });
  }

  schoolData(): ISchool {
    return this.schools.filter(
      (school) => school.sch_id == this.selectedSchool
    )[0];
  }

  studyfieldData(): IStudyField {
    return this.studyfields.filter(
      (school) => school.sfd_id == this.selectedStudyfield
    )[0];
  }

  handleAddNewChoice(): void {
    // const school = this.schoolData();
    const studyfield = this.studyfieldData();
    this.choiceIndex = this.tempChoices?.length
      ? this.tempChoices?.length + 1
      : 1;
    // }

    // this.choiceIndex = storageChoices?.length ? storageChoices?.length + 1 : 1;

    const data = {
      chx_session: this.activeYear,
      chx_applicant: this.activeApplicant,
      chx_school: this.selectedSchool,
      chx_studyfield: studyfield,
      chx_preference: this.choiceIndex,
    };

    this.loadPage();
    this.choice.emit(data);
  }

  handleRemoveChoice(): void {}

  updateIndex(): void {
    this.choiceIndex += 1;
  }

  setChoicesToSS(storage: Storage): void {
    storage.setItem('DOB_APPLICANT_CHOICES', JSON.stringify(this.tempChoices));
  }

  getApplicantChoices(applicant_id: string): void {
    this.eexamsService.getChoiceByApplicant(applicant_id).subscribe((res) => {
      this.choices = res.body || [];
      // this.tempChoices = this.choices;

      if (
        this.choices.length !== 0 &&
        JSON.parse(this.storageService.getItem('DOB_APPLICANT_CHOICES')!) &&
        JSON.parse(this.storageService.getItem('DOB_APPLICANT_CHOICES')!)
          .length === 0
      ) {
        this.tempChoices = this.choices;
        this.storageService.setItem(
          'DOB_APPLICANT_CHOICES',
          JSON.stringify(this.tempChoices)
        );
      } else {
        this.loadChoicesFromStorage();
      }
    });
  }

  loadChoicesFromStorage(): void {
    this.eexamsService.getChoices().subscribe((choices) => {
      this.tempChoices = choices;
      this.choiceIndex = this.tempChoices?.length!
        ? this.tempChoices?.length
        : 1;

      this.selectedPreferences = this.tempChoices?.map(
        (choice) => choice.chx_preference!
      );

      this.selectedSchools = this.tempChoices?.map(
        (choice) => choice.chx_school?.sch_id!
      );

      this.selectedStudyfields = this.tempChoices?.map(
        (choice) => choice.chx_studyfield?.sfd_id!
      );
    });
  }

  handleValidateChoice(studyfield_id: string): void {
    this.msgs = [];
    const body = {
      studyfield: studyfield_id,
      applicant: {
        app_id: "01e67fcb-1037-495f-a371-5598c0378bbb",
        created_at: "2022-04-01T11:29:32.687110",
        updated_at: "2022-06-13T12:42:36.427589",
        is_disabled: false,
        app_matricule: "17584676U",
        app_lastname: "BOBLIN",
        app_firstname: "BI TRA DAVID ROCH AROLD",
        app_birthdate: "2007-01-19",
        app_birthplace: "ABOBO",
        app_gender: "M",
        app_email: "",
        app_phone: "0586492758",
        app_authcode: "1901",
        app_nationality: {
            nat_id: "af25d945-5fd5-4fd3-988e-743e26420ecc",
            created_at: "2022-02-01T08:21:23.193647",
            updated_at: "2022-02-01T08:21:23.193647",
            is_disabled: false,
            nat_label: "Ivoirienne (Côte d'Ivoire)"
            }
      },
    };
    this.searching = true;
    this.eexamsService.validateChoiceStudyField(body).subscribe((res) => {
      const data = res.body;
      if (data.result === -1) {
        this.msgs = [{ severity: 'error', detail: data.message }];
        this.editForm.reset({ chx_school: null });
        this.searching = false;
      } else {
        this.studyFieldService
          .loadStudyfieldSchoolsList(this.selectedStudyfield!)
          .pipe(map((value) => value.body))
          .pipe(
            map((value) => {
              const tempLocation: ILocation[] = [];
              value?.forEach((school) => {
                const location = school.sch_location;
                if (
                  !tempLocation
                    .map((loc) => loc.loc_id)
                    .includes(location?.loc_id)
                ) {
                  tempLocation.push(location!);
                }
              });
              this.locations = [...tempLocation];

              return value?.filter(
                (item: ISchool) =>
                  !this.tempChoices
                    ?.map(
                      (choice) =>
                        choice.chx_school?.sch_id +
                        '-' +
                        choice?.chx_studyfield?.sfd_id
                    )
                    .includes(item.sch_id + '-' + this.selectedStudyfield)
              );
            })
          )
          .subscribe((schools) => {
            this.schools = schools || [];
            this.searching = false;
          });
      }
    });
  }

  handleSelectLocation(): void {
    this.selectedSchoolType = 0;

    if (this.selectedLocation) {
      this.filterSchools = this.schools.filter(
        (school) => school.sch_location?.loc_id == this.selectedLocation
      );

      if (
        this.filterSchools.every((school) => school.sch_type?.scty_id === 1)
      ) {
        this.filterSchoolTypeList = FILTER_SCHOOL_TYPE.PRI;
      } else if (
        this.filterSchools.every((school) => school.sch_type?.scty_id === 2)
      ) {
        this.filterSchoolTypeList = FILTER_SCHOOL_TYPE.PUB;
      } else {
        this.filterSchoolTypeList = FILTER_SCHOOL_TYPE.BOTH;
      }
    }
  }

  handleSelectSchoolType(): void {
    this.selectedSchool = null;

    if (this.selectedSchoolType && this.filterSchools) {
      this.filterSchools = this.filterSchools.filter(
        (school) => school.sch_type?.scty_id === this.selectedSchoolType
      );
    }

    if (this.activeApplicant?.app_gender === 'M') {
      this.filterSchools = this.filterSchools.filter(
        (school) => !school.sch_longlabel?.toLowerCase().includes('yarani')
      );
    }
  }

  handleLoadStudyfield() {
    this.selectedStudyfield = null;
    this.searching = true;

    if (Number(this.selectedSchoolStudyOrder) === 1) {
      this.studyFieldService.loadTechnicalStudyFieldList().subscribe((res) => {
        this.studyfields = res.body || [];
        this.searching = false;
      });
    } else if (Number(this.selectedSchoolStudyOrder) === 2) {
      this.studyFieldService
        .loadProfessionalStudyFieldList()
        .subscribe((res) => {
          this.studyfields = res.body || [];
          this.searching = false;
        });
    }
  }

  resetForm(): void {
    this.editForm.reset();
  }

  isChoiceFull(): boolean {
    const storageChoices: IChoice[] = JSON.parse(
      this.storageService.getItem('DOB_APPLICANT_CHOICES')!
    );

    if (
      this.tempChoices &&
      storageChoices &&
      storageChoices.length !== this.tempChoices?.length
    ) {
      return storageChoices.length >= MAX_CHOICE_NUMBER;
    }
    return this.tempChoices?.length >= MAX_CHOICE_NUMBER;
  }

  isFilterDefined(): boolean {
    return (
      this.selectedLocation &&
      this.selectedSchoolStudyOrder &&
      this.selectedSchoolType
    );
  }

}
