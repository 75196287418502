import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';


import { ConcoursRoutingModule } from './concours-routing.module';
import { ConcoursComponent } from './concours/concours.component';
import { UserInfoComponent } from './user-info/user-info.component';


@NgModule({
  declarations: [
    ConcoursComponent,
    UserInfoComponent
  ],
  imports: [
    CommonModule,
    ConcoursRoutingModule,
    SharedModule
  ]
})
export class ConcoursModule { }
