import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IApplicant } from 'src/app/dob/models/applicant.model';
import { IAPIResponse } from '../../shared/model/apiresponse.model';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  orientationResourceURL = environment.apiURL + 'api/v1/orientations';

  constructor(private http: HttpClient) {}

  searchApplicantByReferenceOrName(body: any): Observable<IAPIResponse> {
    return this.http
      .post<IApplicant>(
        `${this.orientationResourceURL}/validation/search/`,
        body,
        { observe: 'body' }
      )
      .pipe(catchError((err) => of(err)));
  }
}
