import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { sidebarItems } from 'src/app/shared/constant/registration-sidebar.constant';
import { IExamenBreadcrumb } from '../../shared/model/examen-breadcrumb.model';
import { StepService } from '../step.service';
import { SessionStorageService } from '../../session-storage.service';

@Component({
  selector: 'app-registration-sidebar',
  templateUrl: './registration-sidebar.component.html',
  styleUrls: ['./registration-sidebar.component.scss'],
})
export class RegistrationSidebarComponent implements OnInit {
  items: IExamenBreadcrumb[] = [];
  itemsLength = 0;

  constructor(
    private stepService: StepService,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.stepService.getSidebarItems(this.sessionStorage).subscribe((data) => {
      this.items = data;
      this.itemsLength = this.items?.length;
    });
  }
}
