import { Component, OnInit } from '@angular/core';
import { IApplicant } from 'src/app/dob/models/applicant.model';
import { IChoice } from 'src/app/dob/models/choice.model';
import { ValidationService } from '../services/validation.service';
import { DobService } from '../../dob/dob.service';
import { IAPIResponse, IStatus } from 'src/app/shared/model/apiresponse.model';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DashboardRegionalOrientationEditNationalityComponent } from '../dashboard-regional-orientation-edit-nationality/dashboard-regional-orientation-edit-nationality.component';
import { DashboardRegionalOrientationEditBirthdateComponent } from '../dashboard-regional-orientation-edit-birthdate/dashboard-regional-orientation-edit-birthdate.component';
import { IUser } from 'src/app/account/model/user.model';
import { AuthService } from 'src/app/account/auth.service';
import { IOATValidation } from 'src/app/dob/models/validation.model';

@Component({
  selector: 'app-dashboard-regional-orientation-validation',
  templateUrl: './dashboard-regional-orientation-validation.component.html',
  styleUrls: ['./dashboard-regional-orientation-validation.component.scss'],
})
export class DashboardRegionalOrientationValidationComponent implements OnInit {
  search = '';
  isSearch: boolean = false;
  applicant?: IApplicant;
  choices: IChoice[] = [];
  isApplicantSelected = false;
  selectedApplicant?: IApplicant;
  isSign: boolean = false;
  isIvorian: boolean = false;
  isBirthDateOK: boolean = false;
  msgs: Message[] = [];
  isFailOrError = false;
  authUser?: IUser;
  validation?: IOATValidation;

  constructor(
    private validationService: ValidationService,
    private dobService: DobService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.getAuthUser().subscribe((user) => (this.authUser = user));
  }

  searchStudent(): void {
    this.isFailOrError = false;
    this.msgs = [];
    if (this.search) {
      this.isSearch = true;
      const body = { search: this.search };
      this.validationService.searchApplicantByReferenceOrName(body).subscribe({
        next: (res) => {
          this.isSearch = false;

          if (res.status === 'FAIL') {
            this.applicant = undefined;
            this.isFailOrError = true;
            this.isApplicantSelected = false;
            this.msgs = [
              {
                severity: 'error',
                summary: '',
                detail: `${res.types
                  ?.map((type) => res.data[type])
                  .join(', ')}`,
              },
            ];
          } else if (res.status === 'SUCCESS') {
            this.applicant = res.data['applicant'];
            this.choices = res.data['choices'];
            this.validation = res.data['validation'] as IOATValidation;
            this.selectedApplicant = this.applicant;
            this.isApplicantSelected = true;

            this.setValidationChecklist(this.validation);
          }
        },

        error: (err) => {
          console.log(err);
        },
      });
    } else {
      this.isSearch = false;
      this.applicant = undefined;
    }
  }

  setValidationChecklist(validation: IOATValidation): void {
    if (validation.oval_id) {
      this.isSign = validation?.oval_documentsign!;
      this.isIvorian = validation?.oval_nationality!;
      this.isBirthDateOK = validation?.oval_birthdate!;
    } else {
      this.isSign = false;
      this.isIvorian = false;
      this.isBirthDateOK = false;
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.searchStudent();
    }
  }

  selectStudent(applicant: IApplicant): void {
    this.isSearch = false;
    this.isApplicantSelected = true;
    this.search =
      applicant.app_lastname?.toUpperCase() +
      ' ' +
      applicant.app_firstname?.toUpperCase();
    this.selectedApplicant = applicant;
  }

  handleNewValidation(): void {
    this.search = '';
    this.isApplicantSelected = false;
    this.applicant = undefined;
    this.selectedApplicant = undefined;
    this.isIvorian = false;
    this.isBirthDateOK = false;
    this.isSign = false;
    this.isFailOrError = false;
  }

  editNationality(event: IApplicant): void {
    const config: DynamicDialogConfig = {
      modal: true,
      header: 'Modification de la nationalité',
      width: '500px',
      closable: true,
      data: {
        applicant: this.applicant,
      },
    };
    const dialog = this.dialogService.open(
      DashboardRegionalOrientationEditNationalityComponent,
      config
    );

    dialog.onClose.subscribe((res: IAPIResponse) => {
      if (res && res.status === 'SUCCESS') {
        this.messageService.add({
          key: 'VALIDATION_TOAST',
          severity: 'success',
          summary: '',
          detail: 'Modification réussie',
        });
        this.applicant = res.data['applicant'];
        this.selectedApplicant = this.applicant;
      }
    });
  }

  editBirthDate(event: any) {
    const config: DynamicDialogConfig = {
      modal: true,
      header: 'Modification de la date de naissance',
      width: '500px',
      closable: true,
      data: {
        applicant: this.applicant,
      },
    };
    const dialog = this.dialogService.open(
      DashboardRegionalOrientationEditBirthdateComponent,
      config
    );

    dialog.onClose.subscribe((res: IAPIResponse) => {
      if (res && res.status === 'SUCCESS') {
        this.messageService.add({
          key: 'VALIDATION_TOAST',
          severity: 'success',
          summary: '',
          detail: 'Modification réussie',
        });
        this.applicant = res.data['applicant'];
        this.selectedApplicant = this.applicant;
      }
    });
  }

  confirm(event: any): void {
    this.confirmationService.confirm({
      target: event.target,
      message: `Voulez-vous vraiment valider cet étudiant ?`,
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        if (this.validation?.oval_id) {
          this.updateValidation();
        } else {
          this.validate();
        }
      },

      reject: () => {},
    });
  }

  validate(): void {
    const body = {
      documentsign: this.isSign,
      birthdate: this.isBirthDateOK,
      nationality: this.isIvorian,
    };

    this.dobService
      .validationSave(this.applicant?.app_matricule!, body)
      .subscribe({
        next: (res) => {
          this.handleResponse(res);
        },

        error: (_) => {},
      });
  }

  updateValidation(): void {
    const body = {
      documentsign: this.isSign,
      birthdate: this.isBirthDateOK,
      nationality: this.isIvorian,
    };

    this.dobService
      .validationUpdate(this.validation?.oval_id!, body)
      .subscribe({
        next: (res) => {
          this.handleResponse(res);
        },

        error: (_) => {},
      });
  }

  handleResponse(res: IAPIResponse): void {
    if (res.status === 'SUCCESS') {
      this.messageService.add({
        key: 'VALIDATION_TOAST',
        severity: 'success',
        summary: '',
        detail: 'Validation réussie',
      });
      this.handleNewValidation();
    } else if (res.status === 'FAIL') {
      this.messageService.add({
        key: 'VALIDATION_TOAST',
        severity: 'error',
        summary: '',
        detail: res.data['matricule'],
      });
    }
  }
}
