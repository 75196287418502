import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { IRegionalBranch } from '../shared/model/regionalbranch.model';
import { ISchool } from '../shared/model/school.model';
import { IStudent } from '../shared/model/student.model';
import { environment } from 'src/environments/environment';
import { IRegistration } from '../shared/model/registration.model';
import { IExamRegistration } from 'src/app/shared/model/examregistration.model';

export interface IGeneralStatistic {
  regionals: IRegionalBranch[];
  schools: ISchool[];
  students: IStudent[];
  registrations: IRegistration[];
  registrationsExam: IRegistration[];
  examregistrations: IExamRegistration[];
}

@Injectable({
  providedIn: 'root',
})
export class StatisticService {
  private statisticsResourceURL = environment.apiURL + 'api/v1/statistics';

  constructor(private http: HttpClient) {}

  getGeneralStatistics(): Observable<HttpResponse<IGeneralStatistic | any>> {
    return this.http.get<IGeneralStatistic>(`${this.statisticsResourceURL}/`, {
      observe: 'response',
    });
  }
}
