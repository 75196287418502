<p-table [value]="choices!" responsiveLayout="scroll" dataKey="chx_id">
  <ng-template pTemplate="header">
    <tr>
      <th>Filière</th>
      <th>Etablissement</th>
      <th style="text-align: right">Préférence</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-choice let-index="rowIndex">
    <tr>
      <td>{{ choice?.chx_studyfield?.sfd_longlabel }}</td>

      <td>{{ choice?.chx_school?.sch_longlabel }}</td>

      <td style="text-align: right">
        <span class="chip chip-main">{{
          getPreferenceOrderLabel(choice?.chx_preference!)
        }}</span>
      </td>
    </tr>
  </ng-template>
</p-table>
