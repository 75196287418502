import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { INationality } from 'src/app/shared/model/nationality.model';
import { Router } from '@angular/router';
import {
  hasRequiredField,
  MustMatch,
  matchValidator,
} from 'src/app/shared/utils/validator.utils';

import { EEStepsService } from '../../services/steps.service';
import { SessionStorageService } from 'src/app/session-storage.service';
import { genders } from 'src/app/shared/constant/gender.contanst';
import { InscriptionService } from 'src/app/inscription/inscription.service';
import { IApplicant } from '../../models/applicant.model';
import { EexamsService } from '../../eexams.service';

import * as moment from 'moment';
import { MAX_ALLOWED_AGE } from '../../constant/validator.constant';

@Component({
  selector: 'app-ee-applicant',
  templateUrl: './ee-applicant.component.html',
  styleUrls: ['./ee-applicant.component.scss']
})
export class EeApplicantComponent implements OnInit {

  editForm = this.fb.group(
    {
      app_matricule: [null],
      app_lastname: [null, [Validators.required]],
      app_firstname: [null, [Validators.required]],
      app_birthdate: [null, [Validators.required]],
      app_birthplace: [null, [Validators.required]],
      app_gender: [null, [Validators.required]],
      app_nationality: [null, [Validators.required]],
      app_email: [null, [Validators.email]],
      app_phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      app_authcode: [
        null,
        [ Validators.pattern(/^[0-9]{4}$/)],
      ],
      app_authcode_confirm: [
        null,
        [ Validators.pattern(/^[0-9]{4}$/)],
      ],
    },
    {
      validator: MustMatch('app_authcode', 'app_authcode_confirm'),
    }
  );

  nationalities: INationality[] = [];
  selectedNationality?: INationality;
  isNationalityInvalid = false;

  genders: any[] = [];
  selectedGender = '';

  msgs: Message[] = [];

  isSaving = false;
  showDialog = false;
  loaded = false;
  isLoading = false;

  studentLastName = '';
  studentFirstName = '';
  studentFatherName = '';
  studentMotherName = '';
  studentBirthPlace = '';
  studentBirthDocPlace = '';
  minBirthDateAllowed = false;

  applicant?: IApplicant;
  applicantFounded = false;
  maxBirthDateAllowed = false;

  isFirstTime = false;
  confidentialCode = '';

  dobMaxAllowedAge = MAX_ALLOWED_AGE;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private EexamsStepsService: EEStepsService,
    private sessionStorage: SessionStorageService,
    private inscriptionService: InscriptionService,
    private eexamsService: EexamsService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.genders = genders;
    this.inscriptionService.getNationalities().subscribe((res) => {
      this.nationalities = res.body || [];
      this.getApplicantRef(this.sessionStorage);
    });
  }

  getApplicantProcessTime(storage: Storage): boolean {
    return JSON.parse(storage.getItem('DOB_APPLICANT_FIRST_REGISTRATION')!);
  }

  getApplicantRef(storage: Storage): void {
    this.isLoading = true;
    const ref = storage.getItem('DOB_APPLICANT_REF')!;
    this.isFirstTime = this.getApplicantProcessTime(this.sessionStorage);

    this.eexamsService.geApplicantByRef(ref).subscribe((res) => {
      this.isLoading = false;
      const response = res.body;

      if (response?.result === -1) {
        this.editForm.patchValue({
          app_matricule: ref,
        });
        this.applicantFounded = false;
      } else {
        this.selectedNationality = response.data?.app_nationality;
        this.confidentialCode = response.data?.app_authcode;
        this.applicant = response.data;

        const dateFormted = new Date(response.data?.app_birthdate!);

        this.editForm.patchValue({
          app_matricule: response.data?.app_matricule,
          app_lastname: response.data?.app_lastname,
          app_firstname: response.data?.app_firstname,
          app_birthdate: dateFormted,
          app_birthplace: response.data?.app_birthplace,
          app_gender: response.data?.app_gender,
          app_nationality: response.data?.app_nationality,
          app_email: response.data?.app_email,
          app_phone: response.data?.app_phone,
          app_authcode: response.data?.app_authcode,
          app_authcode_confirm: response.data?.app_authcode,
        });

        if (response?.result === 2) {
          this.applicantFounded = false;
        } else {
          this.applicantFounded = true;
        }

        if (response.data?.app_birthdate) this.handleDateChange();

        if (!this.isFirstTime) {
          this.editForm.controls['app_authcode_confirm'].clearValidators();
          this.editForm.controls[
            'app_authcode_confirm'
          ].updateValueAndValidity();
          // this.editForm?.rezmoveControl('app_authcode_confirm');
        }
      }
    });
  }

  save(): void {
    const body = this.editForm.value;
    this.eexamsService.saveApplicant(body).subscribe({
      next: (res) => {
        this.onCreatedOrEditedSuccess();
      },
      error: (err) => {
        this.onCreatedOrEditError();
      },
    });
  }

  edit(): void {
    this.eexamsService
      .updateApplicant(this.applicant?.app_matricule!, this.editForm.value)
      .subscribe({
        next: (res) => {
          this.onCreatedOrEditedSuccess();
        },
        error: (err) => {
          this.onCreatedOrEditError();
        },
      });
  }

  handleSaveOrEdit(): void {
    this.isSaving = true;
    this.showDialog = false;

    const birth = this.editForm.controls['app_birthdate'].value;

    this.editForm.patchValue({
      app_birthdate: moment(birth).format('YYYY-MM-DD'),
    });

    if (this.applicantFounded && this.editForm.dirty) {
      this.edit();
    } else if (!this.applicantFounded && this.isFirstTime) {
      this.save();
    } else {
      this.isSaving = false;
      this.router.navigate(['/concours', 'choice']);
      this.EexamsStepsService.setNextStep(this.sessionStorage);
    }
  }

  onCreatedOrEditError(): void {
    this.messageService.clear();
    this.messageService.add({
      key: 'alertMessage',
      severity: 'error',
      detail: "Erreur d'enregistrement, veuillez vérifier vos informations",
    });
    this.isSaving = false;
  }

  onCreatedOrEditedSuccess(): void {
    // this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      key: 'studentInfoEdited',
      summary: 'Enregistrement',
      detail: 'Vos informations ont été enregistré',
    });

    setTimeout(() => {
      this.isSaving = false;
      this.router.navigate(['/concours', 'choice']);
      this.EexamsStepsService.setNextStep(this.sessionStorage);
    }, 1000);
  }

  goPrev(): void {
    this.router.navigate(['/concours', 'search']);
    this.EexamsStepsService.setPrevStep(this.sessionStorage);
  }

  goToNext(): void {
    this.handleSaveOrEdit();
  }

  openConfirmDialog(): void {
    this.showDialog = true;
  }

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }

  // Birth date validation
  handleDateChange(): void {
    const studentBirthDateValue = this.editForm.controls['app_birthdate'].value;
    const nowDate = new Date();
    const studentBirthDate = moment(studentBirthDateValue).format('MM-DD-YYYY');

    const dateDiff =
      nowDate.getFullYear() - new Date(studentBirthDate).getFullYear();

    if (dateDiff > this.dobMaxAllowedAge) {
      this.editForm.controls['app_birthdate'].setErrors({ incorrect: true });
      this.maxBirthDateAllowed = true;
    } else {
      this.maxBirthDateAllowed = false;
    }
  }

  validateNationality() {
    const label = this.selectedNationality?.nat_label?.toLowerCase();
    if (!label?.includes('ivoirienne')) {
      this.editForm.controls['app_nationality'].setErrors({ incorrect: true });
      this.isNationalityInvalid = true;
    } else {
      this.editForm.controls['app_nationality'].clearValidators();
      this.isNationalityInvalid = false;
    }
  }
}
