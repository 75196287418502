import { Component, OnInit } from '@angular/core';
import { RegionalService } from 'src/app/services/regional.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { IRegionalBranch } from 'src/app/shared/model/regionalbranch.model';
import { IRegistration } from 'src/app/shared/model/registration.model';
import { ISchool } from 'src/app/shared/model/school.model';
import { getSchoolType } from 'src/app/shared/utils/schooltype.utils';
import { SCHOOLTYPE } from '../../shared/constant/schooltype.constant';
import { LazyLoadEvent } from 'primeng/api';

interface IRegistrationStatistic {
  numberOfRegistration: number;
  percentOfRegistration: number;
  percentDirection: string;
}
export interface IRegistrationStatus {
  school: ISchool;
  total: number;
}
@Component({
  selector: 'app-registration-status',
  templateUrl: './registration-status.component.html',
  styleUrls: ['./registration-status.component.scss'],
})
export class RegistrationStatusComponent implements OnInit {
  regionals: IRegionalBranch[] = [];
  selectedRegional?: string;
  isLoading = false;

  registrationNumberMin = null;
  registrationNumberMax = null;

  schoolName = '';

  schoolsType = SCHOOLTYPE;
  selectedSchoolType?: number;

  registrations: IRegistrationStatus[] = [];
  filteredRegistrations: IRegistrationStatus[] = [];

  totalRegistrations = 0;

  constructor(
    private registrationService: RegistrationService,
    private regionalService: RegionalService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.loadRegionalList();
    this.loadRegistrations();
  }

  loadRegistrations(): void {
    this.isLoading = true;
    this.registrationService.getAllRegistrations().subscribe((res) => {
      this.isLoading = false;
      this.registrations = res.body || [];
      this.filteredRegistrations = this.registrations;
      this.totalRegistrations = this.registrations.reduce(
        (prev, cur) => prev + cur.total,
        0
      );
    });
  }

  loadRegionalList(): void {
    this.regionalService
      .getAllRegional()
      .subscribe((res) => (this.regionals = res.body || []));
  }

  getSchoolType(type: number): string {
    return getSchoolType(type);
  }

  handleSearch(): void {
    if (this.schoolName) {
      this.filteredRegistrations = this.registrations.filter((reg) =>
        reg?.school?.sch_longlabel
          ?.toLowerCase()
          .includes(this.schoolName?.toLowerCase())
      );
    } else {
      this.filteredRegistrations = [...this.registrations];
    }
  }

  handleSelectSchoolType(): void {
    if (this.selectedSchoolType) {
      this.filteredRegistrations = this.registrations.filter(
        (reg) => Number(reg?.school?.sch_type!) === this.selectedSchoolType!
      );
    } else {
      this.filteredRegistrations = [...this.registrations];
    }
  }

  handleSelectRegionalBranch(): void {
    if (this.selectedRegional) {
      this.filteredRegistrations = this.registrations.filter(
        (reg) =>
          reg?.school?.sch_regionalbranch?.bch_id! === this.selectedRegional!
      );
    } else {
      this.filteredRegistrations = [...this.registrations];
    }
  }

  handleRefreshPage(): void {
    this.schoolName = '';
    this.filteredRegistrations = [];
    this.selectedSchoolType = 0;
    this.selectedRegional = '';
    this.loadPage();
  }
}
