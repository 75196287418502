<!-- <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb> -->

<p-toast></p-toast>

<p class="dashboard-title">Liste des effectifs</p>

<div class="dashboard-section">
  <div class="dashboard-section__header">
    <button class="p-button rounded p-button-main" (click)="showAddNewDialog()">
      <i class="pi pi-plus" style="margin-right: 1rem"></i>
      Nouvel effectif
    </button>
  </div>
  <p-table
    [value]="studentPopulations"
    [paginator]="true"
    [rows]="6"
    [alwaysShowPaginator]="false"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    currentPageReportTemplate="Affichage de {first} à {last} des {totalRecords} enregistrements"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Diplôme</th>
        <th>Filière</th>
        <th>Niveau</th>
        <th class="text-right">Effectif</th>
        <!-- <th class="text-right">Actions</th> -->
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-detail>
      <tr *ngIf="!isLoading">
        <td>{{ detail?.pop_diploma?.dip_shortlabel }}</td>

        <td>{{ detail?.pop_studyfield?.sfd_longlabel }}</td>

        <td>{{ detail?.pop_level?.lvl_label }}</td>

        <td class="text-right">
          {{ detail?.pop_count }}
        </td>
        <!-- <td class="text-right">
          <button
            class="p-button p-button-sm p-button-outlined p-button-secondary"
          >
            Modifier
          </button>
        </td> -->
      </tr>
    </ng-template>
  </p-table>
  <div
    class="empty-table-message-block"
    *ngIf="studentPopulations.length === 0 && !isLoading"
  >
    <p>Aucun effectif trouvé</p>
  </div>
  <div
    *ngIf="isLoading"
    class="loader"
    style="display: flex; justify-content: center"
  >
    <img src="/assets/loader.gif" />
  </div>
</div>

<!-- Add new student population dialog -->
<p-dialog
  header="Enregistrement d'effectif"
  [(visible)]="displayNewStaffDialog"
  [style]="{ width: '500px' }"
  [modal]="true"
  appendTo="body"
  (onHide)="clearDialog()"
>
  <form [formGroup]="editForm">
    <p-messages [(value)]="msgs"></p-messages>

    <div class="p-field p-field-column">
      <label class="p-field-label" for="field_pop_studyOrder"
        >Ordre d'enseignement
        <span class="color-danger">{{
          fieldIsRequired(editForm.controls["pop_studyOrder"])
        }}</span>
      </label>
      <p-dropdown
        id="field_pop_studyOrder"
        [options]="schoolstudyorderList"
        [(ngModel)]="selectedSchoolStudyOrder"
        optionLabel="name"
        optionValue="code"
        formControlName="pop_studyOrder"
        placeholder="Selectionner l'ordre d'enseignement"
        (onChange)="handleStudyOrderChange()"
      >
      </p-dropdown>
      <small
        *ngIf="
          editForm.get('pop_studyOrder')?.errors &&
          (editForm.get('pop_studyOrder')?.errors)!['required'] &&
          (editForm.controls['pop_studyOrder'].dirty ||
            editForm.controls['pop_studyOrder'].touched)
        "
        class="p-field-help"
        id="field_pop_studyOrder-help"
        >Ce champ est obligatore.</small
      >
    </div>

    <div class="p-field p-field-column">
      <label class="p-field-label" for="field_pop_diploma"
        >Diplôme
        <span class="color-danger">{{
          fieldIsRequired(editForm.controls["pop_diploma"])
        }}</span>
      </label>
      <p-dropdown
        id="field_pop_diploma"
        [options]="diplomas"
        [(ngModel)]="selectedDiploma"
        optionLabel="dip_shortlabel"
        formControlName="pop_diploma"
        placeholder="Selectionner le diplôme"
        (onChange)="handleDiplomaChange()"
        [disabled]="!selectedSchoolStudyOrder"
      >
      </p-dropdown>
      <small
        *ngIf="
          editForm.get('pop_diploma')?.errors &&
          (editForm.get('pop_diploma')?.errors)!['required'] &&
          (editForm.controls['pop_diploma'].dirty ||
            editForm.controls['pop_diploma'].touched)
        "
        class="p-field-help"
        id="field_pop_diploma-help"
        >Ce champ est obligatore.</small
      >
    </div>

    <div class="p-field p-field-column">
      <label class="p-field-label" for="field_pop_studyfield"
        >Filière
        <span class="color-danger">{{
          fieldIsRequired(editForm.controls["pop_studyfield"])
        }}</span>
      </label>
      <p-dropdown
        id="field_pop_studyfield"
        [options]="studyfields"
        [(ngModel)]="selectedStudyfield"
        optionLabel="sfd_longlabel"
        optionValue="sfd_id"
        formControlName="pop_studyfield"
        placeholder="Selectionner la filière"
        [disabled]="
          !selectedDiploma ||
          selectedDiploma === '' ||
          selectedDiploma == undefined
        "
      >
      </p-dropdown>
      <small
        *ngIf="
          editForm.get('pop_studyfield')?.errors &&
          (editForm.get('pop_studyfield')?.errors)!['required'] &&
          (editForm.controls['pop_studyfield'].dirty ||
            editForm.controls['pop_studyfield'].touched)
        "
        class="p-field-help"
        id="field_pop_studyfield-help"
        >Ce champ est obligatore.</small
      >
    </div>

    <div class="p-field p-field-column">
      <label class="p-field-label" for="field_pop_level"
        >Niveau
        <span class="color-danger">{{
          fieldIsRequired(editForm.controls["pop_level"])
        }}</span>
      </label>
      <p-dropdown
        id="field_pop_level"
        [options]="levels"
        [(ngModel)]="selectedLevel"
        optionLabel="lvl_label"
        optionValue="lvl_code"
        formControlName="pop_level"
        placeholder="Selectionner le niveau"
        [disabled]="!selectedSchoolStudyOrder || !selectedDiploma"
      >
      </p-dropdown>
      <small
        *ngIf="
          editForm.get('pop_level')?.errors &&
          (editForm.get('pop_level')?.errors)!['required'] &&
          (editForm.controls['pop_level'].dirty ||
            editForm.controls['pop_level'].touched)
        "
        class="p-field-help"
        id="field_pop_level-help"
        >Ce champ est obligatore.</small
      >
    </div>

    <div class="p-field p-field-column">
      <label class="p-field-label" for="field_pop_count"
        >Effectif
        <span class="color-danger">{{
          fieldIsRequired(editForm.controls["pop_count"])
        }}</span>
      </label>
      <p-inputNumber
        id="field_pop_count"
        formControlName="pop_count"
        [min]="0"
        [max]="500"
      ></p-inputNumber>
      <small
        *ngIf="
          editForm.get('pop_count')?.errors &&
          (editForm.get('pop_count')?.errors)!['required'] &&
          (editForm.controls['pop_count'].dirty ||
            editForm.controls['pop_count'].touched)
        "
        class="p-field-help"
        id="field_pop_count-help"
        >Ce champ est obligatore.</small
      >
    </div>

    <div class="p-field p-field-actions loader">
      <div class="search-loader">
        <img src="/assets/loader.gif" alt="Loader" *ngIf="isLoading" />
      </div>

      <div></div>

      <div>
        <button
          pButton
          pRipple
          type="button"
          label="Annuler"
          icon="pi pi-times"
          class="p-button-secondary p-button-text"
          (click)="closeDialog()"
        ></button>

        <button
          [disabled]="isLoading || editForm.invalid"
          class="p-button p-button-main"
          (click)="save()"
        >
          Enregistrer
        </button>
      </div>
    </div>
  </form>
</p-dialog>
