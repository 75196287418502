<div class="header-dexc-update">
  <span>
    <i class="pi pi-pencil" style="font-size: 2rem; margin-right: 1.5rem; color: #519259;"></i>
  </span>
  <div>
    <h3>Modification des informations de l’apprenant</h3>
    <p>Veuillez rechercher l’apprenant à modifier à l’aide de son numéro matricule</p>
  </div>
</div>

<div class="registration-wrapper" #sidebar>
    <!-- <app-dashboard-dexc-update-sidebar></app-dashboard-dexc-update-sidebar> -->
  
    <div class="registration-content">
      <!-- <app-registration-header></app-registration-header> -->
      <app-dashboard-dexc-update-search></app-dashboard-dexc-update-search>
      <router-outlet></router-outlet>
    </div>
  
    <!-- <div
      [ngClass]="{ 'registration-content': !isRegistrationFinished }"
      [style.width]="isRegistrationFinished ? '100%' : ''"
    >
      <router-outlet></router-outlet>
    </div> -->
</div>