import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EeApplicantComponent } from './components/ee-applicant/ee-applicant.component';
import { EeCheckMatriculeComponent } from './components/ee-check-matricule/ee-check-matricule.component';
import { EeChoiceComponent } from './components/ee-choice/ee-choice.component';
import { EeFinishComponent } from './components/ee-finish/ee-finish.component';
import { EeLayoutComponent } from './components/ee-layout/ee-layout.component';
import { EeSummaryComponent } from './components/ee-summary/ee-summary.component';

const routes: Routes = [
  {
    path: 'concours',
    component: EeLayoutComponent,
    children: [
      {
        path: 'search',
        component: EeCheckMatriculeComponent,
      },
      {
        path: 'applicant',
        component: EeApplicantComponent,
      },
      {
        path: 'choice',
        component: EeChoiceComponent,
      },
      {
        path: 'summary',
        component: EeSummaryComponent,
      },
    ],
  },
  {
    path: 'orientation/finish',
    component: EeFinishComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EexamsRoutingModule { }
