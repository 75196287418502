import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-dexc-update-finish',
  templateUrl: './dashboard-dexc-update-finish.component.html',
  styleUrls: ['./dashboard-dexc-update-finish.component.scss']
})
export class DashboardDexcUpdateFinishComponent implements OnInit {

  constructor(private route: Router) {}

  ngOnInit(): void {}

  goHome(): void {
    this.route.navigate(['/']);
  }

}
