import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IStudent } from 'src/app/shared/model/student.model';
import { MessageService } from 'primeng/api';
import { FreeCandidateService } from 'src/app/services/free-candidate.service';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from 'src/app/session-storage.service';
import { FREE_CANDIDATE_INFO_KEY } from '../../shared/constant/step.constant';
import { IFreeCandidate } from '../../shared/model/free-candidate';

@Component({
  selector: 'app-examen-free-confirmation',
  templateUrl: './examen-free-confirmation.component.html',
  styleUrls: ['./examen-free-confirmation.component.scss'],
})
export class ExamenFreeConfirmationComponent implements OnInit {
  @Input() student2!: any | {};
  @Input() student!: IStudent | {};
  @Output('prevStep') prevStep: EventEmitter<boolean> = new EventEmitter();

  baseImg = environment.mediaURL;
  isLoader = false;
  displayDialog = false;
  matricule: any;
  isStudent!: any;
  urlDocument: any;
  base64String: any;
  pdfReceived: any;

  candidate?: IFreeCandidate;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private freeCandidateService: FreeCandidateService,
    private storage: SessionStorageService
  ) {}

  ngOnInit(): void {
    const candidateInfo = JSON.parse(
      this.storage.getItem(FREE_CANDIDATE_INFO_KEY)!
    );

    this.candidate = candidateInfo.student;
    this.onGetRegisterReceived(this.candidate?.fcr_id!);
  }

  onPrevStep() {
    this.prevStep.emit();
    let studentIn: any = this.student2;
    if (studentIn !== undefined) {
    }
  }

  finishInscriptionFree() {
    this.router.navigate(['/inscription', 'examen', 'registred-free-finish']);
  }

  handleSuccess(): void {
    this.isLoader = true;
    this.displayDialog = false;
    this.messageService.add({
      severity: 'success',
      detail: 'Informations enregistrées avec succèss',
    });
    setTimeout(() => {
      this.finishInscriptionFree();
    }, 1500);
  }

  showDialog() {
    this.displayDialog = true;
  }

  // get register received free candidate
  onGetRegisterReceived(candidate_id: string) {
    console.log(candidate_id);
    this.freeCandidateService.getRegisterReceived(candidate_id).subscribe(
      (res) => {
        this.base64String = res.body;
        console.log('this.base64String', this.base64String);
      },
      (_) => {}
    );
  }

  // download received document pdf
  downloadPdf(base64String: any, fileName: any) {
    console.log('base64String', base64String);

    this.pdfReceived = `data:application/pdf;base64, ${this.base64String}`;
    const link = document.createElement('a');
    link.href = this.pdfReceived;
    link.download = `${fileName}_${this.candidate?.fcr_student?.std_lastname}_${this.candidate?.fcr_student?.std_firstname}.pdf`;
    link.target = '_blank';
    link.click();
  }

  onClickDownloadPdf() {
    let base64Strings = this.base64String;

    this.downloadPdf(base64Strings, 'reçu_preinscription');
  }
}
