import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IStudent } from '../../shared/model/student.model';
import { Router } from '@angular/router';

import { activateStep, setPrevStep } from 'src/app/shared/utils/step.utils';
import { FormBuilder } from '@angular/forms';
import { InscriptionService } from '../inscription.service';
import { MessageService } from 'primeng/api';
import { BREADCRUMB_ITEMS_KEY } from 'src/app/shared/constant/step.constant';
import { SessionStorageService } from 'src/app/session-storage.service';

@Component({
  selector: 'app-examen-student-form',
  templateUrl: './examen-student-form.component.html',
  styleUrls: ['./examen-student-form.component.scss'],
})
export class ExamenStudentFormComponent implements OnInit, OnDestroy {
  @Input() student?: IStudent;

  editForm = this.fb.group({
    lastName: [null, []],
    firstName: [null, []],
    birthDate: [null, []],
    birthPlace: [null, []],
    gender: [null, []],
    nationality: [null, []],
    email: [null, []],
    phone1: [null, []],
    phone2: [null, []],
    picture: [null, []],
  });

  genders = [
    { name: 'Masculin', code: 'male' },
    { name: 'Féminin', code: 'female' },
  ];
  selectedGender = '';
  currentExameRef = '';
  studentPictureURL = '';
  isSaving = false;
  showDialog = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private inscriptionService: InscriptionService,
    private messageService: MessageService,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnDestroy(): void {
    this.messageService.clear('studentInfoEdited');
  }

  ngOnInit(): void {
    this.updateForm(this.student!);
  }

  goToNext(): void {
    activateStep(this.getStepDataFromSS(), this.sessionStorage);
    this.router.navigate(['/inscription', 'examen', 'info-examen']);
  }

  goPrev(): void {
    setPrevStep(this.getStepDataFromSS(), this.sessionStorage);
    this.router.navigate(['/inscription', 'examen', 'recherche']);
  }

  getStepDataFromSS(): any {
    return JSON.parse(sessionStorage.getItem(BREADCRUMB_ITEMS_KEY)!);
  }

  edit(): void {
    this.isSaving = true;
    this.inscriptionService
      .update(this.student?.std_menaref!, this.editForm.value)
      .subscribe((event) => {
        this.isSaving = false;
      });
  }

  openConfirmDialog(): void {
    this.showDialog = true;
  }

  showError(): void {}

  updateForm(student: IStudent): void {
    this.selectedGender = student?.std_gender!;
    this.studentPictureURL = 'http://localhost:8000' + student?.std_picture!;
    this.editForm.patchValue({
      lastName: student?.std_lastname,
      firstName: student?.std_firstname,
      birthDate: student?.std_birthdate,
      birthPlace: student?.std_birthplace,
      gender: student?.std_gender,
      nationality: student?.std_nationality,
      email: student?.std_email,
      phone1: student?.std_phone1,
      phone2: student?.std_phone2,
      picture: student?.std_picture,
    });
  }
}
