<p-toast></p-toast>
<p class="dashboard-title">Administrateur Banque</p>

<div class="dashboard-section mt-4 p-flex-between">
  <div class="dashboard-section-filter">
    <input
      type="text"
      pInputText
      placeholder="Recherche par nom ou matricule"
      style="width: 100%"
      [(ngModel)]="searchText"
      (ngModelChange)="handleSearchByNameOrStudentCode()"
    />
  </div>
  <div class="text-right">
    <button type="button" class="p-button" (click)="openModalRegisterBank()">
      Inscription
    </button>
  </div>
</div>

<!-- <div class="dashboard-section-filter-transparent">
  <div class="dashboard-section-filter-actions">
    <input
      type="text"
      pInputText
      placeholder="Recherche par nom ou matricule"
      style="width: 300px"
      [(ngModel)]="searchText"
      (ngModelChange)="handleSearchByNameOrStudentCode()"
    />

    <p-multiSelect
      [options]="diplomas"
      [(ngModel)]="selectedDiploma"
      optionLabel="dip_shortlabel"
      optionValue="dip_id"
      placeholder="Afficher par diplôme"
      display="chip"
      [style]="{ width: '300px' }"
      (onChange)="handleSearchByDiploma()"
    >
    </p-multiSelect>

    <p-multiSelect
      [options]="paymentStatusList"
      [(ngModel)]="selectedPaymentStatus"
      optionLabel="name"
      optionValue="code"
      placeholder="Afficher par statut"
      display="chip"
      [style]="{ width: '300px' }"
      (onChange)="handleSearchByStatus()"
    >
    </p-multiSelect>
  </div>

  <div class="dashboard-section-filter-button">
    <button
      class="p-button p-button-main"
      pButton
      [disabled]="selectedStudent?.length === 0"
    >
      Initier le paiement de la selection
    </button>
  </div>
</div> -->

<div class="dashboard-section">
  <p-table
    dataKey="fcr_id"
    [value]="filteredExamRegistrations"
    [paginator]="true"
    [rows]="6"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    [alwaysShowPaginator]="false"
    currentPageReportTemplate="Affichage de {first} à {last} des {totalRecords} enregistrements"
    [(selection)]="selectedStudent"
    [rowTrackBy]="examregistrationTrackBy"
    [rowSelectable]="isExamregistratiionRowSelectable"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>Nom</th>
        <!-- <th>Matricule MENA</th> -->
        <!-- <th>Matricule ETFP</th> -->
        <th>Diplôme préparé</th>
        <th>Filière</th>
        <th>UE</th>
        <th>Statut</th>
        <th class="text-right">Detail</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-examregistration>
      <tr>
        <td>
          <p-tableCheckbox
            [value]="examregistration"
            [disabled]="isPaymentInitiatedPendingOrValidated(examregistration)"
          ></p-tableCheckbox>
        </td>
        <td style="display: flex; align-items: center">
          <div style="width: 40px; height: 50px; margin-right: 1rem">
            <img
              style="width: 100%; height: 100%"
              [src]="
                getPicture(examregistration.fcr_bankcandidate?.bkc_picture)
              "
              alt=""
            />
          </div>
          <div style="display: flex; flex-direction: column">
            <span>{{
              examregistration.fcr_bankcandidate.bkc_lastname | uppercase
            }}</span>
            <span style="opacity: 0.5">{{
              examregistration.fcr_bankcandidate.bkc_firstname | lowercase
            }}</span>
          </div>
        </td>
        <!-- <td>{{ examregistration.fcr_bankcandidate.bkc_menaref }}</td> -->
        <!-- <td>{{ examregistration.fcr_bankcandidate.bkc_etfparef }}</td> -->
        <td>
          {{ examregistration.fcr_diploma?.dip_shortlabel }}
        </td>
        <td>
          {{ examregistration.fcr_studyfield?.sfd_longlabel }}
        </td>
        <td>
          {{ examregistration.fcr_examtotake }}
        </td>
        <!-- <td>
          {{ examregistration.created_at | date: "longDate":"utc":"fr-fr" }}
        </td> -->
        <td>
          <span class="statusStudent" [ngClass]="{'statutCours': examregistration.fcr_validationstatus == 0, 'statutAttente': examregistration.fcr_validationstatus == 1, 'statutValider': examregistration.fcr_validationstatus == 2}">
            {{(examregistration.fcr_validationstatus == 0) ? "En cours ..." : (examregistration.fcr_validationstatus == 1) ? "En attente de validation ..." : "Valider"}}
          </span>
        </td>
        <!-- <td>
          <p-chip
            class="chip-main"
            *ngIf="examregistration.fcr_validationstatus === 0"
            label="-"
          ></p-chip>
          <span *ngIf="examregistration.fcr_validationstatus === 0"> - </span>
          <p-chip
            class="chip-secondary"
            *ngIf="examregistration.fcr_validationstatus === 1"
            label="Initiation en cours..."
          ></p-chip>
          <p-chip
            class="chip-warning"
            *ngIf="examregistration.fcr_validationstatus === 2"
            label="Paiement en attente"
          ></p-chip>
          <p-chip
            class="chip-success"
            *ngIf="examregistration.fcr_paymentstatus === 3"
            label="Paiement validé"
          ></p-chip>
          <p-chip
            class="chip-success"
            *ngIf="examregistration.fcr_paymentstatus === 4"
            label="Paiement rejeté"
          ></p-chip>
        </td> -->
        <td class="text-right">
          <!-- <p-confirmPopup key="PAYMENT_POPUP"></p-confirmPopup>
          <p-toast key="PAYEMENT_RESULT"></p-toast>
          <button
            style="margin-right: 1rem"
            class="p-button p-button-success"
            (click)="confirmInitiatePayment($event, examregistration)"
            [hidden]="isPaymentInitiatedPendingOrValidated(examregistration)"
          >
            <i
              class="pi pi-wallet"
              style="margin-right: 0.5rem; font-size: 1.3rem"
            ></i>
            Initier le paiement
          </button> -->
          <button (click)="onGetRegisterReceived(examregistration.fcr_id)" class="btn-received" title="Telecharger fiche d'inscription">
            <i class="pi pi-file-pdf" style="font-size: 2rem;"></i>
          </button>
          <button
            class="p-button p-button-sm p-button-outlined p-button-secondary"
            (click)="showDetail(examregistration)"
          >
            Details
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div
    class="empty-table-message-block"
    *ngIf="filteredExamRegistrations.length === 0 && !isLoading"
  >
    <p>Aucun apprenant trouvé</p>
  </div>

  <div
    *ngIf="isLoading"
    class="loader"
    style="display: flex; justify-content: center"
  >
    <img src="/assets/loader.gif" />
  </div>
</div>

<p-sidebar [(visible)]="displayDetail" position="right" [closeOnEscape]="true">
  <div class="validation-detail-item picture">
    <div class="validation-detail-item-photo">
      <img
        [src]="
          getPicture(selectedExamRegistration?.fcr_bankcandidate.bkc_picture!)
        "
        alt=""
      />
    </div>
    <div>
      <p class="mb-1">
        <strong>Nom :</strong>
        {{ selectedExamRegistration?.fcr_bankcandidate?.bkc_lastname! }}
      </p>
      <p class="mb-1">
        <strong>Prénom(s) :</strong>
        {{ selectedExamRegistration?.fcr_bankcandidate?.bkc_firstname! }}
      </p>
      <!-- <p class="mb-1"><strong>Matricule :</strong> {{selectedExamRegistration?.fcr_bankcandidate?.bkc_menaref!}}</p> -->
      <p class="mb-1">
        <strong>Nationalité :</strong>
        {{
          selectedExamRegistration?.fcr_bankcandidate?.bkc_nationality
            ?.nat_label
        }}
      </p>
      <p class="mb-1">
        <strong>Date de Nais :</strong>
        {{ selectedExamRegistration?.fcr_bankcandidate?.bkc_birthdate }}
      </p>
    </div>
  </div>

  <div class="validation-detail-item __space-between">
    <div class="validation-detail-item__left">
      <p class="mb-1">
        <strong>Session :</strong>
        {{ selectedExamRegistration?.fcr_session?.yrs_academic! }}
      </p>
      <p class="mb-1"><strong>Type Candidature :</strong> Candidat Banque</p>
      <p class="mb-1">
        <strong>Etablissement :</strong>
        {{ selectedExamRegistration?.fcr_bank?.bnk_longlabel }}
      </p>
      <p class="mb-1">
        <strong>Diplome :</strong>
        {{ selectedExamRegistration?.fcr_diploma?.dip_longlabel! }}
      </p>
      <p class="mb-1">
        <strong>Filiere :</strong>
        {{ selectedExamRegistration?.fcr_studyfield?.sfd_longlabel! }}
      </p>
      <p class="mb-1">
        <strong>Unité(s) d'enseignement :</strong>
        {{ selectedExamRegistration?.fcr_examtotake! }}
      </p>
    </div>
    <div class="validation-detail-item__right">
      <p class="mb-1">
        <strong>Lieu de Naissance :</strong>
        {{ selectedExamRegistration?.fcr_bankcandidate?.bkc_birthplace! }}
      </p>
      <p class="mb-1">
        <strong>Genre :</strong>
        {{ selectedExamRegistration?.fcr_bankcandidate?.bkc_gender! }}
      </p>
      <!-- <p class="mb-1"><strong>Numéro de l'acte de naissance :</strong> {{selectedExamRegistration?.fcr_bankcandidate?.bkc_birthdocnumber!}}</p> -->
      <!-- <p class="mb-1"><strong>Lieu d'établissement de l'acte :</strong> {{selectedExamRegistration?.fcr_bankcandidate?.bkc_birthdoclocation!}}</p> -->
      <!-- <p class="mb-1"><strong>Date d'établissement de l'acte :</strong> {{selectedExamRegistration?.fcr_bankcandidate?.bkc_birthdocdate!}}</p> -->
      <p class="mb-1">
        <strong>Téléphone 1 :</strong>
        {{ selectedExamRegistration?.fcr_bankcandidate?.bkc_phone1! }}
      </p>
      <!-- <p class="mb-1"><strong>Nom et prénom(s) du père :</strong> {{selectedExamRegistration?.fcr_bankcandidate?.bkc_fatherfullname!}}</p> -->
      <!-- <p class="mb-1"><strong>Nom et prénom(s) de la mere :</strong> {{selectedExamRegistration?.fcr_bankcandidate?.bkc_motherfullname!}}</p> -->
    </div>
  </div>
  <div class="validation-detail-item">
    <!-- update btn -->
    <button
      *ngIf="selectedExamRegistration?.fcr_validationstatus == 0"
      style="margin-right: 1rem;"
      type="button"
      class="updateBCK"
      (click)="updateSelectedBankCandidate()"
    >
      Modification
    </button>

    <!-- <button
      class="p-button-danger"
      pButton
      type="button"
      label="Rejeter"
      (click)="reject()"
      [disabled]="
        selectedExamRegistration?.fcr_validationstatus! === 3 ||
        selectedExamRegistration?.fcr_validationstatus! === 2
      "
    ></button> -->
    <!-- <button
      class="p-button-danger"
      pButton
      type="button"
      label="Rejeter"
      (click)="reject()"
      [disabled]="
        selectedExamRegistration?.fcr_validationstatus! === 3 ||
        selectedExamRegistration?.fcr_validationstatus! === 2
      "
    ></button> -->

    <p-confirmDialog
      header="Confirmation"
      icon="pi pi-exclamation-triangle"
    ></p-confirmDialog>

    <button
      *ngIf="selectedExamRegistration?.fcr_validationstatus == 0"
      class=""
      pButton
      type="button"
      label="Valider"
      (click)="showDialog()"
    ></button>

    <!-- <button
    class="p-button-success"
    pButton
    type="button"
    label="Valider"
    (click)="validate()"
    [disabled]="
      selectedExamRegistration?.fcr_validationstatus! === 3 ||
      selectedExamRegistration?.fcr_validationstatus! === 2
    "
  ></button> -->
  </div>
</p-sidebar>

<!-- modal register student bank -->
<div class="modal-student-bank" *ngIf="modalRegisterBank">
  <div class="modal-content">
    <div class="modal-body">
      <!-- header -->
      <div class="p-flex-between mb-4">
        <h2>Inscription Candidat Bank</h2>
        <div style="display: flex; justify-content: end">
          <button
            type="button"
            class="close-modal"
            (click)="closeModalRegisterBank()"
          >
            <i class="pi pi-times"></i>
          </button>
        </div>
      </div>

      <hr class="divider" />

      <div>
        <!-- stepper registration free candidate -->
        <!-- <ul class="steppers">
                    <li [class]="{active : step == 1 || step == 2 || step == 3}"> 
                        01 - Identification
                    </li>
                    <li [class]="{active : step == 2 || step == 3 }">
                        02 - Détail Examen
                    </li>
                    <li [class]="{active : step == 3}">
                        03 - Recapitulatif
                    </li>
                </ul> -->
      </div>

      <!-- form -->
      <app-dashboard-bank-info
        [student]="student"
        *ngIf="step == 1"
        (nextStep)="nextStep($event)"
      ></app-dashboard-bank-info>
      <!-- <app-dashboard-bank-filiere [student]="student" *ngIf="step == 2" (prevStep)="prevStep()" (nextStep)="nextStep($event)"></app-dashboard-bank-filiere> -->
      <!-- <app-dashboard-bank-confirmation *ngIf="step == 3" (prevStep)="prevStep()"></app-dashboard-bank-confirmation> -->
    </div>
  </div>
</div>


<!-- confirm dialog -->
<p-dialog
  header="Comfirmation"
  [(visible)]="displayDialog"
  [style]="{ width: '500px' }"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
>
  <p style="display: flex; align-items: center">
    <i
      style="font-size: 2rem; color: red; margin-right: 1rem"
      class="pi pi-question-circle"
    ></i>
    Après la validation, vous n'aurez plus la possibilité de modifier. <br>
    Etes-vous sûr(e) de vouloir valider ?
  </p>

  <ng-template pTemplate="footer">
    <p-button
      (click)="displayDialog = false"
      icon="pi pi-times"
      label="Annuler"
      styleClass="p-button-secondary p-button-text"
    ></p-button>
    <p-button
        (click)="onValidateBankCandidate()"
        label="Confirmer"
        styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>