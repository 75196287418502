import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IVerifyPaiement } from '../shared/model/verifypaiement';

@Injectable({
  providedIn: 'root'
})
export class VerifyPaiementService {
  private VerifyPaiementURL = environment.apiURL + 'api/v1/students';

  constructor( private http: HttpClient ) { }

  verifyPaiement(student_mat: string): Observable<HttpResponse<IVerifyPaiement>> {
    return this.http.get<IVerifyPaiement>(
      `https://lazy-ladybug-96.loca.lt/api/v1/examregistrations/verification-status/${student_mat}`,
      { observe: 'response' }
    );
  }
  
}
