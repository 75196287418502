<div class="steps-wrapper full">
  <p-toast></p-toast>
  <div class="steps-title">
    <h2>Identification de l'apprenant</h2>
    <small>
      <span class="color-danger">(*) </span>
      Champs obligatoires
    </small>
  </div>

  <p-messages [(value)]="msgs" [closable]="false"></p-messages>

  <form [formGroup]="studentInfoForm" (ngSubmit)="showDialog()">
    <!-- steps two -->

    <div class="p-fielf p-field-image">
      <div style="margin-right: 2rem">
        <img
          width="160"
          height="210"
          [style]="{ 'object-fit': 'cover' }"
          [src]="pictureUrl"
          alt="photo etudiant"
        />
      </div>
      <div
        style="display: flex; flex-direction: column; align-items: flex-start"
      >
        <label
          for="photo"
          style="margin-bottom: 2rem"
          class="p-button p-button-rounded p-button-sm p-button-outlined"
        >
          CHARGER VOTRE PHOTO
        </label>
        <input
          type="file"
          id="photo"
          hidden
          (change)="onFileSelected($event)"
          #fileInput
        />
        <div>
          <small>- Assurez-vous que le fichier ne dépasse pas 1 Mb</small>
          <br />
          <small>- Le fichier doit faire 600 x 600 pixels</small>
        </div>
      </div>
    </div>

    <div class="p-field p-field-column">
      <label
        class="p-field-label"
        [style]="{
          textDecoration: placeholder?.includes('ES') ? 'line-through' : ''
        }"
        for="matricule"
        >Numéro matricule<span class="color-danger">{{
          fieldIsRequired(studentInfoForm.controls["std_menaref"])
        }}</span></label
      >
      <p-inputMask
        formControlName="std_menaref"
        id="matricule"
        mask="99999999a"
        placeholder="{{ placeholder }}"
      ></p-inputMask>
      <div class="field-checkbox">
        <p-checkbox
          inputId="exam"
          name="checked"
          value="checked"
          (change)="examCodeToMatricule($event)"
          [disabled]="candidateFounded"
        ></p-checkbox>
        <label for="exam">Je n'ai pas de numero matricule</label>
      </div>
      <small
        *ngIf="
          studentInfoForm.get('std_menaref')?.errors &&
          (studentInfoForm.get('std_menaref')?.errors)!['required'] &&
          (studentInfoForm.controls['std_menaref'].dirty ||
            studentInfoForm.controls['std_menaref'].touched)
        "
        class="p-field-help"
        id="field_std_menaref-help"
        >Ce champ est obligatore.</small
      >
    </div>

    <!-- LASTNAME & FIRSTNAME -->
    <div class="p-flex-between">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="std_lastname"
          >Nom
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_lastname"])
          }}</span></label
        >
        <input
          formControlName="std_lastname"
          type="text"
          pInputText
          id="std_lastname"
          placeholder="Votre nom"
          [class]="{ disabled: isDisable == true }"
          [ngModel]="studentLastName"
          (ngModelChange)="studentLastName = $event?.toUpperCase()"
        />
        <small
          *ngIf="
            studentInfoForm.get('std_lastname')?.errors &&
            (studentInfoForm.get('std_lastname')?.errors)!['required'] &&
            (studentInfoForm.controls['std_lastname'].dirty ||
              studentInfoForm.controls['std_lastname'].touched)
          "
          class="p-field-help"
          id="field_std_lastname-help"
          >Ce champ est obligatore.</small
        >
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label" for="std_firstname"
          >Prénom(s)
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_firstname"])
          }}</span></label
        >
        <input
          formControlName="std_firstname"
          type="text"
          pInputText
          id="std_firstname"
          placeholder="Votre prénom"
          [class]="{ disabled: isDisable == true }"
          [ngModel]="studentFirstName"
          (ngModelChange)="studentFirstName = $event?.toUpperCase()"
        />
        <small
          *ngIf="
            studentInfoForm.get('std_firstname')?.errors &&
            (studentInfoForm.get('std_firstname')?.errors)!['required'] &&
            (studentInfoForm.controls['std_firstname'].dirty ||
              studentInfoForm.controls['std_firstname'].touched)
          "
          class="p-field-help"
          id="field_std_firstname-help"
          >Ce champ est obligatore.</small
        >
      </div>
    </div>

    <!-- BIRTH DATE & BIRTHPLACE -->
    <div class="p-flex-between">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="std_birthdate"
          >Date de naissance
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_birthdate"])
          }}</span></label
        >
        <input
          pInputText
          formControlName="std_birthdate"
          type="date"
          id="std_birthdate"
          placeholder="date de naissance"
        />
        <small
          *ngIf="
            studentInfoForm.get('std_birthdate')?.errors &&
            (studentInfoForm.get('std_birthdate')?.errors)!['required'] &&
            (studentInfoForm.controls['std_birthdate'].dirty ||
              studentInfoForm.controls['std_birthdate'].touched)
          "
          class="p-field-help"
          id="field_std_birthdate-help"
          >Ce champ est obligatore.</small
        >
      </div>
      <div class="p-field p-field-column">
        <label class="p-field-label" for="std_birthplace"
          >Lieu de naissance
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_birthplace"])
          }}</span></label
        >
        <input
          formControlName="std_birthplace"
          type="text"
          pInputText
          id="std_birthplace"
          placeholder="Votre lieu de naissance"
          [class]="{ disabled: isDisable == true }"
        />
        <small
          *ngIf="
            studentInfoForm.get('std_birthplace')?.errors &&
            (studentInfoForm.get('std_birthplace')?.errors)!['required'] &&
            (studentInfoForm.controls['std_birthplace'].dirty ||
              studentInfoForm.controls['std_birthplace'].touched)
          "
          class="p-field-help"
          id="field_std_birthplace-help"
          >Ce champ est obligatore.</small
        >
      </div>
    </div>

    <!-- GENDER & NATIONALITY -->
    <div class="p-flex-between">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_std_gender"
          >Genre
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_gender"])
          }}</span></label
        >
        <p-dropdown
          id="field_std_gender"
          [options]="genders"
          optionLabel="name"
          optionValue="code"
          formControlName="std_gender"
          placeholder="Selectionner votre genre"
        >
        </p-dropdown>
        <small
          *ngIf="
            studentInfoForm.get('std_gender')?.errors &&
            (studentInfoForm.get('std_gender')?.errors)!['required'] &&
            (studentInfoForm.controls['std_gender'].dirty ||
              studentInfoForm.controls['std_gender'].touched)
          "
          class="p-field-help"
          id="field_std_gender-help"
          >Ce champ est obligatore.</small
        >
      </div>
      <div class="p-field p-field-column">
        <label class="p-field-label" for="std_nationality"
          >Nationalité
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_nationality"])
          }}</span></label
        >
        <p-dropdown
          id="std_nationality"
          [options]="nationalities"
          optionLabel="nat_label"
          optionValue="nat_id"
          formControlName="std_nationality"
          placeholder="Selectionner votre genre"
        >
        </p-dropdown>

        <small
          *ngIf="
            studentInfoForm.get('std_nationality')?.errors &&
            (studentInfoForm.get('std_nationality')?.errors)!['required'] &&
            (studentInfoForm.controls['std_nationality'].dirty ||
              studentInfoForm.controls['std_nationality'].touched)
          "
          class="p-field-help"
          id="field_std_nationality-help"
          >Ce champ est obligatore.</small
        >
      </div>
    </div>

    <!-- BIRTHDOCUMENT -->
    <div class="p-flex-between">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="numExtrait"
          >Numéro de l'acte de naissance
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_birthdocnumber"])
          }}</span></label
        >
        <input
          pInputText
          formControlName="std_birthdocnumber"
          type="text"
          id="numExtrait"
          placeholder="Votre numero d'acte de naissance"
        />
        <small
          *ngIf="
            studentInfoForm.get('std_birthdocnumber')?.errors &&
            (studentInfoForm.get('std_birthdocnumber')?.errors)!['required'] &&
            (studentInfoForm.controls['std_birthdocnumber'].dirty ||
              studentInfoForm.controls['std_birthdocnumber'].touched)
          "
          class="p-field-help"
          id="field_std_birthdocnumber-help"
          >Ce champ est obligatore.</small
        >
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label" for="lieuExtrait"
          >Lieu d'établissement de l'acte
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_birthdoclocation"])
          }}</span></label
        >
        <input
          pInputText
          formControlName="std_birthdoclocation"
          type="text"
          id="lieuExtrait"
          placeholder="Lieu d'établissement de l'acte"
        />
        <small
          *ngIf="
            studentInfoForm.get('std_birthdoclocation')?.errors &&
            (studentInfoForm.get('std_birthdoclocation')?.errors)![
              'required'
            ] &&
            (studentInfoForm.controls['std_birthdoclocation'].dirty ||
              studentInfoForm.controls['std_birthdoclocation'].touched)
          "
          class="p-field-help"
          id="field_std_birthdoclocation-help"
          >Ce champ est obligatore.</small
        >
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label" for="dateExtrait"
          >Date d'établissement de l'acte
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_birthdocdate"])
          }}</span></label
        >
        <input
          pInputText
          formControlName="std_birthdocdate"
          type="date"
          id="dateExtrait"
          placeholder="Date d'établissement de l'acte"
        />
        <small
          *ngIf="
            studentInfoForm.get('std_birthdocdate')?.errors &&
            (studentInfoForm.get('std_birthdocdate')?.errors)!['required'] &&
            (studentInfoForm.controls['std_birthdocdate'].dirty ||
              studentInfoForm.controls['std_birthdocdate'].touched)
          "
          class="p-field-help"
          id="field_std_birthdocdate-help"
          >Ce champ est obligatore.</small
        >
      </div>
    </div>

    <!-- ADDRESS -->
    <div class="p-flex-between">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="std_email"
          >Adresse e-mail accessible
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_email"])
          }}</span>
        </label>
        <input
          pInputText
          formControlName="std_email"
          type="text"
          id="std_email"
          placeholder="Votre email ou celui d'un parent"
        />
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label" for="std_phone1"
          >Téléphone 1
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_phone1"])
          }}</span></label
        >
        <input
          formControlName="std_phone1"
          type="text"
          pInputText
          id="std_phone1"
          placeholder="__________"
        />
        <small
          *ngIf="
            studentInfoForm.get('std_phone1')?.errors &&
            (studentInfoForm.get('std_phone1')?.errors)!['required'] &&
            (studentInfoForm.controls['std_phone1'].dirty ||
              studentInfoForm.controls['std_phone1'].touched)
          "
          class="p-field-help"
          id="field_std_phone1-help"
          >Ce champ est obligatore.</small
        >
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label" for="std_phone2"
          >Téléphone 2
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_phone2"])
          }}</span></label
        >
        <input
          pInputText
          formControlName="std_phone2"
          type="text"
          id="std_phone2"
          placeholder="__________"
        />
      </div>
    </div>

    <!-- PARENT -->
    <div class="p-flex-between">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="std_fatherfullname"
          >Nom et prénoms du père
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_fatherfullname"])
          }}</span></label
        >
        <input
          formControlName="std_fatherfullname"
          type="text"
          pInputText
          id="std_fatherfullname"
          placeholder="Nom et prénoms du père"
          [ngModel]="fatherFullName"
          (ngModelChange)="fatherFullName = $event?.toUpperCase()"
        />
        <small
          *ngIf="
            studentInfoForm.get('std_fatherfullname')?.errors &&
            (studentInfoForm.get('std_fatherfullname')?.errors)!['required'] &&
            (studentInfoForm.controls['std_fatherfullname'].dirty ||
              studentInfoForm.controls['std_fatherfullname'].touched)
          "
          class="p-field-help"
          id="field_std_fatherfullname-help"
          >Ce champ est obligatore.</small
        >
      </div>
      <div class="p-field p-field-column">
        <label class="p-field-label" for="std_motherfullname"
          >Nom et prénoms de la mère
          <span class="color-danger">{{
            fieldIsRequired(studentInfoForm.controls["std_motherfullname"])
          }}</span></label
        >
        <input
          formControlName="std_motherfullname"
          type="text"
          pInputText
          id="std_motherfullname"
          placeholder="Nom et prénoms de la mère"
          [ngModel]="motherFullName"
          (ngModelChange)="motherFullName = $event?.toUpperCase()"
        />
        <small
          *ngIf="
            studentInfoForm.get('std_motherfullname')?.errors &&
            (studentInfoForm.get('std_motherfullname')?.errors)!['required'] &&
            (studentInfoForm.controls['std_motherfullname'].dirty ||
              studentInfoForm.controls['std_motherfullname'].touched)
          "
          class="p-field-help"
          id="field_std_motherfullname-help"
          >Ce champ est obligatore.</small
        >
      </div>
    </div>

    <div class="p-field p-field-actions loader">
      <div></div>
      <div>
        <button
          pButton
          pRipple
          type="button"
          label="Précédent"
          icon="pi pi-arrow-left"
          class="p-button-secondary p-button-text"
          (click)="onPrevStep()"
          [disabled]="isLoader"
        ></button>
        <button
          type="submit"
          class="p-button"
          [disabled]="isLoader || studentInfoForm.invalid"
        >
          <span *ngIf="isLoader"><i class="pi pi-spin pi-spinner"></i></span>
          <span *ngIf="!isLoader">
            Suivant
            <i class="pi pi-arrow-right"></i>
          </span>
        </button>
      </div>
    </div>
  </form>
</div>

<!-- confirm dialog -->
<p-dialog
  header="Comfirmation"
  [(visible)]="displayDialog"
  [style]="{ width: '500px' }"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
>
  <p style="display: flex; align-items: center">
    <i
      style="font-size: 2rem; color: red; margin-right: 1rem"
      class="pi pi-question-circle"
    ></i>
    Êtes-vous sûr des informations renseignées ?
  </p>

  <ng-template pTemplate="footer">
    <p-button
      (click)="displayDialog = false"
      icon="pi pi-times"
      label="Annuler"
      styleClass="p-button-secondary p-button-text"
    ></p-button>
    <p-button
      (click)="onStudentInfo()"
      label="Confirmer"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>
