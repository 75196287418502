import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Message } from 'primeng/api';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';
import { Router } from '@angular/router';
import { activeSidebarItem } from 'src/app/shared/utils/step.utils';
import { SIDEBAR_ITEMS_KEY } from '../../shared/constant/step.constant';
import { StepService } from '../step.service';
import { StudentService, ISearchStudent } from '../../services/student.service';
import { MessageService } from 'primeng/api';
import { YearService } from '../../services/year.service';
import { IYear } from 'src/app/shared/model/year.model';
import { SessionStorageService } from 'src/app/session-storage.service';

@Component({
  selector: 'app-registration-search',
  templateUrl: './registration-search.component.html',
  styleUrls: ['./registration-search.component.scss'],
})
export class RegistrationSearchComponent implements OnInit {
  registrationMSG: Message[] = [];
  editForm = this.fb.group({
    codeMena: [
      [],
      [Validators.required, Validators.pattern(/^\d{8}[A-Z]{1}$/)],
    ],
    academicYear: ['', []],
  });
  isLoading = false;
  isSaving = false;
  codeMenaValue = '';
  searchResult?: ISearchStudent = {};

  years?: IYear[] = [];
  year = 0;
  yearAcademic = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sidebarStepService: StepService,
    private studentService: StudentService,
    private messageService: MessageService,
    private yearService: YearService,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnInit(): void {
    const registration = this.loadChoiceFromSS();
    localStorage.setItem('registrationStarted', JSON.stringify(true));

    this.yearService.geActiveYear().subscribe((value) => {
      this.yearAcademic = value.yrs_academic!;
      this.setActiveYear(value.yrs_session!);
    });

    if (registration) {
      this.editForm.patchValue({
        codeMena: registration.codeMena,
      });

      this.codeMenaValue = registration.codeMena;
    }
  }

  setActiveYear(year: number): void {
    this.sessionStorage.setItem('activeYear', JSON.stringify(year));
  }

  searchStudent(): void {
    this.messageService.clear();
    this.isLoading = true;
    this.saveChoiceToSS();
    this.studentService.searchStudent(this.codeMenaValue).subscribe({
      next: (res) => {
        const data = res.body;
        console.log(data);
        if (data?.response_code === 2) {
          this.registrationMSG = [
            {
              severity: 'warn',
              detail: data.message,
            },
          ];
          this.isLoading = false;
          this.sessionStorage.removeItem('registration');
        } else {
          this.sessionStorage.setItem(
            'foundedStudent',
            JSON.stringify({ message: data?.message, founded: data?.founded })
          );
          setTimeout(() => {
            this.isLoading = false;
            this.goToNext();
          }, 0);
        }
      },
      error: (error) => {
        const element = error.error;
        console.log(error, element);
        this.sessionStorage.setItem(
          'foundedStudent',
          JSON.stringify({ message: element.message, founded: element.founded })
        );

        setTimeout(() => {
          this.isLoading = false;
          this.goToNext();
        }, 1000);
      },
    });
  }

  saveChoiceToSS(): void {
    this.sessionStorage.setItem(
      'registration',
      JSON.stringify(this.editForm.value)
    );
  }

  handleKeyUp(): void {
    if (this.editForm.valid) this.searchStudent();
  }

  loadChoiceFromSS(): any {
    const exam = this.sessionStorage.getItem('registration');
    if (exam) {
      return JSON.parse(exam);
    }
    return {};
  }

  goToNext(): void {
    activeSidebarItem(this.loadDataFromLS(), this.sessionStorage);
    this.sidebarStepService.init(this.sessionStorage);
    this.router.navigate(['/', 'registration', 'infos-student']);
  }

  fieldIsRequired(control: AbstractControl): string {
    if (hasRequiredField(control)) {
      return '*';
    }

    return '';
  }

  loadDataFromLS(): any {
    return JSON.parse(this.sessionStorage.getItem(SIDEBAR_ITEMS_KEY)!);
  }
}
