import { Injectable } from '@angular/core';
import { IStudent } from 'src/app/shared/model/student.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface ISearchStudent {
  status_code?: number;
  message?: string;
  student?: IStudent;
  founded?: boolean;
  response_code?: number;
}

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  private studentResourceURL = environment.apiURL + 'api/v1/students';

  constructor(private http: HttpClient) {}

  searchStudent(student_ref: string): Observable<HttpResponse<ISearchStudent>> {
    return this.http.get<ISearchStudent>(
      `${this.studentResourceURL}/search-student/${student_ref}/`,
      { observe: 'response' }
    );
  }
}
