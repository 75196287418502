import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { IRegistration } from 'src/app/shared/model/registration.model';
import { RegistrationService } from '../../services/registration.service';
import { getLevel } from 'src/app/shared/utils/level.utils';
import { getAccessMode } from 'src/app/shared/utils/accessnode.utils';
import { getGenderLabel } from '../../shared/utils/gender.utils';
import { ConfirmationService } from 'primeng/api';
import {
  levels,
  getLevel as selectedDiplomaLevels,
  getLevelByDiploma,
} from 'src/app/shared/constant/studylevel.constant';
import { statusList } from 'src/app/shared/constant/status.constant';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';
import { IDiploma } from 'src/app/shared/model/diploma.model';
import { IUser } from 'src/app/account/model/user.model';
import { AuthService } from 'src/app/account/auth.service';
import { IStudyField } from 'src/app/shared/model/studyfield.model';
import { ISchool } from 'src/app/shared/model/school.model';
import { combineLatestWith, map, tap } from 'rxjs/operators';
import { ExamregistrationService } from 'src/app/services/examregistration.service';
import { ExamRegistration } from 'src/app/shared/model/examregistration.model';
import { environment } from 'src/environments/environment';
import { DEFAULT_PROFILE_PICTURE } from 'src/app/shared/constant/image.constant';
import { Observable } from 'rxjs';
import { tree } from 'd3';
import {} from 'primeng/messages';

@Component({
  selector: 'app-dashboard-validation-listing',
  templateUrl: './dashboard-validation-listing.component.html',
  styleUrls: ['./dashboard-validation-listing.component.scss'],
})
export class DashboardValidationListingComponent implements OnInit {
  items: MenuItem[] = [];
  home: MenuItem = {};
  isLoading = false;

  displayDetail = false;
  registrations?: IRegistration[];
  selectedRegistration?: IRegistration;

  statusList: any[] = [];
  selectedStatus: any[] = [];

  exportColumns: any[] = [];

  rejectedMessage = '';
  rejectForm = this.fb.group({
    message: [null, [Validators.required]],
  });

  filterRegistrations?: IRegistration[];

  displayRejectMessageForm = false;
  isSaving = false;

  searchStudent = '';

  levels = levels;
  selectedLevel: any;

  schoolDiplomas: IDiploma[] = [];
  selectedDiploma?: IDiploma | null;

  studyfields: IStudyField[] = [];
  selectedStudyfield?: IStudyField | null;

  authUser?: IUser;

  authUserSchool?: ISchool;
  tempRegistration?: IRegistration[];

  displayValidationDialog = false;

  candidatureChecked = false;
  searchFounded = 0;

  constructor(
    private registrationService: RegistrationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private authService: AuthService,
    private examRegistrationService: ExamregistrationService
  ) {}

  ngOnInit(): void {
    // this.levels = levels;
    this.statusList = statusList;
    // this.authService.getAuthSchool().subscribe((res) => {
    //   this.authUserSchool = res.body || {};
    // });
    this.authService.getAuthUser().subscribe((user) => {
      this.authUser = user;
      if (this.authUser) {
        this.loadRegistratedUserSchoolDiploma();
        this.loadRegistratedUserSchoolStudyField();
        // this.loadRegistration();
        this.authUserSchool = this.authUser?.usr_school;
      }
    });
    this.items = [
      {
        label: 'Listing',
      },
    ];
    this.home = {
      label: 'Dashboard',
      icon: 'pi pi-home',
      routerLink: '/dashboard/validators/home',
    };
  }

  showDetail(registration: IRegistration): void {
    this.selectedRegistration = registration;
    this.confirmationService.close();
    this.displayDetail = true;
  }

  getPicture(image: string): string {
    if (image) {
      return environment.mediaURL + image;
    }
    return DEFAULT_PROFILE_PICTURE;
  }

  loadRegistration(): void {
    this.isLoading = true;
    this.registrationService
      .findSchoolRegistrations(this.authUser?.usr_school?.sch_id!)
      .subscribe((res) => {
        this.isLoading = false;
        this.registrations = res.body || [];
        this.filterRegistrations = [...this.registrations];
        this.tempRegistration = [...this.registrations];
      });
  }

  studentLevelLabel(level: string): string {
    return getLevel(level);
  }

  studentAccessMode(mode: string): string {
    return getAccessMode(mode);
  }

  studentGender(gender: string): string {
    return getGenderLabel(gender);
  }

  confirm(): void {
    this.displayValidationDialog = true;
  }

  validate(): void {
    this.isSaving = true;
    const reg_id = this.selectedRegistration?.reg_id!;
    const examRegistration = new ExamRegistration(
      this.selectedRegistration?.reg_student,
      this.selectedRegistration?.reg_year
    );

    if (this.candidatureChecked) {
      this.registrationService
        .validateRegistration(reg_id, {
          reg_validationstatus: 2,
          initiatePayment: true,
        })
        .pipe(
          combineLatestWith(
            this.examRegistrationService.save({ exam: examRegistration })
          ),
          map(([registration, examregistration]) => {
            if (registration && examregistration) {
              this.isSaving = false;
              this.messageService.add({
                severity: 'success',
                summary: 'validation',
                detail: 'Validation effectuée avec succèes!',
              });
              // this.loadRegistration();
              this.filterRegistrations = this.registrations;
            }
          })
        )
        .subscribe((res) => {
          console.log(res);
        });
    } else {
      this.registrationService
        .validateRegistration(reg_id, {
          reg_validationstatus: 1,
          initiatePayment: false,
        })
        .subscribe((res) => {
          this.loadRegistration();
          this.messageService.add({
            severity: 'success',
            summary: 'validation',
            detail: 'Validation effectuée avec succèes!',
          });
        });
    }

    this.displayDetail = false;
    this.displayValidationDialog = false;
    this.loadRegistration();
  }

  reject(): void {
    this.displayRejectMessageForm = true;
  }

  handleRegistrationReject(): void {
    const reg_id = this.selectedRegistration?.reg_id!;
    const message = this.rejectedMessage;
    this.isSaving = true;

    console.log(message);

    this.registrationService
      .validateRegistration(reg_id, {
        reg_validationstatus: 2,
        reg_rejectedmessage: message,
        initiatePayment: false,
      })
      .subscribe((res) => {
        this.isSaving = false;
        this.messageService.add({
          severity: 'success',
          summary: 'rejet',
          detail: "L'enregistrement à été supprimé",
        });
        this.displayRejectMessageForm = false;
        this.displayDetail = false;

        // this.loadRegistration();
        this.filterRegistrations = this.registrations;
      });
  }

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }

  handleFilterByStatus(): void {
    let tempRegistration: IRegistration[] =
      this.searchStudent && this.tempRegistration?.length !== 0
        ? [...this.tempRegistration!]
        : [...this.registrations!];
    if (this.selectedStatus.length !== 0) {
      this.filterRegistrations = tempRegistration.filter((registration) =>
        this.selectedStatus.includes(registration.reg_validationstatus)
      );
    } else {
      if (this.searchStudent) {
        this.tempRegistration = [...this.filterRegistrations!];
        this.handleFilterByStudentNameOrRef();
      } else {
        this.filterRegistrations = [...this.registrations!];
        this.tempRegistration = [...this.registrations!];
      }
    }
  }

  handleFilterByStudentNameOrRef(): void {
    let tempRegistration: IRegistration[] =
      this.selectedStatus?.length !== 0 && this.tempRegistration?.length !== 0
        ? [...this.tempRegistration!]
        : [...this.registrations!];
    if (this.searchStudent) {
      this.searchStudent = this.searchStudent?.toLowerCase();
      this.filterRegistrations = tempRegistration.filter(
        (registration) =>
          registration.reg_student?.std_lastname
            ?.toLowerCase()
            .includes(this.searchStudent) ||
          registration.reg_student?.std_firstname
            ?.toLowerCase()
            .includes(this.searchStudent) ||
          registration.reg_student?.std_menaref
            ?.toLowerCase()
            ?.includes(this.searchStudent) ||
          registration.reg_student?.std_etfparef
            ?.toLowerCase()
            ?.includes(this.searchStudent)
      );
    } else {
      if (this.selectedStatus?.length !== 0) {
        this.tempRegistration = [...this.filterRegistrations!];
        this.handleFilterByStatus();
      } else {
        this.filterRegistrations = this.registrations;
        this.tempRegistration = [...this.registrations!];
      }
    }

    console.log(this.filterRegistrations);
  }

  handleSearchRegistrationWithNameAndStatus(): void {}

  loadRegistratedUserSchoolDiploma(): void {
    this.registrationService
      .findSchoolDiplomas(this.authUser?.usr_school?.sch_id!)
      .subscribe((res) => {
        this.schoolDiplomas = res.body || [];
      });
  }

  loadRegistratedUserSchoolStudyField(): void {
    this.registrationService
      .findStudyFieldByAuthUserSchool(this.authUser?.usr_school?.sch_id!)
      .subscribe((res) => (this.studyfields = res.body || []));
  }

  handleDiplomaChange(): void {
    this.selectedStudyfield = null;
    this.selectedLevel = null;
    this.registrationService
      .findDiplomaStudyFields(
        this.authUser?.usr_school?.sch_id!,
        this.selectedDiploma?.dip_id!
      )
      .subscribe((res) => (this.studyfields = res.body || []));
  }

  handleSearch(): void {
    this.isLoading = true;
    const criteria: any = {};
    this.searchStudent = '';
    this.selectedStatus = [];
    if (this.selectedLevel) criteria['level'] = this.selectedLevel;
    if (this.selectedStudyfield)
      criteria['studyfield'] = this.selectedStudyfield.sfd_id;
    if (this.selectedDiploma) criteria['diploma'] = this.selectedDiploma.dip_id;
    this.registrationService
      .searchRegistrationByCriteria(criteria, this.authUserSchool?.sch_id!)
      .subscribe((registrations) => {
        this.isLoading = false;
        this.filterRegistrations = registrations;
        this.registrations = registrations;

        if (this.registrations.length === 0) {
          this.searchFounded = 2;
        } else {
          this.searchFounded = 1;
        }
      });
  }

  clearRegistration(): void {
    this.searchFounded = 0;
    this.registrations = [];
    this.filterRegistrations = [];
  }

  handleLevelStudyFieldChange(): void {
    this.selectedLevel = null;
    const order = this.selectedDiploma?.dip_shortlabel === 'BAC' ? 1 : 2;
    this.levels = selectedDiplomaLevels(order, this.selectedDiploma!);
  }

  refreshRegistrations(): void {
    this.clearRegistration();
    this.selectedLevel = null;
    this.selectedStatus = [];
    this.searchStudent = '';
    this.selectedDiploma = null;
    this.selectedStudyfield = null;
  }

  closeValidationDialog(): void {
    this.displayDetail = false;
  }

  // exportPDF(): void {
  //   import('jspdf').then((jsPDF) => {
  //     import('jspdf-autotable').then((x) => {
  //     import { IUser } from 'src/app/account/model/user.model';
  // doc import { IStudyField } from '../../shared/model/studyfield.model';
  //  = nimport { tap } from 'rxjs/operators';
  // ew jsPDF.default(0, 0);
  //       doc.autoTable(this.exportColumns, this.registrations);
  //       doc.save('products.pdf');
  //     });
  //   });
  // }
}
