import { Component, OnInit } from '@angular/core';
import { IStudent } from 'src/app/shared/model/student.model';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../session-storage.service';
import { IExamenBreadcrumb } from 'src/app/shared/model/examen-breadcrumb.model';
import { FreeCandidateStepService } from 'src/app/inscription/free-candidate-step.service';

@Component({
  selector: 'app-examen-free-candidate',
  templateUrl: './examen-free-candidate.component.html',
  styleUrls: ['./examen-free-candidate.component.scss'],
})
export class ExamenFreeCandidateComponent implements OnInit {
  step: any = 1;
  student!: IStudent;
  student2!: any;
  stepsItems: IExamenBreadcrumb[] = [];
  currentStep = 0;
  isCandidateFound = false;

  constructor(
    private router: Router,
    private storageService: SessionStorageService,
    private fcStepService: FreeCandidateStepService
  ) {}

  ngOnInit(): void {
    this.getStepItems();
  }

  nextStep(event: any) {
    // this.student = event.student;
    // this.isCandidateFound = event.exist;

    this.fcStepService.setNextStep(this.storageService);
    this.currentStep = this.fcStepService.getCurrentStep(this.storageService);
  }

  prevStep() {
    this.fcStepService.setPrevStep(this.storageService);
    this.currentStep = this.fcStepService.getCurrentStep(this.storageService);
  }

  quitProcess() {
    this.router.navigate(['/']);
  }

  private getActiveStep(): void {
    this.currentStep = this.fcStepService.getCurrentStep(this.storageService);
  }

  getStepItems(): void {
    this.getActiveStep();
    this.fcStepService
      .getStepItems(this.storageService)
      .subscribe((items) => (this.stepsItems = items));
  }
}
