<div class="dob-wrapper" style="padding-top: 2rem">
  <div class="dob-header" style="margin-bottom: 0rem">
    <h1 class="dob-header-title">
      Orientation dans les étblissements de l'Enseignement Technique et de la
      Formation Professionelle
    </h1>
  </div>
</div>

<div class="dob-steps-container">
  <div class="dob-steps">
    <ul class="dob-step-list">
      <li
        class="dob-step-item"
        [ngClass]="{ active: item?.active!, validate: item?.validate! }"
        *ngFor="let item of items$ | async"
      >
        <span class="dob-step-item-index">
          <span *ngIf="!item?.validate! || (item?.validate && item?.active)">
            {{ item?.step! }}</span
          >
          <span *ngIf="item?.validate! && !item?.active"
            ><i class="pi pi-check"></i
          ></span>
        </span>
        <div class="dob-step-item-content">
          <span class="dob-step-item-label">{{ item?.label }}</span>
          <!-- <span class="dob-step-item-detail">{{ item?.detail }}</span> -->
        </div>
      </li>
    </ul>
  </div>
</div>

<!-- DOBM APPLICANT SECTION -->
<ng-container *ngIf="(currentStep$ | async) === 1">
  <div class="dob-main-content-wrapper">
    <div class="dob-main-content-header">
      <p class="dob-main-content-title">Identification</p>

      <small>
        <span class="color-danger">(*) </span>
        Champ obligatoire
      </small>
    </div>

    <p-messages [(value)]="msgs" key="alertMessage"></p-messages>

    <form [formGroup]="editForm">
      <p-toast key="studentInfoEdited"></p-toast>

      <div class="form-section">
        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_matricule"
              >Matricule
              <span class="color-danger">{{
                fieldIsRequired(editForm.controls["app_matricule"])
              }}</span>
            </label>
            <p-inputMask
              mask="99999999a"
              formControlName="app_matricule"
              [ngModel]="matriculeValue"
              id="field_matricule"
              placeholder="_________"
              [maxlength]="9"
              (ngModelChange)="matriculeValue = $event?.toUpperCase()"
              [autoClear]="false"
              (keydown.enter)="handleKeyUp()"
            >
            </p-inputMask>
            <small
              *ngIf="
                editForm.get('matricule')?.errors &&
                (editForm.get('matricule')?.errors)!['required'] &&
                (editForm.controls['matricule'].dirty ||
                  editForm.controls['matricule'].touched)
              "
              class="p-field-help"
              id="field_matricule-help"
              >Ce champ est obligatoire.</small
            >
            <small
              *ngIf="
                editForm.get('matricule')?.errors &&
                (editForm.get('matricule')?.errors)!['pattern']
              "
              class="p-field-help"
              id="field_matricule-help"
              >Format du matricule non reconnu: (Ex: 0000000X)</small
            >
          </div>
        </div>

        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_lastname"
              >Nom
              <span class="color-danger">{{
                fieldIsRequired((editForm?.controls)!["app_lastname"])
              }}</span>
            </label>
            <input
              type="text"
              pInputText
              formControlName="app_lastname"
              id="field_lastname"
              placeholder="Votre nom"
              [ngModel]="studentLastName"
              (ngModelChange)="studentLastName = $event?.toUpperCase()"
            />
            <small
              *ngIf="
                editForm.get('app_lastname')?.errors &&
                (editForm.get('app_lastname')?.errors)!['required'] &&
                (editForm.controls['app_lastname'].dirty ||
                  editForm.controls['app_lastname'].touched)
              "
              class="p-field-help"
              id="field_app_lastname-help"
              >Ce champ est obligatore.</small
            >
            <small class="p-field-help" id="field_app_lastname-help"
              >&nbsp;</small
            >
          </div>

          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_firstname"
                >Prénom(s)
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_firstname"])
                }}</span>
              </label>
              <input
                type="text"
                pInputText
                formControlName="app_firstname"
                id="field_app_firstname"
                placeholder="Votre prénom"
                [ngModel]="studentFirstName"
                (ngModelChange)="studentFirstName = $event?.toUpperCase()"
              />
              <small
                *ngIf="
                  editForm.get('app_firstname')?.errors &&
                  (editForm.get('app_firstname')?.errors)!['required'] &&
                  (editForm.controls['app_firstname'].dirty ||
                    editForm.controls['app_firstname'].touched)
                "
                class="p-field-help"
                id="field_app_firstname-help"
                >Ce champ est obligatore.</small
              >
              <small class="p-field-help" id="field_app_firstname-help"
                >&nbsp;</small
              >
            </div>
          </div>
        </div>

        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_app_birthdate"
              >Date de naissance
              <span class="color-danger">{{
                fieldIsRequired((editForm?.controls)!["app_birthdate"])
              }}</span>
            </label>
            <p-calendar
              formControlName="app_birthdate"
              [showIcon]="true"
              inputId="field_app_birthdate"
              placeholder="Date de naissance Ex: 21-12-2010"
              dateFormat="dd-mm-yy"
              [showButtonBar]="true"
              (onSelect)="handleDateChange()"
              (onInput)="handleDateChange()"
            >
            </p-calendar>
            <small
              *ngIf="
                editForm.get('app_birthdate')?.errors &&
                (editForm.get('app_birthdate')?.errors)!['required'] &&
                (editForm.controls['app_birthdate'].dirty ||
                  editForm.controls['app_birthdate'].touched)
              "
              class="p-field-help"
              id="field_app_birthdate-help"
              >Ce champ est obligatore.</small
            >

            <small
              *ngIf="maxBirthDateAllowed"
              class="p-field-help"
              id="field_app_birthdate-help"
              style="line-height: 1.4"
            >
              Vous ne remplissez pas les critères d'age pour être orienté dans
              les filières de l'ETFP (l'age maximum est de 22 ans)
            </small>

            <small class="p-field-help" id="field_app_birthdate-help"
              >&nbsp;</small
            >
          </div>

          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_birthplace"
                >Lieu de naissance
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_birthplace"])
                }}</span>
              </label>
              <input
                type="text"
                pInputText
                formControlName="app_birthplace"
                id="field_app_birthplace"
                placeholder="Votre lieu de naissance"
                [ngModel]="studentBirthPlace"
                (ngModelChange)="studentBirthPlace = $event?.toUpperCase()"
              />
              <small
                *ngIf="
                  editForm.get('app_birthplace')?.errors &&
                  (editForm.get('app_birthplace')?.errors)!['required'] &&
                  (editForm.controls['app_birthplace'].dirty ||
                    editForm.controls['app_birthplace'].touched)
                "
                class="p-field-help"
                id="field_app_birthplace-help"
                >Ce champ est obligatore.</small
              >
              <small class="p-field-help" id="field_app_birthplace-help"
                >&nbsp;</small
              >
            </div>
          </div>
        </div>

        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_app_gender"
              >Genre
              <span class="color-danger">{{
                fieldIsRequired((editForm?.controls)!["app_gender"])
              }}</span>
            </label>
            <p-dropdown
              id="field_app_gender"
              [options]="genders"
              [(ngModel)]="selectedGender"
              optionLabel="name"
              optionValue="code"
              formControlName="app_gender"
              placeholder="Selectionner votre genre"
            >
            </p-dropdown>
            <small
              *ngIf="
                editForm.get('app_gender')?.errors &&
                (editForm.get('app_gender')?.errors)!['required'] &&
                (editForm.controls['app_gender'].dirty ||
                  editForm.controls['app_gender'].touched)
              "
              class="p-field-help"
              id="field_app_gender-help"
              >Ce champ est obligatore.</small
            >
            <small class="p-field-help" id="field_app_gender-help"
              >&nbsp;</small
            >
          </div>

          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_nationality"
                >Nationalité
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_nationality"])
                }}</span>
              </label>
              <p-dropdown
                id="field_app_nationality"
                [options]="nationalities"
                [(ngModel)]="selectedNationality"
                optionLabel="nat_label"
                formControlName="app_nationality"
                [filter]="true"
                filterPlaceholder="Nationalité"
                emptyFilterMessage="Aucune nationalité"
                placeholder="Selectionner votre nationalité"
                (onChange)="validateNationality()"
              >
              </p-dropdown>
              <small
                *ngIf="
                  editForm.get('app_nationality')?.errors &&
                  (editForm.get('app_nationality')?.errors)!['required'] &&
                  (editForm.controls['app_nationality'].dirty ||
                    editForm.controls['app_nationality'].touched)
                "
                class="p-field-help"
                id="field_app_nationality-help"
                >Ce champ est obligatore.</small
              >
              <small
                *ngIf="isNationalityInvalid"
                class="p-field-help"
                id="field_app_nationality-help"
                >Seule les candidats de nationalité IVOIRIENNE sont
                autorisée</small
              >
              <small
                *ngIf="!editForm.get('app_nationality')?.errors"
                class="p-field-help"
                id="field_app_nationality-help"
                >&nbsp;</small
              >
            </div>
          </div>
        </div>

        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_email"
                >Email
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_email"])
                }}</span>
              </label>
              <input
                type="email"
                pInputText
                formControlName="app_email"
                id="field_app_email"
                placeholder="Votre email"
              />
              <small
                *ngIf="
                  editForm.get('app_email')?.errors &&
                  (editForm.get('app_email')?.errors)!['required'] &&
                  (editForm.controls['app_email'].dirty ||
                    editForm.controls['app_email'].touched)
                "
                class="p-field-help"
                id="field_app_email-help"
                >Ce champ est obligatore.</small
              >
              <small
                *ngIf="
                  editForm.get('app_email')?.errors &&
                  (editForm.get('app_email')?.errors)!['email'] &&
                  (editForm.controls['app_email'].dirty ||
                    editForm.controls['app_email'].touched)
                "
                class="p-field-help"
                id="field_app_email-help"
                >Ce champ est doit être un email valide</small
              >
              <small class="p-field-help" id="field_app_email-help"
                >&nbsp;</small
              >
            </div>
          </div>

          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_phone"
                >Téléphone
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_phone"])
                }}</span>
              </label>
              <!-- <input
                type="text"
                pInputText
                formControlName="app_phone1"
                id="field_app_phone1"
                placeholder="Téléphone 1"
              /> -->
              <p-inputMask
                mask="9999999999"
                formControlName="app_phone"
                id="field_app_phone1"
                placeholder="__________"
                [autoClear]="false"
              >
              </p-inputMask>
              <small
                *ngIf="
                  editForm.get('app_phone')?.errors &&
                  (editForm.get('app_phone')?.errors)!['required'] &&
                  (editForm.controls['app_phone'].dirty ||
                    editForm.controls['app_phone'].touched)
                "
                class="p-field-help"
                id="field_app_phone1-help"
                >Ce champ est obligatore.</small
              >
              <small
                *ngIf="
                  editForm.get('app_phone')?.errors &&
                  (editForm.get('app_phone')?.errors)!['pattern'] &&
                  (editForm.controls['app_phone'].dirty ||
                    editForm.controls['app_phone'].touched)
                "
                class="p-field-help"
                id="field_app_phone-help"
                >Le numéro de téléphone incorrecte.</small
              >
              <small class="p-field-help" id="field_app_phone-help"
                >&nbsp;</small
              >
            </div>
          </div>
        </div>
      </div>

      <div class="p-field p-field-actions loader">
        <div></div>
        <div>
          <button
            pButton
            pRipple
            type="button"
            label="Précédent"
            icon="pi pi-arrow-left"
            class="p-button-secondary p-button-text"
            (click)="goPrev()"
            [disabled]="isSaving"
          ></button>

          <button
            [disabled]="editForm?.invalid || isLoading || isSaving"
            pButton
            type="button"
            (click)="goNext()"
            [style]="{ width: '130px' }"
          >
            <span *ngIf="isSaving"
              ><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i
            ></span>
            <span *ngIf="!isSaving">Suivant</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-container>
<!-- END IDENTIFICATION -->

<!-- STEP CHOICES -->
<ng-container *ngIf="(currentStep$ | async) === 2">
  <p-toast key="DOB_CHOICE_ALERT"></p-toast>
  <div class="dob-main-content-wrapper">
    <div class="dob-main-content-header">
      <p class="dob-main-content-title">Choix d'orientation</p>
      <p class="dob-main-content-sub-title">
        Vous devez faire au minimum
        <span class="chip chip-main">{{ minChoiceNumber }}</span> choix et au
        maximum <span class="chip chip-main">{{ maxChoiceNumber }}</span> choix
      </p>
      <p>
        Faite glisser chaque ligne de choix pour modifier l'ordre de préférence
      </p>
    </div>

    <app-choice-form
      (choice)="addChoice($event)"
      [selectedChoicesData]="selectedChoices"
    ></app-choice-form>

    <div
      cdkDropList
      class="choice-list"
      (cdkDropListDropped)="reorderChoice($event)"
    >
      <div class="choice-header">
        <div class="choice-header-item grow-2">Filières / Series</div>
        <div class="choice-header-item grow-2">Etablissements</div>
        <div class="choice-header-item grow-1">Préférences</div>
        <div class="choice-header-item grow-1 right">&nbsp;</div>
      </div>
      <div class="choice-box" *ngFor="let choice of tempChoices" cdkDrag>
        <div class="choice-item grow-2">
          {{ choice?.chx_studyfield?.sfd_longlabel }}
        </div>
        <div class="choice-item grow-2">
          {{ choice?.chx_school?.sch_longlabel }}
        </div>
        <div class="choice-item grow-1">
          <span class="chip chip-main">{{
            getPreferenceOrderLabel(choice?.chx_preference!)
          }}</span>
        </div>
        <div class="choice-item grow-1 right">
          <button
            (click)="removeChoice(choice)"
            class="p-button p-button-sm p-button-danger p-button-text"
          >
            retirer
          </button>
        </div>
      </div>

      <div
        class="empty-table-message-block"
        *ngIf="tempChoices && tempChoices?.length === 0 && !isLoading"
      >
        <p>Aucun choix</p>
      </div>

      <div class="p-field p-field-actions loader" style="margin-top: 5rem">
        <div></div>
        <div>
          <button
            pButton
            pRipple
            type="button"
            label="Précédent"
            icon="pi pi-arrow-left"
            [disabled]="loaded || isSaving"
            class="p-button-secondary p-button-text"
            (click)="goPrev()"
          ></button>

          <button
            pButton
            type="button"
            (click)="showDialog()"
            [disabled]="!isChoicesMinOk()"
          >
            <span *ngIf="isSaving"
              ><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i
            ></span>
            <span *ngIf="!isSaving">Enregistrer</span>
          </button>
        </div>
      </div>
    </div>

    <!-- confirm dialog -->
    <p-dialog
      header="Confirmation"
      [(visible)]="displayDialog"
      [style]="{ width: '500px' }"
      [resizable]="false"
      appendTo="body"
      [modal]="true"
    >
      <p style="display: flex; align-items: center">
        <i
          style="font-size: 2rem; color: red; margin-right: 1rem"
          class="pi pi-question-circle"
        ></i>
        Êtes-vous sûr des informations renseignées ?
      </p>

      <ng-template pTemplate="footer">
        <p-button
          (click)="displayDialog = false"
          icon="pi pi-times"
          label="Annuler"
          styleClass="p-button-secondary p-button-text"
        ></p-button>
        <p-button
          (click)="save()"
          label="Confirmer"
          styleClass="p-button"
        ></p-button>
      </ng-template>
    </p-dialog>
  </div>
</ng-container>

<!-- END CHOICES -->
