import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { IStudentPopulation } from '../shared/model/studentpopulation.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StudentpopulationService {
  private studentPopulationResourceURL =
    environment.apiURL + 'api/v1/studentpopulations';

  constructor(private http: HttpClient) {}

  findAll(): Observable<HttpResponse<IStudentPopulation[]>> {
    return this.http.get<IStudentPopulation[]>(
      `${this.studentPopulationResourceURL}/`,
      { observe: 'response' }
    );
  }

  findBySchool(
    school_id: string
  ): Observable<HttpResponse<IStudentPopulation[]>> {
    return this.http.get<IStudentPopulation[]>(
      `${this.studentPopulationResourceURL}/${school_id}/`,
      { observe: 'response' }
    );
  }

  save(
    school_id: string,
    body: IStudentPopulation
  ): Observable<HttpResponse<IStudentPopulation | any>> {
    return this.http.post<IStudentPopulation | any>(
      `${this.studentPopulationResourceURL}/${school_id}/`,
      body,
      { observe: 'response' }
    );
  }
}
