<form [formGroup]="editForm">
  <div class="p-field p-field-column">
    <div class="p-field p-field-column">
      <!-- <label class="p-field-label flex" for="field_nationality"
        >Nationalité
      </label> -->
      <p-dropdown
        id="field_nationality"
        [options]="nationalities"
        [(ngModel)]="selectedNationality"
        optionLabel="nat_label"
        formControlName="nationality"
        [filter]="true"
        filterPlaceholder="Nationalité"
        emptyFilterMessage="Aucune nationalité"
        placeholder="Selectionner votre nationalité"
        styleClass="rounded"
        appendTo="body"
      >
      </p-dropdown>
    </div>
  </div>

  <div class="p-field p-field-actions" style="margin-top: 0">
    <div></div>
    <div>
      <button
        pButton
        pRipple
        type="button"
        label="Annuler"
        icon="pi pi-times"
        class="p-button-secondary p-button-text rounded"
        (click)="closeDialog()"
        [disabled]="isSaving"
      ></button>

      <button
        [disabled]="isSaving || editForm.invalid"
        pButton
        (click)="save()"
        class="rounded"
      >
        <span *ngIf="isSaving"
          ><i style="font-size: 2rem" class="pi pi-spin pi-spinner"></i
        ></span>
        <span *ngIf="!isSaving">Enregistrer</span>
      </button>
    </div>
  </div>
</form>
