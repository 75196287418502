import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { SchoolService } from 'src/app/services/school.service';
import { StudyfieldService } from 'src/app/services/studyfield.service';
import { SessionStorageService } from 'src/app/session-storage.service';
import { ISchool } from 'src/app/shared/model/school.model';
import { IStudyField } from 'src/app/shared/model/studyfield.model';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';
import { Choice, IChoice } from '../../models/choice.model';
import { EEStepsService } from '../../services/steps.service';
import { IApplicant } from '../../models/applicant.model';
import { getPreferenceLabel } from '../../utils/preference.utils';
import { map } from 'rxjs';
import { ISelectedChoiceData } from '../ee-choice-form/ee-choice-form.component';
import {
  MAX_CHOICE_NUMBER,
  MIN_CHOICE_NUMBER,
} from '../../constant/choice.constant';
import { IYear, Year } from 'src/app/shared/model/year.model';
import { YearService } from 'src/app/services/year.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { EexamsService } from '../../eexams.service';

@Component({
  selector: 'app-ee-choice',
  templateUrl: './ee-choice.component.html',
  styleUrls: ['./ee-choice.component.scss']
})
export class EeChoiceComponent implements OnInit {

  loaded = false;
  isLoading = false;
  isSaving = false;
  displayDialog = false;

  choices: IChoice[] = [];
  tempChoices: IChoice[] = [];

  editForm = this.fb.group({
    chx_studyfield1: [[], [Validators.required]],
    chx_school1: [[], [Validators.required]],
  });

  studyfields: IStudyField[] = [];

  seletedPreferences: number[] = [];
  selectedStudyfields: string[] = [];
  selectedSchools: string[] = [];

  selectedChoices?: ISelectedChoiceData;
  applicant?: IApplicant;
  year?: IYear;

  selectedChoice?: Choice;
  minChoiceNumber = MIN_CHOICE_NUMBER;
  maxChoiceNumber = MAX_CHOICE_NUMBER;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dobStepService: EEStepsService,
    private sessionStorage: SessionStorageService,
    private eexamService: EexamsService,
    private messageService: MessageService,
    private yearService: YearService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.sessionStorage.setItem('DOB_APPLICANT_CHOICES', JSON.stringify([]));
    this.getActiveApplicant(this.sessionStorage);
    this.getActiveYear();
  }

  showDialog(): void {
    this.displayDialog = true;
  }

  goPrev(): void {
    this.router.navigate(['concours', 'applicant']);
    this.dobStepService.setPrevStep(this.sessionStorage);
  }

  getActiveYear(): void {
    this.yearService.geActiveYear().subscribe((year) => (this.year = year));
  }

  goToNext(): void {
    this.router.navigate(['concours', 'summary']);
    this.dobStepService.setNextStep(this.sessionStorage);
  }

  addChoice(event: IChoice): void {
    this.tempChoices = [...this.tempChoices, event];
    this.setChoicesToSS(this.sessionStorage);
  }

  removeChoice(choice: Choice): void {
    const temp = this.tempChoices?.filter(
      (item) => item.chx_preference !== choice.chx_preference
    );

    const newTempChoices: Choice[] = [];

    for (let index = 0; index < temp.length; index++) {
      const element = temp[index];
      element.chx_preference = index + 1;
      newTempChoices.push(element);
    }

    this.tempChoices = [...newTempChoices];
    this.setChoicesToSS(this.sessionStorage);
    this.loadPage();
  }

  saveChoices(): void {
    this.isSaving = true;
    this.displayDialog = false;

    const body = {
      choices: this.tempChoices,
      applicant_id: this.applicant?.app_id!,
      session_id: this.year?.yrs_id!,
    };

    this.eexamService
      .addChoices(body)
      .subscribe((res) => this.showSuccessResponse());
  }

  showSuccessResponse(): void {
    this.messageService.add({
      key: 'DOB_CHOICE_ALERT',
      severity: 'success',
      detail: "Vos choix de concours ont été enregistrés avec succès",
    });
    setTimeout(() => {
      this.isSaving = false;
      this.goToNext();
    }, 0);
  }

  setChoicesToSS(storage: Storage): void {
    storage.setItem('DOB_APPLICANT_CHOICES', JSON.stringify(this.tempChoices));
  }

  getActiveApplicant(storage: Storage): void {
    const ref = storage.getItem('DOB_APPLICANT_REF');
    this.eexamService.geApplicantByRef(ref!).subscribe((res) => {
      this.applicant = res.body.data;
      if (this.applicant) this.getApplicantChoices(this.applicant?.app_id!);
    });
  }

  getApplicantChoices(applicant_id: string): void {
    this.isLoading = true;
    this.eexamService.getChoiceByApplicant(applicant_id).subscribe((res) => {
      const foundChoices = res.body || [];
      this.choices = [...foundChoices];
      this.tempChoices = [...foundChoices];
      this.isLoading = false;
      if (
        foundChoices.length !== 0 &&
        JSON.parse(this.sessionStorage.getItem('DOB_APPLICANT_CHOICES')!) &&
        JSON.parse(this.sessionStorage.getItem('DOB_APPLICANT_CHOICES')!)
          .length === 0
      ) {
        this.choices = foundChoices;
        this.tempChoices = this.choices;

        this.sessionStorage.setItem(
          'DOB_APPLICANT_CHOICES',
          JSON.stringify(this.tempChoices)
        );
      } else {
        this.loadChoicesFromSS();
      }
    });
  }

  loadChoicesFromSS(): void {
    this.eexamService.getChoices().subscribe((choices) => {
      this.choices = choices;
      this.tempChoices = choices;

      if (
        this.tempChoices == null ||
        this.tempChoices.length === 0 ||
        (this.tempChoices &&
          this.tempChoices[0]?.chx_applicant?.app_matricule !==
            this.sessionStorage.getItem('DOB_APPLICANT_REF'))
      ) {
        this.sessionStorage.setItem(
          'DOB_APPLICANT_CHOICES',
          JSON.stringify([])
        );
      }

      this.seletedPreferences = this.choices?.map(
        (choice) => choice.chx_preference!
      );

      this.selectedSchools = this.choices?.map(
        (choice) => choice.chx_school?.sch_id!
      );

      this.selectedStudyfields = this.choices?.map(
        (choice) => choice.chx_studyfield?.sfd_id!
      );

      this.selectedChoices = {
        preference: this.seletedPreferences,
        school: this.selectedSchools,
        studyfield: this.selectedStudyfields,
      };
    });
  }

  getPreferenceOrderLabel(preference: number): string {
    return getPreferenceLabel(preference);
  }

  isChoicesMinOk(): boolean {
    return this.tempChoices?.length >= MIN_CHOICE_NUMBER;
  }

  reorderIndex(choices: Choice[]): void {
    for (let index = 0; index < choices.length; index++) {
      const element = choices[index];
      const preference = index + 1;
      element.chx_preference = preference;
    }

    this.setChoicesToSS(this.sessionStorage);
    // this.loadPage();
  }

  reorderChoice(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tempChoices, event.previousIndex, event.currentIndex);
    this.reorderIndex(this.tempChoices);
  }
}
