import { Component, OnInit } from '@angular/core';
import { BankService } from 'src/app/services/bank.service';
import { IStudent } from 'src/app/shared/model/student.model';
import { HttpResponse } from '@angular/common/http';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/account/auth.service';
import { IUser } from 'src/app/account/model/user.model';
import { ExamregistrationService } from 'src/app/services/examregistration.service';
import { IDiploma } from 'src/app/shared/model/diploma.model';
import { IExamRegistration } from 'src/app/shared/model/examregistration.model';
import { environment } from 'src/environments/environment';
import { RegistrationService } from '../../services/registration.service';
import * as R from 'ramda';
import { FreeCandidateService } from 'src/app/services/free-candidate.service';
import { IFreeCandidate } from 'src/app/shared/model/free-candidate';
import { DiplomaService } from 'src/app/services/diploma.service';
import { Router } from '@angular/router';

interface IPaymentStatus {
  name: string;
  code: number;
}

@Component({
  selector: 'app-dashboard-bank',
  templateUrl: './dashboard-bank.component.html',
  styleUrls: ['./dashboard-bank.component.scss'],
})
export class DashboardBankComponent implements OnInit {
  displayDialog = false;
  isLoading: boolean = false;
  filteredRegionalSchoolsData: any[] = [];
  modalRegisterBank: boolean = false;
  step: any = 1;
  student!: IStudent;
  studentMatricule = '';
  detailBankStudent: any;

  items: MenuItem[] = [];
  home: MenuItem = {};

  selectedStudent: string[] = [];

  searchText = '';
  displayDetail = false;
  selectedExamRegistration?: any;
  examregistrations: IFreeCandidate[] = [];

  filteredExamRegistrations: IFreeCandidate[] = [];
  examRegistrationFilteredByNameOrCode: IFreeCandidate[] = [];
  examRegistrationFilteredByDiplomas: IFreeCandidate[] = [];
  examRegistrationFilteredByStatus: IFreeCandidate[] = [];

  diplomas: IDiploma[] = [];
  selectedDiploma: any[] = [];

  paymentStatusList: IPaymentStatus[] = [];
  selectedPaymentStatus: number[] = [];

  authUser?: IUser;
  isLoader!: boolean;
  urlDocument: any;
  base64String: any;
  pdfReceived!: string;
  isStudent!: any;

  constructor(
    private examRegistrationService: ExamregistrationService,
    private freeCandidateService: FreeCandidateService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private registrationService: RegistrationService,
    private authService: AuthService,
    private diplomaService: DiplomaService,
    private bankService: BankService,
    private router: Router
  ) {
    this.isExamregistratiionRowSelectable =
      this.isExamregistratiionRowSelectable.bind(this);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadPage();
    this.onGetBankCandidateByBank();
  }

  openModalRegisterBank() {
    this.displayDetail = false;
    this.modalRegisterBank = !this.modalRegisterBank;
  }

  closeModalRegisterBank() {
    this.displayDetail = false;
    this.modalRegisterBank = !this.modalRegisterBank;
    localStorage.removeItem('selectedBankCandidate');
    localStorage.removeItem('InfoBankStudent');
    localStorage.removeItem('bankStudent');
  }

  // stepper register
  nextStep(event: any) {
    this.student = event;
    this.studentMatricule = event;
    this.step = this.step + 1;
  }

  prevStep() {
    this.step = this.step - 1;
  }

  // -----------------------------------------------------------
  // -----------------------------------------------------------

  loadPage(): void {
    this.loadExamRegistrations();

    this.items = [{ label: 'Listing des candidats' }];

    this.authService.getAuthUser().subscribe((user) => {
      this.authUser = user;
      console.log(this.authUser);

      // this.loadDiplomas();
      this.onGetAllDiplomas();
    });

    this.home = {
      label: 'Dashboard',
      icon: 'pi pi-home',
      routerLink: '/dashboard/validators/home',
    };
    this.paymentStatusList = [
      {
        name: 'Initiation en cours',
        code: 1,
      },
      {
        name: 'En attente',
        code: 2,
      },
      {
        name: 'Paiement effectué',
        code: 3,
      },
      {
        name: 'Paiement réfusé',
        code: 4,
      },
    ];
  }

  loadExamRegistrations(): void {
    this.bankService.getBankCandidateByBank().subscribe((res) => {
      this.examregistrations = res.body || [];
      console.log('this.examregistrations', this.examregistrations);

      this.filteredExamRegistrations = [...this.examregistrations];
      this.isLoading = false;
    });
  }

  // loadDiplomas(): void {
  //   const school_id = this.authUser?.usr_school?.sch_id!;
  //   this.registrationService
  //     .findSchoolDiplomas(school_id)
  //   .subscribe((res) => {
  //     this.diplomas = res.body || [];
  //     console.log(this.diplomas);

  //   });
  // }

  // get all diplomas
  onGetAllDiplomas() {
    this.diplomaService.getDiplomas().subscribe((res) => {
      this.diplomas = res;
    });
  }

  showDetail(exam: IFreeCandidate): void {
    this.selectedExamRegistration = exam;
    this.displayDetail = true;
  }

  getPicture(image: string): string {
    return environment.mediaURL + image;
  }

  updateStatus(status: number): Observable<HttpResponse<IFreeCandidate>> {
    return this.freeCandidateService.updateStatus(
      JSON.stringify({
        status: status,
        uuid: this.selectedExamRegistration?.fcr_id!,
      })
    );
  }

  reject(): void {
    this.updateStatus(3).subscribe((data) => this.loadExamRegistrations());
  }

  validate(): void {
    this.updateStatus(2).subscribe((data) => this.loadExamRegistrations());
  }

  confirm() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to perform this action?',
      accept: () => {
        //Actual logic to perform a confirmation
      },
    });
  }

  confirmInitiatePayment(event: any, examRegistration: IExamRegistration) {
    this.confirmationService.confirm({
      target: event.target,
      message: "Confirmez-vous l'initiation du paiement?",
      icon: 'pi pi-exclamation-triangle',
      key: 'PAYMENT_POPUP',
      acceptLabel: 'Confirmer',
      rejectLabel: 'Annuler',
      rejectIcon: 'pi pi-times',
      accept: () => {
        this.handleInitiatePayment(examRegistration);
      },
      reject: () => {
        //reject action
      },
    });
  }

  handleInitiatePayment(exam: IExamRegistration): void {
    this.examRegistrationService.initiatePayment(exam?.exa_id!).subscribe({
      next: (res) => {
        const data = res.body;
        this.messageService.add({
          key: 'PAYEMENT_RESULT',
          severity: 'success',
          detail: ` Paiement initié pour l\'apprenant ${data?.exa_student?.std_lastname?.toUpperCase()} ${data?.exa_student?.std_firstname?.toUpperCase()}`,
        });
      },
      error: (err) => {
        this.messageService.add({
          key: 'PAYEMENT_RESULT',
          severity: 'error',
          detail: `Erreur d\initiation, veuillez réessayè!`,
        });
      },
    });
  }

  examregistrationTrackBy(
    index: number,
    examregistration: IFreeCandidate
  ): string {
    return examregistration?.fcr_id!;
  }

  isPaymentInitiatedPendingOrValidated(exam: IFreeCandidate): boolean {
    return ![0, 4].includes(exam.fcr_paymentstatus!);
    // return [0, 4].includes(exam.fcr_validationstatus!);
  }

  isExamregistratiionRowSelectable(event: any): boolean {
    return !this.isPaymentInitiatedPendingOrValidated(event.data);
  }

  // ################# FILTER ####################
  // Search by name or matricule
  // handleSearchByNameOrStudentCode(): void {
  //   this.searchText = this.searchText.toLowerCase();
  //   if (this.searchText) {
  //     this.examRegistrationFilteredByNameOrCode = this.examregistrations.filter(
  //       (exam) =>
  //         exam.fcr_student?.std_lastname
  //           ?.toLowerCase()
  //           .includes(this.searchText) ||
  //         exam.fcr_student?.std_firstname
  //           ?.toLowerCase()
  //           .includes(this.searchText) ||
  //         exam.fcr_student?.std_menaref
  //           ?.toLowerCase()
  //           .includes(this.searchText) ||
  //         exam.fcr_student?.std_etfpref?.toLowerCase().includes(this.searchText)
  //     );
  //   } else {
  //     this.examRegistrationFilteredByNameOrCode = [];
  //   }
  //   this.filteredExamRegistrations = this.onGetBankCandidateByBank();
  // }

  //fonction á revoir
  handleSearchByNameOrStudentCode(): void {
    this.searchText = this.searchText.toLowerCase();
    if (this.searchText) {
      this.examRegistrationFilteredByNameOrCode = this.examregistrations.filter(
        (exam) =>
          exam.fcr_bankcandidate?.bkc_lastname
            ?.toLowerCase()
            .includes(this.searchText) ||
          exam.fcr_bankcandidate?.bkc_firstname
            ?.toLowerCase()
            .includes(this.searchText) ||
          exam.fcr_diploma?.dip_shortlabel
            ?.toLowerCase()
            .includes(this.searchText) ||
          exam.fcr_bankcandidate?.std_etfpref
            ?.toLowerCase()
            .includes(this.searchText)
      );
    } else {
      this.loadExamRegistrations();
    }

    this.filteredExamRegistrations = this.examRegistrationFilteredByNameOrCode;
  }

  handleSearchByDiploma(): void {
    if (this.selectedDiploma.length !== 0) {
      this.examRegistrationFilteredByDiplomas = this.examregistrations.filter(
        (exam) => this.selectedDiploma.includes(exam.fcr_diploma?.dip_id!)
      );
    } else {
      this.examRegistrationFilteredByDiplomas = [];
    }
    this.filteredExamRegistrations = this.onGetBankCandidateByBank();
  }

  handleSearchByStatus(): void {
    if (this.selectedPaymentStatus.length !== 0) {
      this.examRegistrationFilteredByStatus = this.examregistrations.filter(
        (exam) => this.selectedPaymentStatus.includes(exam.fcr_paymentstatus)
      );
    } else {
      this.examRegistrationFilteredByStatus = [];
    }
    this.filteredExamRegistrations = this.onGetBankCandidateByBank();
  }

  onGetBankCandidateByBank(): IFreeCandidate[] {
    if (
      this.examRegistrationFilteredByDiplomas.length === 0 &&
      this.examRegistrationFilteredByNameOrCode.length === 0 &&
      this.examRegistrationFilteredByStatus.length === 0
    ) {
      return this.examregistrations;
    }
    return R.uniqBy(
      R.propEq('fcr_id'),
      R.concat(
        this.examRegistrationFilteredByDiplomas,
        this.examRegistrationFilteredByNameOrCode
      ).concat(this.examRegistrationFilteredByStatus)
    );
  }

  updateSelectedBankCandidate() {
    this.closeModalRegisterBank();
    console.log(this.selectedExamRegistration);
    localStorage.setItem(
      'selectedBankCandidate',
      JSON.stringify(this.selectedExamRegistration)
    );
  }

  // validation candidat by bank user
  onValidateBankCandidate() {
    const idFrc = this.selectedExamRegistration?.fcr_id;
    console.log('idFrc', idFrc);

    const formData = new FormData();
    formData.append('fcr_validationstatus', '1');

    this.bankService
      .bankCandidateValidateByBankAdmin(idFrc, formData)
      .subscribe(
        (data) => {
          this.handleSuccess();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // get register received free candidate
  onGetRegisterReceived(idFcr: any) {
    const idBankCandidate = idFcr;
    console.log(idBankCandidate);

    this.bankService.getRegisterReceived(idBankCandidate).subscribe(
      (res) => {
        this.urlDocument = res.body.url_fichier;
        this.base64String = res.body;
        // this.pdfReceived = `data:application/pdf;base64,${this.base64String}`;
        this.onClickDownloadPdf();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  downloadPdf(base64String: any, fileName: any) {
    this.pdfReceived = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = this.pdfReceived;
    link.download = `${fileName}_banque.pdf`;
    link.target = '_blank';
    link.click();
  }

  // download received document pdf
  onClickDownloadPdf() {
    let base64Strings = this.base64String;
    this.downloadPdf(base64Strings, 'reçu_preinscription');
  }

  // show dialog
  showDialog() {
    this.displayDialog = true;
  }

  // popup success
  handleSuccess(): void {
    this.isLoader = false;
    this.displayDialog = false;
    this.messageService.add({
      severity: 'success',
      detail: 'Validation effectuée avec succès',
    });
    setTimeout(() => {
      // this.nextStep.emit(this.student);
      // Reload page
      this.router
        .navigateByUrl('/dashboard', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(['/dashboard', 'bank', 'home']);
        });
    }, 1500);
  }
  // -----------------------------------------------------------
  // -----------------------------------------------------------
}
