import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IDiploma } from '../shared/model/diploma.model';
import { IExamUnit } from '../shared/model/examunit.model';
import { IFreeCandidate } from '../shared/model/free-candidate';
import { INationality } from '../shared/model/nationality.model';
import { ISchool } from '../shared/model/school.model';
import { IStudent } from '../shared/model/student.model';
import { IStudentPopulation } from '../shared/model/studentpopulation.model';
import { IStudyField } from '../shared/model/studyfield.model';

@Injectable({
  providedIn: 'root',
})
export class FreeCandidateService {
  private baseUrl = environment.apiURL + 'api/v1';
  // private baseUrl = 'https://silent-goose-83.loca.lt//api/v1';
  private nationalityResourceURL = environment.apiURL + 'api/v1/nationalities';
  private studentResourceURL = environment.apiURL + 'api/v1/students';
  private studentInfoSup = environment.apiURL + 'api/v1';
  private freeCandidateResourceURL =
    environment.apiURL + 'api/v1/free-candidates';
  // private testUrl = 'http://127.0.0.1:8000';

  constructor(private http: HttpClient) {}

  getExaCode(exaCode: string, year: any): Observable<IStudent> {
    return this.http.get<IStudent>(
      `${this.baseUrl}/check-free-candidate/${exaCode}/${year}/`
    );
  }

  getInfoStudent(): Observable<IStudent[]> {
    return this.http.get<IStudent[]>(`${this.baseUrl}/students/`);
  }

  saveInfoFreeCandidate(body: FormData): Observable<IStudent> {
    return this.http.post<any>(`${this.baseUrl}/free-candidate/`, body);
  }

  updateInfoFreeCandidate(ref: string, body: any): Observable<IStudent> {
    return this.http.patch<any>(`${this.baseUrl}/free-candidate/${ref}/`, body);
  }

  // uploadStudentPicture(
  //   ref: string,
  //   file: FormData
  // ): Observable<HttpResponse<any>> {
  //   return this.http.put<any>(
  //     `${this.studentResourceURL}/${ref}/upload-picture/`,
  //     file,
  //     { observe: 'response' }
  //   );
  // }

  // get nationality
  getNationalities(): Observable<HttpResponse<INationality[]>> {
    return this.http.get<INationality[]>(`${this.baseUrl}/nationalities`, {
      observe: 'response',
    });
  }

  // post info steps 3
  postInforSup(body: FormData) {
    return this.http.post<any>(
      `${this.baseUrl}/candidate-registration-list/`,
      body
    );
  }

  // update info steps 3
  updateInforSup(id: string, body: FormData) {
    return this.http.put<any>(
      `${this.baseUrl}/candidate-registration-list/${id}/`,
      body
    );
  }

  // liste des etablissements
  getSchools(): Observable<ISchool[]> {
    return this.http.get<ISchool[]>(`${this.baseUrl}/schools`);
  }

  // liste des diplomes
  findDiplomasBySchool(
    id_school: string
  ): Observable<HttpResponse<IDiploma[]>> {
    return this.http.get<IDiploma[]>(
      `${this.baseUrl}/schools/${id_school}/diplomas`,
      { observe: 'response' }
    );
  }

  findDiplomaStudyFields(
    id_school: string,
    id_diploma?: string
  ): Observable<HttpResponse<IStudyField[]>> {
    return this.http.get<IStudyField[]>(
      `${this.baseUrl}/schools/${id_school}/${id_diploma}/studyfield/`,
      { observe: 'response' }
    );
  }

  // getExamunitByDiploma(diploma_id: string): Observable<HttpResponse<IExamUnit[]>> {
  //   return this.http.get<IExamUnit[]>(
  //     `${this.testUrl}/api/v1/diplomas/${diploma_id}/exam-units/`,
  //     { observe: 'response' }
  //   );
  // }
  getExamunitByDiploma(diploma_id: string): Observable<HttpResponse<any[]>> {
    return this.http.get<any>(
      `${this.baseUrl}/diplomas/${diploma_id}/exam-units/`,
      { observe: 'response' }
    );
  }

  // dashboard: get all free candidate student
  getAllFreeCandidate(): Observable<HttpResponse<IFreeCandidate[]>> {
    return this.http.get<IFreeCandidate[]>(
      `${this.baseUrl}/candidate-registration-list/libre/`,
      { observe: 'response' }
    );
  }

  // status free candidate
  updateStatus(body: any): Observable<HttpResponse<IFreeCandidate>> {
    return this.http.patch<IFreeCandidate>(
      // `${this.testUrl}/api/v1/candidate-registration-update/`,
      `${this.baseUrl}/validation-candidat/`,
      body,
      { observe: 'response' }
    );
  }

  // download register received freecandidate
  getRegisterReceived(candidate_id: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/students/${candidate_id}/download-document/`,
      { observe: 'response' }
    );
  }

  // status free candidate
  updateStatusFreeCanidate(
    idFcr: any,
    body: any
  ): Observable<HttpResponse<any>> {
    return this.http.patch<any>(
      `${this.baseUrl}/validation-candidat/${idFcr}/`,
      body,
      { observe: 'response' }
    );
  }

  // get location
  getLocation(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.baseUrl}/Location_list/`, {
      observe: 'response',
    });
  }

  // get school by studyfield and location
  getSchoolByStudyfieldAndLocation(
    studyFiel: any,
    location: any
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/schools/${studyFiel}/${location}/`,
      { observe: 'response' }
    );
  }

  saveFreeCandidateRegistration(body: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.baseUrl}/register-candidate/`, body, {
      observe: 'response',
    });
  }

  getCandidateExamUnitValid(examCode: string): Observable<string> {
    return this.http.get<string>(
      `${this.baseUrl}/free-candidate-exam-valid/${examCode}/`,
      {
        observe: 'body',
      }
    );
  }

  updateFreeCandidateRegistration(
    examCode: string,
    body: any
  ): Observable<IFreeCandidate> {
    return this.http.patch<IFreeCandidate>(
      `${this.freeCandidateResourceURL}/registrations/${examCode}/`,
      body,
      { observe: 'body' }
    );
  }
}
