import { Moment } from 'moment';
import { IDiploma } from './diploma.model';
import { IPayment } from './payment.model';
import { ISchool } from './school.model';
import { IStudent } from './student.model';
import { IStudyField } from './studyfield.model';
import { IYear } from './year.model';

export interface IExamRegistration {
  exa_id?: string;
  exa_created_at?: Moment;
  exa_updated_at?: Moment;
  exa_student?: IStudent;
  exa_year?: IYear;
  exa_payment?: IPayment;
  exa_examcode?: string;
  exa_diploma?: IDiploma;
  exa_school?: ISchool;
  exa_studyfield?: IStudyField;
  exa_code?: string;
  exa_type_candidate?: number;
  exa_validation_status?: string;
  exa_rejection_message?: string;
  exa_fiche?: string;
  exa_receipt?: string;
  exa_disabled?: boolean;
  exa_paymentstatus?: number;
}

export class ExamRegistration implements IExamRegistration {
  constructor(
    public exa_student?: IStudent,
    public exa_year?: IYear,
    public exa_payment?: IPayment,
    public exa_code?: string,
    public exa_validation_status?: string,
    public exa_rejection_message?: string,
    public exa_disabled?: boolean,
    public exa_fiche?: string
  ) {}
}
