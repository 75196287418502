import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/api';
import { environment } from 'src/environments/environment';
import {
  hasRequiredField,
  MustMatch,
} from '../../shared/utils/validator.utils';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  lastName = '';
  firstName = '';
  isSaving = false;
  siteKey = environment.captchaSiteKey;

  msgs: Message[] = [];

  editForm = this.fb.group(
    {
      last_name: [null, [Validators.required]],
      first_name: [null, [Validators.required]],
      password: [null, [Validators.required]],
      password_confirm: [null, [Validators.required]],
    },
    {
      validator: MustMatch('password', 'password_confirm'),
    }
  );
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {}

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }

  handleUserUpdate(): void {
    const body = this.editForm.value;
    const token = this.route.snapshot.paramMap.get('token')!;
    const uid = this.route.snapshot.paramMap.get('uid')!;
    this.isSaving = true;
    this.authService.updateRegistratedUser(uid, token, body).subscribe({
      next: (res) => {
        this.isSaving = false;
        const data = res.body;
        if (data) {
          this.router.navigate(['/account', 'activated'], {
            state: { email: data.email },
          });
        }
      },

      error: () => {
        this.isSaving = false;
        this.msgs = [
          {
            severity: 'error',
            detail:
              "Erreur, veuillez vérifier que lien d'activation soit valide",
          },
        ];
      },
    });
  }

  showResponse(event: any): void {
    console.log(event);
  }
}
