<div class="steps steps-one steps-wrapper">
  <div class="steps-title">
    <h2>Examen antérieur</h2>
    <small
      >Veuillez renseigner l'année et le numéro de table de votre dernière
      examen à l'ETFP</small
    >

    <p-messages
      [(value)]="msgs"
      KEY="FREE_CANDIDATE_REGISTRATION_SEARCH"
      [closable]="false"
    ></p-messages>
  </div>

  <form
    class="form-steps"
    [formGroup]="searchForm"
    (ngSubmit)="onSearchStudent()"
  >
    <!-- steps one -->
    <div class="p-field p-field-column">
      <label class="p-field-label" for="field_stp_year"
        >Dernière année de composition
        <span class="color-danger">{{
          fieldIsRequired(searchForm.controls["stp_year"])
        }}</span>
      </label>
      <p-calendar
        formControlName="stp_year"
        placeholder="Ex: 2010"
        view="year"
        dateFormat="yy"
        inputId="field_stp_year"
      ></p-calendar>
      <small
        *ngIf="
          searchForm.get('stp_year')?.errors &&
          (searchForm.get('stp_year')?.errors)!['required'] &&
          (searchForm.controls['stp_year'].dirty ||
            searchForm.controls['stp_year'].touched)
        "
        class="p-field-help"
        id="field_stp_year-help"
        >Ce champ est obligatore.</small
      >
    </div>

    <div class="p-field p-field-column">
      <label class="p-field-label" for="matricule"
        >N˚ de table lors de la dernière année de composition (Ex: 1203654ES)
        <span class="color-danger">{{
          fieldIsRequired(searchForm.controls["std_id"])
        }}</span>
      </label>
      <p-inputMask
        formControlName="std_id"
        id="matricule"
        mask="9999999aa"
        placeholder="_________"
        [maxlength]="9"
        (keyup)="hiddenError()"
        [autoClear]="false"
        (keyup)="setValueToUpper(searchForm.controls['std_id'])"
      ></p-inputMask>
      <small
        *ngIf="
          searchForm.get('std_id')?.errors &&
          (searchForm.get('std_id')?.errors)!['required'] &&
          (searchForm.controls['std_id'].dirty ||
            searchForm.controls['std_id'].touched)
        "
        class="p-field-help"
        id="field_std_id-help"
        >Ce champ est obligatore.</small
      >
    </div>

    <div class="text-right">
      <button
        pButton
        pRipple
        type="button"
        label="Annuler"
        icon="pi pi-arrow-left"
        class="p-button-secondary p-button-text"
        [routerLink]="['/']"
      ></button>

      <button [disabled]="searchForm.invalid" type="submit" class="p-button">
        <span *ngIf="isLoader">
          <i style="font-size: 2rem" class="pi pi-spin pi-spinner"></i>
        </span>
        <span *ngIf="!isLoader">Rechercher et Continuer</span>
      </button>
    </div>
  </form>
</div>
