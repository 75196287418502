<div class="account-wrapper">
  <!-- content -->
  <div class="account-content">
    <!-- Logo -->
    <div class="account-content__logo">
      <a href="/" class="link link-main">
        <img src="/assets/logo_ers.png" alt="Logo ERSYS" />
      </a>
    </div>

    <!-- Detail -->
    <div class="account-content__detail">
      <!-- Image -->
      <div class="account-content__detail-image">
        <img src="/assets/account/login-image.svg" alt="" />
      </div>

      <!-- form -->
      <div class="account-content__detail-form">
        <p-messages [(value)]="msgs" [closable]="false"></p-messages>
        <p class="account-content__detail-form-title">Connexion</p>
        <form [formGroup]="editForm">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_email">Email </label>
            <input
              type="email"
              pInputText
              id="field_email"
              formControlName="email"
              placeholder="Votre email"
              (keydown.enter)="handleEnterKeyDownOnEmail()"
            />
            <small
              *ngIf="
                editForm.get('email')?.errors &&
                (editForm.get('email')?.errors)!['required'] &&
                (editForm.controls['email'].dirty ||
                  editForm.controls['email'].touched)
              "
              class="p-field-help"
              id="field_email-help"
              >Ce champ est obligatore.</small
            >
            <small
              *ngIf="
                editForm.get('email')?.errors &&
                (editForm.get('email')?.errors)!['email']
              "
              class="p-field-help"
              id="field_email-help"
              >Email invalid.</small
            >
          </div>

          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_password"
              >Mot de passe
            </label>
            <input
              type="password"
              pInputText
              id="field_password"
              formControlName="password"
              placeholder="Votre mot de passe"
              (keydown.enter)="handleEnterKeyDownOnPassword()"
            />
            <small
              *ngIf="
                editForm.get('password')?.errors &&
                (editForm.get('password')?.errors)!['required'] &&
                (editForm.controls['password'].dirty ||
                  editForm.controls['password'].touched)
              "
              class="p-field-help"
              id="field_password-help"
              >Ce champ est obligatore.</small
            >
          </div>

          <!-- <div class="account-content__detail-form-recaptcha">
            <re-captcha
              #captchaElem
              required
              class="invalid"
              (resolved)="resolved($event)"
              [siteKey]="CAPTCHA_SITE_KEY"
              (error)="captchaErrored($event)"
              errorMode="handled"
            >
            </re-captcha>
          </div> -->
          <!--
          <div class="account-content__detail-form-footer">
            <a class="link link-main">Mot de passe oublié ?</a>
          </div> -->

          <div class="p-field p-field-actions full">
            <button
              [disabled]="
                (editForm.invalid &&
                  editForm.controls['email'].value === null) ||
                editForm.controls['email'].value === '' ||
                (editForm.invalid &&
                  editForm.controls['password'].value === null) ||
                editForm.controls['password'].value === '' ||
                editForm.invalid ||
                isSaving
              "
              pButton
              type="button"
              (click)="handleLogin()"
            >
              <i
                *ngIf="isSaving"
                style="font-size: 1.8rem"
                class="pi pi-spin pi-spinner"
              ></i>
              <span *ngIf="!isSaving">Se connecter</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
