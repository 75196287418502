import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InscriptionService } from 'src/app/inscription/inscription.service';
import { genders } from 'src/app/shared/constant/gender.contanst';
import { INationality } from 'src/app/shared/model/nationality.model';
import { IApplicant } from 'src/app/dob/models/applicant.model';
import { IOATValidation } from 'src/app/dob/models/validation.model';
import { getGenderLabel } from 'src/app/shared/utils/gender.utils';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard-regional-orientation-validation-applicant-form',
  templateUrl:
    './dashboard-regional-orientation-validation-applicant-form.component.html',
  styleUrls: [
    './dashboard-regional-orientation-validation-applicant-form.component.scss',
  ],
})
export class DashboardRegionalOrientationValidationApplicantFormComponent
  implements OnInit
{
  @Input() applicant?: IApplicant;
  @Input() validation?: IOATValidation;

  @Output()
  editNationality: EventEmitter<IApplicant> = new EventEmitter();

  @Output()
  onEditBirthDate: EventEmitter<IApplicant> = new EventEmitter();

  nationalities: INationality[] = [];
  selectedNationality?: INationality;

  genders: any[] = [];
  selectedGender = '';

  studentLastName = '';
  studentFirstName = '';
  studentBirthPlace = '';
  studentBirthDate = '';

  editForm = this.fb.group({
    app_lastname: [null, [Validators.required]],
    app_firstname: [null, [Validators.required]],
    app_birthdate: [null, [Validators.required]],
    app_birthplace: [null, [Validators.required]],
    app_gender: [null, [Validators.required]],
    app_nationality: [null, [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private inscriptionService: InscriptionService
  ) {}

  ngOnInit(): void {
    moment.locale('fr');
    this.genders = genders;
    this.inscriptionService.getNationalities().subscribe((res) => {
      this.nationalities = res.body || [];
    });

    if (this.applicant) {
      this.selectedNationality = this.applicant?.app_nationality!;
      this.selectedGender = getGenderLabel(this.applicant?.app_gender!);
      this.studentFirstName = this.applicant?.app_firstname!;
      this.studentLastName = this.applicant?.app_lastname!;
      this.studentBirthPlace = this.applicant?.app_birthplace!;
      this.studentBirthDate = moment(this.applicant?.app_birthdate).format(
        'DD MMMM YYYY'
      );

      const dateFormted = new Date(this.applicant?.app_birthdate!.toString());

      this.editForm.patchValue({
        app_lastname: this.applicant?.app_lastname,
        app_firstname: this.applicant?.app_firstname,
        app_birthdate: dateFormted,
        app_birthplace: this.applicant?.app_birthplace!,
        app_gender: this.applicant?.app_gender,
        app_nationality: this.applicant?.app_nationality,
      });
    }
  }

  handleEditNationality(): void {
    this.editNationality.emit(this.applicant);
  }

  handleEditBirthDate(): void {
    this.onEditBirthDate.emit(this.applicant);
  }
}
