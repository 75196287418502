import { Component, OnInit } from '@angular/core';
import { ExamregistrationService } from 'src/app/services/examregistration.service';
import { IExamRegistration } from 'src/app/shared/model/examregistration.model';
import {
  retreive_type_candidature_label,
  retreive_validation_status_label,
} from 'src/app/shared/utils/student-infos.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-examen-registred-detail',
  templateUrl: './examen-registred-detail.component.html',
  styleUrls: ['./examen-registred-detail.component.scss'],
})
export class ExamenRegistredDetailComponent implements OnInit {
  examregistration?: IExamRegistration;
  imageURL = '';
  typeCandidature = '';
  validationStatus = '';

  constructor(private examregistrationService: ExamregistrationService) {}

  ngOnInit(): void {
    const ref = JSON.parse(sessionStorage.getItem('exam')!)?.matricule;
    const year = JSON.parse(sessionStorage.getItem('activeYear')!);

    this.examregistrationService.get(ref, year).subscribe((exam) => {
      this.examregistration = exam;

      this.imageURL =
        environment.mediaURL + this.examregistration.exa_student?.std_picture;
      this.typeCandidature = retreive_type_candidature_label(
        this.examregistration?.exa_type_candidate!
      );
      this.validationStatus = retreive_validation_status_label(
        Number(this.examregistration?.exa_validation_status!)
      );
    });
  }
}
