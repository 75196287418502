import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExamenEnregistrementComponent } from './examen-enregistrement/examen-enregistrement.component';
import { ExamenValidationComponent } from './examen-validation/examen-validation.component';
import { ExamenComponent } from './examen/examen.component';
import { ExamenRechercheComponent } from './examen-recherche/examen-recherche.component';
import { ExamenInfoComponent } from './examen-info/examen-info.component';
import { ExamenRegistredDetailComponent } from './examen-registred-detail/examen-registred-detail.component';
import { ExamenFreeCandidateComponent } from './examen-free-candidate/examen-free-candidate.component';
import { ExamenFreeFinishComponent } from './examen-free-finish/examen-free-finish.component';

const routes: Routes = [
  {
    path: 'inscription/examen',
    component: ExamenComponent,
    children: [
      {
        path: 'recherche',
        component: ExamenRechercheComponent,
      },
      {
        path: 'info-candidat',
        component: ExamenEnregistrementComponent,
      },
      {
        path: 'info-examen',
        component: ExamenInfoComponent,
      },
      {
        path: 'validation',
        component: ExamenValidationComponent,
      },
      {
        path: 'registred-detail',
        component: ExamenRegistredDetailComponent,
      },
      {
        path: 'registred-free-candidate',
        component: ExamenFreeCandidateComponent,
      },
      {
        path: 'registred-free-finish',
        component: ExamenFreeFinishComponent,
      },
    ],
  },
];


@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InscriptionRoutingModule {}
