import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';
import { FreeCandidateService } from 'src/app/services/free-candidate.service';
import { SchoolService } from 'src/app/services/school.service';
import { Diploma, IDiploma } from 'src/app/shared/model/diploma.model';
import { IExamUnit } from 'src/app/shared/model/examunit.model';
import { ISchool, School } from 'src/app/shared/model/school.model';
import { IStudent, Student } from 'src/app/shared/model/student.model';
import { IStudyField } from 'src/app/shared/model/studyfield.model';
import { MessageService } from 'primeng/api';
import { YearService } from 'src/app/services/year.service';
import { IYear } from 'src/app/shared/model/year.model';
import { SessionStorageService } from 'src/app/session-storage.service';
import { FREE_CANDIDATE_INFO_KEY } from 'src/app/shared/constant/step.constant';
import { IFreeCandidate } from 'src/app/shared/model/free-candidate';
import { LocationService } from '../../services/location.service';
import { ILocation } from 'src/app/shared/model/location.model';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';

@Component({
  selector: 'app-examen-free-filiere',
  templateUrl: './examen-free-filiere.component.html',
  styleUrls: ['./examen-free-filiere.component.scss'],
})
export class ExamenFreeFiliereComponent implements OnInit {
  isLoader: boolean = false;
  displayDialog = false;
  formSubmited: boolean = false;
  formDone: boolean = false;
  // student! : Student;

  @Input() student!: IStudent | {};

  student2!: any;

  @Output('nextStep') nextStep: EventEmitter<any> = new EventEmitter();
  @Output('prevStep') prevStep: EventEmitter<Student> = new EventEmitter();
  schools!: ISchool[];
  selectedSchool?: ISchool;
  diploma!: IDiploma[];

  studyField!: IStudyField[];
  examUnit!: any;
  session: any;
  year!: IYear;
  successMessage!: string;
  localities: any;
  selectedLocality: any;
  castExamUnitValide: any;
  examUnitValid: any;
  valueOption!: string;
  examToTake!: any;
  fcrDiploma!: any;
  codeStydifield: any;
  candidateStudyField?: IStudyField;

  selectedLocation?: ILocation;

  studentFiliereForm = this.fb.group({
    fcr_session: ['', [Validators.required]],
    fcr_diploma: ['', [Validators.required]],
    fcr_studyfield: ['', [Validators.required]],
    fcr_school: ['', [Validators.required]],
    fcr_locality: ['', [Validators.required]],
    fcr_examtotake: ['', [Validators.required]],
    fcr_examunit_valid: ['', [Validators.required]],
  });

  constructor(
    private freeCandidateService: FreeCandidateService,
    private schoolService: SchoolService,
    private messageService: MessageService,
    private yearService: YearService,
    private fb: FormBuilder,
    private storage: SessionStorageService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.loadLocations();
    this.loadPage();
  }

  loadPage(): void {
    const studentInfo = JSON.parse(
      this.storage.getItem(FREE_CANDIDATE_INFO_KEY)!
    );
    this.loadFormData(studentInfo.student as IFreeCandidate);
    this.getCandidateExamValid(studentInfo.exaCode);
  }

  loadLocations(): void {
    this.locationService
      .getLocations()
      .subscribe((res) => (this.localities = res.body));
  }

  loadFormData(candidate: IFreeCandidate): void {
    this.candidateStudyField = candidate.fcr_studyfield;
    this.selectedSchool = candidate.fcr_school;
    this.selectedLocality = candidate?.fcr_school?.sch_location;
    this.onLocalityChange(candidate?.fcr_school?.sch_location!);

    this.studentFiliereForm.patchValue({
      fcr_session: candidate.fcr_session?.yrs_academic,
      fcr_diploma: candidate.fcr_diploma?.dip_shortlabel,
      fcr_studyfield: candidate.fcr_studyfield?.sfd_longlabel,
      fcr_examtotake: this.replaceInString(
        candidate.fcr_examtotake,
        ',',
        ' - '
      ),
      fcr_school: candidate?.fcr_school?.sch_id,
      fcr_locality: candidate?.fcr_school?.sch_location,
    });
  }

  replaceInString(str: string, search: string, replacement: string): string {
    if (str === null || str.toUpperCase() === 'NULL') return '-';
    if (str.includes(search)) return str.split(search).join(replacement);
    return str;
  }

  getCandidateExamValid(examcode: string): void {
    this.freeCandidateService
      .getCandidateExamUnitValid(examcode)
      .subscribe((res) => {
        this.examUnitValid = res;
        this.studentFiliereForm.patchValue({
          fcr_examunit_valid: this.replaceInString(
            this.examUnitValid,
            ',',
            ' - '
          ),
        });
      });
  }

  castExushortlabellListTostring(uniteEnseignementList: any) {
    const exu_shortlabellList: string[] = uniteEnseignementList.map(
      (uniteEnseignement: any) => uniteEnseignement.exu_shortlabel
    );
    const exu_shortlabellListToString: string = exu_shortlabellList.join();
    return exu_shortlabellListToString;
  }

  onHandleFreeCandidateExam(): void {
    const body = {
      frc_school: this.studentFiliereForm.value.fcr_school.sch_id,
    };
    const examCode = JSON.parse(
      this.storage.getItem(FREE_CANDIDATE_INFO_KEY)!
    ).exaCode;

    this.freeCandidateService
      .updateFreeCandidateRegistration(examCode, body)
      .subscribe({
        next: (res) => {
          const message = 'Informations enregistrées avec succèss';
          const info = JSON.parse(
            this.storage.getItem(FREE_CANDIDATE_INFO_KEY)!
          );

          info.student = res;
          this.storage.setItem(FREE_CANDIDATE_INFO_KEY, JSON.stringify(info));

          this.handleSuccess(message);
        },
        error: (_) => {
          /* TODO document why this method 'error' is empty */
        },
      });
  }

  onHandleCandidateExam() {
    this.isLoader = true;
    this.studentFiliereForm.get('fcr_session')?.setValue(this.year.yrs_id);

    this.freeCandidateService
      .postInforSup(this.studentFiliereForm.getRawValue())
      .subscribe(
        (res) => {
          this.isLoader = false;
          this.student2 = res;

          this.formSubmited = true;
          this.formDone = true;
          this.successMessage = 'Informations enregistrées avec succèss';
          this.handleSuccess();
          // this.nextStep.emit(this.student2);
        },
        (err) => {
          this.isLoader = false;
          console.log(err);
          // this.nextStep.emit();
          const id = this.studentFiliereForm.get('candidate')?.value;

          if (err.status === 500) {
            this.freeCandidateService
              .updateInforSup(id, this.studentFiliereForm.getRawValue())
              .subscribe(
                (res) => {
                  this.isLoader = false;
                  this.formSubmited = true;
                  this.formDone = true;
                  this.student2 = res;
                  // this.nextStep.emit(this.student2);
                  (this.successMessage = 'Informations modifiées avec succèss'),
                    this.handleSuccess();
                },
                (err) => {
                  this.isLoader = false;
                  console.log(err);
                }
              );
          }
        }
      );
  }

  onLocalityChange(location: ILocation): void {
    this.schoolService
      .getSchoolsByLocation(
        this.candidateStudyField?.sfd_code!,
        location?.loc_label!
      )
      .subscribe((res) => {
        this.schools = res;
      });
  }

  onPrevStep() {
    this.prevStep.emit();
    let studentIn: any = this.student;
    if (studentIn !== undefined) {
    }
  }

  getYear(): void {
    this.yearService.geActiveYear().subscribe((year) => {
      this.year = year;
      this.session = this.year?.yrs_academic!;
    });
  }

  handleSuccess(message?: string): void {
    this.displayDialog = false;
    this.messageService.add({
      severity: 'success',
      detail: message ? message : this.successMessage,
    });
    this.isLoader = false;
    this.nextStep.emit(this.student2);
  }

  showDialog() {
    this.displayDialog = true;
  }

  omGetLocation(): void {
    this.freeCandidateService.getLocation().subscribe(
      (res: any) => {
        this.localities = res.body;
      },
      (_) => {}
    );
  }

  onGetSchoolByStudyfieldAndLocation(event: any): void {
    const study = this.codeStydifield;
    console.log('study', study);

    const local = event.target.value;
    console.log('local', local);

    this.freeCandidateService
      .getSchoolByStudyfieldAndLocation(study, local)
      .subscribe(
        (res) => {
          console.log('Etablissement', res.body);
          this.schools = res.body;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }
}
