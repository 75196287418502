import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/account/auth.service';
import { IUser } from 'src/app/account/model/user.model';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss'],
})
export class DashboardAdminComponent implements OnInit {
  authUser?: IUser;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  // loadPage(): void {
  //   this.authService
  //     .getAuthSchool()
  //     .subscribe((user) => (this.authUser = user));
  // }
}
