import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  private settingResourceURL = environment.apiURL + 'api/v1/settings';

  constructor(private http: HttpClient) {}

  getApplicantCode(applicant: string): Observable<any> {
    return this.http.get<any>(`${this.settingResourceURL}/${applicant}/code/`, {
      observe: 'body',
    });
  }
}
