import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionStorageService } from '../session-storage.service';
import { BREADCRUMB_ITEMS_KEY } from '../shared/constant/step.constant';
import { IExamenBreadcrumb } from '../shared/model/examen-breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class StepService {
  private steps$ = new BehaviorSubject<IExamenBreadcrumb[]>([]);

  constructor() {}

  private init(storage: Storage): void {
    this.steps$.next(JSON.parse(storage.getItem(BREADCRUMB_ITEMS_KEY)!)?.data);
  }

  public getSteps(storage: Storage): Observable<IExamenBreadcrumb[]> {
    this.init(storage);
    return this.steps$;
  }
}
