<p-toast key="DOB_CHOICE_ALERT"></p-toast>
<div class="dob-main-content-wrapper full-100">
  <div class="dob-main-content-header">
    <p class="dob-main-content-title">Choix d'orientation</p>
    <p class="dob-main-content-sub-title">
      Vous devez faire au minimum
      <span class="chip chip-main">{{ minChoiceNumber }}</span> choix et au
      maximum <span class="chip chip-main">{{ maxChoiceNumber }}</span> choix
    </p>
    <p>
      Faite glisser chaque ligne de choix pour modifier l'ordre de préférence
    </p>
  </div>

  <app-dob-choice-form
    (choice)="addChoice($event)"
    [selectedChoicesData]="selectedChoices"
  ></app-dob-choice-form>

  <div
    cdkDropList
    class="choice-list"
    (cdkDropListDropped)="reorderChoice($event)"
  >
    <div class="choice-header">
      <div class="choice-header-item grow-2">Filières / Series</div>
      <div class="choice-header-item grow-2">Etablissements</div>
      <div class="choice-header-item grow-1">Préférences</div>
      <div class="choice-header-item grow-1 right">&nbsp;</div>
    </div>
    <div class="choice-box" *ngFor="let choice of tempChoices" cdkDrag>
      <div class="choice-item grow-2">
        {{ choice?.chx_studyfield?.sfd_longlabel }}
      </div>
      <div class="choice-item grow-2">
        {{ choice?.chx_school?.sch_longlabel }}
      </div>
      <div class="choice-item grow-1">
        <span class="chip chip-main">{{
          getPreferenceOrderLabel(choice?.chx_preference!)
        }}</span>
      </div>
      <div class="choice-item grow-1 right">
        <button
          (click)="removeChoice(choice)"
          class="p-button p-button-sm p-button-danger p-button-text"
        >
          retirer
        </button>
      </div>
    </div>

    <div
      class="empty-table-message-block"
      *ngIf="tempChoices && tempChoices?.length === 0 && !isLoading"
    >
      <p>Aucun choix</p>
    </div>
    <!-- <div
    *ngIf="isLoading"
    class="loader"
    style="display: flex; justify-content: center"
  >
    <img src="/assets/loader.gif" />
  </div> -->

    <div class="p-field p-field-actions loader" style="margin-top: 5rem">
      <!-- <div class="search-loader">
          <img src="/assets/loader.gif" alt="Loader" *ngIf="loaded" />
        </div> -->
      <div></div>
      <div>
        <button
          pButton
          pRipple
          type="button"
          label="Précédent"
          icon="pi pi-arrow-left"
          [disabled]="loaded"
          class="p-button-secondary p-button-text"
          (click)="goPrev()"
        ></button>

        <!-- <p-confirmPopup appendTo="body"></p-confirmPopup> -->

        <!-- <button
        pButton
        type="button"
        label="Suivant"
        (click)="showDialog()"
        [disabled]="!isChoicesMinOk()"
      ></button> -->

        <button
          pButton
          type="button"
          (click)="showDialog()"
          [disabled]="!isChoicesMinOk()"
        >
          <span *ngIf="isSaving"
            ><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i
          ></span>
          <span *ngIf="!isSaving">Terminer et Imprimer</span>
        </button>
      </div>
    </div>
  </div>

  <!-- confirm dialog -->
  <p-dialog
    header="Confirmation"
    [(visible)]="displayDialog"
    [style]="{ width: '500px' }"
    [resizable]="false"
    appendTo="body"
    [modal]="true"
  >
    <p style="display: flex; align-items: center">
      <i
        style="font-size: 2rem; color: red; margin-right: 1rem"
        class="pi pi-question-circle"
      ></i>
      Êtes-vous sûr des informations renseignées ?
    </p>

    <ng-template pTemplate="footer">
      <p-button
        (click)="displayDialog = false"
        icon="pi pi-times"
        label="Annuler"
        styleClass="p-button-secondary p-button-text"
      ></p-button>
      <p-button
        (click)="saveChoices()"
        label="Confirmer"
        styleClass="p-button"
      ></p-button>
    </ng-template>
  </p-dialog>
</div>
