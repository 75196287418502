import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { YearService } from 'src/app/services/year.service';
import { SessionStorageService } from 'src/app/session-storage.service';
import { IYear } from 'src/app/shared/model/year.model';
import { EexamsService } from '../../eexams.service';
import { IApplicant } from '../../models/applicant.model';

@Component({
  selector: 'app-ee-summary',
  templateUrl: './ee-summary.component.html',
  styleUrls: ['./ee-summary.component.scss']
})
export class EeSummaryComponent implements OnInit {

  isLoading = false;
  applicant?: IApplicant;
  document: any;
  isDocLoading = false;
  displayDialog = false;
  activeYear?: IYear;
  base64File: any;

  constructor(
    private router: Router,
    private eexamService: EexamsService,
    private storage: SessionStorageService,
    private sanitizer: DomSanitizer,
    private yearService: YearService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.getActiveApplicant(this.storage);
    this.yearService
      .geActiveYear()
      .subscribe((year) => (this.activeYear = year));
  }

  docDefinition(): any {
    return {
      content: ['First paragraph', 'Another paragraph'],
    };
  }

  getActiveApplicant(storage: Storage): void {
    this.isDocLoading = true;
    const ref = storage.getItem('DOB_APPLICANT_REF');
    this.eexamService.geApplicantByRef(ref!).subscribe((res) => {
      this.applicant = res.body.data;
      this.generateApplicantDocument();
    });
  }

  generateApplicantDocument(): void {
    const body = {
      applicant: this.applicant?.app_id,
    };
    this.eexamService.getDocument(body).subscribe((res) => {
      this.isDocLoading = false;
      const doc = res.body;
      this.base64File = doc;
      this.loadDocument(doc);
    });
  }

  loadDocument(base64File: any): void {
    this.document = this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:application/pdf;base64,${base64File}`
    );
  }

  finish(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      this.router.navigate(['/concours', 'finish']);
    }, 1000);
  }

  showDialog() {
    this.displayDialog = true;
  }

  downloadDocument(): void {
    this.downloadPdf(this.base64File, 'recu_paiement');
  }

  // download received document pdf
  downloadPdf(base64String: any, fileName: any) {
    const doc = `data:application/pdf;base64, ${base64String}`;
    const link = document.createElement('a');
    link.href = doc;
    link.download = `${fileName.toUpperCase()}_${this.applicant?.app_lastname?.toUpperCase()}_${this.applicant?.app_firstname?.toUpperCase()}_SESSION_${
      this.activeYear?.yrs_session
    }.pdf`;
    link.target = '_blank';
    link.click();
  }

}
