export const config = {
  apiURL: '',
  authRoles: {
    SA: ['ROLE_ADMIN'],
    ADMIN: ['ROLE_ADMIN', 'ROLE_USER'],
    SUPERVISOR: ['ROLE_SUPERVISOR_ADMIN', 'ROLE_SUPERVISOR_USER'],
    REGIONAL: ['ROLE_REGIONAL_ADMIN', 'ROLE_REGIONAL_USER'],
    SCHOOL: ['ROLE_SCHOOL_ADMIN', 'ROLE_SCHOOL_USER'],
    BANK: ['ROLE_BANK_ADMIN', 'ROLE_BANK_USER'],
    DOB: ['ROLE_DOB_ADMIN', 'ROLE_DOB_USER'],
  },
};
