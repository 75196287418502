import { IUser } from 'src/app/account/model/user.model';
import { config } from 'src/config';

export function getAuthUserDashboardLabel(user: IUser): string {
  let label = '';
  if (config.authRoles.SCHOOL.includes(user.role?.toUpperCase()!)) {
    label = 'etablissement';
  } else if (config.authRoles.SUPERVISOR.includes(user.role?.toUpperCase()!)) {
    label = 'DEXC';
  } else if (config.authRoles.ADMIN.includes(user.role?.toUpperCase()!)) {
    label = 'administrateur';
  } else if (config.authRoles.REGIONAL.includes(user.role?.toUpperCase()!)) {
    label = 'DR / DD';
  }
  return label;
}
