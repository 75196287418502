import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DobApplicantComponent } from './components/dob-applicant/dob-applicant.component';
import { DobCheckMatriculeComponent } from './components/dob-check-matricule/dob-check-matricule.component';
import { DobChoiceComponent } from './components/dob-choice/dob-choice.component';
import { DobFinishComponent } from './components/dob-finish/dob-finish.component';
import { DobLayoutComponent } from './components/dob-layout/dob-layout.component';
import { DobSummaryComponent } from './components/dob-summary/dob-summary.component';

const routes: Routes = [
  {
    path: 'orientation',
    component: DobLayoutComponent,
    redirectTo: '',
    children: [
      {
        path: 'search',
        component: DobCheckMatriculeComponent,
      },
      {
        path: 'applicant',
        component: DobApplicantComponent,
      },
      {
        path: 'choice',
        component: DobChoiceComponent,
      },
      {
        path: 'summary',
        component: DobSummaryComponent,
      },
    ],
  },
  {
    path: 'orientation/finish',
    component: DobFinishComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DobRoutingModule {}
