<app-navbar></app-navbar>

<div class="examen-section" style="margin-top: 4rem">
  <div class="examen-section__content">
    <!-- <p-messages [(value)]="msgs"></p-messages> -->
    <div class="message message-success">
      <p>
        Félicitation, votre compte {{ email }} à été activé, connectez-vous
        maintenant
        <a class="link link-success" [routerLink]="['/account', 'login']"
          >connextion</a
        >
      </p>
    </div>
  </div>
</div>
