<app-navbar></app-navbar>

<div class="content-wrapper">
  <div class="registration-status-wrapper">
    <h1 class="registration-status-title">Statut des enregistrements</h1>

    <div class="registration-status-header">
      <p style="display: flex; align-items: baseline">
        <span style="font-size: 16px">Nombre total d'enregistements:</span>
        <span style="margin-left: 1rem; font-size: 20px" class="color-main">{{
          totalRegistrations
        }}</span>
      </p>
      <button
        (click)="handleRefreshPage()"
        pButton
        type="button"
        icon="pi pi-refresh"
        label="Rafraichir"
        [disabled]="isLoading"
      ></button>
    </div>
    <div class="registration-status-filters">
      <!-- recherche par nom -->
      <input
        type="text"
        pInputText
        [(ngModel)]="schoolName"
        placeholder="Entrer le nom de l'établissement"
        [style]="{ flex: 2 }"
        (ngModelChange)="handleSearch()"
        [disabled]="isLoading"
      />

      <!-- direction regionalle -->
      <p-dropdown
        [options]="regionals"
        [(ngModel)]="selectedRegional"
        optionLabel="bch_label"
        optionValue="bch_id"
        placeholder="Sélectionner la direction regionale"
        [style]="{ width: '100%', flex: 1 }"
        (onChange)="handleSelectRegionalBranch()"
        [disabled]="isLoading"
      ></p-dropdown>

      <p-dropdown
        [options]="schoolsType"
        [(ngModel)]="selectedSchoolType"
        optionLabel="name"
        optionValue="code"
        placeholder="Type d'établissement"
        [style]="{ width: '100%' }"
        (onChange)="handleSelectSchoolType()"
        [disabled]="isLoading"
      ></p-dropdown>

      <!-- filtrer by range -->
      <!-- <p-inputNumber
        inputId="integeronly"
        [(ngModel)]="registrationNumberMin"
        placeholder="Entrer une valeur"
      ></p-inputNumber>

      <p-inputNumber
        inputId="integeronly"
        [(ngModel)]="registrationNumberMax"
        placeholder="Entrer une valeur"
      ></p-inputNumber> -->

      <!-- <button
        (click)="handleFilter()"
        pButton
        type="button"
        label="Friltrer"
      ></button> -->
    </div>

    <p-table
      [value]="filteredRegistrations"
      responsiveLayout="scroll"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      responsiveLayout="scroll"
      [alwaysShowPaginator]="false"
      currentPageReportTemplate="Affichage de {first} à {last} sur {totalRecords} enregistrements"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Etablissemnents</th>
          <th>Directions Regionales</th>
          <th>Type d'établissement</th>
          <th style="text-align: right">Nombre d'enregistements</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{ data?.school?.sch_longlabel }}</td>
          <td>{{ data?.school?.sch_regionalbranch?.bch_label }}</td>
          <td>
            <span
              class="chip"
              [ngClass]="{
                'chip-main': data?.school?.sch_type! == 2,
                'chip-warning': data?.school?.sch_type! == 1
              }"
              >{{ getSchoolType(data?.school?.sch_type!) }}</span
            >
          </td>
          <td style="text-align: right">{{ data?.total }}</td>
        </tr>
      </ng-template>
    </p-table>

    <div class="search-loader" style="display: flex; justify-content: center">
      <img src="/assets/loader.gif" alt="Loader" *ngIf="isLoading" />
    </div>
  </div>
</div>
