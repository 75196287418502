import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { IExamRegistration } from '../shared/model/examregistration.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IStatistic {
  labels: string[];
  values: any[];
  percentages: any[];
}
@Injectable({
  providedIn: 'root',
})
export class ExamregistrationService {
  private examRegistrationResourceURL =
    environment.apiURL + 'api/v1/examregistrations';

  private examregistation$ = new BehaviorSubject<IExamRegistration>({});

  constructor(private http: HttpClient) {}

  save(body: any): Observable<HttpResponse<IExamRegistration>> {
    return this.http.post<IExamRegistration>(
      `${this.examRegistrationResourceURL}/`,
      body,
      { observe: 'response' }
    );
  }

  get(student_code: string, year_code: string): Observable<IExamRegistration> {
    return this.http.get<IExamRegistration>(
      `${this.examRegistrationResourceURL}/${student_code}/${year_code}/`
    );
  }

  // getByStudentRefAndYearSession(ref: string, year: string): Observable<<>>

  getAll(): Observable<HttpResponse<IExamRegistration[]>> {
    return this.http.get<IExamRegistration[]>(
      `${this.examRegistrationResourceURL}/`,
      { observe: 'response' }
    );
  }

  findExamRegistrationBySchool(
    school_id: string
  ): Observable<HttpResponse<IExamRegistration[]>> {
    return this.http.get<IExamRegistration[]>(
      `${this.examRegistrationResourceURL}/${school_id}/school-examregistration/`,
      { observe: 'response' }
    );
  }

  updateStatus(body: any): Observable<HttpResponse<IExamRegistration>> {
    return this.http.patch<IExamRegistration>(
      `${this.examRegistrationResourceURL}/update-status/`,
      body,
      { observe: 'response' }
    );
  }

  checkForExistRegistration(
    student_code: string,
    year_code: number
  ): Observable<HttpResponse<IExamRegistration | any>> {
    return this.http.get<IExamRegistration | any>(
      `${this.examRegistrationResourceURL}/check-student-registration/${student_code}/${year_code}/`,
      { observe: 'response' }
    );
  }

  initiatePayment(
    exam_id: string
  ): Observable<HttpResponse<IExamRegistration>> {
    return this.http.patch<IExamRegistration>(
      `${this.examRegistrationResourceURL}/update-status/${exam_id}`,
      {},
      { observe: 'response' }
    );
  }

  getExamRegistrationStatisticsByDiplomas(): Observable<
    HttpResponse<IStatistic | any>
  > {
    return this.http.get<IStatistic | any>(
      `${this.examRegistrationResourceURL}/exam-statistics-by-diplomas/`,
      { observe: 'response' }
    );
  }

  getExamRegistrationPaymentStatusStatistics(): Observable<
    HttpResponse<IStatistic | any>
  > {
    return this.http.get<IStatistic | any>(
      `${this.examRegistrationResourceURL}/exam-payment-status-statistics/`,
      { observe: 'response' }
    );
  }
}
