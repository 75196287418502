<p class="dashboard-title">Liste des candidats</p>

<div class="dashboard-section">
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <!-- <div class="p-inputgroup" [style]="{ marginRight: '2rem' }">
        <input
          type="text"
          pInputText
          placeholder="Recherche"
          [(ngModel)]="searchText"
          (keydown.enter)="handleSearch()"
          [style]="{ width: '300px' }"
        />
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-search"
          (click)="handleSearch()"
        ></button>
      </div> -->

      <p-dropdown
        [options]="statusList"
        [(ngModel)]="selectedStatus"
        optionLabel="name"
        filterBy="name"
        [showClear]="true"
        placeholder="Statut..."
        styleClass="rounded"
        (onChange)="handleFilterByStatus()"
        [style]="{ width: '150px' }"
      >
        <ng-template pTemplate="selectedItem">
          <div *ngIf="selectedStatus">
            <i
              [style]="{ color: selectedStatus.color }"
              class="pi pi-circle-fill"
            ></i>
            {{ selectedStatus.name }}
          </div>
        </ng-template>
        <ng-template let-status pTemplate="item">
          {{ status.name }}
        </ng-template>
      </p-dropdown>
    </div>

    <div class="p-toolbar-group-right">
      <button
        class="p-button rounded p-button-outlined p-button-secondary"
        style="margin-left: 4rem"
        (click)="refreshPage()"
      >
        <i class="pi pi-refresh" style="margin-right: 1rem"></i>
        Rafraichir
      </button>
    </div>
  </p-toolbar>

  <!-- <p-card> -->
  <p-table
    #registrationtable
    [value]="filterApplicants"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    currentPageReportTemplate="Affichage de {first} à {last} des {totalRecords} enregistrements"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Identification</th>
        <th>Nationalité</th>
        <th>Contact</th>
        <th>Date de validation</th>
        <th>Validé par</th>
        <th>Direction régionale</th>
        <th>Status</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr *ngIf="!isLoading">
        <td
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            border: none;
          "
        >
          <span style="opacity: 0.5">{{ item?.applicant?.app_matricule }}</span>
          <span
            >{{ item?.applicant?.app_lastname }}
            {{ item?.applicant?.app_firstname }}</span
          >
        </td>

        <td>{{ item?.applicant?.app_nationality__nat_label }}</td>

        <td>
          {{ item?.applicant?.app_phone }}
        </td>
        <td>
          <span *ngIf="item?.validated; else novalidated">{{
            item?.validation_date | date: "medium":"fr"
          }}</span>
          <ng-template #novalidated>
            <span>-</span>
          </ng-template>
        </td>
        <td>
          {{ item?.validated_by | uppercase }}
        </td>
        <td>
          {{ item?.regional_branch }}
        </td>
        <td>
          <span class="chip chip-success" *ngIf="item?.validated"
            ><i class="pi pi-check" style="font-size: 1.2rem"></i> validé</span
          >
          <span class="chip chip-danger" *ngIf="!item?.validated"
            ><i class="pi pi-times" style="font-size: 1.2rem"></i> Non
            validé</span
          >
        </td>
        <td style="text-align: right">
          <button
            class="p-button rounded p-button-outlined p-button-secondary"
            (click)="showDetail($event, op, item?.applicant)"
          >
            Afficher les choix
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div
    class="empty-table-message-block"
    *ngIf="filterApplicants.length === 0 && !isLoading"
  >
    <p>Aucun applicant trouvé</p>
  </div>
  <div
    *ngIf="isLoading"
    class="loader"
    style="display: flex; justify-content: center"
  >
    <img src="/assets/loader.gif" />
  </div>
  <!-- </p-card> -->

  <div class="paginator">
    <!-- <div class="paginator-text">Total {{ pageCount }}</div> -->
    <p-button
      type="button"
      icon="pi pi-chevron-left"
      (click)="prev()"
      [disabled]="isFirstPage()"
      styleClass="paginator-button"
      [ngClass]="{'no-pointer': isFirstPage()}"
    ></p-button>
    <p-button
      type="button"
      icon="pi pi-refresh"
      (click)="reset()"
      styleClass="paginator-button"
    ></p-button>
    <p-button
      type="button"
      icon="pi pi-chevron-right"
      (click)="next()"
      [disabled]="isLastPage()"
      styleClass="paginator-button"
      [ngClass]="{'no-pointer':isLastPage()}"
    ></p-button>
  </div>
</div>

<!-- <div class="dob-content-detail">
  <h3>Selectionner un candidat</h3>
</div> -->

<p-overlayPanel
  [style]="{ width: '600px' }"
  #op
  appendTo="body"
  [dismissable]="true"
  [showCloseIcon]="true"
>
  <ng-template pTemplate>
    <p-table
      #registrationtable
      [value]="applicantChoices"
      [paginator]="true"
      [rows]="9"
      [alwaysShowPaginator]="false"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Filière</th>
          <th>Etablissement</th>
          <th class="text-right">Ordre de préférence</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-choice>
        <tr *ngIf="!isChoiceLoading">
          <td>{{ choice?.chx_studyfield?.sfd_longlabel }}</td>

          <td>
            {{ choice?.chx_school?.sch_longlabel }}
          </td>
          <td style="text-align: right">
            <span class="chip chip-main">
              Choix {{ choice?.chx_preference }}</span
            >
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div
      class="empty-table-message-block"
      *ngIf="applicantChoices.length === 0 && !isChoiceLoading"
    >
      <p>Aucun applicant trouvé</p>
    </div>
    <div
      *ngIf="isChoiceLoading"
      class="loader"
      style="display: flex; justify-content: center"
    >
      <img src="/assets/loader.gif" />
    </div>
  </ng-template>
</p-overlayPanel>
