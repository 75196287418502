<p-toast></p-toast>
<form class="form-steps" [formGroup]="studentFiliereForm" (ngSubmit)="showDialog()">
    <div class="steps steps-three">
        <div class="steps-title">
            <h2>Detail filière</h2>
            <small>Veuillez choisir votre filiere</small>
        </div>

        <div class="mb-2">
            <label for="year">Session</label>
            <input type="number" id="year" disabled placeholder="{{session}}">
            <p *ngIf="formSubmited && !studentFiliereForm.controls?.['fcr_session']?.valid">
                <span class="required" *ngIf="fcr_session?.errors?.['required']" >veuillez renseigner le champ</span>
            </p>
        </div>

        <div class="two-children mb-2">

            <div class="mb-2">
                <label for="school">Choisir votre etablissement</label>
                <input type="text" formControlName="fcr_school">
                <!-- (change)="getDiplomeBySchoolId($event)" -->
                <!-- <select id="school" formControlName="fcr_school">
                    <option *ngFor="let bank of bankList" value="{{bank.bnk_id}}">{{ bank.bnk_longlabel}}</option>
                </select> -->
                <p *ngIf="formSubmited && !studentFiliereForm.controls?.['fcr_school']?.valid">
                    <span class="required" *ngIf="fcr_school?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>

            <div class="mb-2">
                <label for="diplome">Choisir votre Diplome</label>
                <!-- (change)="getStudyfieldBySchoolIdAndDiplome($event)" -->
                <select id="diplome" formControlName="fcr_diploma" (change)="getStudyfieldBySchoolIdAndDiplome($event)">
                    <option value=""></option>
                    <option *ngFor="let diploma of diplomas" value="{{diploma.dip_id}}">{{diploma.dip_longlabel}}</option>
                    <!-- <option value="0">BP</option> -->
                    <!-- <option value="1">BT</option> -->
                    <!-- <option value="2">CAP</option> -->
                </select>
                <p *ngIf="formSubmited && !studentFiliereForm.controls?.['fcr_diploma']?.valid">
                    <span class="required" *ngIf="fcr_diploma?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>

        </div>

        <div class="filiere-unit mb-2">

            <div class="filiere-unit__item mb-2">
                <label for="filiere">Choisir votre filiere</label>
                <select id="filiere" formControlName="fcr_studyfield">
                    <option *ngFor="let studyField of studyFields" value="{{studyField.sfd_id}}">{{studyField.sfd_longlabel}}</option>
                    <!-- <option value="0">BP Banque</option> -->
                    <!-- <option value="1">Comptabilité</option> -->
                    <!-- <option value="2">Informatique</option> -->
                </select>
                <p *ngIf="formSubmited && !studentFiliereForm.controls?.['fcr_studyfield']?.valid">
                    <span class="required" *ngIf="fcr_studyfield?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>

            <div class="filiere-unit__item mb-2">
                <label for="unite">Unité d'enseignement</label>
                <!-- <input type="number" id="unite" disabled placeholder="UF1,UF2,UF3"> -->
                <p-multiSelect formControlName="fcr_examtotake" [style]="{'width':'100%', 'padding':'.5rem 0', 'margin-top':'.6rem'}" [options]="examUnit" optionLabel="exu_shortlabel"></p-multiSelect>
                <!-- <select id="unite" formControlName="fcr_examtotake">
                    <option *ngFor="let examU of examUnit" value="{{examU.exu_shortlabel}}">{{examU.exu_longlabel}}</option>
                    <option value="0">UF1</option>
                    <option value="1">UF2</option>
                    <option value="2">UF3</option>
                </select>
                <p *ngIf="formSubmited && !studentFiliereForm.controls?.['fcr_examtotake']?.valid">
                    <span class="required" *ngIf="fcr_examtotake?.errors?.['required']" >veuillez renseigner le champ</span>
                </p> -->
            </div>

        </div>

        <div class="text-right box-btn">
            <div class="text-center loader" *ngIf="isLoader">
                <img src="../assets/loader.gif" alt="">
            </div>
            <a (click)="onPrevStep()">
                <i class="pi pi-arrow-left"></i>
                RETOUR
            </a>
            <button [disabled]="studentFiliereForm.invalid" type="submit" class="p-button">
                SELECTION FILIERE
            </button>
        </div>
        
    </div>
</form>

<!-- confirm dialog -->
<p-dialog
  header="Comfirmation"
  [(visible)]="displayDialog"
  [style]="{ width: '500px' }"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
>
  <p style="display: flex; align-items: center">
    <i
      style="font-size: 2rem; color: red; margin-right: 1rem"
      class="pi pi-question-circle"
    ></i>
    Êtes-vous sûr des informations renseignées ?
  </p>

  <ng-template pTemplate="footer">
    <p-button
      (click)="displayDialog = false"
      icon="pi pi-times"
      label="Annuler"
      styleClass="p-button-secondary p-button-text"
    ></p-button>
    <p-button
    *ngIf="updatedBtn == false"
      (click)="onStudentBankFiliere()"
      label="Confirmer"
      styleClass="p-button"
    ></p-button>
    <p-button
    *ngIf="updatedBtn == true"
    (click)="updateBankStudent()"
    label="Modifier"
    styleClass="p-button"
  ></p-button>
  </ng-template>
</p-dialog>