import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { FreeCandidateService } from 'src/app/services/free-candidate.service';
import { IStudent, Student } from 'src/app/shared/model/student.model';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';
import { Messages } from 'primeng/messages';
import { Message } from 'primeng/api';
import { SessionStorageService } from 'src/app/session-storage.service';
import { FREE_CANDIDATE_INFO_KEY } from 'src/app/shared/constant/step.constant';

@Component({
  selector: 'app-examen-free-search',
  templateUrl: './examen-free-search.component.html',
  styleUrls: ['./examen-free-search.component.scss'],
})
export class ExamenFreeSearchComponent implements OnInit {
  isLoader: boolean = false;
  matriculeNotFound: boolean = false;
  msgs: Message[] = [];

  searchForm!: FormGroup;

  formSubmited: boolean = false;
  formDone: boolean = false;
  @Input() student!: IStudent;
  @Output('nextStep') nextStep: EventEmitter<any> = new EventEmitter();

  constructor(
    private freeCandidateService: FreeCandidateService,
    private storage: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getCandidateInfo();
    let studentIn: any = this.student;

    const studenttoLocalStorage: any = JSON.parse(
      localStorage.getItem('student') || '{}'
    );
    if (studentIn !== undefined) {
      this.searchForm
        .get('std_id')
        ?.setValue(studenttoLocalStorage['pfc_examcode']);
    }
  }

  initForm() {
    this.searchForm = new FormGroup({
      id: new FormControl(0),
      std_id: new FormControl(null, [
        Validators.required,
        Validators.minLength(6),
      ]),
      stp_year: new FormControl(null, [
        Validators.required,
        Validators.minLength(4),
      ]),
    });
  }

  get std_id() {
    return this.searchForm.get('std_id');
  }
  get stp_year() {
    return this.searchForm.get('stp_year');
  }

  onSearchStudent() {
    this.isLoader = true;
    const exaCode = this.searchForm.get('std_id')?.value;
    const date = this.searchForm.get('stp_year')?.value;
    const year = moment(date).format('YYYY');

    this.freeCandidateService.getExaCode(exaCode, year).subscribe(
      (response: any) => {
        this.isLoader = false;
        this.formSubmited = true;
        this.formDone = true;
        if (response.result === -1) {
          this.msgs = [
            {
              key: 'FREE_CANDIDATE_REGISTRATION_SEARCH',
              severity: 'error',
              summary: '',
              detail: response.message,
            },
          ];
        } else {
          console.log('Response =>', response);
          const info = {
            date: date,
            exaCode: exaCode,
            student: response.data.candidate || {},
            existInPrevious: response.data.exist,
          };

          console.log(info);

          this.storage.setItem(FREE_CANDIDATE_INFO_KEY, JSON.stringify(info));
          this.nextStep.emit({
            student: info.student,
            exist: info.existInPrevious,
          });
        }
      },

      (_) => {
        this.matriculeNotFound = true;
        this.isLoader = false;
        this.msgs = [
          {
            key: 'FREE_CANDIDATE_REGISTRATION_SEARCH',
            severity: 'error',
            summary: '',
            detail:
              "Desolé, Vos informations n'ont pas été trouvé :), Veuillez-vous référer à la DEXC.",
          },
        ];
      }
    );
  }

  getCandidateInfo(): void {
    const info = JSON.parse(this.storage.getItem(FREE_CANDIDATE_INFO_KEY)!);
    if (info) {
      this.searchForm.patchValue({
        std_id: info.exaCode,
        stp_year: new Date(info.date),
      });
    }
  }

  // visibility block error with input event
  hiddenError() {
    this.matriculeNotFound = false;
  }

  fieldIsRequired(control: AbstractControl): string {
    if (hasRequiredField(control)) {
      return '*';
    }
    return '';
  }

  setValueToUpper(control: AbstractControl) {
    if (control.value) {
      control.setValue(control.value.toUpperCase());
    }
  }
}
