import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';

import { IExamenBreadcrumb } from '../../shared/model/examen-breadcrumb.model';
import { hasRequiredField } from '../../shared/utils/validator.utils';
import { CANDIDATURES } from '../../shared/constant/typecandidat.constant';
import { activateStep, setActiveStep } from 'src/app/shared/utils/step.utils';
import { ExamregistrationService } from 'src/app/services/examregistration.service';
import { Message, MessageService } from 'primeng/api';
import { YearService } from '../../services/year.service';
import { IYear } from 'src/app/shared/model/year.model';
import { BREADCRUMB_ITEMS_KEY } from 'src/app/shared/constant/step.constant';
import { SessionStorageService } from 'src/app/session-storage.service';

@Component({
  selector: 'app-examen-recherche',
  templateUrl: './examen-recherche.component.html',
  styleUrls: ['./examen-recherche.component.scss'],
})
export class ExamenRechercheComponent implements OnInit, OnDestroy {
  items: IExamenBreadcrumb[] = [];
  isSaving = false;
  candidatures: any[] = [];
  selectedCandidature: any;
  selectedSearch = '';

  editForm = this.fb.group({
    typeCandidature: [null, [Validators.required]],
    searchBy: [null, []],
    tableNumber: [null, []],
    matricule: [null, [Validators.required]],
  });

  student = '';
  matriculeValue = '';
  year = 0;
  years: IYear[] = [];
  isLoading = false;

  registrationMSG: Message[] = [];

  constructor(
    private router: Router,
    protected fb: FormBuilder,
    private examregistration: ExamregistrationService,
    private messageService: MessageService,
    private yearService: YearService,
    private sessionStorage: SessionStorageService
  ) {}
  ngOnDestroy(): void {
    // sessionStorage.clear()
  }

  ngOnInit(): void {
    this.candidatures = CANDIDATURES;
    const examData = this.loadChoiceFromSS();

    this.setActiveYear();

    if (examData) {
      this.selectedCandidature = examData.typeCandidature;
      this.selectedSearch = examData.searchBy;
      this.editForm.patchValue({
        typeCandidature: examData.typeCandidature,
        matricule: examData.matricule,
        tableNumber: examData.tableNumber,
        searchBy: examData.searchBy,
      });

      this.matriculeValue = examData.matricule;
    }

    const currentStep = this.loadStepFromSS()?.step;
    this.items = this.loadStepFromSS()?.data;
    setActiveStep(this.items, currentStep);
  }

  setActiveYear(): void {
    this.yearService.get().subscribe((res) => {
      this.years = res.body || [];
      this.year = this.years.filter(
        (year) => year.yrs_is_active
      )[0].yrs_session!;
      sessionStorage.setItem('activeYear', JSON.stringify(this.year));
    });
  }

  setCandidateType(): void {
    sessionStorage.setItem(
      'typeCandidate',
      JSON.stringify(this.selectedCandidature)
    );
  }

  checkRegistration(): void {
    this.examregistration
      .checkForExistRegistration(this.student, this.year)
      .subscribe({
        next: (data) => {
          const result = data.body;
          if (result.status && result.status === 404) {
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur',
              detail: result.detail.message,
            });
            this.isLoading = false;
            this.editForm.controls['matricule'].setErrors({ incorrect: true });
          } else if (result.status && result.status === 200) {
            activateStep(
              JSON.parse(this.sessionStorage.getItem(BREADCRUMB_ITEMS_KEY)!),
              this.sessionStorage
            );
            this.router.navigate(['/inscription', 'examen', 'info-candidat']);
            this.isLoading = false;
          } else {
            setTimeout(() => {
              this.isLoading = false;
              this.router.navigate([
                '/inscription',
                'examen',
                'registred-detail',
              ]);
            }, 1000);

            // this.messageService.add({
            //   severity: 'error',
            //   detail: `Le matricule ${this.student} est déjà enregistré à cette session ${this.year} des examens`,
            // });
            // this.editForm.controls['matricule'].setErrors({ incorrect: true });
          }
        },
        error: (error) => {
          if (error.status === 404) {
            activateStep(
              JSON.parse(this.sessionStorage.getItem(BREADCRUMB_ITEMS_KEY)!),
              this.sessionStorage
            );
            this.router.navigate(['/inscription', 'examen', 'info-candidat']);
          } else {
            this.messageService.add({
              severity: 'error',
              detail: `Une erreur est est survenue, veuillez réessayer!`,
            });
          }
          this.registrationMSG = [];
          this.isLoading = false;
        },
        // complete: () => (this.isLoading = false),
      });
  }

  goToNext(): void {
    this.isLoading = true;
    this.registrationMSG = [];
    this.saveChoiceToSS();
    this.checkRegistration();
  }

  saveChoiceToSS(): void {
    if (
      this.editForm.controls['typeCandidature'].value === 'official' ||
      this.editForm.controls['typeCandidature'].value === 'night'
    ) {
      this.editForm.patchValue({
        searchBy: 'matricule',
      });
    }

    sessionStorage.setItem('exam', JSON.stringify(this.editForm.value));
    this.student = this.editForm.controls['matricule'].value;
  }

  loadChoiceFromSS(): any {
    const exam = sessionStorage.getItem('exam');
    if (exam) {
      return JSON.parse(exam);
    }
    return {};
  }

  loadStepFromSS(): any {
    return JSON.parse(sessionStorage.getItem(BREADCRUMB_ITEMS_KEY)!);
  }

  isCandidatureOffOrNight(): boolean {
    return false;
  }

  matriculeLabelText(): string {
    let text = '';
    switch (this.selectedCandidature.toLowerCase()) {
      case 'official':
      case 'free':
      case 'night':
        text = 'Numéro matricule MENA / ETFP';
        break;

      default:
        text = 'Numéro de référence';
        break;
    }

    return text;
  }

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }

  handleCandidatureChange(): void {
    this.editForm.patchValue({
      matricule: '',
      searchBy: '',
    });

    if (this.selectedCandidature?.code === 'free') {
      this.editForm.controls['searchBy'].setValidators([Validators.required]);
      this.editForm.controls['searchBy'].updateValueAndValidity();
    } else {
      this.editForm.controls['searchBy'].clearValidators();
      this.editForm.controls['searchBy'].updateValueAndValidity();
    }

    if (
      this.selectedCandidature?.code === 'bankOfficial' ||
      this.selectedCandidature?.code === 'bankFree'
    ) {
      this.editForm.controls['matricule'].clearValidators();
      this.editForm.controls['tableNumber'].clearValidators();
      this.editForm.controls['matricule'].updateValueAndValidity();
      this.editForm.controls['tableNumber'].updateValueAndValidity();
    } else {
      this.editForm.controls['matricule'].setValidators([Validators.required]);
      this.editForm.controls['matricule'].updateValueAndValidity();
    }
  }

  handleSelectSearch(event: any): void {
    if (event.target?.value === 'matricule') {
      this.editForm.controls['matricule'].setValidators([Validators.required]);
      this.editForm.controls['tableNumber'].clearValidators();
      this.editForm.controls['matricule'].updateValueAndValidity();
      this.editForm.controls['tableNumber'].updateValueAndValidity();

      this.editForm.patchValue({
        tableNumber: '',
      });
    } else {
      this.editForm.controls['tableNumber'].setValidators([
        Validators.required,
      ]);
      this.editForm.controls['matricule'].clearValidators();
      this.editForm.controls['matricule'].updateValueAndValidity();
      this.editForm.controls['tableNumber'].updateValueAndValidity();

      this.editForm.patchValue({
        matricule: '',
      });
    }
  }
}
