import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DobService } from 'src/app/dob/dob.service';
import {
  IApplicant,
  IApplicantWithStatus,
} from 'src/app/dob/models/applicant.model';
import { IChoice } from 'src/app/dob/models/choice.model';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-dashboard-dob-applicants',
  templateUrl: './dashboard-dob-applicants.component.html',
  styleUrls: ['./dashboard-dob-applicants.component.scss'],
})
export class DashboardDobApplicantsComponent implements OnInit {
  isLoading = false;
  isChoiceLoading = false;

  searchText = '';

  applicants: IApplicantWithStatus[] = [];
  filterApplicants: IApplicantWithStatus[] = [];
  selectedApplicant?: IApplicant;
  applicantChoices: IChoice[] = [];
  nextPage = '';
  prevPage = '';
  pageCount = 0;
  pageOffset: any;
  firstCount = 0;
  lastCount = 0;
  page = '';
  applicantResourceURL = environment.apiURL + 'api/v1/applicants/';

  statusList: any[] = [
    {
      name: 'Validé',
      icon: 'pi pi-circle',
      code: 1,
      color: '#519259',
    },
    {
      name: 'Non validé',
      icon: 'pi pi-circle',
      code: 2,
      color: '#e63946',
    },
  ];
  selectedStatus: any;

  constructor(
    private dobService: DobService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.loadApplicant();
  }

  loadApplicant(): void {
    this.setProperties(this.applicantResourceURL, this.getParams());
  }

  getParams(): any {
    let status = undefined;
    const page = this.route.snapshot.params['page'] || undefined;
    if (this.selectedStatus) {
      status = this.selectedStatus?.code;
    }

    return { page, status };
  }

  handleSearch(): void {
    if (this.searchText) {
      const search = this.searchText.toLowerCase();
      this.filterApplicants = this.applicants.filter(
        (applicant) =>
          applicant?.applicant?.app_matricule?.toLowerCase().includes(search) ||
          applicant?.applicant?.app_firstname?.toLowerCase().includes(search) ||
          applicant?.applicant?.app_lastname?.toLowerCase().includes(search) ||
          applicant?.applicant?.app_email?.toLowerCase().includes(search)
      );
    } else {
      this.filterApplicants = [...this.applicants];
    }
  }

  showDetail(event: any, panel: any, applicant: IApplicant): void {
    this.isChoiceLoading = true;
    panel.toggle(event);
    this.selectedApplicant = applicant;
    this.dobService
      .getChoiceByApplicant(this.selectedApplicant?.app_id!)
      .subscribe((res) => {
        this.isChoiceLoading = false;
        this.applicantChoices = res.body || [];
      });
  }

  refreshPage(): void {
    this.searchText = '';
    this.selectedStatus = undefined;
    this.loadPage();
  }

  setProperties(url: string, body?: any) {
    this.isLoading = true;
    this.dobService.getAllApplicant(url, body).subscribe((res) => {
      this.applicants = res.body?.results || [];
      this.filterApplicants = [...this.applicants];
      this.pageCount = res.body?.count!;

      if (res.body?.next) {
        // set the components next property here from the response
        this.nextPage = res.body.next;
      } else this.nextPage = '';

      if (res.body?.previous) {
        // set the components previous property here from the response
        this.prevPage = res.body.previous;
      } else this.prevPage = '';
      this.isLoading = false;
      this.firstAndLastIndex();
    });
  }

  firstAndLastIndex(): void {
    // let params = new HttpParams();
    this.route.queryParams.subscribe((params) => console.log(params));
  }

  prev(): void {
    this.setProperties(this.prevPage);
  }

  next(): void {
    console.log(this.nextPage);
    this.setProperties(this.nextPage);
  }

  reset(): void {
    this.setProperties(this.applicantResourceURL, this.getParams());
  }

  navigateTo(to: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page: to.split('').reverse()[0],
      },
      queryParamsHandling: 'merge',
    });
  }

  isFirstPage(): boolean {
    return this.prevPage === null || this.prevPage === '';
  }
  isLastPage(): boolean {
    return this.nextPage === null || this.nextPage === '';
  }

  handleFilterByStatus(): void {
    const page = this.route.snapshot.params['page'] || undefined;
    console.log(page);
    if (this.selectedStatus) {
      this.setProperties(this.applicantResourceURL, {
        page: page,
        status: this.selectedStatus?.code,
      });
    } else this.setProperties(this.applicantResourceURL);
  }
}
