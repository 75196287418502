<!-- <p class="dashboard-title">
  Bienvenue, {{ authUser?.last_name! | uppercase }}
  {{ authUser?.first_name! | uppercase }}.
</p> -->

<!-- <div class="dashboard-section">
  <div class="dashboard-section-block-card">
    <i class="pi pi-home"></i>
    <h2>
      Liste des établissement
      <i class="pi pi-arrow-right"></i>
    </h2>
  </div>
  <div class="dashboard-section-block-card">
    <i class="pi pi-home"></i>
    <h2>
      Liste des établissement
      <i class="pi pi-arrow-right"></i>
    </h2>
  </div>
</div> -->

<div class="dashboard-section">
  <!-- <p class="dashboard-section-title">Statistique des enregistrements</p> -->
  <div class="dashboard-section__header">
    <!-- <h3>Apprenants po établissements</h3> -->
    <div>&nbsp;</div>
    <a
      [routerLink]="[
        '/dashboard',
        'regional',
        'orientation',
        'listing-validation'
      ]"
      class="link link-main"
    >
      Afficher les détails <i class="pi pi-arrow-right"></i>
    </a>
  </div>
  <div class="dashboard-validation__statistics">
    <div class="dashboard-validation__statistics-item">
      <span class="dashboard-validation__statistics-value">{{
        totalValidation
      }}</span>

      <div class="dashboard-validation__statistics-text">
        <span class="dashboard-validation__statistics-label"
          >Total des validations <span>effectuées</span>
        </span>
      </div>
    </div>

    <div class="dashboard-validation__statistics-item">
      <span class="dashboard-validation__statistics-value success">{{
        totalValidationOK
      }}</span>

      <div class="dashboard-validation__statistics-text">
        <span class="dashboard-validation__statistics-label"
          >Total des validations <span>completes</span>
        </span>
      </div>
    </div>

    <div class="dashboard-validation__statistics-item">
      <span class="dashboard-validation__statistics-value danger">{{
        totalValidationNoOK
      }}</span>
      <div class="dashboard-validation__statistics-text">
        <span class="dashboard-validation__statistics-label"
          >Total des validations <span>non completes</span>
        </span>
        <!-- <a href="" class="dashboard-validation__statistics-link">
          <i class="pi pi-arrow-right"></i>
        </a> -->
      </div>
    </div>
  </div>
</div>

<!-- <div class="dashboard-section">
  <div class="card">
    <h5>Vertical</h5>
    <p-chart type="bar" [data]="basicData" [options]="basicOptions"></p-chart>
  </div>
</div> -->

<div class="dashboard-loader" *ngIf="isLoading">
  <p-progressSpinner
    [style]="{ width: '50px', height: '50px' }"
  ></p-progressSpinner>
</div>
