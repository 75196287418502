// download received document pdf
export function downloadPdf(base64String: any, fileName: any) {
  const doc = `data:application/pdf;base64, ${base64String}`;
  const link = document.createElement('a');
  link.href = doc;

  link.download = `${fileName}.pdf`;
  link.target = '_blank';
  link.click();
}
