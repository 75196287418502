<app-navbar></app-navbar>

<div class="finish-wrapper">
  <img src="/assets/registration/finish.svg" alt="" />

  <p>
    Félicitations, le processus d'enregistrement s'est terminé avec succès. Vos
    informations seront transmises au chef de votre établissement pour
    validation.
  </p>

  <button
    pButton
    type="button"
    label="Retour à l'accueil"
    (click)="goHome()"
  ></button>
</div>
