<div class="examen-section">
  <p-toast></p-toast>
  <div class="mb-8 examen-section__header">
    <app-examen-breadcrumb [items]="items"></app-examen-breadcrumb>
  </div>

  <div class="examen-section__content full">
    <h3>Information examen</h3>
    <p>
      Veuillez remplir le formulaire en tenant compte des champs obligatoire
    </p>

    <form [formGroup]="editForm">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_school"
          >Etablissement
          <!-- <span class="color-danger">{{
            fieldIsRequired(editForm.controls["typeCandidature"])
          }}</span> -->
        </label>
        <p-dropdown
          id="field_school"
          [options]="schools"
          [(ngModel)]="selectedSchool"
          optionLabel="sch_longlabel"
          formControlName="school"
          placeholder="Selectionner votre établissement"
        >
        </p-dropdown>
      </div>

      <div class="p-flex-between">
        <div class="p-field p-field-column">
          <label class="p-field-label" for="field_diploma"
            >Diplôme préparé
            <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
          </label>
          <p-dropdown
            id="field_diploma"
            [options]="diplomas"
            [(ngModel)]="selectedDiploma"
            formControlName="diploma"
            optionLabel="dip_longlabel"
            placeholder="Selectionner votre diplôme"
            (onChange)="loadExamunit()"
          >
          </p-dropdown>
        </div>

        <div class="p-field p-field-column">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_sector"
              >Filière
              <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
            </label>
            <p-dropdown
              id="field_sector"
              [options]="(sectors$ | async)!"
              [(ngModel)]="selectedSector"
              optionLabel="sfd_longlabel"
              formControlName="sector"
              placeholder="Selectionner filière"
            >
            </p-dropdown>
          </div>
        </div>
      </div>

      <div
        class="p-field p-field-column"
        *ngIf="typeCandidature === 1 || typeCandidature === 2"
      >
        <label
          style="margin-bottom: 1rem"
          class="p-field-label"
          for="field_school"
          >Unités présentées
          <!-- <span class="color-danger">{{
            fieldIsRequired(editForm.controls["typeCandidature"])
          }}</span> -->
        </label>

        <div class="p-field-checkbox-container">
          <div class="p-field-checkbox" *ngFor="let examunit of examunits">
            <p-checkbox
              formControlName="unity"
              [value]="examunit"
              [(ngModel)]="selectedUnity"
              [inputId]="examunit?.uni_id!"
              [readonly]="true"
            ></p-checkbox>
            <label [for]="examunit.uni_id"
              >{{ examunit.uni_longlabel }} -
              {{ examunit.uni_shortlabel }}</label
            >
          </div>
        </div>
      </div>

      <div class="p-field p-field-actions">
        <img src="/assets/loader.gif" alt="Loader" *ngIf="loaded" />
        <!-- <button
          *ngIf="!loaded"
          pButton
          pRipple
          type="button"
          label="Enregistrer"
          icon="pi pi-save"
          class="p-button-outlined"
          style="margin-right: auto"
          [disabled]="
            !editForm.dirty || (editForm.touched && !editForm.dirty) || loaded
          "
          (click)="edit()"
        ></button> -->
        <div></div>
        <button
          pButton
          pRipple
          type="button"
          label="Annuler"
          icon="pi pi-arrow-left"
          [disabled]="loaded"
          class="p-button-secondary p-button-text"
          (click)="goPrev()"
        ></button>

        <p-confirmPopup appendTo="body"></p-confirmPopup>

        <button
          pButton
          type="button"
          label="Enregister et continuer"
          [disabled]="loaded"
          (click)="showDialog()"
        ></button>
      </div>
    </form>
  </div>
</div>

<!-- confirm dialog -->
<p-dialog
  header="Comfirmation"
  [(visible)]="displayDialog"
  [style]="{ width: '500px' }"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
>
  <p style="display: flex; align-items: center">
    <i
      style="font-size: 2rem; color: red; margin-right: 1rem"
      class="pi pi-question-circle"
    ></i>
    Êtes-vous sure des informations renseignées ?
  </p>

  <ng-template pTemplate="footer">
    <p-button
      (click)="displayDialog = false"
      icon="pi pi-times"
      label="Annuler"
      styleClass="p-button-secondary p-button-text"
    ></p-button>
    <p-button
      (click)="confirm()"
      label="Confirmer"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>
