import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/account/auth.service';
import { IUser } from 'src/app/account/model/user.model';
import { config } from '../../../config';

@Component({
  selector: 'app-dashboard-validation-navbar',
  templateUrl: './dashboard-validation-navbar.component.html',
  styleUrls: ['./dashboard-validation-navbar.component.scss'],
})
export class DashboardValidationNavbarComponent implements OnInit {
  constructor(private authService: AuthService) {}

  currentUser?: IUser;
  dashboardLabel = '';
  ngOnInit(): void {
    this.authService.getAuthUser().subscribe((user) => {
      this.currentUser = user;
      if (
        this.currentUser?.role?.includes('ROLE_SCHOOL_ADMIN') ||
        this.currentUser?.role?.includes('ROLE_SCHOOL_USER')
      ) {
        this.dashboardLabel = this.currentUser?.usr_school?.sch_longlabel!;
      } else if (
        this.currentUser?.role?.includes('ROLE_REGIONAL_ADMIN') ||
        this.currentUser?.role?.includes('ROLE_REGIONAL_USER')
      ) {
        this.dashboardLabel = this.currentUser?.usr_regionalbranch?.bch_label!;
      }
    });
  }

  logout(): void {
    this.authService.logout();
  }
}
