import { Component, OnInit } from '@angular/core';
import { apply } from 'ramda';
import { AuthService } from 'src/app/account/auth.service';
import { IUser } from 'src/app/account/model/user.model';
import { DobService } from 'src/app/dob/dob.service';

export interface IApplicantByRegional {
  regional: string;
  origin: number;
  registration: number;
}

export interface IDobStatistic {
  all: number;
  validated: number;
  not_validated: number;
}

@Component({
  selector: 'app-dashboard-dob',
  templateUrl: './dashboard-dob.component.html',
  styleUrls: ['./dashboard-dob.component.scss'],
})
export class DashboardDobComponent implements OnInit {
  authUser?: IUser;

  totalApplicant = 0;
  totalMen = 0;
  totalValidated = 0;
  totalNotValidated = 0;
  totalWomen = 0;
  isLoading = false;
  applicantsRegionalStatistics?: IApplicantByRegional[];

  constructor(
    private authService: AuthService,
    private dobService: DobService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.isLoading = true;
    this.loadApplicantStatisticByRegional();
    this.authService.getAuthUser().subscribe((user) => {
      this.authUser = user;
      this.loadStatistics();
    });
  }

  loadStatistics(): void {
    if (this.authUser?.role?.includes('ROLE_DOB_ADMIN')) {
      this.loadDobAdminStatistics();
    } else {
      this.loadDobUserStatistics();
    }
  }

  loadDobUserStatistics(): void {
    this.dobService
      .getDobUserStatistics(this.authUser?.id!)
      .subscribe((res) => {
        this.setStatistics(res);
        this.isLoading = false;
      });
  }

  loadDobAdminStatistics(): void {
    this.dobService.getDobStatistics().subscribe((res) => {
      this.setStatistics(res);
      this.isLoading = false;
    });
  }

  setStatistics(statistic: IDobStatistic): void {
    this.totalApplicant = statistic.all;
    this.totalValidated = statistic.validated;
    this.totalNotValidated = statistic.not_validated;
  }

  loadApplicantStatisticByRegional(): void {
    this.dobService.getApplicationByRegional().subscribe((res) => {
      this.applicantsRegionalStatistics = res.body || [];
    });
  }
}
