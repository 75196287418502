<nav class="nav">
  <div class="nav__logo">
    <a href="/">
      <img
        style="cursor: pointer"
        src="/assets/logo_ers.png"
        alt="ETFPA Logo"
      />
    </a>
  </div>

  <ul class="nav__menu">
    <!-- <li class="nav__item">
      <a class="nav__link" [routerLink]="['/']">Accueil</a>
    </li> -->
    <!-- <li class="nav__item">
      <a href="" class="nav__link">Actualité</a>
    </li>
    <li class="nav__item">
      <a href="" class="nav__link">Contact</a>
    </li> -->

    <!-- <li class="nav__item">
      <a [routerLink]="['/account', 'login']" class="nav__link button"
        >Mon espace</a
      > -->
    <!-- <a [routerLink]="['/']" class="nav__link button">Mon espace</a> -->
    <!-- </li> -->
  </ul>
</nav>
