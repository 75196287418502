<!-- <p class="dashboard-title">
  Bienvenue, {{ currentUser?.last_name! | uppercase }}
  {{ currentUser?.first_name! | uppercase }}.
</p> -->

<!-- <div class="dashboard-section"> -->
<!-- <div class="dashboard-wrapper-grid-main"> -->
<div class="dashboard-section">
  <!-- <p class="dashboard-section-title">Statistique des enregistrements</p> -->
  <div class="dashboard-section__header">
    <div>&nbsp;</div>
    <a
      [routerLink]="['/dashboard', 'validators', 'listing']"
      class="link link-main"
    >
      Tout afficher <i class="pi pi-arrow-right"></i>
    </a>
  </div>
  <div class="dashboard-validation__statistics">
    <div class="dashboard-validation__statistics-item">
      <span class="dashboard-validation__statistics-value">{{
        registrationTotalRegistred
      }}</span>

      <div class="dashboard-validation__statistics-text">
        <span class="dashboard-validation__statistics-label"
          >Total des apprenants <span>enregistrés</span>
        </span>
        <!-- <a
          [routerLink]="['/dashboard', 'validators', 'listing']"
          class="dashboard-validation__statistics-link"
        >
          <i class="pi pi-arrow-right"></i>
        </a> -->
      </div>
    </div>

    <div class="dashboard-validation__statistics-item">
      <span class="dashboard-validation__statistics-value warning">{{
        registrationTotalPending
      }}</span>

      <div class="dashboard-validation__statistics-text">
        <span class="dashboard-validation__statistics-label"
          >Total des apprenants <span>en attente</span>
        </span>
        <!-- <a
          [routerLink]="['/dashboard', 'validators', 'listing']"
          class="dashboard-validation__statistics-link"
        >
          <i class="pi pi-arrow-right"></i>
        </a> -->
      </div>
    </div>

    <div class="dashboard-validation__statistics-item">
      <span class="dashboard-validation__statistics-value success">{{
        registrationTotalValidated
      }}</span>
      <div class="dashboard-validation__statistics-text">
        <span class="dashboard-validation__statistics-label"
          >Total des apprenants <span>validés</span>
        </span>
        <!-- <a
          [routerLink]="['/dashboard', 'validators', 'listing']"
          class="dashboard-validation__statistics-link"
        >
          <i class="pi pi-arrow-right"></i>
        </a> -->
      </div>
    </div>

    <div class="dashboard-validation__statistics-item">
      <span class="dashboard-validation__statistics-value danger">{{
        registrationTotalRejected
      }}</span>
      <div class="dashboard-validation__statistics-text">
        <span class="dashboard-validation__statistics-label"
          >Total des apprenants <span>rejetés</span>
        </span>
        <!-- <a
          [routerLink]="['/dashboard', 'validators', 'listing']"
          class="dashboard-validation__statistics-link"
        >
          <i class="pi pi-arrow-right"></i>
        </a> -->
      </div>
    </div>
  </div>
</div>
<!-- </div> -->

<!-- <div class="dashboard-section-sidebar dashboard-wrapper-grid-sidebar">
    <div class="dashboard-section">
      <p class="dashboard-title">Etablissement</p>
      <div class="dashboard-wrapper-grid-sidebar-item">
        <span class="dashboard-wrapper-grid-sidebar-item-label">Nom</span>
        <span
          class="dashboard-wrapper-grid-sidebar-item-value chip chip-main"
          >{{ currentUser?.usr_school?.sch_longlabel }}</span
        >
      </div>

      <div class="dashboard-wrapper-grid-sidebar-item">
        <span class="dashboard-wrapper-grid-sidebar-item-label">Code DSPS</span>
        <span
          class="dashboard-wrapper-grid-sidebar-item-value chip chip-main"
          >{{ currentUser?.usr_school?.sch_dspscode }}</span
        >
      </div>
    </div>
  </div> -->
<!-- </div> -->

<!-- <button (click)="testRefreshToken()">Test refresh token</button> -->

<div class="dashboard-loader" *ngIf="isLoading">
  <p-progressSpinner
    [style]="{ width: '50px', height: '50px' }"
  ></p-progressSpinner>
</div>
