export const accessmodelist = [
  {
    name: 'Par orientation',
    code: 'O',
  },
  {
    name: 'Par concours',
    code: 'C',
  },
  {
    name: 'Privé (Payant)',
    code: 'P',
  },
  {
    name: 'Cours du soir',
    code: 'S',
  },
];

export const accessModeBySchoolType = {
  private: [
    {
      name: 'Par orientation',
      code: 'O',
    },
    {
      name: 'Privé (Payant)',
      code: 'P',
    },
  ],
  public: [
    {
      name: 'Par orientation',
      code: 'O',
    },
    {
      name: 'Par concours',
      code: 'C',
    },
    {
      name: 'Cours du soir',
      code: 'S',
    },
  ],
};
