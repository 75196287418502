import { config } from '../../../config';
import { Router } from '@angular/router';

export function redirectTo(userRole: string, router: Router): void {
  if (userRole && config.authRoles.SCHOOL.includes(userRole?.toUpperCase())) {
    router.navigate(['/dashboard', 'validators', 'home']);
  } else if (
    userRole &&
    config.authRoles.REGIONAL.includes(userRole.toUpperCase())
  ) {
    router.navigate(['/dashboard', 'regional', 'home']);
  } else if (
    userRole &&
    config.authRoles.SUPERVISOR.includes(userRole.toUpperCase())
  ) {
    router.navigate(['/dashboard', 'supervisor', 'home']);
  } else if (
    userRole &&
    config.authRoles.ADMIN.includes(userRole.toUpperCase())
  ) {
    router.navigate(['/dashboard', 'admin', 'home']);
  } else if (
    userRole &&
    config.authRoles.DOB.includes(userRole.toUpperCase())
  ) {
    router.navigate(['/dashboard', 'dob', 'home']);
  } else if (
    userRole &&
    config.authRoles.BANK.includes(userRole.toUpperCase())
  ) {
    router.navigate(['/dashboard', 'bank', 'home']);
  }
}
