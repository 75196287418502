import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpResponse } from '@angular/common/http';
import { ILevel } from '../shared/model/level.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LevelService {
  private levelResourceURL = environment.apiURL + 'api/v1/levels';

  constructor(private http: HttpClient) {}

  findLevels(): Observable<HttpResponse<ILevel[]>> {
    return this.http.get<ILevel[]>(`${this.levelResourceURL}/`, {
      observe: 'response',
    });
  }
}
