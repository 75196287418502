<div class="dob-main-content-wrapper full-100">
    <div class="dob-main-content-header">
      <p class="dob-main-content-title">Fiche d'orientation</p>
      <p class="dob-main-content-sub-title">
        Assurez-vous d'avoir télécharger votre fiche d'orientation avant de
        terminer le processus.
      </p>
  
      <!-- <small>
        <span class="color-danger">(*) </span>
        Champs obligatoire
      </small> -->
    </div>
    <div
      *ngIf="isDocLoading"
      style="
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
      class="loader"
    >
      <p class="pi pi-spin pi-spinner" style="font-size: 4rem"></p>
    </div>
    <!-- <p-skeleton shape="rectangle" width="100%" height="400px"></p-skeleton> -->
    <iframe
      [src]="document"
      style="width: 100%; height: 600px; border: none"
    ></iframe>
  
    <div class="p-field p-field-actions loader" style="margin-top: 5rem">
      <!-- <div class="search-loader">
        <img src="/assets/loader.gif" alt="Loader" *ngIf="isLoading" />
      </div> -->
  
      <div>
        <button
          [hidden]="isDocLoading"
          pButton
          class="p-button-outlined p-button-main"
          (click)="downloadDocument()"
        >
          <i class="pi pi-download"></i>
          Télécharger
        </button>
      </div>
  
      <div style="float: right">
        <button
          pButton
          pRipple
          type="button"
          label="Precedent"
          icon="pi pi-arrow-left"
          [disabled]="isLoading"
          class="p-button-secondary p-button-text"
          [routerLink]="['/orientation', 'choice']"
          [hidden]="isDocLoading"
        ></button>
  
        <button
          [hidden]="isDocLoading"
          [disabled]="isDocLoading"
          pButton
          type="button"
          label="Terminer"
          (click)="showDialog()"
        ></button>
      </div>
    </div>
  </div>
  
  <div class="main-loader" *ngIf="isLoading">
    <p-progressSpinner></p-progressSpinner>
    <h2>Finalisation du processus..</h2>
  </div>
  
  <!-- confirm dialog -->
  <p-dialog
    header="Confirmation"
    [(visible)]="displayDialog"
    [style]="{ width: '550px' }"
    [resizable]="false"
    appendTo="body"
    [modal]="true"
  >
    <p
      style="
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
      "
    >
      <span style="display: flex; align-items: center; gap: 0.8rem">
        <i
          style="font-size: 2rem; color: red; margin-right: 1rem"
          class="pi pi-question-circle"
        ></i
        >Avez-vous télécharger votre fiche d'orientation ?.</span
      >
      <span class="link link-main" (click)="downloadDocument()">
        <i style="font-size: 1.5rem" class="pi pi-download"></i> je télécharge ma
        fiche</span
      >
    </p>
  
    <ng-template pTemplate="footer">
      <p-button
        (click)="displayDialog = false"
        icon="pi pi-times"
        label="Annuler"
        styleClass="p-button-secondary p-button-text"
      ></p-button>
      <p-button
        (click)="finish()"
        label="Confirmer"
        styleClass="p-button"
      ></p-button>
    </ng-template>
  </p-dialog>
  