import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MenuItem, Message } from 'primeng/api';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/account/auth.service';
import { IUser } from 'src/app/account/model/user.model';
import { LevelService } from 'src/app/services/level.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { SCHOOLSTUDYORDER } from 'src/app/shared/constant/schooltype.constant';
import { getLevel } from 'src/app/shared/constant/studylevel.constant';
import { IDiploma } from 'src/app/shared/model/diploma.model';
import { ILevel } from 'src/app/shared/model/level.model';
import { ISchool } from 'src/app/shared/model/school.model';
import { IStudentPopulation } from 'src/app/shared/model/studentpopulation.model';
import { IStudyField } from 'src/app/shared/model/studyfield.model';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';
import { StudentpopulationService } from '../../services/studentpopulation.service';
import { MessageService } from 'primeng/api';
import { Messages } from 'primeng/messages';

@Component({
  selector: 'app-dashboard-schools-staff',
  templateUrl: './dashboard-schools-staff.component.html',
  styleUrls: ['./dashboard-schools-staff.component.scss'],
})
export class DashboardSchoolsStaffComponent implements OnInit {
  items: MenuItem[] = [];
  home: MenuItem = {};
  isLoading = false;
  displayNewStaffDialog = false;

  studentPopulations: IStudentPopulation[] = [];
  school?: ISchool;
  authUser?: IUser;

  diplomas: IDiploma[] = [];
  selectedDiploma?: IDiploma;

  studyfields: IStudyField[] = [];
  selectedStudyfield?: string;

  levels: ILevel[] = [];
  selectedLevel?: ILevel;

  schoolstudyorderList: any[] = [];
  selectedSchoolStudyOrder = '';

  editForm = this.fb.group({
    pop_studyOrder: [null, [Validators.required]],
    pop_diploma: [null, [Validators.required]],
    pop_studyfield: [null, [Validators.required]],
    pop_level: [null, [Validators.required]],
    pop_count: [null, [Validators.required]],
  });

  msgs: Message[] = [];

  constructor(
    private studentPopulationService: StudentpopulationService,
    private authService: AuthService,
    private fb: FormBuilder,
    private registrationService: RegistrationService,
    private levelService: LevelService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.isLoading = true;

    this.authService.getAuthUser().subscribe((user) => {
      this.authUser = user;
      this.school = this.authUser?.usr_school;
      this.loadStaff();
      this.loadSchoolDiplomas(this.school?.sch_id!);
      this.loadLevel();
      this.loadSchollOrderList();
    });
    this.items = [
      {
        label: 'Effectif des apprenants',
      },
    ];
    this.home = {
      label: 'Dashboard',
      icon: 'pi pi-home',
      routerLink: '/dashboard/validators/home',
    };
  }

  loadStaff(): void {
    this.studentPopulationService
      .findBySchool(this.school?.sch_id!)
      .subscribe((res) => {
        this.studentPopulations = res.body || [];
        this.isLoading = false;
      });
  }

  handleAddNewStudentPopulation(): void {}

  showAddNewDialog(): void {
    this.displayNewStaffDialog = true;
  }

  closeDialog(): void {
    this.displayNewStaffDialog = false;
    this.clearDialog();
  }

  clearDialog(): void {
    this.selectedDiploma = undefined;
    this.selectedLevel = undefined;
    this.selectedStudyfield = undefined;
    this.selectedSchoolStudyOrder = '';
  }

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }

  loadSchoolDiplomas(school_id: string): void {
    this.registrationService
      .findSchoolDiplomas(school_id)
      .subscribe((res) => (this.diplomas = res.body || []));
  }

  loadSchoolDiplomaStudyFields(school_id: string, diploma_id: string): void {
    this.registrationService
      .findDiplomaStudyFields(school_id, diploma_id)
      .subscribe((res) => {
        this.studyfields = res.body || [];
      });
  }

  handleDiplomaChange(): void {
    this.selectedStudyfield = '';
    this.selectedLevel = undefined;

    this.loadSchoolDiplomaStudyFields(
      this.school?.sch_id!,
      this.selectedDiploma?.dip_id!
    );

    this.handleLevel();
  }

  loadLevel(): void {
    this.levelService
      .findLevels()
      .subscribe((res) => (this.levels = res.body || []));
  }

  handleLevel(): void {
    if (this.selectedSchoolStudyOrder && this.selectedDiploma) {
      this.levels = getLevel(
        Number(this.selectedSchoolStudyOrder),
        this.selectedDiploma
      );
    }
  }

  loadSchollOrderList(): void {
    if (this.school?.sch_istech && this.school?.sch_isprof) {
      this.schoolstudyorderList = SCHOOLSTUDYORDER;
    } else if (this.school?.sch_istech && !this.school?.sch_isprof) {
      this.schoolstudyorderList = SCHOOLSTUDYORDER.filter(
        (order) => order.code === 1
      );
    } else if (!this.school?.sch_istech && this.school?.sch_isprof) {
      this.schoolstudyorderList = SCHOOLSTUDYORDER.filter(
        (order) => order.code === 2
      );
    }
  }

  handleStudyOrderChange() {
    const school_id = this.school?.sch_id!;
    this.diplomas = [];
    this.studyfields = [];

    this.selectedDiploma = undefined;
    this.selectedStudyfield = undefined;
    this.selectedLevel = undefined;

    this.registrationService
      .findSchoolDiplomas(school_id)
      .pipe(
        map((value) => {
          return value.body?.filter((item) => {
            if (Number(this.selectedSchoolStudyOrder) === 1) {
              return item.dip_shortlabel?.toLocaleUpperCase() === 'BAC';
            } else {
              return item.dip_shortlabel?.toLocaleUpperCase() !== 'BAC';
            }
          });
        })
      )
      .subscribe((diplomas) => {
        this.diplomas = diplomas || [];
      });
  }

  save(): void {
    this.isLoading = true;
    this.studentPopulationService
      .save(this.school?.sch_id!, this.editForm.value)
      .subscribe({
        next: (res) => {
          const data = res.body;
          console.log(data);
          if (data.pop_id) {
            this.displayNewStaffDialog = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Effectif apprenant',
              detail: 'Vos informations ont été enregistréss avec succès',
            });
            this.loadStaff();
          } else if (data.status === 404) {
            this.msgs = [
              {
                severity: 'error',
                detail: data?.message,
              },
            ];
          }
          this.isLoading = false;
        },

        error: (err) => {
          this.msgs.push({
            severity: 'error',
            detail:
              "Une erreur est survenue lors de l'enregistrement, veuillez réessayé",
          });
          this.isLoading = false;
        },
      });
  }
}
