<form [formGroup]="editForm">
  <p-toast key="studentInfoEdited"></p-toast>
  <div class="p-flex-between">
    <div class="p-field p-field-column">
      <label class="p-field-label" for="field_lastname"
        >Nom
        <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
      </label>
      <input
        type="text"
        pInputText
        formControlName="lastName"
        id="field_lastname"
        placeholder="Votre nom"
      />
    </div>

    <div class="p-field p-field-column">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_firstname"
          >Prénoms
          <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
        </label>
        <input
          type="text"
          pInputText
          formControlName="firstName"
          id="field_firstname"
          placeholder="Votre prénom"
        />
      </div>
    </div>
  </div>

  <div class="p-flex-between">
    <div class="p-field p-field-column">
      <label class="p-field-label" for="field_birthDate"
        >Date de naissance
        <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
      </label>
      <input
        type="date"
        pInputText
        formControlName="birthDate"
        id="field_birthDate"
        placeholder="Votre date de naissance"
      />
    </div>

    <div class="p-field p-field-column">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_birthPlace"
          >Lieu de naissance
          <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
        </label>
        <input
          type="text"
          pInputText
          formControlName="birthPlace"
          id="field_birthPlace"
          placeholder="Votre lieu de naissance"
        />
      </div>
    </div>
  </div>

  <div class="p-flex-between">
    <div class="p-field p-field-column">
      <label class="p-field-label" for="field_gender"
        >Sexe
        <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
      </label>
      <p-dropdown
        id="field_gender"
        [options]="genders"
        [(ngModel)]="selectedGender"
        optionLabel="name"
        optionValue="code"
        formControlName="gender"
        placeholder="Selectionner sexe"
      >
      </p-dropdown>
    </div>

    <div class="p-field p-field-column">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_nationality"
          >Nationalité
          <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
        </label>
        <input
          type="text"
          pInputText
          formControlName="nationality"
          id="field_nationality"
          placeholder="Votre nationalité"
        />
      </div>
    </div>
  </div>

  <div class="p-flex-between">
    <div class="p-field p-field-column">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_email"
          >Email
          <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
        </label>
        <input
          type="text"
          pInputText
          formControlName="email"
          id="field_email"
          placeholder="Votre email"
        />
      </div>
    </div>

    <div class="p-field p-field-column">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_phone1"
          >Téléphone 1
          <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
        </label>
        <input
          type="text"
          pInputText
          formControlName="phone1"
          id="field_phone1"
          placeholder="Téléphone 1"
        />
      </div>
    </div>

    <div class="p-field p-field-column">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_phone2"
          >Téléphone 2
          <!-- <span class="color-danger">{{
              fieldIsRequired(editForm.controls["lastName"])
            }}</span> -->
        </label>
        <input
          type="text"
          pInputText
          formControlName="phone2"
          id="field_phone1"
          placeholder="Téléphone 2"
        />
      </div>
    </div>
  </div>

  <div class="p-fielf p-field-image">
    <div>
      <p-image [src]="studentPictureURL" alt="Image" width="200"></p-image>
    </div>

    <div class="p-field-image__description">
      <p-fileUpload
        name="myfile[]"
        url="./upload.php"
        cancelLabel="Annuler"
        uploadLabel="sauvegarder"
        chooseLabel="charger"
        [disabled]="isSaving"
      ></p-fileUpload>
      <ul>
        <li>- Assurez-vouz que le fichier ne dépase pas 2Mb</li>
        <li>- Le fichier doit faire 24x24 de taile</li>
      </ul>
    </div>
  </div>

  <div class="p-field p-field-actions">
    <button
      pButton
      pRipple
      type="button"
      label="Enregistrer"
      icon="pi pi-save"
      class="p-button-outlined"
      style="margin-right: auto"
      [disabled]="!editForm.dirty"
      (click)="edit()"
    ></button>

    <button
      pButton
      pRipple
      type="button"
      label="Annuler"
      icon="pi pi-arrow-left"
      class="p-button-secondary p-button-text"
      (click)="goPrev()"
      [disabled]="isSaving"
    ></button>

    <button
      [disabled]="isSaving || editForm.invalid"
      pButton
      type="button"
      label="Suivant"
      (click)="openConfirmDialog()"
    ></button>
  </div>
</form>

<!-- confirm dialog -->
<p-dialog
  header="Comfirmation"
  [(visible)]="showDialog"
  [style]="{ width: '40vw' }"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
>
  <p style="display: flex; align-items: center">
    <i
      style="font-size: 2rem; color: red; margin-right: 1rem"
      class="pi pi-question-circle"
    ></i>
    Êtes-vous sure des informations renseignées ?
  </p>

  <ng-template pTemplate="footer">
    <p-button
      (click)="showDialog = false"
      icon="pi pi-times"
      label="Annuler"
      styleClass="p-button-secondary p-button-text"
    ></p-button>
    <p-button
      (click)="goToNext()"
      label="Comfirmer"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>
