import { IExamenBreadcrumb } from '../model/examen-breadcrumb.model';
import { freeCandidateSteps, steps } from '../constant/steps.constant';
import { sidebarItems } from '../constant/registration-sidebar.constant';
import { BehaviorSubject } from 'rxjs';
import {
  BREADCRUMB_ITEMS_KEY,
  FREE_CANDIDATE_ITEMS_KEY,
  SIDEBAR_ITEMS_KEY,
} from '../constant/step.constant';

// export let isAllStepValidated = JSON.parse(
//   sessionStorage.getItem(BREADCRUMB_ITEMS_KEY)!
// )?.data.every((item: IExamenBreadcrumb) => item.validated === true);

// export let checkValidatedStep$ = new BehaviorSubject<boolean>(
//   JSON.parse(sessionStorage.getItem(BREADCRUMB_ITEMS_KEY)!)?.data.every(
//     (item: IExamenBreadcrumb) => item.validated === true
//   )
// );

// export function setValidatedStep(value: boolean): void {
//   checkValidatedStep$.next(value);
// }

export const activateStep = (steps: any, storage: Storage): void => {
  let cur = steps.step;
  const data: IExamenBreadcrumb[] = steps.data;

  const newSteps = data.map((item: IExamenBreadcrumb) => {
    if (item.step === Number(cur)) {
      item.validated = true;
      item.active = false;
    }

    return item;
  });

  cur += 1;
  storage.setItem(
    BREADCRUMB_ITEMS_KEY,
    JSON.stringify({ step: cur, data: newSteps })
  );
};

export const setActiveStep = (data: IExamenBreadcrumb[], cur: number): void => {
  data.forEach((item) => {
    if (item.step === cur) {
      item.active = true;
    }
  });
};

export function activeSidebarItem(items: any, storage: Storage): void {
  let cur = items?.step;
  const data: IExamenBreadcrumb[] = items?.data;

  const newSteps = data.map((item: IExamenBreadcrumb) => {
    if (item.step === Number(cur)) {
      item.validated = true;
      item.active = false;
    }
    return item;
  });

  cur += 1;
  setActiveStep(newSteps, cur);
  storage.setItem(
    SIDEBAR_ITEMS_KEY,
    JSON.stringify({ step: cur, data: newSteps })
  );
}

export function setPrevStep(steps: any, storage: Storage): void {
  let cur = steps.step;
  const data: IExamenBreadcrumb[] = steps.data;

  cur -= 1;
  storage.setItem(
    BREADCRUMB_ITEMS_KEY,
    JSON.stringify({ step: cur, data: data })
  );
}

export function setStepDataToSS(storage: Storage): void {
  if (!storage.getItem(BREADCRUMB_ITEMS_KEY)) {
    const stepData = steps;
    const currentStep = steps.filter((element) => element.active)[0]?.step;

    storage.setItem(
      BREADCRUMB_ITEMS_KEY,
      JSON.stringify({ step: currentStep, data: stepData })
    );
  }
}

export function setRegistrationPrevStep(steps: any, storage: Storage): void {
  let cur = steps.step;
  const data: IExamenBreadcrumb[] = steps.data;

  data.forEach((item: IExamenBreadcrumb) => {
    if (item.step === Number(cur)) {
      item.active = false;
    }
  });
  cur -= 1;

  setActiveStep(data, cur);
  storage.setItem(SIDEBAR_ITEMS_KEY, JSON.stringify({ step: cur, data: data }));
}

export function setRegistrationStep(storage: Storage): void {
  if (!storage.getItem(SIDEBAR_ITEMS_KEY)) {
    const data = sidebarItems;
    const currentStep = steps.filter((element) => element.active)[0]?.step;
    storage.setItem(
      SIDEBAR_ITEMS_KEY,
      JSON.stringify({ step: currentStep, data: data })
    );
  }
}

export function isStepFinished(data: IExamenBreadcrumb[]): boolean {
  return data.every((item) => item.validated);
}

export function finishStep(steps: any, storage: Storage): void {
  let cur = steps.step;
  const data: IExamenBreadcrumb[] = steps.data;

  const newSteps = data.map((item: IExamenBreadcrumb) => {
    item.validated = true;
    item.active = false;

    return item;
  });

  storage.setItem(
    BREADCRUMB_ITEMS_KEY,
    JSON.stringify({ step: cur, data: newSteps })
  );
}

// ############ FREE CANDIDATE STEP ############
export function setFreeCandidateStepDataToSS(storage: Storage): void {
  if (!storage.getItem(FREE_CANDIDATE_ITEMS_KEY)) {
    const stepData = freeCandidateSteps;
    const currentStep = freeCandidateSteps.filter(
      (element) => element.active
    )[0]?.step;

    storage.setItem(
      FREE_CANDIDATE_ITEMS_KEY,
      JSON.stringify({ step: currentStep, data: stepData })
    );
  }
}

export function goToPreviousStep(
  steps: any,
  itemKey: string,
  storage: Storage
): void {
  let cur = steps.step;
  const data: IExamenBreadcrumb[] = steps.data;

  data.forEach((item: IExamenBreadcrumb) => {
    if (item.step === Number(cur)) {
      item.active = false;
    }
  });
  cur -= 1;

  setActiveStep(data, cur);
  storage.setItem(itemKey, JSON.stringify({ step: cur, data: data }));
}

export function goToNextStep(
  steps: any,
  itemKey: string,
  storage: Storage
): void {
  let cur = steps.step;
  const data: IExamenBreadcrumb[] = steps.data;

  data.forEach((item: IExamenBreadcrumb) => {
    if (item.step === Number(cur)) {
      item.active = false;
    }
  });
  cur += 1;

  setActiveStep(data, cur);
  storage.setItem(itemKey, JSON.stringify({ step: cur, data: data }));
}

export function validateStep(
  items: any,
  itemKey: string,
  storage: Storage
): void {
  let cur = items?.step;
  const data: IExamenBreadcrumb[] = items?.data;

  const newSteps = data.map((item: IExamenBreadcrumb) => {
    if (item.step === Number(cur)) {
      item.validated = true;
      item.active = false;
    }
    return item;
  });

  cur += 1;
  setActiveStep(newSteps, cur);
  storage.setItem(itemKey, JSON.stringify({ step: cur, data: newSteps }));
}

export const currentStep = (steps: IExamenBreadcrumb[]): number => {
  let activeStep = 0;
  steps.forEach((item) => {
    if (item.active) {
      activeStep = item.step!;
    }
  });
  return activeStep;
};
