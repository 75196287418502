import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';
import { AuthService, IToken } from '../auth.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { config } from '../../../config';

import { environment } from 'src/environments/environment';
import { RecaptchaService } from '../recaptcha.service';
import { RecaptchaComponent, RecaptchaErrorParameters } from 'ng-recaptcha';
import { redirectTo } from 'src/app/shared/utils/http.utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isSaving = false;
  editForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required]],
  });

  msgs: any[] = [];

  CAPTCHA_SITE_KEY = environment.captchaSiteKey;

  isNotRobot?: boolean = undefined;

  @ViewChild('captchaElem') captchaElem?: RecaptchaComponent;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private recaptchaService: RecaptchaService
  ) {}

  ngOnInit(): void {}

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }

  handleEnterKeyDownOnPassword(): void {
    this.msgs = [];
    this.editForm.controls['email'].setErrors({ firstError: null });
    this.editForm.controls['email'].updateValueAndValidity();

    if (
      this.editForm.invalid &&
      this.editForm.controls['email'].value != null
    ) {
      this.msgs = [
        {
          severity: 'error',
          detail: 'Veuillez entrer un email valide',
        },
      ];
    } else if (
      this.editForm.invalid &&
      this.editForm.controls['email'].value == null
    ) {
      this.msgs = [
        {
          severity: 'error',
          detail: 'Veuillez remplir tous les champs',
        },
      ];
    } else {
      this.handleLogin();
    }
  }

  handleEnterKeyDownOnEmail(): void {
    this.msgs = [];
    this.editForm.controls['email'].setErrors({ firstError: null });
    this.editForm.controls['email'].updateValueAndValidity();
    if (
      this.editForm.invalid &&
      this.editForm.controls['password'].value != null
    ) {
      this.msgs = [
        {
          severity: 'error',
          detail: 'Veuillez entrer un email valide',
        },
      ];
    } else if (
      this.editForm.invalid &&
      this.editForm.controls['password'].value == null
    ) {
      this.msgs = [
        {
          severity: 'error',
          detail: 'Veuillez remplir tous les champs',
        },
      ];
    } else {
      this.handleLogin();
    }
  }

  handleLogin(): void {
    this.isSaving = true;
    this.msgs = [];
    const email = this.editForm.controls['email'].value;
    const password = this.editForm.controls['password'].value;

    this.authService.login(email, password).subscribe({
      next: (res: IToken) => {
        this.isSaving = false;
        const userRole = jwt_decode<any>(res?.access!)?.role;
        redirectTo(userRole, this.router);
      },
      error: () => {
        this.isSaving = false;
        this.msgs = [
          {
            severity: 'error',
            detail: 'Email ou mot de passe incorrecte',
          },
        ];
      },
    });
  }

  resolved(event: any): void {
    const body = {
      token: event,
    };
    this.recaptchaService.sendToken(body).subscribe((res) => {
      const data = res.body;
      if (data['result'] === 1) {
        this.isNotRobot = true;
      } else if (data['result'] === -1) {
        this.isNotRobot = false;
        this.captchaElem?.reset();
        this.msgs = [
          {
            severity: 'error',
            detail: data['message'],
          },
        ];
      } else {
        this.msgs = [
          {
            severity: 'error',
            detail: 'Impossibe de vérifier le token, veuillez réessayer!',
          },
        ];
      }
    });
  }

  captchaErrored(event: RecaptchaErrorParameters): void {
    this.captchaElem?.reset();
  }
}
