<div class="registration-content-wrapper">
  <div class="registration-content-item">
    <div class="registration-content-item-header">
      <h3>Récapitulatif de l'enregistrement</h3>
      <p>
        Veuillez vérifier l'exactitude de vos informations et cliquez ensuite
        sur le bouton
        <span class="color color-main" style="font-weight: bold"
          >Terminer l'enregistrement</span
        >
        pour finaliser votre enregistrement. En cas d'erreur dans la saisie,
        cliquer sur le bouton
        <span class="color color-main bold" style="font-weight: bold"
          >Précédent</span
        >
        pour retourner aux étapes précédentes et apporter les corrections
        nécessaires.
      </p>
    </div>

    <form>
      <p-card>
        <!-- <p-tabPanel> -->
        <!-- <ng-template pTemplate="header">
            <i
              class="pi pi-user"
              style="font-size: 1.6rem; margin-right: 1rem"
            ></i>
            <span>Détails infos apprenant</span>
          </ng-template> -->
        <div class="detail-infos-wrapper">
          <p-divider align="left">
            <span class="p-tag"> Infos apprenant </span>
          </p-divider>

          <div class="detail-infos-block">
            <div class="detail-infos-item">
              <p-image
                *ngIf="studentPictureURL"
                [src]="studentPictureURL"
                alt="Image"
                width="120"
                height="150"
                [style]="{ 'object-fit': 'cover' }"
              ></p-image>
            </div>

            <div class="detail-infos-item">
              <span class="title">Matricule MENA</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_menaref
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title"></span>
              <span class="content"></span>
            </div>
          </div>

          <!-- <div class="detail-infos-block">
            <div class="detail-infos-item">
              <span class="title">Matricule MENA</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_menaref
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title"></span>
              <span class="content"></span>
            </div>

            <div class="detail-infos-item">
              <span class="title"></span>
              <span class="content"></span>
            </div>
          </div> -->

          <div class="detail-infos-block">
            <div class="detail-infos-item">
              <span class="title">Nom</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_lastname
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Prénoms</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_firstname
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Nationalité</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_nationality?.nat_label
              }}</span>
            </div>
          </div>

          <div class="detail-infos-block">
            <div class="detail-infos-item">
              <span class="title">Date de naissance</span>
              <span class="content">{{ birthDate }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Lieu de naissance</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_birthplace
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Genre</span>
              <span class="content">{{ studentGenderLabel }}</span>
            </div>
          </div>

          <div class="detail-infos-block">
            <div class="detail-infos-item">
              <span class="title">Numéro de l'acte de naissance</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_birthdocnumber
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Lieu d'établissement de l'acte</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_birthdoclocation
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Date d'établissement de l'acte</span>
              <span class="content">{{ docNumberDate }}</span>
            </div>
          </div>

          <div class="detail-infos-block">
            <div class="detail-infos-item">
              <span class="title">Email</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_email &&
                registratedStudent?.reg_student?.std_email != "null"
                  ? registratedStudent?.reg_student?.std_email
                  : "-"
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Téléphone 1</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_phone1
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Téléphone 2</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_phone2 &&
                registratedStudent?.reg_student?.std_phone2 != "null"
                  ? registratedStudent?.reg_student?.std_phone2
                  : "-"
              }}</span>
            </div>
          </div>

          <!-- <p-divider align="left">
            <span class="p-tag"> Infos parent </span>
          </p-divider> -->

          <div class="detail-infos-block">
            <div class="detail-infos-item">
              <span class="title">Nom et prénoms du père</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_fatherfullname
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Nom et prénoms de la mère</span>
              <span class="content">{{
                registratedStudent?.reg_student?.std_motherfullname
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title"></span>
              <span class="content"></span>
            </div>
          </div>

          <p-divider align="left">
            <span class="p-tag"> Infos inscription </span>
          </p-divider>

          <div class="detail-infos-block">
            <div class="detail-infos-item">
              <span class="title">Etablissement</span>
              <span class="content">{{
                registratedStudent?.reg_school?.sch_longlabel
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Diplôme préparé</span>
              <span class="content">{{
                registratedStudent?.reg_diploma?.dip_longlabel
              }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Filière</span>
              <span class="content">{{
                registratedStudent?.reg_studyfield?.sfd_longlabel
              }}</span>
            </div>
          </div>

          <div class="detail-infos-block">
            <div class="detail-infos-item">
              <span class="title">Niveau</span>
              <span class="content">{{ studentLevelLabel }}</span>
            </div>
            <div class="detail-infos-item">
              <span class="title">Mode d'accès ETFP</span>
              <span class="content">{{ studentAccessModeLabel }}</span>
            </div>

            <div class="detail-infos-item">
              <span class="title">Année accadémique</span>
              <span class="content">{{
                registratedStudent?.reg_year?.yrs_academic
              }}</span>
            </div>
          </div>
        </div>
        <!-- </p-tabPanel> -->
      </p-card>

      <div class="p-field p-field-actions" style="margin-top: 4rem">
        <button
          pButton
          pRipple
          type="button"
          label="Précédent"
          icon="pi pi-arrow-left"
          class="p-button-secondary p-button-text"
          (click)="goPrev()"
        ></button>

        <button
          pButton
          type="button"
          label="Terminer l'enregistrement"
          (click)="showDialog()"
        ></button>
      </div>
    </form>
  </div>
</div>

<!-- confirm dialog -->
<p-dialog
  header="Comfirmation"
  [(visible)]="displayDialog"
  [style]="{ width: '500px' }"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
>
  <p style="display: flex; align-items: center">
    <i
      style="font-size: 2rem; color: red; margin-right: 1rem"
      class="pi pi-question-circle"
    ></i>
    Confirmez-vous toutes les informations ?
  </p>

  <ng-template pTemplate="footer">
    <p-button
      (click)="displayDialog = false"
      icon="pi pi-times"
      label="Annuler"
      styleClass="p-button-secondary p-button-text"
    ></p-button>
    <p-button
      (click)="confirm()"
      label="Confirmer"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>

<div class="main-loader" *ngIf="isLoading">
  <p-progressSpinner></p-progressSpinner>
  <h2>Finalisation de l'enregistrement ...</h2>
</div>
