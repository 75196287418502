export function getAccessMode(mode: string): string {
  let result = '';
  switch (mode) {
    case 'O':
      result = 'Par orientation';
      break;

    case 'C':
      result = 'Par concours';
      break;

    case 'P':
      result = 'Privé (payant)';
      break;

    default:
      result = 'Cours du soir';
      break;
  }

  return result;
}
