import { APP_ID, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ILocation } from '../shared/model/location.model';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private resourceURL = environment.apiURL + 'api/v1/locations';

  constructor(private http: HttpClient) {}

  getLocations(): Observable<HttpResponse<ILocation[]>> {
    return this.http.get<ILocation[]>(`${this.resourceURL}/`, {
      observe: 'response',
    });
  }
}
