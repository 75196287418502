import { IExamenBreadcrumb } from '../model/examen-breadcrumb.model';

export const sidebarItems = [
  {
    icon: 'pi pi-search',
    title: 'Recherche',
    step: 1,
    active: true,
    validated: false,
    validatedIcon: 'pi pi-check',
  },
  {
    icon: 'pi pi-user',
    title: 'infos apprenant',
    step: 2,
    active: false,
    validated: false,
    validatedIcon: 'pi pi-check',
  },
  {
    icon: 'pi pi-list',
    title: 'infos inscription',
    step: 3,
    active: false,
    validated: false,
    validatedIcon: 'pi pi-check',
  },
  {
    icon: 'pi pi-check-circle',
    title: 'Résumé',
    step: 4,
    active: false,
    validated: false,
    validatedIcon: 'pi pi-check',
  },
].sort((a: IExamenBreadcrumb, b: IExamenBreadcrumb) => {
  return a.step! < b.step! ? -1 : 1;
});
