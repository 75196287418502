import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SIDEBAR_ITEMS_KEY } from 'src/app/shared/constant/step.constant';
import {
  setRegistrationPrevStep,
  setRegistrationStep,
  setStepDataToSS,
} from 'src/app/shared/utils/step.utils';
import { StepService } from '../step.service';
import { RegistrationService } from '../../services/registration.service';
import { IRegistration } from 'src/app/shared/model/registration.model';
import { getLevel } from 'src/app/shared/utils/level.utils';
import { getAccessMode } from 'src/app/shared/utils/accessnode.utils';
import { getGenderLabel } from 'src/app/shared/utils/gender.utils';
import * as moment from 'moment';
import { SessionStorageService } from '../../session-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-registration-validation',
  templateUrl: './registration-validation.component.html',
  styleUrls: ['./registration-validation.component.scss'],
})
export class RegistrationValidationComponent implements OnInit {
  registratedStudent?: IRegistration;
  studentLevelLabel = '';
  studentAccessModeLabel = '';
  studentGenderLabel = '';
  studentPictureURL = '';
  displayDialog = false;
  isLoading = false;
  birthDate: any;
  docNumberDate: any;

  constructor(
    private router: Router,
    private sidebarStepService: StepService,
    private registrationService: RegistrationService,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnInit(): void {
    const ref = JSON.parse(
      this.sessionStorage.getItem('registration')!
    )?.codeMena;
    const year = JSON.parse(this.sessionStorage.getItem('activeYear')!);

    this.registrationService.retrieveStudent(ref, year).subscribe((res) => {
      this.registratedStudent = res.body!;
      this.studentLevelLabel = getLevel(this.registratedStudent?.reg_level!);
      this.studentAccessModeLabel = getAccessMode(
        this.registratedStudent?.reg_etfpaccessmode!
      );
      this.studentGenderLabel = getGenderLabel(
        this.registratedStudent?.reg_student?.std_gender!
      );
      this.studentPictureURL = `${environment.mediaURL}${this.registratedStudent?.reg_student?.std_picture}`;

      moment.locale('fr');
      this.birthDate = moment(
        this.registratedStudent?.reg_student?.std_birthdate!
      ).format('DD MMMM yy');

      this.docNumberDate = moment(
        this.registratedStudent?.reg_student?.std_birthdocdate!
      ).format('DD MMMM yy');
    });
  }

  finish(): void {
    this.router.navigate(['/', 'registration', 'finish']);
    this.sessionStorage.clear();
    setStepDataToSS(this.sessionStorage);
    setRegistrationStep(this.sessionStorage);
  }

  goPrev(): void {
    setRegistrationPrevStep(this.loadSidebarItemsFromLS(), this.sessionStorage);
    this.sidebarStepService.init(this.sessionStorage);
    this.router.navigate(['/', 'registration', 'infos-class']);
  }

  loadSidebarItemsFromLS(): any {
    return JSON.parse(this.sessionStorage.getItem(SIDEBAR_ITEMS_KEY)!);
  }

  confirm(): void {
    this.isLoading = true;
    this.displayDialog = false;
    setTimeout(() => {
      this.isLoading = false;
      this.finish();
    }, 2000);
  }

  showDialog(): void {
    this.displayDialog = true;
  }
}
