import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { AccountRoutingModule } from './account-routing.module';
import { AuthenticationComponent } from './authentication/authentication.component';
import { NavComponent } from './nav/nav.component';
import { RegisterComponent } from './register/register.component';
import { ActivatedComponent } from './activated/activated.component';



@NgModule({
  declarations: [
    LoginComponent,
    AuthenticationComponent,
    NavComponent,
    RegisterComponent,
    ActivatedComponent
  ],
  imports: [
    SharedModule, AccountRoutingModule
  ]
})
export class AccountModule { }
