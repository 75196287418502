import { NgModule } from '@angular/core';
import { DashboardValidationComponent } from './dashboard-validation/dashboard-validation.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from '../shared/shared.module';
import { DasboardValidationLayoutComponent } from './dasboard-validation-layout/dasboard-validation-layout.component';
import { DashboardValidationSidebarComponent } from './dashboard-validation-sidebar/dashboard-validation-sidebar.component';
import { DashboardValidationNavbarComponent } from './dashboard-validation-navbar/dashboard-validation-navbar.component';
import { DashboardValidationCandidatureComponent } from './dashboard-validation-candidature/dashboard-validation-candidature.component';
import { DashboardValidationListingComponent } from './dashboard-validation-listing/dashboard-validation-listing.component';
import { DashboardRegionalComponent } from './dashboard-regional/dashboard-regional.component';
import { DashboardSchoolsComponent } from './dashboard-schools/dashboard-schools.component';
import { DashboardSupervisorComponent } from './dashboard-supervisor/dashboard-supervisor.component';
import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
import { DashboardSchoolsStaffComponent } from './dashboard-schools-staff/dashboard-schools-staff.component';
import { DashboardBankComponent } from './dashboard-bank/dashboard-bank.component';
import { DashboardBankInfoComponent } from './dashboard-bank-info/dashboard-bank-info.component';
import { DashboardBankFiliereComponent } from './dashboard-bank-filiere/dashboard-bank-filiere.component';
import { DashboardBankConfirmationComponent } from './dashboard-bank-confirmation/dashboard-bank-confirmation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashbordFreeCandidateComponent } from './dashbord-free-candidate/dashbord-free-candidate.component';
import { DashboardBankValidationComponent } from './dashboard-bank-validation/dashboard-bank-validation.component';
import { DashboardAdminAccountConfigureComponent } from './dashboard-admin-account-configure/dashboard-admin-account-configure.component';
import { DashboardAdminAccountConfigureEditComponent } from './dashboard-admin-account-configure-edit/dashboard-admin-account-configure-edit.component';
import { DialogService } from 'primeng/dynamicdialog';
import { DashboardDobComponent } from './dashboard-dob/dashboard-dob.component';
import { DashboardDobApplicantsComponent } from './dashboard-dob-applicants/dashboard-dob-applicants.component';
import { DashboardDexcUpdateComponent } from './dashboard-dexc-update/dashboard-dexc-update.component';
import { DashboardDexcUpdateSearchComponent } from './dashboard-dexc-update-search/dashboard-dexc-update-search.component';
import { DashboardDexcUpdateStudentComponent } from './dashboard-dexc-update-student/dashboard-dexc-update-student.component';
import { DashboardDexcUpdateClassComponent } from './dashboard-dexc-update-class/dashboard-dexc-update-class.component';
import { DashboardDexcUpdateFinishComponent } from './dashboard-dexc-update-finish/dashboard-dexc-update-finish.component';
import { DashboardDexcUpdateSidebarComponent } from './dashboard-dexc-update-sidebar/dashboard-dexc-update-sidebar.component';
import { DashboardRegionalOrientationValidationComponent } from './dashboard-regional-orientation-validation/dashboard-regional-orientation-validation.component';
import { DashboardRegionalOrientationValidationListingComponent } from './dashboard-regional-orientation-validation-listing/dashboard-regional-orientation-validation-listing.component';
import { DashboardRegionalOrientationValidationChoicesListComponent } from './dashboard-regional-orientation-validation-choices-list/dashboard-regional-orientation-validation-choices-list.component';
import { DashboardRegionalOrientationValidationApplicantFormComponent } from './dashboard-regional-orientation-validation-applicant-form/dashboard-regional-orientation-validation-applicant-form.component';
import { DashboardRegionalOrientationEditNationalityComponent } from './dashboard-regional-orientation-edit-nationality/dashboard-regional-orientation-edit-nationality.component';
import { DashboardRegionalOrientationEditBirthdateComponent } from './dashboard-regional-orientation-edit-birthdate/dashboard-regional-orientation-edit-birthdate.component';
import { DashboardRegionalSettingsApplicantCodeComponent } from './dashboard-regional-settings-applicant-code/dashboard-regional-settings-applicant-code.component';
import { DashboardDobUserRegistrationComponent } from './dashboard-dob-user-registration/dashboard-dob-user-registration.component';
import { DashboardDobUserRegistrationEditComponent } from './dashboard-dob-user-registration-edit/dashboard-dob-user-registration-edit.component';

@NgModule({
  declarations: [
    DashboardValidationComponent,
    DasboardValidationLayoutComponent,
    DashboardValidationSidebarComponent,
    DashboardValidationNavbarComponent,
    DashboardValidationCandidatureComponent,
    DashboardValidationListingComponent,
    DashboardRegionalComponent,
    DashboardSchoolsComponent,
    DashboardSupervisorComponent,
    DashboardAdminComponent,
    DashboardSchoolsStaffComponent,
    DashboardBankComponent,
    DashboardBankInfoComponent,
    DashboardBankFiliereComponent,
    DashboardBankConfirmationComponent,
    DashbordFreeCandidateComponent,
    DashboardBankValidationComponent,
    DashboardAdminAccountConfigureComponent,
    DashboardAdminAccountConfigureEditComponent,
    DashboardDobComponent,
    DashboardDobApplicantsComponent,
    DashboardDexcUpdateComponent,
    DashboardDexcUpdateSearchComponent,
    DashboardDexcUpdateStudentComponent,
    DashboardDexcUpdateClassComponent,
    DashboardDexcUpdateFinishComponent,
    DashboardDexcUpdateSidebarComponent,
    DashboardRegionalOrientationValidationComponent,
    DashboardRegionalOrientationValidationListingComponent,
    DashboardRegionalOrientationValidationChoicesListComponent,
    DashboardRegionalOrientationValidationApplicantFormComponent,
    DashboardRegionalOrientationEditNationalityComponent,
    DashboardRegionalOrientationEditBirthdateComponent,
    DashboardRegionalSettingsApplicantCodeComponent,
    DashboardDobUserRegistrationComponent,
    DashboardDobUserRegistrationEditComponent,
  ],
  imports: [SharedModule, DashboardRoutingModule, BrowserAnimationsModule],
  providers: [DialogService],
})
export class DashboardModule {}
