<div class="examen-section">
  <div class="mb-4 examen-section__header">
    <app-examen-breadcrumb [items]="items"></app-examen-breadcrumb>
  </div>

  <div class="examen-section__content">
    <h3>Choix de candidature</h3>
    <p>Veuillez selectionner votre type de candidature</p>

    <p-messages [(value)]="registrationMSG"></p-messages>

    <form [formGroup]="editForm">
      <!-- Type of candidature -->
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_typeCandidature"
          >Type de candidature
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["typeCandidature"])
          }}</span></label
        >
        <p-dropdown
          id="field_typeCandidature"
          [options]="candidatures"
          [(ngModel)]="selectedCandidature"
          optionLabel="name"
          optionValue="code"
          formControlName="typeCandidature"
          placeholder="Selectionner le type de candidature"
          (onChange)="handleCandidatureChange()"
        >
        </p-dropdown>
        <small
          *ngIf="
            editForm.get('typeCandidature')?.errors &&
            (editForm.get('typeCandidature')?.errors)!['required']
          "
          class="p-field-help"
          id="field_typeCandidature-help"
          >Ce champ est obligatore.</small
        >
      </div>

      <!-- Selected search by -->
      <div
        class="p-field p-field-column"
        [hidden]="!selectedCandidature || selectedCandidature?.code !== 'free'"
      >
        <label class="p-field-label" for="field_matricule"
          >Rechercher par:
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["matricule"])
          }}</span>
        </label>

        <div class="p-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              name="searchBy"
              value="matricule"
              formControlName="searchBy"
              inputId="searchByMatricule"
              [(ngModel)]="selectedSearch"
              (onClick)="handleSelectSearch($event)"
            ></p-radioButton>
            <label for="searchByMatricule">Matricule</label>
          </div>

          <div class="p-field-radiobutton">
            <p-radioButton
              name="searchBy"
              value="table number"
              formControlName="searchBy"
              inputId="searchByTableNumber"
              [(ngModel)]="selectedSearch"
              (onClick)="handleSelectSearch($event)"
            ></p-radioButton>
            <label for="searchByTableNumber">Numéro de table</label>
          </div>
        </div>

        <small
          *ngIf="
            editForm.get('searchBy')?.errors &&
            (editForm.get('searchBy')?.errors)!['required']
          "
          class="p-field-help"
          id="field_matricule-help"
          >Ce champ est obligatore.</small
        >
      </div>

      <!-- matricule -->
      <div
        class="p-field p-field-column"
        [hidden]="
          !selectedCandidature ||
          (selectedCandidature?.code === 'free' && !selectedSearch) ||
          selectedSearch === 'table number' ||
          selectedCandidature?.code === 'bankOfficial' ||
          selectedCandidature?.code === 'bankFree'
        "
      >
        <label class="p-field-label" for="field_matricule"
          >Numéro matricule MENA / ETFP
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["matricule"])
          }}</span>
        </label>
        <input
          type="text"
          pInputText
          formControlName="matricule"
          [ngModel]="matriculeValue"
          id="field_matricule"
          placeholder="Entrer votre numéro matricule"
          (ngModelChange)="matriculeValue = $event?.toUpperCase()"
        />
        <small
          *ngIf="
            editForm.get('matricule')?.errors &&
            (editForm.get('matricule')?.errors)!['required']
          "
          class="p-field-help"
          id="field_matricule-help"
          >Ce champ est obligatore.</small
        >
      </div>

      <!-- table number -->
      <div
        class="p-field p-field-column"
        [hidden]="
          !selectedSearch ||
          selectedSearch === 'matricule' ||
          selectedCandidature?.code !== 'free'
        "
      >
        <label class="p-field-label" for="field_tableNumber"
          >Numéro de table
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["tableNumber"])
          }}</span>
        </label>
        <input
          type="text"
          pInputText
          formControlName="tableNumber"
          id="field_tableNumber"
          placeholder="Entrer votre numéro de table"
        />
        <small
          *ngIf="
            editForm.get('tableNumber')?.errors &&
            (editForm.get('tableNumber')?.errors)!['required']
          "
          class="p-field-help"
          id="field_tableNumber-help"
          >Ce champ est obligatore.</small
        >
      </div>

      <div class="p-field p-field-actions loader">
        <div class="search-loader">
          <img src="/assets/loader.gif" alt="Loader" *ngIf="isLoading" />
        </div>
        <div></div>
        <div style="float: right">
          <button
            pButton
            pRipple
            type="button"
            label="Annuler"
            icon="pi pi-arrow-left"
            [disabled]="isLoading"
            class="p-button-secondary p-button-text"
            [routerLink]="['/']"
          ></button>

          <button
            [disabled]="
              (editForm.invalid &&
                editForm.controls['matricule'].value === '') ||
              isSaving ||
              isLoading
            "
            pButton
            type="button"
            label="Rechercher"
            (click)="goToNext()"
          ></button>
        </div>
      </div>
    </form>
  </div>
</div>
