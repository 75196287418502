<div class="card-search">
  <div class="card-search-loader" *ngIf="searching">
    <p-progressSpinner
      [style]="{ width: '50px', height: '50px' }"
    ></p-progressSpinner>
  </div>
  <p-messages [(value)]="msgs" [style]="{ marginBottom: '2rem' }"></p-messages>

  <form [formGroup]="editForm">
    <div class="p-flex-between">
      <!-- Orddre d'enseignement -->
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_reg_level"
          >Type Enseignement
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["studyFieldType"])
          }}</span></label
        >
        <p-dropdown
          id="field_sch_schoolstudyorder"
          [options]="schoolstudyorderList"
          [(ngModel)]="selectedSchoolStudyOrder"
          optionLabel="name"
          optionValue="code"
          formControlName="studyFieldType"
          placeholder="Selectionner le type d'enseignement"
          (onChange)="handleLoadStudyfield()"
          [disabled]="isChoiceFull()"
        >
        </p-dropdown>
      </div>
      <!-- end -->

      <!-- StudyField -->
      <div class="p-field p-field-column" style="flex: 1 1 auto">
        <div class="p-field p-field-column">
          <label class="p-field-label" for="field_chx_studyfield"
            >Filière / Série
            <span class="color-danger">{{
              fieldIsRequired(editForm.controls["chx_studyfield"])
            }}</span>
          </label>
          <p-dropdown
            id="field_chx_studyfield"
            [options]="studyfields"
            [showClear]="true"
            [filter]="true"
            [(ngModel)]="selectedStudyfield"
            [itemSize]="30"
            optionLabel="sfd_longlabel"
            optionValue="sfd_id"
            formControlName="chx_studyfield"
            placeholder="Selectionner la filière / série"
            [virtualScroll]="true"
            emptyMessage="Aucune filière trouvé"
            emptyFilterMessage="Aucun résultat"
            (onChange)="handleLoadSelectedStudyfieldSchool()"
            [disabled]="isChoiceFull() || !selectedSchoolStudyOrder"
          >
          </p-dropdown>
        </div>
      </div>
      <!-- end -->
    </div>

    <div class="flex-between-center">
      <div class="p-flex-between">
        <!-- Location -->
        <div class="p-field p-field-column">
          <label class="p-field-label" for="field_location"
            >Localité
            <span class="color-danger">{{
              fieldIsRequired(editForm.controls["location"])
            }}</span>
          </label>
          <p-dropdown
            id="field_location"
            [showClear]="true"
            [filter]="true"
            [options]="locations"
            [(ngModel)]="selectedLocation"
            formControlName="location"
            optionLabel="loc_label"
            optionValue="loc_id"
            emptyMessage="Aucune localité trouvée"
            emptyFilterMessage="Aucun résultat"
            placeholder="Sélectionner la localité"
            [disabled]="!selectedStudyfield || isChoiceFull()"
            (onChange)="handleSelectLocation()"
          >
          </p-dropdown>
        </div>
        <!-- end -->

        <!-- Localité -->
        <div class="p-field p-field-column">
          <label class="p-field-label" for="field_location"
            >Type Etablissement
            <span class="color-danger">{{
              fieldIsRequired(editForm.controls["schoolType"])
            }}</span>
          </label>
          <p-dropdown
            id="field_location"
            [options]="filterSchoolTypeList"
            [(ngModel)]="selectedSchoolType"
            formControlName="schoolType"
            optionLabel="scty_label"
            optionValue="scty_id"
            placeholder="Sélectionner le type d'établissement"
            [disabled]="!selectedLocation || isChoiceFull()"
            (onChange)="handleSelectSchoolType()"
          >
          </p-dropdown>
        </div>
        <!-- end -->

        <!-- School -->
        <div class="p-field p-field-column" style="flex: 1 1 auto">
          <label class="p-field-label" for="field_chx_school"
            >Etablissement
            <span class="color-danger">{{
              fieldIsRequired(editForm.controls["chx_school"])
            }}</span>
          </label>
          <p-dropdown
            id="field_chx_school"
            [filter]="true"
            [options]="filterSchools"
            [showClear]="true"
            [(ngModel)]="selectedSchool"
            optionLabel="sch_longlabel"
            placeholder="Selectionner l'établissement"
            [virtualScroll]="true"
            formControlName="chx_school"
            [itemSize]="30"
            emptyMessage="Aucun etablissement trouvé."
            emptyFilterMessage="Aucun résultat"
            [disabled]="!selectedSchoolType || isChoiceFull()"
          >
            <ng-template pTemplate="selectedItem">
              <div>{{ selectedSchool.sch_longlabel }}</div>
            </ng-template>
            <ng-template let-school pTemplate="item">
              <div>
                <div>
                  {{ school.sch_type?.scty_label }} - {{ school.sch_longlabel }}
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <!-- end -->
      </div>
    </div>
    <button
      pButton
      type="button"
      class="p-button-sm p-button-outlined"
      label="Ajouter"
      (click)="handleAddNewChoice()"
      [disabled]="editForm?.invalid || isChoiceFull()"
      style="width: 100%"
    ></button>
  </form>
</div>
