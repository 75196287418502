import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RegistrationLayoutComponent } from './registration-layout/registration-layout.component';
import { RegistrationRoutingModule } from './registration-routing.module';
import { RegistrationSearchComponent } from './registration-search/registration-search.component';
import { RegistrationSidebarComponent } from './registration-sidebar/registration-sidebar.component';
import { RegistrationHeaderComponent } from './registration-header/registration-header.component';
import { RegistrationInfosStudentComponent } from './registration-infos-student/registration-infos-student.component';
import { RegistrationInfosClassComponent } from './registration-infos-class/registration-infos-class.component';
import { RegistrationValidationComponent } from './registration-validation/registration-validation.component';
import { RegistrationFinishComponent } from './registration-finish/registration-finish.component';
import { RegistrationStatusComponent } from './registration-status/registration-status.component';

@NgModule({
  declarations: [
    RegistrationLayoutComponent,
    RegistrationSearchComponent,
    RegistrationSidebarComponent,
    RegistrationHeaderComponent,
    RegistrationInfosStudentComponent,
    RegistrationInfosClassComponent,
    RegistrationValidationComponent,
    RegistrationFinishComponent,
    RegistrationStatusComponent,
  ],
  imports: [SharedModule, RegistrationRoutingModule],
})
export class RegistrationModule {}
