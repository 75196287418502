<div class="registration-content-wrapper">
  <div class="registration-content-item">
    <p-toast></p-toast>
    <div class="registration-content-item-header">
      <h3>Informations classe</h3>
      <p>Veuillez remplir tous les champs</p>

      <div style="display: flex; justify-content: space-between">
        <h4 style="margin-top: 2rem">
          <span class="color-danger">(*) </span>
          Champs obligatoires
        </h4>

        <div class="search-loader" *ngIf="isDataLoading">
          <img src="/assets/loader.gif" alt="Loader" />
        </div>
      </div>
    </div>

    <div style="margin-bottom: 4rem">
      <p-messages [(value)]="msg" [closable]="false"></p-messages>
    </div>

    <form [formGroup]="editForm">
      <div class="p-flex-between">
        <div class="p-field p-field-column">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_reg_level"
              >Ordre d'enseignement
              <span class="color-danger">{{
                fieldIsRequired(editForm.controls["reg_level"])
              }}</span>
            </label>
            <p-dropdown
              id="field_sch_schoolstudyorder"
              [options]="schoolstudyorderList"
              [(ngModel)]="selectedSchoolStudyOrder"
              optionLabel="name"
              optionValue="code"
              formControlName="sch_schoolstudyorder"
              placeholder="Selectionner l'ordre d'enseignement"
              (onChange)="handleLoadSchool()"
            >
            </p-dropdown>
            <small
              *ngIf="
                editForm.get('sch_schoolstudyorder')?.errors &&
                (editForm.get('sch_schoolstudyorder')?.errors)!['required'] &&
                (editForm.controls['sch_schoolstudyorder'].dirty ||
                  editForm.controls['sch_schoolstudyorder'].touched)
              "
              class="p-field-help"
              id="field_sch_schoolstudyorder-help"
              >Ce champ est obligatore.</small
            >
            <small
              *ngIf="!editForm.get('sch_schoolstudyorder')?.errors"
              class="p-field-help"
              id="field_sch_schoolstudyorder-help"
              >&nbsp;</small
            >
          </div>
        </div>

        <div class="p-field p-field-column">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_sch_schooltype"
              >Type d'établissement
              <span class="color-danger">{{
                fieldIsRequired(editForm.controls["sch_schooltype"])
              }}</span>
            </label>
            <p-dropdown
              id="field_sch_schooltype"
              [options]="schooltypeList"
              [(ngModel)]="selectedSchoolType"
              optionLabel="scty_label"
              optionValue="scty_id"
              formControlName="sch_schooltype"
              placeholder="Sélectionner le type d'établissement"
              [disabled]="!selectedSchoolStudyOrder"
              (onChange)="handleLoadSchool()"
            >
            </p-dropdown>
            <small
              *ngIf="
                editForm.get('sch_schooltype')?.errors &&
                (editForm.get('sch_schooltype')?.errors)!['required'] &&
                (editForm.controls['sch_schooltype'].dirty ||
                  editForm.controls['sch_schooltype'].touched)
              "
              class="p-field-help"
              id="field_sch_schooltype-help"
              >Ce champ est obligatore.</small
            >
            <small
              *ngIf="!editForm.get('sch_schooltype')?.errors"
              class="p-field-help"
              id="field_sch_schooltype-help"
              >&nbsp;</small
            >
          </div>
        </div>
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_reg_school"
          >Etablissement
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["reg_school"])
          }}</span>
        </label>
        <p-dropdown
          id="field_reg_school"
          [filter]="true"
          [options]="schools"
          [(ngModel)]="selectedSchool"
          optionLabel="sch_longlabel"
          formControlName="reg_school"
          placeholder="Selectionner votre établissement"
          (onChange)="handleSchoolChange()"
          [virtualScroll]="true"
          [itemSize]="30"
          emptyMessage="Aucun établissement trouvé"
          emptyFilterMessage="Aucun résultat"
          [disabled]="
            schools.length === 0 ||
            !selectedSchoolType ||
            !selectedSchoolStudyOrder ||
            isDataLoading
          "
        >
        </p-dropdown>
        <small
          *ngIf="
            editForm.get('reg_school')?.errors &&
            (editForm.get('reg_school')?.errors)!['required'] &&
            (editForm.controls['reg_school'].dirty ||
              editForm.controls['reg_school'].touched)
          "
          class="p-field-help"
          id="field_reg_school-help"
          >Ce champ est obligatore.</small
        >
        <small
          *ngIf="!editForm.get('reg_school')?.errors"
          class="p-field-help"
          id="field_reg_school-help"
          >&nbsp;</small
        >
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_reg_diploma"
          >Diplôme préparé
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["reg_diploma"])
          }}</span>
        </label>
        <p-dropdown
          id="field_reg_diploma"
          [options]="diplomas"
          [(ngModel)]="selectedDiploma"
          formControlName="reg_diploma"
          optionLabel="dip_longlabel"
          placeholder="Selectionner votre diplôme"
          (onChange)="loadExamunit()"
          [disabled]="
            !selectedSchool ||
            selectedSchool == undefined ||
            (selectedSchool && schools?.length === 0) ||
            isDataLoading
          "
          (onChange)="handleChangeDiploma()"
        >
        </p-dropdown>
        <small
          *ngIf="
            editForm.get('reg_diploma')?.errors &&
            (editForm.get('reg_diploma')?.errors)!['required'] &&
            (editForm.controls['reg_diploma'].dirty ||
              editForm.controls['reg_diploma'].touched)
          "
          class="p-field-help"
          id="field_reg_diploma-help"
          >Ce champ est obligatore.</small
        >
        <small
          *ngIf="!editForm.get('reg_diploma')?.errors"
          class="p-field-help"
          id="field_reg_diploma-help"
          >&nbsp;</small
        >
      </div>
      <div class="p-field p-field-column">
        <div class="p-field p-field-column">
          <label class="p-field-label" for="field_reg_studyfield"
            >Filière
            <span class="color-danger">{{
              fieldIsRequired(editForm.controls["reg_studyfield"])
            }}</span>
          </label>
          <p-dropdown
            id="field_reg_studyfield"
            [options]="studyfields"
            [(ngModel)]="selectedStudyfield"
            optionLabel="sfd_longlabel"
            formControlName="reg_studyfield"
            placeholder="Selectionner filière"
            [disabled]="
              !selectedDiploma || selectedDiploma == undefined || isDataLoading
            "
          >
          </p-dropdown>
          <small
            *ngIf="
              editForm.get('reg_studyfield')?.errors &&
              (editForm.get('reg_studyfield')?.errors)!['required'] &&
              (editForm.controls['reg_studyfield'].dirty ||
                editForm.controls['reg_studyfield'].touched)
            "
            class="p-field-help"
            id="field_reg_studyfield-help"
            >Ce champ est obligatore.</small
          >
          <small
            *ngIf="!editForm.get('reg_studyfield')?.errors"
            class="p-field-help"
            id="field_reg_studyfield-help"
            >&nbsp;</small
          >
        </div>
      </div>

      <div class="p-flex-between">
        <div class="p-field p-field-column">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_reg_level"
              >Niveau
              <span class="color-danger">{{
                fieldIsRequired(editForm.controls["reg_level"])
              }}</span>
            </label>
            <p-dropdown
              id="field_reg_level"
              [options]="levelsList"
              optionLabel="lvl_label"
              optionValue="lvl_code"
              formControlName="reg_level"
              placeholder="Selectionner votre niveau"
              [disabled]="
                !selectedSchoolStudyOrder || !selectedDiploma || isDataLoading
              "
              (onChange)="handleChangeLevel()"
            >
            </p-dropdown>
            <small
              *ngIf="
                editForm.get('reg_level')?.errors &&
                (editForm.get('reg_level')?.errors)!['required'] &&
                (editForm.controls['reg_level'].dirty ||
                  editForm.controls['reg_level'].touched)
              "
              class="p-field-help"
              id="field_reg_level-help"
              >Ce champ est obligatore.</small
            >
            <small
              *ngIf="!editForm.get('reg_level')?.errors"
              class="p-field-help"
              id="field_reg_level-help"
              >&nbsp;</small
            >
          </div>
        </div>

        <div class="p-field p-field-column">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_reg_etfpaccessmode"
              >Mode d'accès à l'ETFP
              <span class="color-danger">{{
                fieldIsRequired(editForm.controls["reg_etfpaccessmode"])
              }}</span>
            </label>
            <p-dropdown
              id="field_reg_etfpaccessmode"
              [options]="accessmodelist"
              [(ngModel)]="selectedAccessMode"
              optionLabel="name"
              optionValue="code"
              formControlName="reg_etfpaccessmode"
              placeholder="Mode d'accès à l'ETFP"
              [disabled]="!selectedSchoolType || selectedSchoolType === 0"
            >
            </p-dropdown>
            <small
              *ngIf="
                editForm.get('reg_etfpaccessmode')?.errors &&
                (editForm.get('reg_etfpaccessmode')?.errors)!['required'] &&
                (editForm.controls['reg_etfpaccessmode'].dirty ||
                  editForm.controls['reg_etfpaccessmode'].touched)
              "
              class="p-field-help"
              id="field_reg_etfpaccessmode-help"
              >Ce champ est obligatore.</small
            >
            <small
              *ngIf="!editForm.get('reg_etfpaccessmode')?.errors"
              class="p-field-help"
              id="field_reg_etfpaccessmode-help"
              >&nbsp;</small
            >
          </div>
        </div>
      </div>

      <div
        class="p-field p-field-column"
        *ngIf="typeCandidature === 1 || typeCandidature === 2"
      >
        <label
          style="margin-bottom: 1rem"
          class="p-field-label"
          for="field_school"
          >Unités présentées
          <!-- <span class="color-danger">{{
            fieldIsRequired(editForm.controls["typeCandidature"])
          }}</span> -->
        </label>

        <div class="p-field-checkbox-container">
          <div class="p-field-checkbox" *ngFor="let examunit of examunits">
            <p-checkbox
              formControlName="unity"
              [value]="examunit"
              [(ngModel)]="selectedUnity"
              [inputId]="examunit?.uni_id!"
              [readonly]="true"
            ></p-checkbox>
            <label [for]="examunit.uni_id"
              >{{ examunit.uni_longlabel }} -
              {{ examunit.uni_shortlabel }}</label
            >
          </div>
        </div>
      </div>

      <div class="p-field p-field-actions loader">
        <!-- <div class="search-loader">
          <img src="/assets/loader.gif" alt="Loader" *ngIf="loaded" />
        </div> -->
        <div></div>
        <!-- <button
          *ngIf="!loaded"
          pButton
          pRipple
          type="button"
          label="Enregistrer"
          icon="pi pi-save"
          class="p-button-outlined"
          style="margin-right: auto"
          [disabled]="
            !editForm.dirty || (editForm.touched && !editForm.dirty) || loaded
          "
          (click)="edit()"
        ></button> -->
        <div>
          <button
            pButton
            pRipple
            type="button"
            label="Précédent"
            icon="pi pi-arrow-left"
            [disabled]="loaded"
            class="p-button-secondary p-button-text"
            (click)="goPrev()"
          ></button>

          <p-confirmPopup appendTo="body"></p-confirmPopup>

          <button
            [disabled]="editForm.invalid || loaded"
            pButton
            type="button"
            label="Enregister et continuer"
            (click)="showDialog()"
          ></button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- confirm dialog -->
<p-dialog
  header="Comfirmation"
  [(visible)]="displayDialog"
  [style]="{ width: '500px' }"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
>
  <p style="display: flex; align-items: center">
    <i
      style="font-size: 2rem; color: red; margin-right: 1rem"
      class="pi pi-question-circle"
    ></i>
    Êtes-vous sûr des informations renseignées ?
  </p>

  <ng-template pTemplate="footer">
    <p-button
      (click)="displayDialog = false"
      icon="pi pi-times"
      label="Annuler"
      styleClass="p-button-secondary p-button-text"
    ></p-button>
    <p-button
      (click)="confirm()"
      label="Confirmer"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>

<div class="registration-loader" *ngIf="loaded">
  <p-progressSpinner></p-progressSpinner>
</div>
