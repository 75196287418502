import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FreeCandidateService } from 'src/app/services/free-candidate.service';
import { BankService } from 'src/app/services/bank.service';
import { IStudent, Student } from 'src/app/shared/model/student.model';
import { MessageService } from 'primeng/api';
import { YearService } from 'src/app/services/year.service';
import { IYear } from 'src/app/shared/model/year.model';
import { ISchool } from 'src/app/shared/model/school.model';
import { IDiploma } from 'src/app/shared/model/diploma.model';
import { IStudyField } from 'src/app/shared/model/studyfield.model';
import { ExamUnit, IExamUnit } from 'src/app/shared/model/examunit.model';
import { DiplomaService } from 'src/app/services/diploma.service';
import { StudyfieldService } from 'src/app/services/studyfield.service';

@Component({
  selector: 'app-dashboard-bank-filiere',
  templateUrl: './dashboard-bank-filiere.component.html',
  styleUrls: ['./dashboard-bank-filiere.component.scss'],
})
export class DashboardBankFiliereComponent implements OnInit {
  studentFiliereForm!: FormGroup;
  formSubmited: boolean = false;
  formDone: boolean = false;
  session: any;
  years!: IYear;
  diploma!: IDiploma[];
  studyField!: IStudyField[];
  examUnit!: any;
  successMessage!: string;
  isLoader!: boolean;
  displayDialog!: boolean;
  schools!: ISchool[];
  // student! : Student;

  @Input() student!: any | {};
  @Output('nextStep') nextStep: EventEmitter<Student> = new EventEmitter();
  @Output('prevStep') prevStep: EventEmitter<Student> = new EventEmitter();
  idDiplom: any;
  bankList: any;
  idSchool: any;
  diplomas: any;
  studyFields!: IStudyField[];
  bankStudent: any;
  selectedBankCandidate : any = undefined;
  updatedBtn = false;
  bankDetail: any;
  bankId: any;

  constructor(
    private freeCandidateService: FreeCandidateService,
    private bankService: BankService,
    private messageService: MessageService,
    private yearService: YearService,
    private diplomaService: DiplomaService,
    private studyfieldService: StudyfieldService,
  ) {}

  ngOnInit(): void {
    
    this.initForm();
    this.getYear();
    this.getUserBankDetail();
    this.getAllBank();
    // this.getAllSchool();
    // this.getDiplomeBySchoolId();
    // this.getStudyField();
    this.selectedBankCandidate = JSON.parse(localStorage.getItem("selectedBankCandidate") || '{}');
    
    // if (typeof this.selectedBankCandidate !== 'undefined') {
    //   console.log(this.selectedBankCandidate);
    //   const fcr_bank = this.selectedBankCandidate["fcr_bank"];
    //   this.studentFiliereForm.get('fcr_school')?.setValue((fcr_bank.bnk_id));  
    //   this.studentFiliereForm.get('fcr_diploma')?.setValue((this.selectedBankCandidate["fcr_diploma"].dip_id));  
    //   this.studentFiliereForm.get('fcr_studyfield')?.setValue((this.selectedBankCandidate["fcr_studyfield"].sfd_id));  
    //   this.updatedBtn = true;
    // }
    
    // const studenttoLocalStorage :any  = JSON.parse(localStorage.getItem(this.bankStudent) || '{}');
    // let student :any = this.student;
    // console.table("studenttoLocalStorage",studenttoLocalStorage);

    console.log('student etape 2', this.student.bkc_id);
    this.studentFiliereForm.get("candidate")?.setValue(this.student.bkc_id);
    
    // this.studentFiliereForm.controls["fcr_session"].disable()
    // this.studentFiliereForm.get("fcr_session")?.setValue(this.years.yrs_id)
  }

  initForm() {
    this.studentFiliereForm = new FormGroup({
      // id: new FormControl(0),
      // fcr_id: new FormControl(null),
      candidate: new FormControl(null),
      typecandidate: new FormControl("bank"),
      fcr_session : new FormControl(null),
      fcr_diploma: new FormControl(null, Validators.required),
      fcr_studyfield: new FormControl(null, Validators.required),
      fcr_examtotake: new FormControl(null),
      fcr_school: new FormControl(null, Validators.required),
    });
  }

  get fcr_session() {return this.studentFiliereForm.get('fcr_session');}
  get fcr_studyfield() {return this.studentFiliereForm.get('fcr_studyfield');}
  get fcr_diploma() {return this.studentFiliereForm.get('fcr_diploma');}
  get fcr_examtotake() {return this.studentFiliereForm.get('fcr_examtotake');}
  get fcr_school() {return this.studentFiliereForm.get('fcr_school');}


  // get user bank detail
  getUserBankDetail(): void{
    this.bankService.getUserBankDetail().subscribe(
      (response : any) => {
        this.bankDetail = response.body;
        this.bankId = this.bankDetail[0].bnk_id;
        console.log("bank id", this.bankId);
        console.log("bank detail", this.bankDetail);
        
        this.studentFiliereForm.get('fcr_school')?.setValue(this.bankDetail[0].bnk_longlabel)
        this.studentFiliereForm.get('fcr_school')?.disable()
      }
    )
  }

  // get all bank
  getAllBank(): void{
    this.bankService.getBankList().subscribe(
      (response) => {
        this.bankList = response.body;
        this.idSchool = this.bankList.sch_id;
        console.log(this.idSchool);
        console.log("Bank list", this.bankList);

        this.getDiplomeBySchoolId(this.idSchool)
      }
    )
  }

  // diplome en fonction des etablisements
  getDiplomeBySchoolId(schoolId: string): void{
    console.log(this.idSchool);
    this.freeCandidateService.findDiplomasBySchool(schoolId).subscribe(
      (response : any) =>{
        this.diplomas = response.body;
        console.log("disploma", this.diplomas);
      }
    )
  }

  // get studyfield by school and diplome
  getStudyfieldBySchoolIdAndDiplome(event : any){
    this.idDiplom = event.target.value;
    console.log(this.idSchool);
    
    this.freeCandidateService.findDiplomaStudyFields(this.idSchool, this.idDiplom).subscribe(
      (response : any ) =>{
        this.studyFields = response.body;
      }
    )

    this.getExamunitByDiploma(this.idDiplom)
  }

  // get exam unit by diplome
  getExamunitByDiploma(diplomaId: string){
    
    this.freeCandidateService.getExamunitByDiploma(diplomaId).subscribe(
      (response : any) =>{
        this.examUnit = response.body;
        console.log(this.examUnit);
      }
    )
  }

  castExushortlabellListTostring(uniteEnseignementList:any){
    const exu_shortlabellList:string[] = uniteEnseignementList.map((uniteEnseignement: any) => uniteEnseignement.exu_shortlabel)
    const exu_shortlabellListToString:string = exu_shortlabellList.join() 
    return exu_shortlabellListToString
  }

  onStudentBankFiliere() {
    this.formSubmited = true;
    this.formDone = true;
    this.studentFiliereForm.get('fcr_session')?.setValue(this.years.yrs_id);
    this.studentFiliereForm.get('fcr_school')?.setValue(this.bankId);
    console.log(this.studentFiliereForm.get('fcr_school')?.value);
    
    // examtotake
    const uniteEnseignementList = this.studentFiliereForm.get('fcr_examtotake')?.value
    const exu_shortlabel:string = this.castExushortlabellListTostring(uniteEnseignementList)
    this.studentFiliereForm.get('fcr_examtotake')?.setValue(exu_shortlabel);

    // id student
    // const id = this.student.bkc_id;
    console.log(this.studentFiliereForm.getRawValue());
    

    this.bankService.postSecondInfoBank(this.studentFiliereForm.getRawValue()).subscribe(
      (res) => {
        this.isLoader = false;
        this.bankStudent = res;
        localStorage.setItem("bankStudent", JSON.stringify(this.bankStudent));

        this.formSubmited = true;
        this.formDone = true;
        (this.successMessage = 'Informations enregistrées avec succèss'),
          this.handleSuccess();
        // this.nextStep.emit(this.student2);
      },
      (err) => {
        this.isLoader = false;
        console.log(err);
        // this.nextStep.emit();
        // const id = this.studentFiliereForm.get('candidate')?.value;
        // console.log(id);

        // console.log(this.studentFiliereForm.getRawValue());

        // if (err.status === 500){
        //   this.bankService.updateInforSup(id, this.studentFiliereForm.getRawValue()).subscribe(
        //     (res) => {
        //       this.isLoader = false;
        //       console.log('error response',res);
        //       this.formSubmited = true;
        //       this.formDone = true;
        //       this.student2 = res;
        //       // this.nextStep.emit(this.student2);
        //       this.successMessage = "Informations modifiées avec succèss",
        //       this.handleSuccess()
        //     },
        //     (err) => {
        //       this.isLoader = false;
        //       console.log(err);
        //     }
        //   )
        // }
      }
    );

    // this.nextStep.emit();
  }

  updateBankStudent(): void{
    const idBankCandidate = this.selectedBankCandidate.bkc_id
    this.bankService.updateSecondInfoBank(idBankCandidate, this.studentFiliereForm.getRawValue()).subscribe(
      (res) => {
        this.isLoader = false;
        console.log('error response',res);
        this.formSubmited = true;
        this.formDone = true;
        // this.nextStep.emit(this.student2);
        this.successMessage = "Informations modifiées avec succèss",
        this.handleSuccess()
      },
      (err) => {
        this.isLoader = false;
        console.log(err);
      }
    )
  }

  onPrevStep() {
    this.prevStep.emit(this.student);
  }

  // popup success
  handleSuccess(): void {
    this.isLoader = false;
    this.displayDialog = false;
    this.messageService.add({
      severity: 'success',
      detail: this.successMessage,
    });
    setTimeout(() => {
      this.nextStep.emit(this.student);
    }, 1500);
  }

  // show modal confirmation
  showDialog() {
    this.displayDialog = true;
  }
  

  // get diploma
  // getDiplomas(){
  //   this.diplomaService.getDiplomas().subscribe(
  //     (response) => {
  //       this.diplomas = response;
  //     }
  //   )
  // }

  // liste des etablissements
  // getAllSchool(){
  //   this.freeCandidateService.getSchools().subscribe(
  //     (response : ISchool[]) => {
  //       this.schools = response;
  //       console.log(this.schools);
  //     }
  //   )
  // }

  // liste des filieres
  // getStudyField(){
  //   this.studyfieldService.getStudyField().subscribe(
  //     (response : IStudyField[]) => {
  //       this.studyFields = response;
  //     }
  //   )
  // }

  //  get year
  getYear(): void {
    this.yearService.geActiveYear().subscribe((year) => {
      this.years = year;
      this.session = this.years?.yrs_academic!;
    });
  }
}
