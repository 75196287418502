<p class="dashboard-title">Liste des enregistrements</p>

<div class="dashboard-section">
  <p-toolbar>
    <div class="p-toolbar-group-left"></div>

    <div class="p-toolbar-group-right">
      <button
        class="p-button rounded p-button-main"
        (click)="addRegistration()"
        style="margin-right: 1rem"
      >
        <i class="pi pi-plus" style="margin-right: 1rem"></i>
        Nouvel enregistrement
      </button>

      <button
        class="p-button rounded p-button-outlined p-button-secondary"
        (click)="refreshPage()"
        [disabled]="choicesRegistrations.length === 0"
      >
        <i class="pi pi-refresh" style="margin-right: 1rem"></i>
        Rafraichir
      </button>
    </div>
  </p-toolbar>

  <!-- <p-card> -->
  <p-table
    #registrationtable
    [value]="choicesRegistrations"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    currentPageReportTemplate="Affichage de {first} à {last} des {totalRecords} enregistrements"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Identification</th>
        <th>Nationalité</th>
        <th>Contact</th>
        <th>Status</th>
        <th class="text-center">Fiche d'enregistrement</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr *ngIf="!isLoading">
        <td
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            border: none;
          "
        >
          <span style="opacity: 0.5">{{
            item?.drl_applicant?.app_matricule
          }}</span>
          <span
            >{{ item?.drl_applicant?.app_lastname }}
            {{ item?.drl_applicant?.app_firstname }}</span
          >
        </td>

        <td>{{ item?.drl_applicant?.app_nationality.nat_label }}</td>

        <td>
          {{ item?.drl_applicant?.app_phone }}
        </td>
        <td>
          <span class="chip chip-success" *ngIf="item?.validated"
            ><i class="pi pi-check" style="font-size: 1.2rem"></i> validé</span
          >
          <span class="chip chip-danger" *ngIf="!item?.validated"
            ><i class="pi pi-times" style="font-size: 1.2rem"></i> Non
            validé</span
          >
        </td>
        <td class="text-center">
          <span
            (click)="downloadRegistrationFile(item?.drl_applicant)"
            class="link link-main"
          >
            <i class="pi pi-download"></i>
            Télécharger
          </span>
        </td>
        <td class="text-right">
          <button
            class="p-button rounded p-button-outlined p-button-secondary"
            (click)="showDetail($event, op, item?.drl_applicant)"
          >
            Afficher les choix
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div
    class="empty-table-message-block"
    *ngIf="choicesRegistrations.length === 0 && !isLoading"
  >
    <p>Aucun enregistrement trouvé</p>
  </div>
  <div
    *ngIf="isLoading"
    class="loader"
    style="display: flex; justify-content: center"
  >
    <img src="/assets/loader.gif" />
  </div>
  <!-- </p-card> -->
</div>

<p-overlayPanel
  [style]="{ width: '600px' }"
  #op
  appendTo="body"
  [dismissable]="true"
  [showCloseIcon]="true"
>
  <ng-template pTemplate>
    <p-table
      #registrationtable
      [value]="applicantChoices"
      [paginator]="true"
      [rows]="9"
      [alwaysShowPaginator]="false"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Filière</th>
          <th>Etablissement</th>
          <th class="text-right">Ordre de préférence</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-choice>
        <tr *ngIf="!isChoiceLoading">
          <td>{{ choice?.chx_studyfield?.sfd_longlabel }}</td>

          <td>
            {{ choice?.chx_school?.sch_longlabel }}
          </td>
          <td style="text-align: right">
            <span class="chip chip-main">
              Choix {{ choice?.chx_preference }}</span
            >
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div
      class="empty-table-message-block"
      *ngIf="applicantChoices.length === 0 && !isChoiceLoading"
    >
      <p>Aucun applicant trouvé</p>
    </div>
    <div
      *ngIf="isChoiceLoading"
      class="loader"
      style="display: flex; justify-content: center"
    >
      <img src="/assets/loader.gif" />
    </div>
  </ng-template>
</p-overlayPanel>
