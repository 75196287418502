import { Component, OnInit } from '@angular/core';
import { SettingService } from '../services/setting.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-dashboard-regional-settings-applicant-code',
  templateUrl: './dashboard-regional-settings-applicant-code.component.html',
  styleUrls: ['./dashboard-regional-settings-applicant-code.component.scss'],
})
export class DashboardRegionalSettingsApplicantCodeComponent implements OnInit {
  isApplicantSelected = false;
  isLoading = false;
  search = '';
  code = '';
  msgs: Message[] = [];

  constructor(private settingService: SettingService) {}

  ngOnInit(): void {}

  searchStudent(): void {
    this.isLoading = true;
    this.settingService.getApplicantCode(this.search).subscribe((res) => {
      this.isLoading = false;
      if (res.type == 'success') {
        this.code = res.data.code;
        this.isApplicantSelected = true;
        this.msgs = [];
      } else {
        this.isLoading = false;
        this.isApplicantSelected = false;
        this.code = '';
        this.msgs = [
          {
            severity: 'error',
            detail: res.msg,
          },
        ];
      }
    });
  }

  Clear() {
    this.msgs = [];
    this.isApplicantSelected = false;
    this.isLoading = false;
    this.code = '';
    this.search = '';
  }
}
