import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IDiploma } from '../shared/model/diploma.model';
import { ISchool } from '../shared/model/school.model';
import { IStudyField } from '../shared/model/studyfield.model';

@Injectable({
  providedIn: 'root',
})
export class StudyfieldService {
  private studyfieldResourceURL = environment.apiURL + 'api/v1/studyfield';

  private studyfields$ = new BehaviorSubject<IStudyField[]>([]);

  constructor(private http: HttpClient) {}

  getStudyField(): Observable<IStudyField[]> {
    this.loadStudyFields();
    return this.studyfields$;
  }

  loadStudyFields(): void {
    this.http
      .get<any[]>(`${this.studyfieldResourceURL}/`)
      .subscribe((schools: any) => this.studyfields$.next(schools?.results!));
  }

  loadStudyFieldByDiplomas(): Observable<HttpResponse<IStudyField[]>> {
    return this.http.get<IStudyField[]>(
      `${this.studyfieldResourceURL}/studyfield-by-diplomas/`,
      { observe: 'response' }
    );
  }

  loadStudyfieldSchools(
    studyfiled_id: string,
    applicant_id: string
  ): Observable<HttpResponse<ISchool[]>> {
    return this.http.get<ISchool[]>(
      `${this.studyfieldResourceURL}/${studyfiled_id}/${applicant_id}/studyfield-schools/`,
      { observe: 'response' }
    );
  }

  loadStudyfieldSchoolsList(
    studyfiled_id: string
  ): Observable<HttpResponse<ISchool[]>> {
    return this.http.get<ISchool[]>(
      `${this.studyfieldResourceURL}/${studyfiled_id}/studyfield-schools-list/`,
      { observe: 'response' }
    );
  }

  loadTechnicalStudyFieldList(): Observable<HttpResponse<IStudyField[]>> {
    return this.http.get<IStudyField[]>(
      `${this.studyfieldResourceURL}/technical-studyfield/`,
      { observe: 'response' }
    );
  }

  loadProfessionalStudyFieldList(): Observable<HttpResponse<IStudyField[]>> {
    return this.http.get<IStudyField[]>(
      `${this.studyfieldResourceURL}/professional-studyfield/`,
      { observe: 'response' }
    );
  }
}
