import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRegionalBranch } from '../shared/model/regionalbranch.model';

@Injectable({
  providedIn: 'root',
})
export class RegionalService {
  private regionalResourceURL = environment.apiURL + 'api/v1/regionals';

  constructor(private http: HttpClient) {}

  getAllRegional(): Observable<HttpResponse<IRegionalBranch[]>> {
    return this.http.get<IRegionalBranch[]>(`${this.regionalResourceURL}/`, {
      observe: 'response',
    });
  }
}
