import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/account/auth.service';

@Directive({
  selector: '[ersHasAnyAuthority]',
})
export class HasAnyAuthorityDirective implements OnDestroy {
  private authorities: string[] = [];
  private authenticationSubscription?: Subscription;

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  @Input()
  set ersHasAnyAuthority(value: string | string[]) {
    // this.authorities = typeof value === 'string' ? [value] : value;
    this.updateView();

    // Get notified each time authentication state changes.
    this.authenticationSubscription = this.authService
      .getAuthenticationState()
      .subscribe(() => {
        this.updateView();
      });
  }

  ngOnDestroy(): void {
    if (this.authenticationSubscription) {
      this.authenticationSubscription.unsubscribe();
    }
  }

  private updateView(): void {
    const hasAnyAuthority = this.authService.authenticateUserRole();
    this.viewContainerRef.clear();
    if (hasAnyAuthority) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
