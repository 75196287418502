import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedLibModule } from './shared-lib.module';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { HasAnyAuthorityDirective } from './directives/has-any-authority.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ChoiceFormComponent } from './dob-choice-form/dob-choice-form.component';
// import { NgApexchartsModule } from 'ng-apexcharts';
// import { DobChoiceFormComponent } from '../dob/components/dob-choice-form/dob-choice-form.component';

@NgModule({
  declarations: [
    NavbarComponent,
    HasAnyAuthorityDirective,
    ChoiceFormComponent,
    // DobChoiceFormComponent,
  ],
  imports: [RouterModule, FormsModule, ReactiveFormsModule, SharedLibModule],
  exports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedLibModule,
    HasAnyAuthorityDirective,
    NavbarComponent,
    ChoiceFormComponent,
    // DobChoiceFormComponent,
    RouterModule,
    // NgApexchartsModule,
  ],
})
export class SharedModule {}
