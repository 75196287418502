<form [formGroup]="editForm">
  <div class="p-field p-field-column">
    <div class="p-field p-field-column">
      <p-calendar
        formControlName="birthdate"
        [showIcon]="true"
        inputId="field_birthdate"
        placeholder="Date de naissance Ex: 21-12-2010"
        dateFormat="dd-mm-yy"
        [showButtonBar]="true"
        styleClass="rounded"
        appendTo="body"
      >
      </p-calendar>
    </div>
  </div>

  <div class="p-field p-field-actions" style="margin-top: 0">
    <div></div>
    <div>
      <button
        pButton
        pRipple
        type="button"
        label="Annuler"
        icon="pi pi-times"
        class="p-button-secondary p-button-text rounded"
        (click)="closeDialog()"
        [disabled]="isSaving"
      ></button>

      <button
        [disabled]="isSaving || editForm.invalid"
        pButton
        (click)="save()"
        class="rounded"
      >
        <span *ngIf="isSaving"
          ><i style="font-size: 2rem" class="pi pi-spin pi-spinner"></i
        ></span>
        <span *ngIf="!isSaving">Enregistrer</span>
      </button>
    </div>
  </div>
</form>
