import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BankService } from 'src/app/services/bank.service';
import { FreeCandidateService } from 'src/app/services/free-candidate.service';
import { YearService } from 'src/app/services/year.service';
import { INationality } from 'src/app/shared/model/nationality.model';
import { IStudent } from 'src/app/shared/model/student.model';
import { IYear } from 'src/app/shared/model/year.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-bank-info',
  templateUrl: './dashboard-bank-info.component.html',
  styleUrls: ['./dashboard-bank-info.component.scss']
})
export class DashboardBankInfoComponent implements OnInit {

  isLoader: boolean = false;
  displayDialog = false;
  studentInfoForm! : FormGroup;
  formSubmited : boolean =false;
  formDone : boolean = false;
  nationalities: INationality[] = [];
  pictureStudent!: File;
  successMessage!: string;

  pictureUrl: any = "../assets/default_picture.png";

  @Input() student! : IStudent | {};
  @Output("nextStep") nextStep : EventEmitter<IStudent> = new EventEmitter();
  @Output("prevStep") prevStep : EventEmitter<IStudent> = new EventEmitter();
  InfoBankToLocalStorage: any;
  studentInfo: any;
  selectedBankCandidate : any = undefined;
  bankList: any;
  idSchool: any;
  diplomas: any;
  idDiplom: any;
  studyFields: any;
  session: any;
  years!: IYear;
  updateBtn: boolean = false;

  constructor(
    private bankService: BankService, 
    private messageService: MessageService,
    private freeCandidateService: FreeCandidateService,
    private yearService: YearService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getYear();
    this.loadNationalites();
    this.getAllBank();
    this.InfoBankToLocalStorage = JSON.parse(localStorage.getItem("InfoBankStudent") || '{}');
    this.selectedBankCandidate = JSON.parse(localStorage.getItem("selectedBankCandidate") || '{}');

    // let student :any = this.student;
    console.table("getInfoStudentBankToLocalStorage", this.InfoBankToLocalStorage);
    // console.log(this.student);
    let studentIn :any = this.InfoBankToLocalStorage;
    // // this.studentInfoForm.setValue(stud)

    if (this.selectedBankCandidate !== undefined) {
      const fcr_bankcandidate = this.selectedBankCandidate["fcr_bankcandidate"];
      this.setstudentInfoFormValue(fcr_bankcandidate);
      this.studentInfoForm.get('bkc_lastname')?.setValue((fcr_bankcandidate.bkc_lastname));  
      this.updateBtn = true
    }
    if (studentIn !== {} && this.selectedBankCandidate == undefined ) {
      this.setstudentInfoFormValue(studentIn);

      // this.studentInfoForm.get('bkc_lastname')?.setValue(studentIn["bkc_lastname"]);  
      // this.studentInfoForm.get('bkc_firstname')?.setValue(studentIn["bkc_firstname"]);  
      // this.studentInfoForm.get('bkc_birthplace')?.setValue(studentIn["bkc_birthplace"]);  
      // this.studentInfoForm.get('bkc_birthdate')?.setValue(studentIn["bkc_birthdate"]);  
      // this.studentInfoForm.get('bkc_gender')?.setValue(studentIn["bkc_gender"]);  
      // this.studentInfoForm.get('bkc_nationality')?.setValue(studentIn["bkc_nationality"].nat_id);  
      // this.studentInfoForm.get('bkc_email')?.setValue(studentIn["bkc_email"]);  
      // this.studentInfoForm.get('bkc_phone1')?.setValue(studentIn["bkc_phone1"]);  
      // this.studentInfoForm.get('bkx_phone2')?.setValue(studentIn["bkx_phone2"]);  
    }

  }

  initForm(){
    this.studentInfoForm = new FormGroup({
      bkc_id : new FormControl(0),
      // bkc_bank: new FormControl("864efb6f-be1d-4843-b770-52f6c33e35db"),
      bkc_picture : new FormControl(null, Validators.required),
      // bkc_menaref : new FormControl(null, Validators.required),
      bkc_lastname : new FormControl(null, Validators.required),
      bkc_firstname : new FormControl(null, Validators.required),
      bkc_birthdate : new FormControl(null, Validators.required),
      bkc_birthplace : new FormControl(null, Validators.required),
      bkc_gender : new FormControl(null, Validators.required),
      bkc_nationality : new FormControl(null, Validators.required),
      bkc_email : new FormControl(null, Validators.email),
      bkc_phone1 : new FormControl(null, Validators.required),
      bkx_phone2 : new FormControl(null),
      fcr_session : new FormControl(null),
      fcr_diploma: new FormControl(null, Validators.required),
      fcr_studyfield: new FormControl(null, Validators.required),
      fcr_school: new FormControl(null),
    })
  }

  get bkc_picture(){ return this.studentInfoForm.get('bkc_picture')}
  get bkc_lastname(){ return this.studentInfoForm.get('bkc_lastname')}
  get bkc_firstname(){ return this.studentInfoForm.get('bkc_firstname')}
  get bkc_birthdate(){ return this.studentInfoForm.get('bkc_birthdate')}
  get bkc_birthplace(){ return this.studentInfoForm.get('bkc_birthplace')}
  get bkc_gender(){ return this.studentInfoForm.get('bkc_gender')}
  get bkc_nationality(){ return this.studentInfoForm.get('bkc_nationality')}
  get bkc_phone1(){ return this.studentInfoForm.get('bkc_phone1')}
  get fcr_diploma(){ return this.studentInfoForm.get('fcr_diploma')}
  get fcr_studyfield(){ return this.studentInfoForm.get('fcr_studyfield')}
  


  loadNationalites(): void {
    this.bankService.getNationalities().subscribe((res) => {
      this.nationalities = res.body || [];
    });
  }

  // read picture select
  onFileSelected(e: any){
    
    this.pictureStudent = e.target.files[0];

    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.pictureUrl = e.target?.result;
      }
    }
  }

  // get all bank
  getAllBank(): void{
    this.bankService.getBankList().subscribe(
      (response) => {
        this.bankList = response.body;
        this.idSchool = this.bankList.sch_id;
        console.log(this.idSchool);
        console.log("Bank list", this.bankList);

        this.getDiplomeBySchoolId(this.idSchool)
      }
    )
  }

  // diplome en fonction des etablisements
  getDiplomeBySchoolId(schoolId: string): void{
    console.log(this.idSchool);
    this.freeCandidateService.findDiplomasBySchool(schoolId).subscribe(
      (response : any) =>{
        this.diplomas = response.body;
        console.log("disploma", this.diplomas);
      }
    )
  }

  // get studyfield by school and diplome
  getStudyfieldBySchoolIdAndDiplome(event : any){
    this.idDiplom = event.target.value;
    console.log(this.idSchool);
    
    this.freeCandidateService.findDiplomaStudyFields(this.idSchool, this.idDiplom).subscribe(
      (response : any ) =>{
        this.studyFields = response.body;
      }
    )

    // this.getExamunitByDiploma(this.idDiplom)
  }

  onStudentInfoBank(): void{
    
    this.isLoader = true;

    const formData: any = new FormData();
    // formData.append('bkc_id', this.studentInfoForm.get('bkc_id')?.value);
    formData.append('fcr_session', this.session);
    formData.append('bkc_picture', this.pictureStudent, this.pictureStudent.name);
    // formData.append('bkc_bank', this.studentInfoForm.get('bkc_bank')?.value);
    formData.append('bkc_lastname', this.studentInfoForm.get('bkc_lastname')?.value);
    formData.append('bkc_firstname', this.studentInfoForm.get('bkc_firstname')?.value);
    formData.append('bkc_birthdate', this.studentInfoForm.get('bkc_birthdate')?.value);
    formData.append('bkc_birthplace', this.studentInfoForm.get('bkc_birthplace')?.value);
    formData.append('bkc_gender', this.studentInfoForm.get('bkc_gender')?.value);
    formData.append('bkc_nationality', this.studentInfoForm.get('bkc_nationality')?.value);
    formData.append('bkc_email', this.studentInfoForm.get('bkc_email')?.value);
    formData.append('bkc_phone1', this.studentInfoForm.get('bkc_phone1')?.value);
    formData.append('bkc_phone2', this.studentInfoForm.get('bkx_phone2')?.value);
    formData.append('fcr_diploma', this.studentInfoForm.get('fcr_diploma')?.value);
    formData.append('fcr_studyfield', this.studentInfoForm.get('fcr_studyfield')?.value);
    formData.append('fcr_school', this.idSchool);
  
    console.log(...formData);
    
    this.bankService.postInfoBankCandidate(formData).subscribe(
      (res) => {
        this.student = res;
        this.studentInfo = res;
        localStorage.setItem("InfoBankStudent", JSON.stringify(this.studentInfo));
        this.isLoader = false;
        this.formSubmited = true;
        this.formDone = true;
        // this.nextStep.emit(this.student);
        this.successMessage = "Informations enregistrées avec succèss";
        this.handleSuccess()
      },
      (err : HttpErrorResponse) => {
        // this.formSubmited = true;
        // this.formDone = true;
        // // this.nextStep.emit();
        // this.isLoader = false;
        console.log(err);
      },
    );

  }

  // update bank candidate
  updateStudentInfoBank(){
    this.isLoader = true;

    const formData: any = new FormData();
    // formData.append('bkc_id', this.studentInfoForm.get('bkc_id')?.value);
    formData.append('fcr_session', this.session);
    formData.append('bkc_picture', this.pictureStudent, this.pictureStudent.name);
    // formData.append('bkc_bank', this.studentInfoForm.get('bkc_bank')?.value);
    formData.append('bkc_lastname', this.studentInfoForm.get('bkc_lastname')?.value);
    formData.append('bkc_firstname', this.studentInfoForm.get('bkc_firstname')?.value);
    formData.append('bkc_birthdate', this.studentInfoForm.get('bkc_birthdate')?.value);
    formData.append('bkc_birthplace', this.studentInfoForm.get('bkc_birthplace')?.value);
    formData.append('bkc_gender', this.studentInfoForm.get('bkc_gender')?.value);
    formData.append('bkc_nationality', this.studentInfoForm.get('bkc_nationality')?.value);
    formData.append('bkc_email', this.studentInfoForm.get('bkc_email')?.value);
    formData.append('bkc_phone1', this.studentInfoForm.get('bkc_phone1')?.value);
    formData.append('bkc_phone2', this.studentInfoForm.get('bkx_phone2')?.value);
    formData.append('fcr_diploma', this.studentInfoForm.get('fcr_diploma')?.value);
    formData.append('fcr_studyfield', this.studentInfoForm.get('fcr_studyfield')?.value);
    formData.append('fcr_school', this.idSchool);
  
    console.log(...formData);
    const idBankCandidate = this.selectedBankCandidate["fcr_bankcandidate"].bkc_id;
    this.bankService.updateInfoBankCandidate(idBankCandidate, formData).subscribe(
      (res) => {
        this.student = res;
        this.studentInfo = res;
        localStorage.setItem("InfoBankStudent", JSON.stringify(this.studentInfo));
        this.isLoader = false;
        this.formSubmited = true;
        this.formDone = true;
        // this.nextStep.emit(this.student);
        this.successMessage = "Informations modifiés avec succèss";
        this.handleSuccess()
      },
      (err : HttpErrorResponse) => {
        // this.formSubmited = true;
        // this.formDone = true;
        // // this.nextStep.emit();
        // this.isLoader = false;
        console.log(err);
      },
    );
  }

  // popup success
  handleSuccess(): void {
   this.isLoader = false;
   this.displayDialog = false;
    this.messageService.add({
      severity: 'success',
      detail: this.successMessage,
    });
    setTimeout(() => {
      // this.nextStep.emit(this.student);
      // Reload page
      this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/dashboard', 'bank', 'home']);
      });
    }, 1500);
  }

  showDialog() {
    this.displayDialog = true;
  }
  

  // onStudentInfo(){
  //   this.formSubmited = true;
  //   this.formDone = true;
    
  //   this.nextStep.emit();
  // }

  // onPrevStep(){
  //   this.prevStep.emit();
  // }

  //  get year
  getYear(): void {
    this.yearService.geActiveYear().subscribe((year) => {
      this.years = year;
      this.session = this.years?.yrs_id!;
    });
  }



  setstudentInfoFormValue(BankCandidateForm : any){
    this.pictureUrl = environment.apiURL + BankCandidateForm["bkc_picture"]
    this.studentInfoForm.get('bkc_lastname')?.setValue(BankCandidateForm["bkc_lastname"]);  
    this.studentInfoForm.get('bkc_firstname')?.setValue(BankCandidateForm["bkc_firstname"]);  
    this.studentInfoForm.get('bkc_birthplace')?.setValue(BankCandidateForm["bkc_birthplace"]);  
    this.studentInfoForm.get('bkc_birthdate')?.setValue(BankCandidateForm["bkc_birthdate"]);  
    this.studentInfoForm.get('bkc_gender')?.setValue(BankCandidateForm["bkc_gender"]);  
    this.studentInfoForm.get('bkc_nationality')?.setValue(BankCandidateForm["bkc_nationality"].nat_id);  
    this.studentInfoForm.get('bkc_email')?.setValue(BankCandidateForm["bkc_email"]);  
    this.studentInfoForm.get('bkc_phone1')?.setValue(BankCandidateForm["bkc_phone1"]);  
    this.studentInfoForm.get('bkx_phone2')?.setValue(BankCandidateForm["bkx_phone2"]);  
    this.studentInfoForm.get('fcr_diploma')?.setValue(this.selectedBankCandidate["fcr_diploma"]?.dip_id);  
    this.studentInfoForm.get('fcr_studyfield')?.setValue(this.selectedBankCandidate["fcr_studyfield"]?.sfd_id); 
  }
}
