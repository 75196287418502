<ul class="examen-breadcrumb__list">
  <li
    class="examen-breadcrumb__item"
    *ngFor="let item of items"
    [ngClass]="{ active: item.active }"
  >
    <div
      class="examen-breadcrumb__icon"
      *ngIf="
        (!item.validated && item.active) || (item.active && item.validated)
      "
    >
      <i class="{{ item.icon }}"></i>
    </div>

    <div
      class="examen-breadcrumb__icon"
      [ngClass]="{
        active:
          (item.validated && item.active) || (item.validated && !item.active)
      }"
      *ngIf="item.validated && !item.active"
    >
      <i class="pi pi-check-circle"></i>
    </div>

    <div class="examen-breadcrumb__text">
      <span class="examen-breadcrumb__text-step"
        >Etape {{ item.step }}/{{ itemsLength }}</span
      >
      <span class="examen-breadcrumb__text-description">{{ item.title }}</span>
    </div>
  </li>
</ul>
