<div class="registration-sidebar">
    <div class="registration-sidebar__menu">
      <div
        [ngClass]="{ active: item.active, valid: item.validated && !item.active }"
        class="registration-sidebar__item"
        *ngFor="let item of items"
      >
        <div class="registration-sidebar__text">
          <span>Etape {{ item.step }}/ {{ itemsLength }}</span>
          <span>{{ item.title }}</span>
        </div>
        <div class="registration-sidebar__icon">
          <i *ngIf="item.validated" class="{{ item.validatedIcon }}"></i>
          <i *ngIf="!item.validated" class="{{ item.icon }}"></i>
        </div>
      </div>
    </div>
</div>
  