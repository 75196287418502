<p class="dashboard-title">List des utilisateurs</p>
<p-toast key="USER_TOAST_MESSAGE"></p-toast>

<!-- dashboard section -->
<div class="dashboard-section-filter-transparent">
  <div class="dashboard-section-filter-actions">
    <div class="dashboard-section-filter-search">
      <input
        type="text"
        pInputText
        placeholder="Recherche"
        [(ngModel)]="searchUser"
        (ngModelChange)="handleFilter()"
        [style]="{ width: '500px' }"
      />
    </div>

    <!-- <div class="dashboard-section-filter-item" style="width: 300px">
      <p-multiSelect
        optionLabel="loc_label"
        optionValue="loc_id"
        defaultLabel="Selectionner le type de compte"
        display="chip"
        filterPlaceHolder="Recherche la localisation"
        [style]="{ width: '100%' }"
      >
      </p-multiSelect>
    </div> -->
  </div>

  <div class="dashboard-section-filter-button">
    <button type="button" class="p-button btn-add-user" (click)="openDialog()">
      <i class="pi pi-user-plus"></i>
      Ajouter
    </button>
  </div>
</div>

<div class="dashboard-section">
  <!-- <p-card> -->
  <p-table
    [value]="filteredUserAccount"
    [paginator]="true"
    [rows]="10"
    [alwaysShowPaginator]="false"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    currentPageReportTemplate="Affichage de {first} à {last} des {totalRecords} comptes utilisateurs"
  >
    <ng-template pTemplate="header">
      <tr>
        <th scope="col">Utilisateur</th>
        <th scope="col">Rôle</th>
        <th scope="col">Entité</th>
        <th scope="col">Status</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-user>
      <tr>
        <td style="display: flex; flex-direction: column">
          <span class="color color-main">{{
            user?.last_name && user?.first_name
              ? user?.last_name + " " + user?.first_name
              : "-"
          }}</span>
          <span style="font-size: 1.1rem">{{ user?.email }}</span>
        </td>
        <td>{{ user?.role }}</td>
        <td>
          <span
            *ngIf="
              user?.role == 'ROLE_SCHOOL_ADMIN' ||
              user?.role == 'ROLE_SCHOOL_USER'
            "
            >{{ user?.usr_school?.sch_longlabel }}</span
          >
          <span
            *ngIf="
              user?.role == 'ROLE_REGIONAL_ADMIN' ||
              user?.role == 'ROLE_REGIONAL_USER'
            "
            >{{ user?.usr_regionalbranch?.bch_label }}</span
          >

          <span
            *ngIf="
              user?.role == 'ROLE_SUPERVISOR_ADMIN' ||
              user?.role == 'ROLE_SUPERVISOR_USER' ||
              user?.role == 'ROLE_USER' ||
              user?.role == 'ROLE_ADMIN'
            "
            >-</span
          >
        </td>
        <td>
          <p-inputSwitch
            [disabled]="user?.id == authUser?.id!"
            *ngIf="user?.is_active!"
            class="switch-success"
            [(ngModel)]="user?.is_active!"
            (onChange)="handleActivationState(user?.id!, $event)"
          ></p-inputSwitch>

          <p-inputSwitch
            [disabled]="user?.id == authUser?.id!"
            *ngIf="!user?.is_active!"
            class="switch-danger"
            [(ngModel)]="user?.is_active!"
            (onChange)="handleActivationState(user?.id!, $event)"
          ></p-inputSwitch>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div
    class="empty-table-message-block"
    *ngIf="filteredUserAccount.length === 0 && !isLoading"
  >
    <p>Aucun utilisateur trouvé</p>
  </div>

  <div
    *ngIf="isLoading"
    class="loader"
    style="display: flex; justify-content: center"
  >
    <img src="/assets/loader.gif" />
  </div>
</div>
