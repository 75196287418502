import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { IStudent } from '../shared/model/student.model';
import { Observable, BehaviorSubject, fromEvent, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { IExamUnit } from '../shared/model/examunit.model';
import { INationality } from '../shared/model/nationality.model';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

type EntityStudentArrayResponse = HttpResponse<IStudent[]>;
type EntityStudentResponse = HttpResponse<IStudent>;

@Injectable({
  providedIn: 'root',
})
export class InscriptionService {
  private studentResourceURL = environment.apiURL + 'api/v1/students';
  private documentResourceURL = environment.apiURL + 'documents';
  private diplomaResourceURL = environment.apiURL + 'api/v1/diplomas';
  private nationalityResourceURL = environment.apiURL + 'api/v1/nationalities';

  private students$ = new BehaviorSubject<IStudent[]>([]);
  private student$ = new BehaviorSubject<IStudent>({});

  constructor(
    private http: HttpClient // private messageService: MessageService
  ) {}

  public init(): void {
    this.http
      .get<IStudent[]>(`${this.studentResourceURL}`)
      .subscribe((students) => {
        this.students$.next(students);
      });
  }

  public getStudents(): Observable<IStudent[]> {
    return this.students$;
  }

  findStudent(matricule: string): void {
    this.http
      .get<IStudent>(`${this.studentResourceURL}/${matricule}/`)
      .subscribe((student) => {
        this.student$.next(student);
      });
  }

  get(ref: string): Observable<IStudent> {
    this.findStudent(ref);
    return this.student$;
  }

  findStudentByRef(ref: string): Observable<any> {
    return this.http.get<IStudent>(`${this.studentResourceURL}/${ref}/`, {
      observe: 'response',
    });
  }

  getStudent(ref: string): Observable<HttpResponse<IStudent>> {
    return this.http
      .get<IStudent>(`${this.studentResourceURL}/${ref}/`, {
        observe: 'response',
      })
      .pipe(catchError((error) => throwError(error)));
  }

  save(body: FormData): Observable<HttpResponse<IStudent>> {
    return this.http.post<any>(`${this.studentResourceURL}/`, body);
  }

  update(ref: string, body: IStudent): Observable<IStudent> {
    return this.http.patch<IStudent>(
      `${this.studentResourceURL}/${ref}/`,
      body
    );
  }

  showSuccess(): void {
    // this.messageService?.add({
    //   severity: 'success',
    //   summary: 'Modification',
    //   detail: 'Modification réussie',
    //   key: 'studentInfoEdited',
    //   sticky: true,
    // });
  }

  showError(): void {
    // this.messageService?.add({
    //   severity: 'error',
    //   summary: 'Modification',
    //   detail: 'Modification échouée',
    //   key: 'studentInfoEdited',
    //   sticky: true,
    // });
  }

  uploadStudentPicture(
    ref: string,
    file: FormData
  ): Observable<HttpResponse<any>> {
    return this.http.put<any>(
      `${this.studentResourceURL}/${ref}/upload-picture/`,
      file,
      { observe: 'response' }
    );
  }

  downloadExamDocument(filename: string): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.documentResourceURL}/download/`,
      { filename: filename },
      {
        observe: 'response',
      }
    );
  }

  loadExamPreRegistrationDocument(body: any): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({ responseType: 'blob' });
    return this.http.post<Blob>(
      `${this.documentResourceURL}/exam-pre-registration/`,
      body,
      { observe: 'response', headers: headers }
    );
  }

  getDiplomaExamUnit(diploma: string): Observable<HttpResponse<IExamUnit[]>> {
    return this.http.get<IExamUnit[]>(
      `${this.diplomaResourceURL}/${diploma}/exam-units/`,
      {
        observe: 'response',
      }
    );
  }

  getNationalities(): Observable<HttpResponse<INationality[]>> {
    return this.http.get<INationality[]>(`${this.nationalityResourceURL}/`, {
      observe: 'response',
    });
  }

  // getAllStudents(): Observable<EntityStudentArrayResponse> {
  //   return this.http.get<IStudent[]>(`${this.studentResourceURL}`, {
  //     observe: 'response',
  //   });
  // }

  // findStudent(matricule: string): Observable<EntityStudentResponse> {
  //   return this.http.get<IStudent>(`${this.studentResourceURL}/${matricule}`, {
  //     observe: 'response',
  //   });
  // }
}
