export function getPreferenceLabel(preference: number): string {
  let result = '';
  switch (preference) {
    case 1:
      result = 'Choix 1';
      break;

    case 2:
      result = 'Choix 2';
      break;

    case 3:
      result = 'Choix 3';
      break;

    case 4:
      result = 'Choix 4';
      break;

    case 5:
      result = 'Choix 5';
      break;

    case 6:
      result = 'Choix 6';
      break;

    case 7:
      result = 'Choix 7';
      break;

    case 8:
      result = 'Choix 8';
      break;

    default:
      result = 'Choix 9';
      break;
  }

  return result;
}
