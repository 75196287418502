<app-navbar></app-navbar>

<div class="content-wrapper">
  <div class="content-wrapper__main">
    <h1 class="mb-4 text-center">Vérification des statuts de paiements</h1>

    <div class="content-wrapper__card-check-paiement">
      <div class="box-input-search">
        <i class="pi pi-search"></i>
        <input type="text" placeholder="Ex: 20145136M" [(ngModel)] = "matriculeStudent">
        <button type="button" class="btn-virify-paiement" (click)="onVerifyPaiement()">Vérifier</button>
      </div>

      <hr class="divider">

      <div class="content-wrapper__box-result-paiement" *ngIf="messageDefault">
        <p>{{message}}</p>
      </div>

      <!-- paiement initiation -->
      <div class="content-wrapper__box-result-paiement" *ngIf="paimentInitiation">
        <h3>DETAIL</h3>
        <p class="mb-2">
          Session d'examen <span class="detail-info">{{resultPaiement?.exa_year?.yrs_academic}}</span> 
          Diplôme préparé <span class="detail-info">{{resultPaiement?.exa_diploma?.dip_shortlabel}}</span> 
          Filière <span class="detail-info">{{resultPaiement?.exa_studyfield?.sfd_shortlabel}}</span>
        <h3>RESULTAT</h3>
        <p class="p-inline">
          <img src="../assets/icons/initiation.svg" alt="initiation"/>
          <span>
            Votre de candidature est en cours d'initiation pour votre paiement. Veuillez vérifier plus tard
            afin de procédé au paiement sur <a href="#" class="link-tresor-pay">Tresor Pay</a>.
          </span>
        </p>
      </div>

      <!-- paiement en attente -->
      <div class="content-wrapper__box-result-paiement" *ngIf="paimentEntente">
        <h3>DETAIL</h3>
        <p class="mb-2">
          Session d'examen <span class="detail-info">{{resultPaiement?.exa_year?.yrs_academic}}</span> 
          Diplôme préparé <span class="detail-info">{{resultPaiement?.exa_diploma?.dip_shortlabel}}</span> 
          Filière <span class="detail-info">{{resultPaiement?.exa_studyfield?.sfd_shortlabel}}</span>
        </p>

        <h3>RESULTAT</h3>
        <p>
          Paiement en attente veuillez utiliser le code suivant: 
          <span class="result-info">{{resultPaiement?.exa_examcode}}</span> pour effectuer le paiement
          sur la plateforme de <a href="#" class="link-tresor-pay">Tresor Pay</a>.
        </p>
      </div>

      <!-- paiement effectué -->
      <div class="content-wrapper__box-result-paiement" *ngIf="paimentEffectue">
        <h3>DETAIL</h3>
        <p class="mb-2">
          Session d'examen <span class="detail-info">{{resultPaiement?.exa_year?.yrs_academic}}</span> 
          Diplôme préparé <span class="detail-info">{{resultPaiement?.exa_diploma?.dip_shortlabel}}</span> 
          Filière <span class="detail-info">{{resultPaiement?.exa_studyfield?.sfd_shortlabel}}</span>
        </p>

        <h3>RESULTAT</h3>
        <p>
          <i class="pi pi-check-circle icon-success"></i> Votre paiement a été effectué
        </p>
      </div>

      <!-- loader -->
      <div class="loader text-center" *ngIf="isLoader">
        <img src="../assets/loader.gif" alt="chargement"/>
      </div>
    </div>
  </div>
  
</div>
