import { ISchool } from '../model/school.model';

export function getOrderType(order: string, school: ISchool): boolean {
  if (Number(order) === 1) {
    return school?.sch_istech ? true : false;
  } else {
    return school?.sch_isprof ? true : false;
  }
}

export function getSchoolType(type: number): string {
  if (type === 1) {
    return 'PRIVE';
  }
  return 'PUBLIC';
}
