import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ImageModule } from 'primeng/image';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { DividerModule } from 'primeng/divider';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';
// import { EditorModule } from 'primeng/editor';
import { ChartModule } from 'primeng/chart';
import { TooltipModule } from 'primeng/tooltip';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PasswordModule } from 'primeng/password';
import { CaptchaModule } from 'primeng/captcha';
import { InputNumberModule } from 'primeng/inputnumber';
import { StepsModule } from 'primeng/steps';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { SplitterModule } from 'primeng/splitter';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { OrderListModule } from 'primeng/orderlist';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToolbarModule } from 'primeng/toolbar';

@NgModule({
  exports: [
    ButtonModule,
    BreadcrumbModule,
    InputTextModule,
    DropdownModule,
    RadioButtonModule,
    ImageModule,
    FileUploadModule,
    HttpClientModule,
    CheckboxModule,
    ToastModule,
    DialogModule,
    ConfirmPopupModule,
    MessagesModule,
    MessageModule,
    CardModule,
    TableModule,
    SidebarModule,
    ChipModule,
    ConfirmDialogModule,
    InputMaskModule,
    DividerModule,
    CalendarModule,
    MultiSelectModule,
    InputTextareaModule,
    ChartModule,
    TooltipModule,
    TabViewModule,
    ProgressSpinnerModule,
    PasswordModule,
    CaptchaModule,
    InputNumberModule,
    StepsModule,
    InputSwitchModule,
    DynamicDialogModule,
    SplitterModule,
    OverlayPanelModule,
    SkeletonModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    OrderListModule,
    DragDropModule,
    ToolbarModule,
    // EditorModule,
  ],
})
export class SharedLibModule {}
