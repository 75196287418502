import { IDiploma } from '../model/diploma.model';
import { ILevel } from '../model/level.model';

export const levels: ILevel[] = [
  {
    lvl_label: '1ere ANNEE',
    lvl_code: 1,
    lvl_tech: false,
  },
  {
    lvl_label: '2eme ANNNEE',
    lvl_code: 2,
    lvl_tech: false,
  },
  {
    lvl_label: '3eme ANNNEE',
    lvl_code: 3,
    lvl_tech: false,
  },
  {
    lvl_label: 'SECONDE (2nde)',
    lvl_code: 4,
    lvl_tech: true,
  },
  {
    lvl_label: 'PREMIERE (1ere)',
    lvl_code: 5,
    lvl_tech: true,
  },
  {
    lvl_label: 'TERMINALE (Tle)',
    lvl_code: 6,
    lvl_tech: true,
  },
  {
    lvl_label: 'ANNEE PREPARATOIRE (AP) BTS',
    lvl_code: 7,
    lvl_tech: false,
  },
  {
    lvl_label: 'PREPA 1ere ANNEE',
    lvl_code: 8,
    lvl_tech: false,
  },
  {
    lvl_label: 'PREPA 2eme ANNEE',
    lvl_code: 9,
    lvl_tech: false,
  },
];

const levelGroup = {
  DEFAULT: [
    {
      lvl_label: '1ere ANNNEE',
      lvl_code: 1,
      lvl_tech: false,
    },
    {
      lvl_label: '2eme ANNNEE',
      lvl_code: 2,
      lvl_tech: false,
    },
    {
      lvl_label: '3eme ANNNEE',
      lvl_code: 3,
      lvl_tech: false,
    },
    {
      lvl_label: 'SECONDE (2nde)',
      lvl_code: 4,
      lvl_tech: true,
    },
    {
      lvl_label: 'PREMIERE (1ere)',
      lvl_code: 5,
      lvl_tech: true,
    },
    {
      lvl_label: 'TERMINALE (Tle)',
      lvl_code: 6,
      lvl_tech: true,
    },
  ],
  BTS: [
    {
      lvl_label: 'ANNEE PREPARATOIRE (AP) BTS',
      lvl_code: 7,
      lvl_tech: false,
    },
    {
      lvl_label: '1ere ANNNEE',
      lvl_code: 1,
      lvl_tech: false,
    },
    {
      lvl_label: '2eme ANNNEE',
      lvl_code: 2,
      lvl_tech: false,
    },
  ],
  CAP: [
    {
      lvl_label: 'PREPA 1ere ANNEE',
      lvl_code: 8,
      lvl_tech: false,
    },
    {
      lvl_label: 'PREPA 2eme ANNEE',
      lvl_code: 9,
      lvl_tech: false,
    },
    {
      lvl_label: '1ere ANNNEE',
      lvl_code: 1,
      lvl_tech: false,
    },
    {
      lvl_label: '2eme ANNNEE',
      lvl_code: 2,
      lvl_tech: false,
    },
    {
      lvl_label: '3eme ANNNEE',
      lvl_code: 3,
      lvl_tech: false,
    },
  ],
};

export function getLevel(studyOrder: number, diploma: IDiploma): ILevel[] {
  let result: ILevel[] = [];

  if (diploma.dip_shortlabel?.toUpperCase() === 'CAP') {
    result = levelGroup.CAP;
  } else if (diploma.dip_shortlabel?.toUpperCase() === 'BTS') {
    result = levelGroup.BTS;
  } else {
    result = levelGroup.DEFAULT;
  }

  if (studyOrder === 1) {
    result = result.filter((item) => item.lvl_tech);
  } else if (studyOrder === 2) {
    result = result.filter((item) => !item.lvl_tech);
  }

  return result;
}

export function getLevelByDiploma(diploma: IDiploma): ILevel[] {
  let result: ILevel[] = [];

  if (diploma.dip_shortlabel?.toUpperCase() === 'CAP') {
    result = levelGroup.CAP;
  } else if (diploma.dip_shortlabel?.toUpperCase() === 'BTS') {
    result = levelGroup.BTS;
  } else {
    result = levelGroup.DEFAULT;
  }

  return result;
}
