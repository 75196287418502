export const CANDIDATURES = [
  {
    name: 'CANDIDAT OFFICIEL',
    code: 1,
  },
  {
    name: 'CANDIDAT COURS DU SOIR',
    code: 2,
  },
  {
    name: 'Candidat libre',
    code: 'free',
  },
  // {
  //   name: 'Candidat officiele banque',
  //   code: 'bankOfficial',
  // },
  // {
  //   name: 'Candidat libre banque',
  //   code: 'bankFree',
  // },
];
