import { Component, OnInit } from '@angular/core';
import { StepService } from 'src/app/inscription/step.service';
import {
  setStepDataToSS,
  setRegistrationStep,
} from 'src/app/shared/utils/step.utils';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../session-storage.service';
import { environment } from 'src/environments/environment';
import { StepsService } from 'src/app/dob/services/steps.service';
import { EEStepsService } from 'src/app/eexams/services/steps.service';
import { setFreeCandidateStepDataToSS } from '../../shared/utils/step.utils';
import { FreeCandidateStepService } from 'src/app/inscription/free-candidate-step.service';
import { IYear } from 'src/app/shared/model/year.model';
import { YearService } from 'src/app/services/year.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  users: any[] = [];

  showDialog = false;
  year?: IYear;

  constructor(
    private stepService: StepService,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private dobStepService: StepsService,
    private eeStepService: EEStepsService,
    private freeCandidateStepServie: FreeCandidateStepService,
    private yearService: YearService
  ) {}

  ngOnInit(): void {
    this.sessionStorage.clear();
    setStepDataToSS(this.sessionStorage);
    setRegistrationStep(this.sessionStorage);
    this.dobStepService.init(this.sessionStorage);
    this.eeStepService.init(this.sessionStorage);
    this.freeCandidateStepServie.init(this.sessionStorage);
    localStorage.setItem('registrationStarted', JSON.stringify(false));
    this.yearService.geActiveYear().subscribe((year) => (this.year = year));
  }
}
