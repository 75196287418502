import { Component, OnInit } from '@angular/core';
import { IDiploma } from 'src/app/shared/model/diploma.model';
import { ISchool } from 'src/app/shared/model/school.model';
import { IExamUnit } from '../../shared/model/examunit.model';
import { map, Observable } from 'rxjs';
import { IStudyField } from '../../shared/model/studyfield.model';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import {
  activeSidebarItem,
  setRegistrationPrevStep,
} from 'src/app/shared/utils/step.utils';
import { SIDEBAR_ITEMS_KEY } from 'src/app/shared/constant/step.constant';
import { Router } from '@angular/router';
import { StepService } from '../step.service';
import { SchoolService } from '../../services/school.service';
import { DiplomaService } from '../../services/diploma.service';
import { StudyfieldService } from '../../services/studyfield.service';
import { InscriptionService } from '../../inscription/inscription.service';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';
import { IStudent } from 'src/app/shared/model/student.model';
import { IRegistration } from 'src/app/shared/model/registration.model';
import { RegistrationService } from '../../services/registration.service';
import { Message, MessageService } from 'primeng/api';
import { IYear } from 'src/app/shared/model/year.model';
import { YearService } from 'src/app/services/year.service';
import {
  accessmodelist,
  accessModeBySchoolType,
} from 'src/app/shared/constant/accessmode.constant';
import {
  SCHOOLSTUDYORDER,
  SCHOOLTYPE,
} from 'src/app/shared/constant/schooltype.constant';
import { getLevel } from '../../shared/constant/studylevel.constant';
import { SessionStorageService } from 'src/app/session-storage.service';

@Component({
  selector: 'app-registration-infos-class',
  templateUrl: './registration-infos-class.component.html',
  styleUrls: ['./registration-infos-class.component.scss'],
})
export class RegistrationInfosClassComponent implements OnInit {
  displayDialog = false;
  loaded = false;

  studyfields$?: Observable<IStudyField[]>;
  studyfields: IStudyField[] = [];
  selectedStudyfield?: IStudyField;

  examunits: IExamUnit[] = [];
  selectedUnity: IExamUnit[] = [];

  diplomas$?: Observable<IDiploma[]>;
  diplomas: IDiploma[] = [];
  selectedDiploma?: IDiploma;

  schools$?: Observable<ISchool[]>;
  schools: ISchool[] = [];
  selectedSchool?: ISchool;

  levelsList: any[] = [];
  // AllLevels = levels;
  selectedLevel = 0;

  registratedStudent$?: Observable<IRegistration>;
  registratedStudent?: IRegistration;

  student$?: Observable<IStudent>;

  curStudent?: IStudent;
  curYear?: IYear;

  typeCandidature = 0;

  isNewUser = false;
  isDataLoading = false;

  accessmodelist: any[] = [];
  selectedAccessMode = '';

  schooltypeList = SCHOOLTYPE;
  selectedSchoolType = 0;
  schoolstudyorderList = SCHOOLSTUDYORDER;
  selectedSchoolStudyOrder = '';

  editForm = this.fb.group({
    reg_school: [null, [Validators.required]],
    reg_diploma: [null, [Validators.required]],
    reg_studyfield: [null, [Validators.required]],
    reg_level: [null, [Validators.required]],
    reg_etfpaccessmode: [null, [Validators.required]],
    sch_schooltype: [null, [Validators.required]],
    sch_schoolstudyorder: [null, [Validators.required]],
    // unity: [null, []],
  });

  msg: Message[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sidebarStepService: StepService,
    private schoolService: SchoolService,
    private diplomaService: DiplomaService,
    private studyFieldService: StudyfieldService,
    private inscriptionService: InscriptionService,
    private registrationService: RegistrationService,
    private messageService: MessageService,
    private yearService: YearService,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.accessmodelist = accessmodelist;
    const ref = JSON.parse(
      this.sessionStorage.getItem('registration')!
    )?.codeMena;
    const year = JSON.parse(this.sessionStorage.getItem('activeYear')!);

    this.diplomas$ = this.diplomaService.getDiplomas();
    this.studyfields$ = this.studyFieldService.getStudyField();
    this.student$ = this.inscriptionService.get(ref);

    this.student$.subscribe((student) => (this.curStudent = student));

    this.yearService.geActiveYear().subscribe((year) => (this.curYear = year));

    this.registrationService.retrieveStudent(ref, year).subscribe({
      next: (res) => {
        this.registratedStudent = res.body!;

        if (this.registratedStudent) {
          this.isNewUser = false;

          this.selectedSchoolType =
            this.registratedStudent?.reg_school?.sch_type?.scty_id!;
          this.selectedSchoolStudyOrder =
            this.registratedStudent.reg_diploma?.dip_shortlabel?.toUpperCase() ===
            'BAC'
              ? '1'
              : '2';

          this.handleLoadSchool();

          this.selectedSchool = this.registratedStudent?.reg_school;
          this.handleSchoolChange();

          this.selectedDiploma = this.registratedStudent?.reg_diploma;
          this.handleChangeDiploma();

          this.selectedStudyfield = this.registratedStudent?.reg_studyfield;

          this.selectedLevel = Number(this.registratedStudent?.reg_level);
          this.selectedAccessMode =
            this.registratedStudent?.reg_etfpaccessmode!;

          this.editForm.patchValue({
            reg_school: this.selectedSchool,
            reg_diploma: this.selectedDiploma,
            reg_studyfield: this.selectedStudyfield,
            reg_level: this.selectedLevel,
            reg_etfpaccessmode: this.selectedAccessMode,
            sch_schoolstudyorder: Number(this.selectedSchoolStudyOrder),
            sch_schooltype:
              this.registratedStudent?.reg_school?.sch_type?.scty_id!,
          });

          this.handleLevel();
        }
      },
      error: (err) => {
        this.isNewUser = true;
      },
    });
  }

  goPrev(): void {
    setRegistrationPrevStep(this.loadSidebarItemsFromLS(), this.sessionStorage);
    this.sidebarStepService.init(this.sessionStorage);
    this.router.navigate(['/', 'registration', 'infos-student']);
  }

  goNext(): void {
    activeSidebarItem(this.loadSidebarItemsFromLS(), this.sessionStorage);
    this.sidebarStepService.init(this.sessionStorage);
    this.router.navigate(['/', 'registration', 'validation']);
  }

  save(): void {
    const body = {
      reg_student: this.curStudent,
      reg_year: this.curYear,
      ...this.editForm.value,
    };

    this.loaded = false;
    this.registrationService.save(body).subscribe({
      next: () => {
        this.handleSuccess();
      },
    });
  }

  edit(): void {
    const year = JSON.parse(this.sessionStorage.getItem('activeYear')!);
    const ref = JSON.parse(
      this.sessionStorage.getItem('registration')!
    )?.codeMena;
    this.registrationService
      .editRegistrationSchoolOrStudyFieldOrDiploma(
        ref,
        year,
        this.editForm.value
      )
      .subscribe((res) => {
        this.handleSuccess();
      });
  }

  handleSuccess(): void {
    this.loaded = false;
    this.messageService.add({
      severity: 'success',
      detail: 'Informations enregistrées avec succèss',
    });
    this.goNext();
  }

  confirm(): void {
    this.loaded = true;
    this.displayDialog = false;

    if (!this.isNewUser && this.editForm.dirty) {
      this.edit();
    } else if (this.isNewUser) {
      this.save();
    } else {
      this.goNext();
    }
  }

  showDialog() {
    this.displayDialog = true;
  }

  loadExamunit(): void {}

  loadSidebarItemsFromLS(): any {
    return JSON.parse(this.sessionStorage.getItem(SIDEBAR_ITEMS_KEY)!);
  }

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }

  handleSchoolChange() {
    this.isDataLoading = true;
    const school_id = this.selectedSchool?.sch_id!;
    this.diplomas = [];
    this.studyfields = [];

    this.selectedDiploma = undefined;
    this.selectedStudyfield = undefined;

    this.registrationService
      .findSchoolDiplomas(school_id)
      .pipe(
        map((value) => {
          return value.body?.filter((item) => {
            if (Number(this.selectedSchoolStudyOrder) === 1) {
              return item.dip_shortlabel?.toLocaleUpperCase() === 'BAC';
            } else {
              return item.dip_shortlabel?.toLocaleUpperCase() !== 'BAC';
            }
          });
        })
      )
      .subscribe((diplomas) => {
        this.diplomas = diplomas || [];
        this.isDataLoading = false;
      });
  }

  handleChangeDiploma() {
    this.isDataLoading = true;
    const school_id = this.selectedSchool?.sch_id!;
    const diploma_id = this.selectedDiploma?.dip_id!;
    this.studyfields = [];
    this.selectedStudyfield = undefined;

    this.registrationService
      .findDiplomaStudyFields(school_id, diploma_id)
      .subscribe((res) => {
        this.isDataLoading = false;
        this.studyfields = res.body || [];
      });

    this.handleLevel();
  }

  handleLoadSchool(): void {
    this.levelsList = [];
    this.selectedLevel = 0;

    if (this.selectedSchoolType && this.selectedSchoolStudyOrder) {
      this.accessmodelist =
        Number(this.selectedSchoolType) === 1
          ? accessModeBySchoolType.private
          : accessModeBySchoolType.public;

      this.isDataLoading = true;
      this.schools = [];
      this.selectedSchool = undefined;
      this.schoolService
        .getSchools(this.selectedSchoolType, this.selectedSchoolStudyOrder)
        .subscribe((schools) => {
          this.isDataLoading = false;
          this.schools = schools || [];
          this.isDataLoading = false;
        });
    }
  }

  // updateLevel(): void {
  //   if (Number(this.selectedSchoolStudyOrder) === 1) {
  //     this.levelsList = this.AllLevels.filter((level) => level.tech);
  //   } else {
  //     this.levelsList = this.AllLevels.filter((level) => !level.tech);
  //   }
  // }

  handleLevel(): void {
    if (this.selectedSchoolStudyOrder && this.selectedDiploma) {
      this.levelsList = getLevel(
        Number(this.selectedSchoolStudyOrder),
        this.selectedDiploma
      );
    }
  }

  handleChangeLevel(): void {
    const body = {
      diploma: this.selectedDiploma?.dip_id,
      studyfield: this.selectedStudyfield?.sfd_id,
      school: this.selectedSchool?.sch_id,
      level: this.editForm.value.reg_level,
    };
    this.registrationService.checkForExamClassLevel(body).subscribe((res) => {
      if (res.result) {
        this.msg = [
          {
            severity: 'warn',
            summary: 'Inscription examen session ' + this.curYear?.yrs_session,
            detail: res.message,
          },
        ];

        this.editForm.patchValue({
          reg_level: null,
        });
      } else {
        this.msg = [];
      }
    });
  }
}
