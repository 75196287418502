import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { SharedModule } from '../shared/shared.module';
import { ExamPaymentCheckComponent } from './exam-payment-check/exam-payment-check.component';
import { LandingRoutingModule } from './landing-routing.module';

@NgModule({
  declarations: [HomeComponent, ExamPaymentCheckComponent],
  imports: [SharedModule, LandingRoutingModule],
  exports: [HomeComponent],
})
export class LandingModule {}
