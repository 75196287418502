export function getLevel(level: string): string {
  if (level == '1') {
    return '1ere ANNEE';
  } else if (level == '2') {
    return '2eme ANNEE';
  } else if (level == '3') {
    return '3eme ANNEE';
  } else if (level == '4') {
    return 'SECONDE (2nde)';
  } else if (level == '5') {
    return 'PREMIERE (1ere)';
  } else if (level == '6') {
    return 'TERMINALE (Tle)';
  } else if (level == '7') {
    return 'ANNEE PREPARATOIRE (AP) BTS';
  } else if (level == '8') {
    return 'PREPARE 1ere ANNEE';
  } else {
    return 'PREPARE 2eme ANNEE';
  }
}
