<p-toast></p-toast>
<form class="form-steps" [formGroup]="studentInfoForm" (ngSubmit)="showDialog()">

    <!-- steps two -->
    <div class="steps steps-two">
        <!-- <div class="steps-title mb-2">
            <h2>Identification de l'apprenant</h2>
            <small>Veuillez verifier ou renseigner ces champs</small>
        </div> -->

        <div class="mb-2">(<span class="required">*</span>) <strong>CHAMPS OBLIGATOIRE</strong></div>

        <div class="p-divider-content">
            <span class="p-tag"><i class="pi pi-user"></i> Information apprenant</span>
        </div>

        <div class="box-photo mb-2">
            <div class="photo">
                <img [src]="pictureUrl" alt="photo etudiant">
            </div>
            <div>
                <div class="mb-2">
                    <label for="photo" class="p-button">CHARGER VOTRE PHOTO</label>
                    <input type="file" id="photo" formControlName="bkc_picture" hidden (change)="onFileSelected($event)">
                </div>
                <!-- <button type="button" class="p-button mb-2">CHANGER DE PHOTO</button> <br> -->
                <small>- Assurez-vous que le fichier ne dépasse pas 1 Mb</small> <br>
                <small>- Le fichier doit faire 600 x 600 pixels</small>
            </div>
        </div>

        <!-- <div class="mb-2">
            <label for="matricule">Numéro matricule <span class="required">*</span></label>
            <input formControlName="bkc_menaref" type="text" id="matricule" placeholder="Ex: 12036541ES" >
            <p *ngIf="formSubmited && !studentInfoForm.controls?.['bkc_menaref']?.valid">
                <span class="required" *ngIf="bkc_menaref?.errors?.['required']" >veuillez renseigner le champ</span>
            </p>
        </div> -->

        <div class="two-children mb-2">
            <div>
                <label for="name">Nom <span class="required">*</span></label>
                <input formControlName="bkc_lastname" type="text" id="name" placeholder="Votre nom">
                <p *ngIf="formSubmited && !studentInfoForm.controls?.['bkc_lastname']?.valid">
                    <span class="required" *ngIf="bkc_lastname?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>
            <div>
                <label for="lastName">Prénom(2) <span class="required">*</span></label>
                <input formControlName="bkc_firstname" type="text" id="lastName" placeholder="Votre prénom">
                <p *ngIf=" formSubmited && !studentInfoForm.controls?.['bkc_firstname']?.valid">
                    <span class="required" *ngIf="bkc_firstname?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>
        </div>

        <div class="two-children mb-2">
            <div>
                <label for="dateNaissance">Date de naissance <span class="required">*</span></label>
                <input formControlName="bkc_birthdate" type="date" id="dateNaissance" placeholder="date de naissance">
                <p *ngIf=" formSubmited && !studentInfoForm.controls?.['bkc_birthdate']?.valid">
                    <span class="required" *ngIf="bkc_birthdate?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>
            <div>
                <label for="lieuNaissance">Lieu de naissance <span class="required">*</span></label>
                <input formControlName="bkc_birthplace" type="text" id="lieuNaissance" placeholder="Votre lieu de naissance">
                <p *ngIf=" formSubmited && !studentInfoForm.controls?.['bkc_birthplace']?.valid">
                    <span class="required" *ngIf="bkc_birthplace?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>
        </div>

        <div class="two-children mb-2">
            <div>
                <label for="gender">Genre <span class="required">*</span></label>
                <select formControlName="bkc_gender" name="" id="gender">
                    <option value="0">Selectionner votre genre</option>
                    <option value="F">FEMININ</option>
                    <option value="M">MASCULIN</option>
                </select>
                <p *ngIf=" formSubmited && !studentInfoForm.controls?.['bkc_gender']?.valid">
                    <span class="required" *ngIf="bkc_gender?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>
            <div>
                <label for="nationality">Nationalité <span class="required">*</span></label>
                <select formControlName="bkc_nationality" name="" id="nationality">
                    <option *ngFor="let nationality of nationalities" value="{{nationality.nat_id}}">{{ nationality.nat_label }}</option>
                </select>
                <p *ngIf=" formSubmited && !studentInfoForm.controls?.['bkc_nationality']?.valid">
                    <span class="required" *ngIf="bkc_nationality?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>
        </div>

        <!-- <div class="three-childreen mb-2">
            <div>
                <label for="numExtrait">Numéro de l'acte de naissance <span class="required">*</span></label>
                <input formControlName="bkc_birthdocnumber" type="text" id="numExtrait" placeholder="Votre numero d'acte de naissance"/>
                <p *ngIf=" formSubmited && !studentInfoForm.controls?.['bkc_birthdocnumber']?.valid">
                    <span class="required" *ngIf="bkc_birthdocnumber?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>

            <div>
                <label for="lieuExtrait">Lieu d'établissement de l'acte <span class="required">*</span></label>
                <input formControlName="bkc_birthdoclocation" type="text" id="lieuExtrait" placeholder="Lieu d'établissement de l'acte"/>
                <p *ngIf=" formSubmited && !studentInfoForm.controls?.['bkc_birthdoclocation']?.valid">
                    <span class="required" *ngIf="bkc_birthdoclocation?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>

            <div>
                <label for="dateExtrait">Date d'établissement de l'acte <span class="required">*</span></label>
                <input formControlName="bkc_birthdocdate" type="date" id="dateExtrait" placeholder="Date d'établissement de l'acte"/>
                <p *ngIf=" formSubmited && !studentInfoForm.controls?.['bkc_birthdocdate']?.valid">
                    <span class="required" *ngIf="bkc_birthdocdate?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>
        </div> -->

        <div class="three-childreen mb-2">
            <div>
                <label for="email">Adresse e-mail accessible </label>
                <input formControlName="bkc_email" type="text" id="email" placeholder="Votre email ou celui d'un parent"/>
            </div>

            <div>
                <label for="phoneOne">Téléphone 1 <span class="required">*</span></label>
                <input formControlName="bkc_phone1" type="text" id="phoneOne" placeholder="__________"/>
                <p *ngIf=" formSubmited && !studentInfoForm.controls?.['bkc_phone1']?.valid">
                    <span class="required" *ngIf="bkc_phone1?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>

            <div>
                <label for="phoneTwo">Téléphone 2 </label> <br>
                <input formControlName="bkx_phone2" type="text" id="phoneTwo" placeholder="__________"/>
            </div>
        </div>

        <div class="p-divider-content">
            <span class="p-tag"><i class="pi pi-user"></i> Detail Filière </span>
        </div>

        <div class="two-children mb-2">

            <div class="mb-2">
                <label for="diplome">Choisir votre Diplome</label>
                <select id="diplome" formControlName="fcr_diploma" (change)="getStudyfieldBySchoolIdAndDiplome($event)">
                    <option value=""></option>
                    <option *ngFor="let diploma of diplomas" value="{{diploma.dip_id}}">{{diploma.dip_longlabel}}</option>
                </select>
                <p *ngIf="formSubmited && !studentInfoForm.controls?.['fcr_diploma']?.valid">
                    <span class="required" *ngIf="fcr_diploma?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>

            <div class="filiere-unit__item mb-2">
                <label for="filiere">Choisir votre filiere</label>
                <select id="filiere" formControlName="fcr_studyfield">
                    <option *ngFor="let studyField of studyFields" value="{{studyField.sfd_id}}">{{studyField.sfd_longlabel}}</option>
                </select>
                <p *ngIf="formSubmited && !studentInfoForm.controls?.['fcr_studyfield']?.valid">
                    <span class="required" *ngIf="fcr_studyfield?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>

        </div>

        <!-- <div class="two-children mb-2">
            <div>
                <label for="nameFather">Nom et prénoms du père <span class="required">*</span></label>
                <input formControlName="bkc_fatherfullname" type="text" id="nameFather" placeholder="Nom et prénoms du père">
                <p *ngIf=" formSubmited && !studentInfoForm.controls?.['bkc_fatherfullname']?.valid">
                    <span class="required" *ngIf="bkc_fatherfullname?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>
            <div>
                <label for="nameMother">Nom et prénoms de la mère <span class="required">*</span></label>
                <input formControlName="bkc_motherfullname" type="text" id="nameMother" placeholder="Nom et prénoms de la mère">
                <p *ngIf=" formSubmited && !studentInfoForm.controls?.['bkc_motherfullname']?.valid">
                    <span class="required" *ngIf="bkc_motherfullname?.errors?.['required']" >veuillez renseigner le champ</span>
                </p>
            </div>
        </div> -->

        <div class="text-right box-btn">
            <!-- <a (click)="onPrevStep()">
                <i class="pi pi-arrow-left"></i>
                RETOUR
            </a> -->
            <div class="text-center loader" *ngIf="isLoader">
                <img src="../assets/loader.gif" alt="">
            </div>
            <button [disabled]="studentInfoForm.invalid" type="submit" class="p-button">
                ENREGISTRER
            </button>
        </div>
        
    </div>

</form>

<!-- confirm dialog -->
<p-dialog
  header="Comfirmation"
  [(visible)]="displayDialog"
  [style]="{ width: '500px' }"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
>
  <p style="display: flex; align-items: center">
    <i
      style="font-size: 2rem; color: red; margin-right: 1rem"
      class="pi pi-question-circle"
    ></i>
    Êtes-vous sûr des informations renseignées ?
  </p>

  <ng-template pTemplate="footer">
    <p-button
      (click)="displayDialog = false"
      icon="pi pi-times"
      label="Annuler"
      styleClass="p-button-secondary p-button-text"
    ></p-button>
    <p-button
        *ngIf="updateBtn"
        (click)="updateStudentInfoBank()"
        label="Modifier"
        styleClass="p-button"
    ></p-button>
    <p-button
        *ngIf="!updateBtn"
        (click)="onStudentInfoBank()"
        label="Confirmer"
        styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>