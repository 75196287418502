import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SessionStorageService } from 'src/app/session-storage.service';
import { StepsService } from '../../services/steps.service';

export interface IDOBStep {
  label: string;
  icon?: string;
  index: number;
  detail?: string;
  active?: boolean;
  validate?: boolean;
  last?: boolean;
}
@Component({
  selector: 'app-dob-layout',
  templateUrl: './dob-layout.component.html',
  styleUrls: ['./dob-layout.component.scss'],
})
export class DobLayoutComponent implements OnInit {
  items$: Observable<IDOBStep[]> = new Observable<IDOBStep[]>();

  constructor(
    private router: Router,
    private sessionStorage: SessionStorageService,
    private dobStepService: StepsService
  ) {}

  ngOnInit(): void {
    // this.router.events
    //   .pipe(filter((event: any) => event instanceof NavigationEnd))
    //   .subscribe(() => {
    //     console.log('olla');

    //     this.fetchData();
    //   });

    this.fetchData();
  }

  fetchData(): void {
    this.items$ = this.dobStepService.getStepItems(this.sessionStorage);
    this.dobStepService.getStepItems(this.sessionStorage).subscribe((steps) => {
      this.dobStepService.setActiveStep(sessionStorage, steps);
    });
  }
}
