<header class="home-header">
  <!-- <app-navbar></app-navbar> -->
  <div class="home-top">
    <div class="home-top__contact">
      <ul class="home-top__contact-list">
        <li class="home-top__contact-item">
          <i class="pi pi-envelope"></i>
          support@ersys-ci.net
        </li>
      </ul>
    </div>
    <ul class="home-top__menu">
      <li class="home-top__item">
        <a [routerLink]="['/account', 'login']" class="home-top__link">
          <i class="pi pi-sign-in"></i>
          Espace administrateur
        </a>
      </li>
    </ul>
  </div>

  <div class="home-navigation">
    <div class="home-navigation__logo">
      <a href="/">
        <img src="/assets/logo_ers.png" alt="Logo ERSYS" />
      </a>
    </div>

    <ul class="home-navigation__menu">
      <li class="home-navigation__item">
        <a [routerLink]="['/']" class="home-navigation__link"> Accueil </a>
      </li>

      
      <li class="home-navigation__item button">
        <a href="http://inscription.ersys-ci.net/" target="_blank"
          class="home-navigation__link p-button p-button-primary"
        >
          INSCRIPTION 2022-2023
        </a>
      </li>
      
<!--       <li class="home-navigation__item button">
        <a
          [routerLink]="['/registration', 'search']"
          class="home-navigation__link p-button p-button-second"
        >
          Enrolement 2021-2022
        </a>
      </li> -->




<!--       <li class="home-navigation__item button">
        <a
          href="https://concours.ersys-ci.net/postdobm"
          class="home-navigation__link p-button p-button-primary"
        >
          POST ORIENTATION
        </a>
      </li> -->
      <!-- <li class="home-navigation__item button">
        <a
          [routerLink]="['/concours', 'search']"
          class="home-navigation__link "
        >
          Concours
        </a>
      </li> -->
    </ul>
  </div>

  <div class="home-hero">
    <div class="home-hero-wrapper">
      <h1 class="home-hero-wrapper-title">
        Système de gestion des apprenants de l'ETFPA
      </h1>
      <p class="home-hero-wrapper-subtitle">
        Bienvenue sur la plateforme de gestion des apprenants du Ministère de
        l'Enseignement Technique, de la Formation Professionnelle et de
        l'Apprentissage
      </p>

      <div class="home-hero-action">
<!--         [routerLink]="['/registration', 'search']" -->
<!--         <a
          class="p-button p-button p-button p-button-primary"
          
          >INSCRIPTION 2022 - 2023
        </a> -->
        
        
<!--           [routerLink]="['/inscription/examen', 'registred-free-candidate']" -->

        <a href="http://inscription.ersys-ci.net/" target="_blank"
          class="p-button home-navigation__link p-button-main"
          >INSCRIPTION 2022-2023
        </a>
      </div>
    </div>

    <div class="hero-image">
      <img src="/assets/landing/illustrations/scene-3.svg" alt="Home hero" />
    </div>
  </div>
</header>

<!-- confirm dialog -->
<p-dialog
  header="OAT {{ year?.yrs_session }}"
  [(visible)]="showDialog"
  [style]="{ width: '500px' }"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
>
  <p style="display: flex; align-items: center">
    <i
      style="font-size: 2rem; color: red; margin-right: 1rem"
      class="pi pi-question-circle"
    ></i>
    Fin de l'opération de collecte des voeux d'orientation dans les Filières
    Techniques et Professionnelles.
  </p>

  <ng-template pTemplate="footer">
    <p-button
      (click)="showDialog = false"
      label="Fermer"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>
