<div class="finish-wrapper">
    <img src="/assets/registration/finish.svg" alt="" />
  
    <p>
      Félicitations, le processus de modification s'est terminé avec succès. Les
      informations modifiées seront mise à jour.
    </p>
  
    <button
      pButton
      type="button"
      label="Retour à l'accueil"
      (click)="goHome()"
    ></button>
</div>