<app-navbar></app-navbar>

<div class="dob-main-content">
  <div class="dob-main-content-wrapper center" style="padding-top: 10rem">
    <h1 class="dob-main-content-title">
      Félicitations vos informations d'orientation ont été enregistrées avec
      succès
    </h1>

    <img src="/assets/dob/dob_finish.svg" alt="" />

    <!-- <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque optio
      molestias tenetur beatae expedita. At adipisci commodi culpa esse, minima
      dolores, voluptatum voluptatibus odit tenetur
    </p> -->

    <div>
      <button
        pButton
        type="button"
        label="Retour à l'accueil"
        (click)="goHome()"
      ></button>
    </div>
  </div>
</div>
