import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IDiploma } from '../shared/model/diploma.model';
import { HttpResponse } from '@angular/common/http';
import { IExamUnit } from '../shared/model/examunit.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DiplomaService {
  private diplomaResourceURL = environment.apiURL + 'api/v1/diplomas';

  private diplomas$ = new BehaviorSubject<IDiploma[]>([]);

  constructor(private http: HttpClient) {}

  getDiplomas(): Observable<IDiploma[]> {
    this.loadDiplomas();
    return this.diplomas$;
  }

  loadDiplomas(): void {
    this.http
      .get<any[]>(`${this.diplomaResourceURL}/`)
      .subscribe((diplomas: any) => this.diplomas$.next(diplomas?.results!));
  }

  getDiplomaExamunit(diploma: IDiploma): Observable<HttpResponse<IExamUnit[]>> {
    return this.http.get<IExamUnit[]>(
      `${this.diplomaResourceURL}/${diploma.dip_id}/exam-units/`,
      { observe: 'response' }
    );
  }
}
