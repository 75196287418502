import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EexamsRoutingModule } from './eexams-routing.module';
import { EeApplicantComponent } from './components/ee-applicant/ee-applicant.component';
import { EeCheckMatriculeComponent } from './components/ee-check-matricule/ee-check-matricule.component';
import { EeChoiceComponent } from './components/ee-choice/ee-choice.component';
import { EeChoiceFormComponent } from './components/ee-choice-form/ee-choice-form.component';
import { EeFinishComponent } from './components/ee-finish/ee-finish.component';
import { EeLayoutComponent } from './components/ee-layout/ee-layout.component';
import { EeSummaryComponent } from './components/ee-summary/ee-summary.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    EeApplicantComponent,
    EeCheckMatriculeComponent,
    EeChoiceComponent,
    EeChoiceFormComponent,
    EeFinishComponent,
    EeLayoutComponent,
    EeSummaryComponent
  ],
  imports: [
    SharedModule,
    EexamsRoutingModule
  ]
})
export class EexamsModule { }
