<form [formGroup]="editForm">
  <p-toast key="studentInfoEdited"></p-toast>

  <div class="form-section">
    <div class="p-flex-between">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_lastname">Nom </label>
        <input
          type="text"
          pInputText
          formControlName="app_lastname"
          id="field_lastname"
          placeholder="Votre nom"
          [ngModel]="studentLastName"
          (ngModelChange)="studentLastName = $event?.toUpperCase()"
          [readonly]="true"
          class="rounded"
        />
      </div>

      <div class="p-field p-field-column">
        <div class="p-field p-field-column">
          <label class="p-field-label" for="field_app_firstname"
            >Prénom(s)
          </label>
          <input
            type="text"
            pInputText
            formControlName="app_firstname"
            id="field_app_firstname"
            placeholder="Votre prénom"
            [ngModel]="studentFirstName"
            (ngModelChange)="studentFirstName = $event?.toUpperCase()"
            [readonly]="true"
            class="rounded"
          />
        </div>
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_app_gender">Genre </label>
        <input
          type="text"
          pInputText
          formControlName="app_gender"
          id="field_app_gender"
          [ngModel]="selectedGender"
          [readonly]="true"
          class="rounded"
        />
      </div>
    </div>

    <div class="p-flex-between">
      <div class="p-field p-field-column">
        <div class="p-field p-field-column">
          <label class="p-field-label flex" for="field_app_nationality"
            >Nationalité

            <span
              *ngIf="
                !validation ||
                (validation && !validation?.oval_validationstatus)
              "
              (click)="handleEditNationality()"
              class="link link-main"
              ><i class="pi pi-pencil"></i> Modifier</span
            >
          </label>
          <input
            type="text"
            pInputText
            formControlName="app_nationality"
            id="field_app_nationality"
            [ngModel]="selectedNationality?.nat_label!"
            [readonly]="true"
            class="rounded"
          />
        </div>
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label flex" for="field_app_birthdate"
          >Date de naissance
          <span
            *ngIf="
              !validation || (validation && !validation?.oval_validationstatus)
            "
            (click)="handleEditBirthDate()"
            class="link link-main"
            ><i class="pi pi-pencil"></i> Modifier</span
          >
        </label>
        <input
          type="text"
          pInputText
          formControlName="app_birthdate"
          id="field_app_birthdate"
          [ngModel]="studentBirthDate"
          [readonly]="true"
          class="rounded"
        />
      </div>

      <div class="p-field p-field-column">
        <div class="p-field p-field-column">
          <label class="p-field-label" for="field_app_birthplace"
            >Lieu de naissance
          </label>
          <input
            type="text"
            pInputText
            formControlName="app_birthplace"
            id="field_app_birthplace"
            placeholder="Votre lieu de naissance"
            [ngModel]="studentBirthPlace"
            [readonly]="true"
            (ngModelChange)="studentBirthPlace = $event?.toUpperCase()"
            class="rounded"
          />
        </div>
      </div>
    </div>
  </div>
</form>
