import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subscribable } from 'rxjs';
import { ISchool } from 'src/app/shared/model/school.model';
import { IDiploma } from 'src/app/shared/model/diploma.model';
import { IStudyField } from 'src/app/shared/model/studyfield.model';
import { INationality } from 'src/app/shared/model/nationality.model';
import { environment } from 'src/environments/environment';
import { Message, MessageService } from 'primeng/api';
import { IStudent, Student } from 'src/app/shared/model/student.model';
import { InscriptionService } from '../../inscription/inscription.service';
import { StepService } from '../../registration/step.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SessionStorageService } from 'src/app/session-storage.service';
import { genders } from 'src/app/shared/constant/gender.contanst';
import { SIDEBAR_ITEMS_KEY } from '../../shared/constant/step.constant';
import * as moment from 'moment';
import {
  activeSidebarItem,
  setRegistrationPrevStep,
} from 'src/app/shared/utils/step.utils';
import { scrollToTop } from 'src/app/shared/utils/common.utils';
import {
  hasRequiredField,
  humanFileSize,
} from 'src/app/shared/utils/validator.utils';

@Component({
  selector: 'app-dashboard-dexc-update-student',
  templateUrl: './dashboard-dexc-update-student.component.html',
  styleUrls: ['./dashboard-dexc-update-student.component.scss']
})
export class DashboardDexcUpdateStudentComponent implements OnInit {

  editForm = this.fb.group({
    std_menaref: [null, [Validators.required]],
    std_lastname: [null, [Validators.required]],
    std_firstname: [null, [Validators.required]],
    std_birthdate: [null, [Validators.required]],
    std_birthplace: [null, [Validators.required]],
    std_gender: [null, [Validators.required]],
    std_nationality: [null, [Validators.required]],
    std_email: [null, [Validators.email, Validators.nullValidator]],
    std_motherfullname: [null, [Validators.required]],
    std_fatherfullname: [null, [Validators.required]],
    std_birthdocnumber: [null, [Validators.required]],
    std_birthdocdate: [null, [Validators.required]],
    std_birthdoclocation: [null, [Validators.required]],
    std_phone1: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    std_phone2: ['', []],
    std_picture: [null, [Validators.required]],
  });

  schools$?: Observable<ISchool[]>;
  selectedSchool?: ISchool;
  diplomas$?: Observable<IDiploma[]>;
  diplomas: IDiploma[] = [];
  selectedDiploma?: IDiploma;

  sectors$?: Observable<IStudyField[]>;
  selectedSector?: IStudyField;

  nationalities: INationality[] = [];
  selectedNationality?: INationality;

  genders: any[] = [];
  selectedGender = '';

  imgWidth = environment.imageWidthAllowed;
  imgHeight = environment.imageHeightAllowed;
  imgSize = environment.imageSizeAllowed;

  msgs: Message[] = [];
  isUploaded = false;
  studentPictureFile: FormData = new FormData();
  selectedFile?: File;
  isFileValid = false;
  isImageSizeOK = false;
  isImageDimOK = false;
  studentPictureURL = '';

  isSaving = false;
  showDialog = false;
  loaded = false;
  student?: IStudent;

  studentData?: any;

  studentLastName = '';
  studentFirstName = '';
  studentFatherName = '';
  studentMotherName = '';
  studentBirthPlace = '';
  studentBirthDocPlace = '';
  minBirthDateAllowed = false;

  subscription?: Subscribable<any>;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private inscriptionService: InscriptionService,
    private messageService: MessageService,
    private sidebarStepService: StepService,
    private domSanitizer: DomSanitizer,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadStudentDataFromLS(): void {
    this.messageService.clear('alertMessage');
    this.messageService.clear();

    this.studentData = JSON.parse(
      this.sessionStorage.getItem('foundedStudent')!
    );
    const meanaRef = JSON.parse(
      this.sessionStorage.getItem('registration')!
    )?.codeMena;

    this.genders = genders;

    if (this.studentData?.message && !this.studentData?.founded) {
      this.msgs = [
        {
          key: 'alertMessage',
          severity: 'warn',
          detail: this.studentData?.message,
        },
      ];
    } else if (this.studentData?.message && this.studentData?.founded) {
      this.msgs = [
        {
          key: 'alertMessage',
          severity: 'success',
          detail: this.studentData?.message,
        },
      ];
    }

    this.inscriptionService
      .getNationalities()
      .subscribe((res) => (this.nationalities = res.body || []));

    this.inscriptionService.getStudent(meanaRef).subscribe({
      next: (res) => {
        this.student = res.body || {};
        if (this.student) {
          this.selectedGender = this.student?.std_gender!;
          this.selectedNationality = this.student?.std_nationality!;
          this.studentPictureURL = `${environment.mediaURL}${this.student?.std_picture}`;
          this.studentLastName = this.student?.std_lastname!;
          this.studentFirstName = this.student?.std_firstname!;
          this.studentFatherName = this.student?.std_fatherfullname!;
          this.studentMotherName = this.student?.std_motherfullname!;
          this.studentBirthPlace = this.student?.std_birthplace!;
          this.studentBirthDocPlace = this.student?.std_birthdoclocation!;

          this.editForm.patchValue({
            std_menaref: this.student?.std_menaref,
            std_lastname: this.student?.std_lastname,
            std_firstname: this.student?.std_firstname,
            std_birthdate: this.student?.std_birthdate,
            std_birthplace: this.student?.std_birthplace,
            std_gender: this.student?.std_gender,
            std_nationality: this.student?.std_nationality,
            std_email: this.student?.std_email,
            std_phone1: this.student?.std_phone1,
            std_phone2: this.student?.std_phone2,
            std_picture: this.student?.std_picture,
            std_motherfullname: this.student?.std_motherfullname,
            std_fatherfullname: this.student?.std_fatherfullname,
            std_birthdocnumber: this.student?.std_birthdocnumber,
            std_birthdoclocation: this.student?.std_birthdoclocation,
            std_birthdocdate: this.student?.std_birthdocdate,
          });

          if (this.student?.std_picture) {
            this.isImageDimOK = true;
            this.isImageSizeOK = true;
          }
          if (this.student?.std_birthdate) this.handleDateChange();
        }
      },
      error: () => {
        this.editForm.patchValue({
          std_menaref: meanaRef,
        });
      },
    });
  }

  getStepDataFromSS(): any {
    return JSON.parse(this.sessionStorage.getItem(SIDEBAR_ITEMS_KEY)!);
  }

  goToNext(): void {
    this.isSaving = true;
    this.showDialog = false;

    if (this.studentData?.founded && this.editForm.dirty) {
      this.edit();
    } else if (!this.studentData?.founded) {
      const formData = this.editForm.value;
      const uploadData = new FormData();
      for (let i in formData) {
        if (formData[i] instanceof Blob) {
          const filename =
            this.studentLastName?.toLowerCase() +
            '-' +
            this.studentFirstName.toLowerCase() +
            '-' +
            moment(Date.now()).format('D-M-YYYY');

          const fileExt = formData[i].name.split('.').pop();
          const newFile = `${filename}.${fileExt}`;
          uploadData.append(i, formData[i], newFile ? newFile : '');
        } else if (i === 'std_nationality') {
          uploadData.append(i, formData[i]['nat_id']);
        } else {
          uploadData.append(i, formData[i]);
        }
      }
      this.save(uploadData);
    } else {
      this.isSaving = false;
      activeSidebarItem(this.loadSidebarItemsFromLS(), this.sessionStorage);
      this.sidebarStepService.init(this.sessionStorage);
      this.router.navigate(['dashboard', 'dexc-update', 'update-class']);
    }
    this.sessionStorage.setItem(
      'foundedStudent',
      JSON.stringify({ founded: true, message: '' })
    );
  }

  goPrev(): void {
    this.sessionStorage.removeItem('foundedStudent');
    setRegistrationPrevStep(this.loadSidebarItemsFromLS(), this.sessionStorage);
    this.sidebarStepService.init(this.sessionStorage);
    this.router.navigate(['dashboard', 'dexc-update', 'home']);
  }

  save(student: FormData): void {
    this.inscriptionService.save(student).subscribe({
      next: (res) => {
        this.onCreatedOrEditedSuccess();
      },
      error: (err) => {
        this.onCreatedOrEditError();
      },
    });
  }

  edit(): void {
    this.inscriptionService
      .update(this.student?.std_menaref!, this.editForm.value)
      .subscribe({
        next: (res) => {
          this.onCreatedOrEditedSuccess();
        },
        error: (err) => {
          this.onCreatedOrEditError();
        },
      });
  }

  onCreatedOrEditError(): void {
    this.messageService.add({
      key: 'alertMessage',
      severity: 'error',
      detail: "Erreur d'enregistrement, veuillez vérifier vos informations",
    });
    this.isSaving = false;
    scrollToTop();
  }

  onCreatedOrEditedSuccess(): void {
    // this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      key: 'studentInfoEdited',
      summary: 'Enregistrement',
      detail: 'Vos information ont été enregistré',
    });

    setTimeout(() => {
      this.isSaving = false;
      activeSidebarItem(this.loadSidebarItemsFromLS(), this.sessionStorage);
      this.sidebarStepService.init(this.sessionStorage);
      this.router.navigate(['/', 'registration', 'infos-class']);
    }, 2000);
  }

  openConfirmDialog(): void {
    this.showDialog = true;
  }

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }

  loadSidebarItemsFromLS(): any {
    return JSON.parse(this.sessionStorage.getItem(SIDEBAR_ITEMS_KEY)!);
  }

  loadPage(): void {
    this.loadStudentDataFromLS();
  }

  // Birth date validation
  handleDateChange(): void {
    const studentBirthDate = this.editForm.controls['std_birthdate'].value;
    if (studentBirthDate.length >= 4) {
      const dateDiff =
        new Date().getFullYear() - new Date(studentBirthDate).getFullYear();

      if (dateDiff < 10) {
        this.editForm.controls['std_birthdate'].setErrors({ incorrect: true });
        this.minBirthDateAllowed = false;
      } else {
        this.minBirthDateAllowed = true;
      }
    }
  }

  // File upload
  onFileUpload(): void {
    this.isUploaded = true;
    this.studentPictureFile.append(
      'picture',
      this.selectedFile!,
      this.selectedFile?.name
    );
    this.inscriptionService
      .uploadStudentPicture(this.student?.std_menaref!, this.studentPictureFile)
      .subscribe((data) => {
        this.student = data.body;
        this.studentPictureURL =
          environment.mediaURL + this.student?.std_picture;
        this.isUploaded = false;
        this.editForm.patchValue({
          picture: this.student?.std_picture,
        });
      });
  }

  onFileChange(event: any): void {
    const _URL = window.URL || window.webkitURL;
    this.messageService.clear('alertMessage');
    this.selectedFile = event.target.files[0];
    let file: File;

    if ((file = event.target.files[0])) {
      const img = new Image();

      img.onload = () => {
        if (humanFileSize(file.size) > 1) {
          this.isFileValid = false;
          this.isImageSizeOK = false;
          this.messageService.add({
            key: 'alertMessage',
            severity: 'error',
            detail: `Le fichier ne doit pas dépasser ${this.imgSize} Mg`,
          });
          scrollToTop();
        } else if (img.width > this.imgWidth || img.height > this.imgHeight) {
          this.isFileValid = false;
          this.isImageDimOK = false;

          this.messageService.add({
            key: 'alertMessage',
            severity: 'error',
            detail: `La dimension autorisée est de ${this.imgWidth} par ${this.imgHeight} pixels`,
          });
          scrollToTop();
        } else {
          this.isImageDimOK = true;
          this.isImageSizeOK = true;
          if (this.studentData?.founded) {
            this.onFileUpload();
          } else {
            this.editForm.get('std_picture')?.setValue(this.selectedFile);
          }
        }
      };
      img.onerror = () => {
        this.isFileValid = false;
        this.messageService.add({
          key: 'alertMessage',
          severity: 'error',
          detail:
            'Le fichier doit être une image avec pour extension possible (jpg, png. jpeg)',
        });
        scrollToTop();
      };
      img.src = _URL.createObjectURL(file);
      this.studentPictureURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
        img.src
      ) as string;
    }
  }

}
