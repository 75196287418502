<p class="dashboard-title">
  Bienvenue, {{ authUser?.last_name! | uppercase }}
  {{ authUser?.first_name! | uppercase }}.
</p>

<ng-container *ngIf="authUser?.role?.includes('ROLE_DOB_ADMIN')">
  <div class="dashboard-section">
    <!-- <p class="dashboard-section-title">Statistique des enregistrements</p> -->
    <div class="dashboard-section__header">
      <!-- <h3>Apprenants po établissements</h3> -->
      <div>&nbsp;</div>
      <a
        [routerLink]="['/dashboard', 'dob', 'applicants']"
        class="link link-main"
      >
        Afficher les détails <i class="pi pi-arrow-right"></i>
      </a>
    </div>
    <div class="dashboard-validation__statistics">
      <div class="dashboard-validation__statistics-item">
        <span class="dashboard-validation__statistics-value">{{
          totalApplicant
        }}</span>

        <div class="dashboard-validation__statistics-text">
          <span class="dashboard-validation__statistics-label"
            >Total des candidatures <span>enregistrées</span>
          </span>
        </div>
      </div>

      <div class="dashboard-validation__statistics-item">
        <span class="dashboard-validation__statistics-value success">{{
          totalValidated
        }}</span>

        <div class="dashboard-validation__statistics-text">
          <span class="dashboard-validation__statistics-label"
            >Total des candidatures <span>Validées</span>
          </span>
        </div>
      </div>

      <div class="dashboard-validation__statistics-item">
        <span class="dashboard-validation__statistics-value danger">{{
          totalNotValidated
        }}</span>
        <div class="dashboard-validation__statistics-text">
          <span class="dashboard-validation__statistics-label"
            >Total des candidatures <span>Non validées</span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="dashboard-section"
    *ngIf="authUser?.role?.includes('ROLE_DOB_ADMIN')"
  >
    <p class="dashboard-section-title">Statistique par direction régionales</p>

    <p-table
      [value]="applicantsRegionalStatistics!"
      [paginator]="true"
      [rows]="6"
      [showCurrentPageReport]="true"
      responsiveLayout="scroll"
      currentPageReportTemplate="Affichage de {first} à {last} des {totalRecords} enregistrements"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Direction Regionale</th>
          <th class="text-right">Total Origine</th>
          <th class="text-right">Total Enregistré</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr *ngIf="!isLoading">
          <td>
            {{ item?.regional }}
          </td>
          <td class="text-right">
            {{ item?.origin }}
          </td>
          <td class="text-right">
            {{ item?.registration }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="dashboard-loader" *ngIf="isLoading">
    <p-progressSpinner
      [style]="{ width: '50px', height: '50px' }"
    ></p-progressSpinner>
  </div>
</ng-container>

<!-- DOB USER -->
<ng-container *ngIf="authUser?.role?.includes('ROLE_DOB_USER')">
  <div class="dashboard-section">
    <!-- <p class="dashboard-section-title">Statistique des enregistrements</p> -->
    <div class="dashboard-section__header">
      <!-- <h3>Apprenants po établissements</h3> -->
      <div>&nbsp;</div>
      <a
        [routerLink]="['/dashboard', 'dob', 'user-choices-registrations']"
        class="link link-main"
      >
        Afficher les détails <i class="pi pi-arrow-right"></i>
      </a>
    </div>
    <div class="dashboard-validation__statistics">
      <div class="dashboard-validation__statistics-item">
        <span class="dashboard-validation__statistics-value">{{
          totalApplicant
        }}</span>

        <div class="dashboard-validation__statistics-text">
          <span class="dashboard-validation__statistics-label"
            >Total des candidatures <span>enregistrées</span>
          </span>
        </div>
      </div>

      <div class="dashboard-validation__statistics-item">
        <span class="dashboard-validation__statistics-value success">{{
          totalValidated
        }}</span>

        <div class="dashboard-validation__statistics-text">
          <span class="dashboard-validation__statistics-label"
            >Total des candidatures <span>Validées</span>
          </span>
        </div>
      </div>

      <div class="dashboard-validation__statistics-item">
        <span class="dashboard-validation__statistics-value danger">{{
          totalNotValidated
        }}</span>
        <div class="dashboard-validation__statistics-text">
          <span class="dashboard-validation__statistics-label"
            >Total des candidatures <span>Non validées</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- END DOB USER -->
