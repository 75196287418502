<p class="dashboard-title">Liste des établissements</p>

<div class="dashboard-section-filter-transparent">
  <div class="dashboard-section-filter-actions">
    <div class="dashboard-section-filter-search">
      <input
        type="text"
        pInputText
        class="rounded"
        placeholder="Recherche par nom ou code DSPS"
        [(ngModel)]="searchSchool"
        (ngModelChange)="handleFilterBySchoolNameOrDSPSCODE()"
      />
    </div>

    <div class="dashboard-section-filter-item" style="width: 400px">
      <p-multiSelect
        [options]="locations"
        [(ngModel)]="selectedLocation"
        optionLabel="loc_label"
        optionValue="loc_id"
        styleClass="rounded"
        defaultLabel="Selectionner la localisation"
        display="chip"
        filterPlaceHolder="Recherche la localisation"
        (onChange)="handleLocationChange()"
        [style]="{ width: '100%' }"
      >
      </p-multiSelect>
    </div>
  </div>
</div>

<div class="dashboard-section">
  <!-- <p-card> -->
  <p-table
    #registrationtable
    [value]="filteredRegionalSchoolsData"
    [paginator]="true"
    [rows]="9"
    [alwaysShowPaginator]="false"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    currentPageReportTemplate="Affichage de {first} à {last} des {totalRecords} enregistrements"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Etablissement</th>
        <th>Localisation</th>
        <th class="text-right">Total enregistré</th>
        <th class="text-right">Total en attente</th>
        <th class="text-right">Total validé</th>
        <th class="text-right">Total rejeté</th>
        <!-- <th style="text-align: center">Actions</th> -->
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-detail>
      <tr *ngIf="!isLoading">
        <td style="display: flex; flex-direction: column">
          <span style="opacity: 0.5">{{ detail?.school?.sch_dspscode }}</span>
          <span>{{ detail?.school?.sch_longlabel }}</span>
        </td>

        <td>{{ detail?.school?.sch_location?.loc_label }}</td>

        <td class="text-right">
          {{ detail?.statistics?.total ? detail?.statistics?.total : "0" }}
        </td>

        <td class="text-right">
          {{ detail?.statistics?.pending ? detail?.statistics?.pending : "0" }}
        </td>
        <td class="text-right">
          {{
            detail?.statistics?.validated ? detail?.statistics?.validated : "0"
          }}
        </td>
        <td class="text-right">
          {{
            detail?.statistics?.rejected ? detail?.statistics?.rejected : "0"
          }}
        </td>
        <!-- <td style="text-align: center">
          <button
            class="p-button p-button-outlined p-button-secondary"
            (click)="showDetail(registration)"
          >
            Details
          </button>
        </td> -->
      </tr>
    </ng-template>
  </p-table>
  <div
    class="empty-table-message-block"
    *ngIf="filteredRegionalSchoolsData.length === 0 && !isLoading"
  >
    <p>Aucun établissement trouvé</p>
  </div>
  <div
    *ngIf="isLoading"
    class="loader"
    style="display: flex; justify-content: center"
  >
    <img src="/assets/loader.gif" />
  </div>
  <!-- </p-card> -->
</div>
