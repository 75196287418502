import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, combineLatestWith, map } from 'rxjs/operators';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { IDiploma } from '../shared/model/diploma.model';
import { IStudyField, StudyField } from '../shared/model/studyfield.model';
import { IUser } from 'src/app/account/model/user.model';
import { AuthService } from '../account/auth.service';
import { IRegistration } from 'src/app/shared/model/registration.model';
import { ACCESS_TOKEN_NAME } from '../shared/constant/token.constant';
import { environment } from 'src/environments/environment';
import { IRegistrationStatus } from '../registration/registration-status/registration-status.component';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  private registrationResourceURL = environment.apiURL + 'api/v1/registrations';
  private registrationSchoolDiplomaURL = environment.apiURL + 'api/v1/schools';

  private registratedStudent$ = new BehaviorSubject<IRegistration>({});
  authUser?: IUser;

  constructor(private http: HttpClient, private authService: AuthService) {}

  save(body: IRegistration): Observable<HttpResponse<IRegistration>> {
    return this.http.post(`${this.registrationResourceURL}/`, body, {
      observe: 'response',
    });
  }

  findAll(user: IUser): Observable<IRegistration[]> {
    return this.http
      .get<IRegistration[]>(`${this.registrationResourceURL}/`)
      .pipe(
        map((registrations) => {
          return registrations.filter((registration) => {
            if (user?.role?.toUpperCase() === 'ROLE_SCHOOL_ADMIN') {
              return (
                user.usr_school?.sch_id === registration.reg_school?.sch_id
              );
            }
            return registration;
          });
        })
      );
  }

  findSchoolRegistrations(
    school_id: string
  ): Observable<HttpResponse<IRegistration[]>> {
    return this.http.get<IRegistration[]>(
      `${this.registrationResourceURL}/${school_id}/`,
      { observe: 'response' }
    );
  }

  getSchoolRegistrationStatistics(
    school_id: string
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.registrationResourceURL}/${school_id}/registration-statistics/`,
      {
        observe: 'response',
      }
    );
  }
  // findAll(): Observable<IRegistration[]> {
  //   return this.http
  //     .get<IRegistration[]>(`${this.registrationResourceURL}/`)
  //     .pipe(
  //       combineLatestWith(this.authService.getAuthUser()),
  //       map(([registrations, user]) => {
  //         return registrations.filter((registration) => {
  //           if (user?.role?.toUpperCase() === 'ROLE_SCHOOL_ADMIN') {
  //             return (
  //               user.usr_school?.sch_id === registration.reg_school?.sch_id
  //             );
  //           }
  //           return registration;
  //         });
  //       })
  //     );
  // }

  getAllRegistrations(): Observable<HttpResponse<IRegistrationStatus[]>> {
    return this.http.get<IRegistrationStatus[]>(
      `${this.registrationResourceURL}/status/`,
      {
        observe: 'response',
      }
    );
  }

  findAllByAuthUserSchool(
    school_id: string
  ): Observable<HttpResponse<IRegistration[]>> {
    return this.http.get<IRegistration[]>(
      `${this.registrationResourceURL}/auth-school-registrations/${school_id}`,
      { observe: 'response' }
    );
  }

  findSchoolDiplomas(id_school: string): Observable<HttpResponse<IDiploma[]>> {
    return this.http.get<IDiploma[]>(
      `${this.registrationSchoolDiplomaURL}/${id_school}/diplomas/`,
      { observe: 'response' }
    );
  }

  // findSchoolStudyField(
  //   id_school: string
  // ): Observable<HttpResponse<IStudyField[]>> {
  //   return this.http.get<IStudyField[]>(
  //     `${this.registrationSchoolDiplomaURL}/${id_school}/studyfields/`,
  //     { observe: 'response' }
  //   );
  // }

  findDiplomaStudyFields(
    id_school: string,
    id_diploma: string
  ): Observable<HttpResponse<IStudyField[]>> {
    return this.http.get<IStudyField[]>(
      `${this.registrationSchoolDiplomaURL}/${id_school}/${id_diploma}/studyfield/`,
      { observe: 'response' }
    );
  }

  findStudyFieldByAuthUserSchool(
    school_id: string
  ): Observable<HttpResponse<IStudyField[] | any>> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: localStorage.getItem(ACCESS_TOKEN_NAME)!,
    //   }),
    //   observe: 'response',
    // };

    return this.http.get<IStudyField[] | any>(
      `${this.registrationSchoolDiplomaURL}/${school_id}/studyfield/`,
      { observe: 'response' }
    );
  }

  searchRegistrationByCriteria(
    criteria: any,
    school_id: string
  ): Observable<IRegistration[]> {
    return this.http.post<IRegistration[]>(
      `${this.registrationResourceURL}/${school_id}/search-registrations/`,
      criteria
    );
  }

  retrieveStudent(
    student: string,
    year: string
  ): Observable<HttpResponse<IRegistration>> {
    return this.http.get<IRegistration>(
      `${this.registrationResourceURL}/update-registration-on-exam/${student}/${year}/`,
      { observe: 'response' }
    );
    // .pipe(catchError(this.handleError));
  }

  getRegistratedStudent(
    student: string,
    year: string
  ): Observable<IRegistration> {
    this.loadRegistratedStudent(student, year);
    return this.registratedStudent$;
  }

  loadRegistratedStudent(student: string, year: string): void {
    this.http
      .get<IRegistration>(
        `${this.registrationResourceURL}/update-registration-on-exam/${student}/${year}/`
      )
      .pipe(catchError(this.handleError))
      .subscribe((registration) => this.registratedStudent$.next(registration));
  }

  private handleError(error: any) {
    return throwError(error);

    // if (error instanceof catchError) {
    //   return throwError(error);
    // } else {
    //   return throwError(error);
    // }
  }

  validateRegistration(
    reg_id: string,
    body: any
  ): Observable<HttpResponse<IRegistration>> {
    return this.http.patch<IRegistration>(
      `${this.registrationResourceURL}/validation/${reg_id}/`,
      body,
      { observe: 'response' }
    );
  }

  editRegistrationSchoolOrStudyFieldOrDiploma(
    student: string,
    year: string,
    body: IRegistration
  ): Observable<HttpResponse<IRegistration>> {
    return this.http.put<IRegistration>(
      `${this.registrationResourceURL}/update-registration-on-exam/${student}/${year}/`,
      body,
      {
        observe: 'response',
      }
    );
  }

  checkForExamClassLevel(body: any): Observable<any> {
    return this.http.post<any>(
      `${this.registrationResourceURL}/check-exam-class-level/`,
      body,
      {
        observe: 'body',
      }
    );
  }
}
