import { Component, OnInit } from '@angular/core';
import { IExamenBreadcrumb } from '../../shared/model/examen-breadcrumb.model';
import { StepService } from '../../registration/step.service';
import { SessionStorageService } from '../../session-storage.service';

@Component({
  selector: 'app-dashboard-dexc-update-sidebar',
  templateUrl: './dashboard-dexc-update-sidebar.component.html',
  styleUrls: ['./dashboard-dexc-update-sidebar.component.scss']
})
export class DashboardDexcUpdateSidebarComponent implements OnInit {

  items: IExamenBreadcrumb[] = [];
  itemsLength = 0;

  constructor(
    private stepService: StepService,
    private sessionStorage: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.stepService.getSidebarItems(this.sessionStorage).subscribe((data) => {
      this.items = data;
      this.itemsLength = this.items?.length;
    });
  }

}
