export function retreive_type_candidature_label(code: number): string {
  let result = ''
  switch (code) {
    case 1:
      result = 'CANDIDAT OFFCIEL';
      break;

    case 2:
      result = 'CANDIDAT COURS DU SOIR';
      break;

    default:
      break;
  }
  return result;
}


export function retreive_validation_status_label(code: number): string {
  let result = '';
  switch (code) {
    case 1:
      result = 'En attente';
      break;

    case 2:
      result = 'Validé';
      break;

    case 3:
      result = 'Rejeté';
      break;

    default:
      break;
  }
  return result;
}
