<app-navbar></app-navbar>

<div class="examen-section" style="margin-top: 4rem">
  <div class="examen-section__content">
    <h3>Création de compte utilisateur</h3>

    <p-messages [(value)]="msgs"></p-messages>

    <form [formGroup]="editForm">
      <!-- LastName -->
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_last_name"
          >Nom
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["last_name"])
          }}</span>
        </label>
        <input
          type="text"
          pInputText
          formControlName="last_name"
          id="field_last_name"
          [ngModel]="lastName"
          (ngModelChange)="lastName = $event?.toUpperCase()"
        />
        <small
          *ngIf="
            editForm.get('last_name')?.errors &&
            (editForm.get('last_name')?.errors)!['required'] &&
            (editForm.controls['last_name'].dirty ||
              editForm.controls['last_name'].touched)
          "
          class="p-field-help"
          id="field_last_name-help"
          >Ce champ est obligatore.</small
        >
      </div>

      <!-- FirstName -->
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_first_name"
          >Prénoms
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["first_name"])
          }}</span>
        </label>
        <input
          type="text"
          pInputText
          formControlName="first_name"
          id="field_first_name"
          [ngModel]="firstName"
          (ngModelChange)="firstName = $event?.toUpperCase()"
        />
        <small
          *ngIf="
            editForm.get('first_name')?.errors &&
            (editForm.get('first_name')?.errors)!['required'] &&
            (editForm.controls['first_name'].dirty ||
              editForm.controls['first_name'].touched)
          "
          class="p-field-help"
          id="field_first_name-help"
          >Ce champ est obligatore.</small
        >
      </div>

      <!-- Mot de passe -->
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_password"
          >Mot de passe
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["password"])
          }}</span>
        </label>
        <p-password formControlName="password">
          <ng-template pTemplate="header">
            <h6>Entrer votre mot de passe</h6>
          </ng-template>
          <ng-template pTemplate="footer">
            <p-divider></p-divider>
            <p>Suggestions</p>
            <ul style="line-height: 1.5">
              <li>Au moins de 8 caractères</li>
              <li>Au moins un chiffre</li>
              <li>Au moins une lettre en minuscule</li>
              <li>Au moins une lettre en majuscule</li>
            </ul>
          </ng-template>
        </p-password>

        <small
          *ngIf="
            editForm.get('password')?.errors &&
            (editForm.get('password')?.errors)!['required'] &&
            (editForm.controls['password'].dirty ||
              editForm.controls['password'].touched)
          "
          class="p-field-help"
          id="field_password-help"
          >Ce champ est obligatore.</small
        >
      </div>

      <!-- Confirm Mot de passe -->
      <div class="p-field p-field-column">
        <label class="p-field-label" for="field_password_confirm"
          >Confirmation du mot de passe
          <span class="color-danger">{{
            fieldIsRequired(editForm.controls["password_confirm"])
          }}</span>
        </label>
        <input pInputText type="password" formControlName="password_confirm" />

        <small
          *ngIf="
            editForm.get('password_confirm')?.errors &&
            (editForm.get('password_confirm')?.errors)!['required'] &&
            (editForm.controls['password_confirm'].dirty ||
              editForm.controls['password_confirm'].touched)
          "
          class="p-field-help"
          id="field_password_confirm-help"
          >Ce champ est obligatore.</small
        >

        <small
          *ngIf="
            editForm.get('password_confirm')?.errors &&
            (editForm.get('password_confirm')?.errors)!['mustMatch'] &&
            (editForm.controls['password_confirm'].dirty ||
              editForm.controls['password_confirm'].touched)
          "
          class="p-field-help"
          id="field_password_confirm-help"
          >Mot de passe non identique.</small
        >
      </div>
      <!--
      <div class="p-field p-field-column">
        <p-captcha
          [siteKey]="siteKey"
          (onResponse)="showResponse($event)"
        ></p-captcha>
      </div> -->

      <div
        *ngIf="isSaving"
        style="display: flex; justify-content: center; margin-button: 4rem"
      >
        <p-progressSpinner></p-progressSpinner>
      </div>

      <div class="p-field p-field-column">
        <button
          [disabled]="editForm.invalid || isSaving"
          pButton
          type="button"
          label="Enregistrer"
          (click)="handleUserUpdate()"
        ></button>
      </div>
    </form>
  </div>
</div>
