<div class="examen-section examen-section-fc">
  <div class="text-center mb-4">
    <h1>CANDIDAT LIBRE - INSCRIPTION</h1>
  </div>

  <!-- stepper registration free candidate -->
  <ul class="steppers">
    <li [class]="{ active: step == 1 || step == 2 || step == 3 || step == 4 }">
      01 - Recherche
    </li>
    <li [class]="{ active: step == 2 || step == 3 || step == 4 }">
      02 - Identification
    </li>
    <li [class]="{ active: step == 3 || step == 4 }">03 - Détail Examen</li>
    <li [class]="{ active: step == 4 }">04 - Recapitulatif</li>
  </ul>

  <div class="examen-section">
    <!-- steps one -->
    <app-examen-free-search
      [student]="student"
      *ngIf="currentStep == 1"
      (nextStep)="nextStep($event)"
    ></app-examen-free-search>

    <!-- steps two -->
    <app-examen-free-info
      [student]="student"
      [candidateFounded]="isCandidateFound"
      *ngIf="currentStep == 2"
      (prevStep)="prevStep()"
      (nextStep)="nextStep($event)"
    ></app-examen-free-info>

    <!-- steps three -->
    <app-examen-free-filiere
      [student]="student"
      *ngIf="currentStep == 3"
      (prevStep)="prevStep()"
      (nextStep)="nextStep($event)"
    ></app-examen-free-filiere>

    <!-- steps four -->
    <app-examen-free-confirmation
      [student2]="student2"
      *ngIf="currentStep == 4"
      (prevStep)="prevStep()"
    ></app-examen-free-confirmation>
  </div>
</div>
