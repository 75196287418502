import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';
import * as moment from 'moment';
import { Message, MessageService } from 'primeng/api';
import { FreeCandidateService } from 'src/app/services/free-candidate.service';
import { INationality } from 'src/app/shared/model/nationality.model';
import { IStudent } from 'src/app/shared/model/student.model';
import { genders } from 'src/app/shared/constant/gender.contanst';
import { SessionStorageService } from 'src/app/session-storage.service';
import { IFreeCandidate } from 'src/app/shared/model/free-candidate';
import { IPreviousFreeCandidate } from 'src/app/shared/model/previous-free-candidate.model';
import { FREE_CANDIDATE_INFO_KEY } from 'src/app/shared/constant/step.constant';
import { hasRequiredField } from 'src/app/shared/utils/validator.utils';
import { environment } from 'src/environments/environment';
import { path } from 'ramda';

@Component({
  selector: 'app-examen-free-info',
  templateUrl: './examen-free-info.component.html',
  styleUrls: ['./examen-free-info.component.scss'],
})
export class ExamenFreeInfoComponent implements OnInit {
  isLoader: boolean = false;
  displayDialog = false;
  formSubmited: boolean = false;
  formDone: boolean = false;
  nationalities: INationality[] = [];
  selectedNationality?: INationality;
  pictureStudent!: File;
  successMessage!: string;
  isDisable = false;
  placeholder: string = 'Votre matricule';
  menaRef!: any;
  checked!: any;
  checkedExamCode!: any;

  genders = genders;
  selectedGender = '';
  pictureUrl: any = '../assets/default_picture.png';

  msgs: Message[] = [];

  studentInfoForm = this.fb.group({
    std_menaref: ['', []],
    std_lastname: ['', [Validators.required]],
    std_firstname: ['', [Validators.required]],
    std_birthdate: ['', [Validators.required]],
    std_birthplace: ['', [Validators.required]],
    std_gender: ['', [Validators.required]],
    std_nationality: ['', [Validators.required]],
    std_email: ['', [Validators.email]],
    std_motherfullname: ['', [Validators.required]],
    std_fatherfullname: ['', [Validators.required]],
    std_birthdocnumber: ['', [Validators.required]],
    std_birthdocdate: ['', [Validators.required]],
    std_birthdoclocation: ['', [Validators.required]],
    std_phone1: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    std_phone2: ['', []],
    std_picture: [File, [Validators.required]],
  });

  studentLastName = '';
  studentFirstName = '';
  fatherFullName = '';
  motherFullName = '';

  @Input() student!: IStudent | {};
  @Input() candidateFounded = false;
  @Output('nextStep') nextStep: EventEmitter<IStudent> = new EventEmitter();
  @Output('prevStep') prevStep: EventEmitter<IStudent> = new EventEmitter();

  constructor(
    private freeCandidateService: FreeCandidateService,
    private messageService: MessageService,
    private storage: SessionStorageService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadPage();
    const info = JSON.parse(this.storage.getItem(FREE_CANDIDATE_INFO_KEY)!);
    const studentData = info.student;
    this.candidateFounded = info.existInPrevious;
    this.checkedExamCode = info.exaCode;
    this.menaRef = info.exaCode;

    this.loadStudentData(studentData);
  }

  loadPage(): void {
    this.loadNationalites();
    this.msgs.push({
      severity: 'warn',
      summary: 'Attention',
      closable: false,
      detail: `En cas d'erreur sur les informations pré-remplir, veuillez-vous référer
        à la Direction des Examens et Concours (DEXC).`,
    });
  }

  loadStudentData(student: any): void {
    if (this.candidateFounded) {
      student as IFreeCandidate;
      this.loadFreeCandidateData(student);
    } else {
      student as IPreviousFreeCandidate;
      this.loadFreePreviousCandidateData(student);
    }
  }

  loadFreePreviousCandidateData(student: IPreviousFreeCandidate): void {
    this.studentFirstName = student.pfc_firstname!;
    this.studentLastName = student.pfc_lastname!;

    this.studentInfoForm.patchValue({
      std_birthdate: student?.pfc_birthdate,
      std_birthplace: student?.pfc_birthplace,
      std_firstname: student?.pfc_firstname,
      std_lastname: student?.pfc_lastname,
      std_gender: student?.pfc_gender,
    });
  }

  loadFreeCandidateData(candidate: IFreeCandidate): void {
    this.pictureUrl = `${environment.mediaURL}${candidate?.fcr_student?.std_picture}`;
    this.studentFirstName = candidate.fcr_student?.std_firstname!;
    this.studentLastName = candidate.fcr_student?.std_lastname!;
    this.fatherFullName = candidate.fcr_student?.std_fatherfullname!;
    this.motherFullName = candidate.fcr_student?.std_motherfullname!;
    this.placeholder = candidate.fcr_student?.std_menaref!;
    this.studentInfoForm.get('std_menaref')?.disable();

    this.studentInfoForm.patchValue({
      std_menaref: candidate?.fcr_student?.std_menaref,
      std_birthdate: candidate?.fcr_student?.std_birthdate,
      std_birthplace: candidate?.fcr_student?.std_birthplace,
      std_firstname: candidate?.fcr_student?.std_firstname,
      std_lastname: candidate?.fcr_student?.std_lastname,
      std_gender: candidate?.fcr_student?.std_gender,
      std_email: candidate?.fcr_student?.std_email,
      std_phone1: candidate?.fcr_student?.std_phone1,
      std_phone2: candidate?.fcr_student?.std_phone2,
      std_motherfullname: candidate?.fcr_student?.std_motherfullname,
      std_fatherfullname: candidate?.fcr_student?.std_fatherfullname,
      std_birthdocnumber: candidate?.fcr_student?.std_birthdocnumber,
      std_birthdocdate: candidate?.fcr_student?.std_birthdocdate,
      std_birthdoclocation: candidate?.fcr_student?.std_birthdoclocation,
      std_nationality: candidate?.fcr_student?.std_nationality?.nat_id,
      std_picture: candidate?.fcr_student?.std_picture,
    });
  }

  initForm() {
    this.studentInfoForm = new FormGroup({
      std_id: new FormControl(0),
      pfc_examcode: new FormControl(null),
      pcf_status: new FormControl(null),
      std_picture: new FormControl(File, Validators.required),
      std_menaref: new FormControl(null, Validators.required),
      std_lastname: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      std_firstname: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      std_birthdate: new FormControl(null, Validators.required),
      std_birthplace: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      std_gender: new FormControl(
        { value: null, disabled: true },
        Validators.required
      ),
      std_nationality: new FormControl(null, Validators.required),
      std_birthdocnumber: new FormControl(null, Validators.required),
      std_birthdoclocation: new FormControl(null, Validators.required),
      std_birthdocdate: new FormControl(null, Validators.required),
      std_email: new FormControl(null, Validators.email),
      std_phone1: new FormControl(null, Validators.required),
      std_phone2: new FormControl(null),
      std_fatherfullname: new FormControl(null, Validators.required),
      std_motherfullname: new FormControl(null, Validators.required),
    });
  }

  get stp_picture() {
    return this.studentInfoForm.get('std_picture');
  }
  get std_menaref() {
    return this.studentInfoForm.get('std_menaref');
  }
  get std_lastname() {
    return this.studentInfoForm.get('std_lastname');
  }
  get std_firstname() {
    return this.studentInfoForm.get('std_firstname');
  }
  get std_birthdate() {
    return this.studentInfoForm.get('std_birthdate');
  }
  get std_birthplace() {
    return this.studentInfoForm.get('std_birthplace');
  }
  get std_gender() {
    return this.studentInfoForm.get('std_gender');
  }
  get std_nationality() {
    return this.studentInfoForm.get('std_nationality');
  }
  get std_birthdocnumber() {
    return this.studentInfoForm.get('std_birthdocnumber');
  }
  get std_birthdoclocation() {
    return this.studentInfoForm.get('std_birthdoclocation');
  }
  get std_birthdocdate() {
    return this.studentInfoForm.get('std_birthdocdate');
  }
  get std_phone1() {
    return this.studentInfoForm.get('std_phone1');
  }
  get std_fatherfullname() {
    return this.studentInfoForm.get('std_fatherfullname');
  }
  get std_motherfullname() {
    return this.studentInfoForm.get('std_motherfullname');
  }

  // take exam code for matricule
  examCodeToMatricule(event: any) {
    if (event.target.checked) {
      this.studentInfoForm.get('std_menaref')?.disable();
      this.placeholder = this.checkedExamCode;
      this.studentInfoForm.get('std_menaref')?.setValue(this.checkedExamCode);
    } else {
      this.studentInfoForm.get('std_menaref')?.enable();
      this.studentInfoForm.get('std_menaref')?.setValue(this.menaRef);
      this.placeholder = 'Votre matricule';
    }
  }

  onPrevStep() {
    this.prevStep.emit();
  }

  loadNationalites(): void {
    this.freeCandidateService.getNationalities().subscribe((res) => {
      this.nationalities = res.body || [];
    });
  }

  // read picture select
  onFileSelected(e: any) {
    this.pictureStudent = e.target.files[0];

    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.pictureUrl = e.target?.result;

        if (this.candidateFounded)
          this.studentInfoForm.patchValue({
            std_picture: this.pictureUrl,
          });
      };
    }
  }

  // post form
  onStudentInfo(): void {
    if (!this.studentInfoForm.dirty) {
      this.nextStep.emit();
    }

    this.isLoader = true;

    const formData: any = new FormData();

    formData.append(
      'std_picture',
      this.pictureStudent,
      this.pictureStudent.name
    );

    formData.append(
      'std_menaref',
      this.studentInfoForm.get('std_menaref')?.value
    );
    formData.append(
      'std_lastname',
      this.studentInfoForm.get('std_lastname')?.value
    );
    formData.append(
      'std_firstname',
      this.studentInfoForm.get('std_firstname')?.value
    );
    formData.append(
      'std_birthdate',
      this.studentInfoForm.get('std_birthdate')?.value
    );
    formData.append(
      'std_birthplace',
      this.studentInfoForm.get('std_birthplace')?.value
    );
    formData.append(
      'std_gender',
      this.studentInfoForm.get('std_gender')?.value
    );
    formData.append(
      'std_nationality',
      this.studentInfoForm.get('std_nationality')?.value
    );
    formData.append(
      'std_birthdocnumber',
      this.studentInfoForm.get('std_birthdocnumber')?.value
    );
    formData.append(
      'std_birthdoclocation',
      this.studentInfoForm.get('std_birthdoclocation')?.value
    );
    formData.append(
      'std_birthdocdate',
      this.studentInfoForm.get('std_birthdocdate')?.value
    );
    formData.append('std_email', this.studentInfoForm.get('std_email')?.value);
    formData.append(
      'std_phone1',
      this.studentInfoForm.get('std_phone1')?.value
    );
    formData.append(
      'std_phone2',
      this.studentInfoForm.get('std_phone2')?.value
    );
    formData.append(
      'std_fatherfullname',
      this.studentInfoForm.get('std_fatherfullname')?.value
    );
    formData.append(
      'std_motherfullname',
      this.studentInfoForm.get('std_motherfullname')?.value
    );

    if (this.candidateFounded) {
      const ref = this.studentInfoForm.get('std_menaref')?.value;

      this.freeCandidateService
        .updateInfoFreeCandidate(ref, formData)
        .subscribe(
          (res) => {
            console.log(res);
            this.student = res;
            this.isLoader = false;
            const info = JSON.parse(
              this.storage.getItem(FREE_CANDIDATE_INFO_KEY)!
            );
            info.student.fcr_student = res;

            this.storage.removeItem(FREE_CANDIDATE_INFO_KEY);
            this.storage.setItem(FREE_CANDIDATE_INFO_KEY, JSON.stringify(info));

            this.handleSuccess();
          },
          (_) => {
            this.isLoader = false;
          }
        );
    } else {
      this.freeCandidateService.saveInfoFreeCandidate(formData).subscribe(
        (res) => {
          this.student = res;
          if (res.std_id!) {
            const studentInfo = JSON.parse(
              this.storage.getItem(FREE_CANDIDATE_INFO_KEY)!
            ).student;

            const body = {
              fcr_studyfield_code: studentInfo.pfc_code_studyfield,
              fcr_diploma: studentInfo.pfc_diploma,
              fcr_examcode: studentInfo.pfc_examcode,
              fcr_examunit: studentInfo.pfc_examunit,
              fcr_examunit_valid: studentInfo.pfc_examunit_valid,
              fcr_student: res.std_id,
            };
            this.freeCandidateService
              .saveFreeCandidateRegistration(body)
              .subscribe((_) => {
                this.isLoader = false;
                this.handleSuccess();
              });
          }
        },

        (_) => {}
      );
    }
  }

  fieldIsRequired(control: AbstractControl): string {
    return hasRequiredField(control) ? '*' : '';
  }

  handleSuccess(): void {
    this.isLoader = false;
    this.displayDialog = false;
    this.messageService.add({
      severity: 'success',
      detail: 'Informations enregistrées avec succèss',
    });
    this.nextStep.emit();
  }

  showDialog() {
    this.displayDialog = true;
  }
}
