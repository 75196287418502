import { Component, OnInit } from '@angular/core';
import { VerifyPaiementService } from '../../services/verify-paiement.service';

@Component({
  selector: 'app-exam-payment-check',
  templateUrl: './exam-payment-check.component.html',
  styleUrls: ['./exam-payment-check.component.scss']
})
export class ExamPaymentCheckComponent implements OnInit {

  isLoader: boolean = false;
  message: string = "Veillez entrer votre matricule dans le champ de recherche";

  messageDefault: boolean = true;
  paimentEntente: boolean = false;
  paimentEffectue: boolean = false;
  paimentInitiation: boolean = false;

  matriculeStudent: string = "";
  resultPaiement: any;

  constructor(private verifyPaiementService: VerifyPaiementService) { }

  ngOnInit(): void {
  }

  onVerifyPaiement():void {
    // console.log(this.matriculeStudent)
    this.isLoader = true;
    this.messageDefault = false;
    this.paimentEntente = false;
    this.paimentEffectue = false;
    this.paimentInitiation = false;

    this.verifyPaiementService.verifyPaiement(this.matriculeStudent).subscribe(
      (Response) => {
        this.resultPaiement = Response.body
        console.log(this.resultPaiement)
        console.log(this.resultPaiement?.exa_paymentstatus)

        if (this.resultPaiement?.exa_paymentstatus === 0) {
          this.messageDefault = false;
          this.paimentEntente = false;
          this.paimentEffectue = false;
          this.paimentInitiation = true;
        } else if (this.resultPaiement?.exa_paymentstatus === 1) {
          this.messageDefault = false;
          this.paimentEntente = true;
          this.paimentEffectue = false;
          this.paimentInitiation = false;
        } else if (this.resultPaiement?.exa_paymentstatus === 2) {
          this.messageDefault = false;
          this.paimentEntente = false;
          this.paimentEffectue = true;
          this.paimentInitiation = false;
        } else if (this.resultPaiement?.exa_paymentstatus === 3) {
          this.messageDefault = false;
          this.paimentEntente = false;
          this.paimentEffectue = true;
          this.paimentInitiation = false;
        } else {
          console.log("None")
        }

        this.isLoader = false;
      },
      (error) => {
        console.log(error);
        if (error?.status === 404) {
          this.messageDefault = true;
          this.message = "Oops:) Erreur de matricule, veuillez verifier votre matricule !"
        }
        this.isLoader = false;
      },
    );
    
  }

}
