import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-activated',
  templateUrl: './activated.component.html',
  styleUrls: ['./activated.component.scss'],
})
export class ActivatedComponent implements OnInit {
  msgs: Message[] = [];
  email = '';

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.email = this.router.getCurrentNavigation()?.extras?.state!['email'];
  }
}
