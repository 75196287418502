import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DobService } from 'src/app/dob/dob.service';

@Component({
  selector: 'app-dashboard-regional-orientation-edit-birthdate',
  templateUrl: './dashboard-regional-orientation-edit-birthdate.component.html',
  styleUrls: ['./dashboard-regional-orientation-edit-birthdate.component.scss'],
})
export class DashboardRegionalOrientationEditBirthdateComponent
  implements OnInit
{
  editForm = this.fb.group({
    birthdate: [null, [Validators.required]],
  });
  isSaving = false;

  constructor(
    private fb: FormBuilder,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dobService: DobService
  ) {}

  ngOnInit(): void {}

  closeDialog(): void {
    this.ref.close();
  }

  save(): void {
    this.isSaving = true;
    const body = {
      birthdate: this.editForm.controls['birthdate'].value,
    };
    const applicantRef = this.config.data.applicant.app_matricule;

    this.dobService
      .validationEditApplicantBirthDate(applicantRef, body)
      .subscribe((res) => {
        this.isSaving = false;
        this.ref.close(res);
      });
  }
}
