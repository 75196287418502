import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StepService } from '../step.service';

@Component({
  selector: 'app-registration-finish',
  templateUrl: './registration-finish.component.html',
  styleUrls: ['./registration-finish.component.scss'],
})
export class RegistrationFinishComponent implements OnInit {
  constructor(private route: Router) {}

  ngOnInit(): void {}

  goHome(): void {
    this.route.navigate(['/']);
  }
}
