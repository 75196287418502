<div class="examen-section">
  <div class="examen-section__content full">
    <!-- <h3>Pré-enregistrement effectué</h3>
    <p>Veuillez selectionner votre type de candidature</p> -->

    <div class="examen-section__registred">
      <p-card>
        <ng-template pTemplate="header">
          <img alt="Card" [src]="imageURL" />
        </ng-template>

        <!-- <div class="examen-section__registred-item">
          <span class="title">Status</span>
          <span class="content">
            <p-chip
              class="chip-warning"
              *ngIf="examregistration?.exa_validation_status! == '1'"
              label="En attente"
            ></p-chip>
            <p-chip
              class="chip-success"
              *ngIf="examregistration?.exa_validation_status! == '2'"
              label="Validé"
            ></p-chip>
            <p-chip
              class="chip-danger"
              *ngIf="examregistration?.exa_validation_status! == '3'"
              label="Rejeté"
            ></p-chip>
          </span>
        </div> -->

        <!-- <ng-template pTemplate="footer"> Footer content here </ng-template> -->
      </p-card>

      <div class="examen-section__registred-detail">
        <div class="examen-section__registred-detail-item">
          <span class="title">Matricule</span>
          <span class="content">{{
            examregistration?.exa_student?.std_menaref
          }}</span>
        </div>

        <div class="examen-section__registred-detail-item">
          <span class="title">Type candidat</span>
          <span class="content">{{ typeCandidature }}</span>
        </div>

        <div class="examen-section__registred-detail-item">
          <span class="title">Nom</span>
          <span class="content">{{
            examregistration?.exa_student?.std_lastname | uppercase
          }}</span>
        </div>

        <div class="examen-section__registred-detail-item">
          <span class="title">Prénoms</span>
          <span class="content">{{
            examregistration?.exa_student?.std_firstname | uppercase
          }}</span>
        </div>

        <div class="examen-section__registred-detail-item">
          <span class="title">Fiche de pré-inscription</span>
          <span class="content file">
            <a class="link link-danger" href="">
              <i class="pi pi-file-pdf"></i>Télécharger
            </a>
          </span>
        </div>
      </div>
    </div>

    <div style="text-align: center; margin-top: 4rem">
      <a [routerLink]="['/']" class="link link-main">
        <i class="pi pi-arrow-left"></i>
        Retour à l'acceuil
      </a>
    </div>
  </div>
</div>
