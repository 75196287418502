<!-- <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb> -->

<p class="dashboard-title">Paiement des frais d'examens</p>

<div class="dashboard-section-filter-transparent">
  <div class="dashboard-section-filter-actions">
    <input
      type="text"
      class="rounded"
      pInputText
      placeholder="Recherche par le nom ou matricule"
      style="width: 300px"
      [(ngModel)]="searchText"
      (ngModelChange)="handleSearchByNameOrStudentCode()"
    />

    <p-multiSelect
      [options]="diplomas"
      styleClass="rounded"
      [(ngModel)]="selectedDiploma"
      optionLabel="dip_shortlabel"
      optionValue="dip_id"
      placeholder="Afficher par diplôme"
      display="chip"
      [style]="{ width: '300px' }"
      (onChange)="handleSearchByDiploma()"
    >
    </p-multiSelect>

    <p-multiSelect
      [options]="paymentStatusList"
      [(ngModel)]="selectedPaymentStatus"
      styleClass="rounded"
      optionLabel="name"
      optionValue="code"
      placeholder="Afficher par statut"
      display="chip"
      [style]="{ width: '300px' }"
      (onChange)="handleSearchByStatus()"
    >
    </p-multiSelect>
  </div>

  <div class="dashboard-section-filter-button">
    <button class="p-button rounded p-button-main" pButton [disabled]="true">
      Initier le paiement de la selection
    </button>

    <button
      class="p-button rounded p-button-outlined p-button-secondary"
      style="margin-left: 2rem"
      (click)="refreshExamRegistrations()"
    >
      <i class="pi pi-refresh" style="margin-right: 1rem"></i>
      Rafraichir
    </button>
  </div>
</div>

<div class="dashboard-section">
  <p-table
    dataKey="exa_id"
    [value]="filteredExamRegistrations"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    [alwaysShowPaginator]="false"
    currentPageReportTemplate="Affichage de {first} à {last} des {totalRecords} enregistrements"
    [(selection)]="selectedStudent"
    [rowTrackBy]="examregistrationTrackBy"
    [rowSelectable]="isExamregistratiionRowSelectable"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>Nom</th>
        <th>Matricule MENA</th>
        <th>Matricule ETFP</th>
        <th>Diplôme préparé</th>
        <th>Filière</th>
        <th>Statut</th>
        <th class="text-right">Code de paiement</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-examregistration>
      <tr>
        <td>
          <p-tableCheckbox
            [value]="examregistration"
            [disabled]="isPaymentInitiatedPendingOrValidated(examregistration)"
          ></p-tableCheckbox>
        </td>
        <td style="display: flex; align-items: center">
          <div style="width: 40px; height: 50px; margin-right: 1rem">
            <img
              style="width: 100%; height: 100%"
              [src]="getPicture(examregistration.exa_student.std_picture)"
              alt=""
            />
          </div>
          <div style="display: flex; flex-direction: column">
            <span>{{
              examregistration.exa_student.std_lastname | uppercase
            }}</span>
            <span style="opacity: 0.5">{{
              examregistration.exa_student.std_firstname | lowercase
            }}</span>
          </div>
        </td>
        <td>{{ examregistration.exa_student.std_menaref }}</td>
        <td>{{ examregistration.exa_student.std_etfparef }}</td>
        <td>
          {{ examregistration.exa_diploma?.dip_shortlabel }}
        </td>
        <td>
          {{ examregistration.exa_studyfield?.sfd_longlabel }}
        </td>
        <!-- <td>
          {{ examregistration.created_at | date: "longDate":"utc":"fr-fr" }}
        </td> -->
        <td>
          <!-- <p-chip
            class="chip-main"
            *ngIf="examregistration.exa_paymentstatus === 0"
            label="-"
          ></p-chip> -->
          <span *ngIf="examregistration.exa_paymentstatus === 0"> - </span>
          <span
            class="chip chip-secondary"
            *ngIf="examregistration.exa_paymentstatus === 1"
          >
            Initiation en cours...
          </span>
          <span
            class="chip chip-warning"
            *ngIf="examregistration.exa_paymentstatus === 2"
          >
            Paiement en attente
          </span>
          <span
            class="chip chip-success"
            *ngIf="examregistration.exa_paymentstatus === 3"
          >
            Paiement effectué
          </span>
          <span
            class="chip chip-danger"
            *ngIf="examregistration.exa_paymentstatus === 4"
          >
            Paiement rejeté
          </span>
        </td>
        <td class="text-right">
          <p-confirmPopup key="PAYMENT_POPUP"></p-confirmPopup>
          <p-toast key="PAYEMENT_RESULT"></p-toast>
          <button
            class="p-button rounded p-button-success"
            (click)="confirmInitiatePayment($event, examregistration)"
            [hidden]="isPaymentInitiatedPendingOrValidated(examregistration)"
          >
            <i
              class="pi pi-wallet"
              style="margin-right: 0.5rem; font-size: 1.3rem"
            ></i>
            Initier le paiement
          </button>

          <button
            class="p-button rounded p-button-outlined p-button-secondary"
            (click)="showPaymentDetail($event, op, examregistration)"
            [hidden]="
              !isPaymentInitiatedPendingOrValidated(examregistration) ||
              examregistration.exa_paymentstatus !== 3
            "
          >
            <i class="pi pi-eye pi-button"></i>
            Details
          </button>

          <div
            [hidden]="
              !isPaymentInitiatedPendingOrValidated(examregistration) ||
              examregistration.exa_paymentstatus === 3
            "
            class="link-main"
            *ngIf="examregistration.exa_examcode"
            style="font-size: 2rem; font-weight: 700"
          >
            {{ examregistration.exa_examcode }}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div
    class="empty-table-message-block"
    *ngIf="filteredExamRegistrations.length === 0 && !isLoading"
  >
    <p>Aucun apprenant trouvé</p>
  </div>

  <div
    *ngIf="isLoading"
    class="loader"
    style="display: flex; justify-content: center"
  >
    <img src="/assets/loader.gif" />
  </div>
</div>

<p-overlayPanel
  [style]="{ width: '370px' }"
  #op
  appendTo="body"
  [dismissable]="true"
  [showCloseIcon]="true"
>
  <ng-template pTemplate>
    <div class="wrapper">
      <div></div>

      <div class="validation-detail-content">
        <div class="validation-detail-content-item">
          <span>Code de paiement</span>
          <span>{{ selectedExamRegistration?.exa_examcode }}</span>
        </div>

        <div class="validation-detail-content-item">
          <span>Référence</span>
          <span>{{
            selectedExamRegistration?.exa_payment?.pay_reference
          }}</span>
        </div>

        <div class="validation-detail-content-item">
          <span>Date de paiement</span>
          <span>{{
            momentToDate(selectedExamRegistration?.exa_payment?.pay_date)
              | date: "medium"
          }}</span>
        </div>

        <div class="validation-detail-content-item">
          <span>Montant</span>
          <span style="font-size: 1.5rem" class="chip chip-main"
            >{{ selectedExamRegistration?.exa_payment?.pay_amount }} FCFA</span
          >
        </div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
