<div class="dob-main-content-wrapper full">
    <div class="dob-main-content-header">
      <p class="dob-main-content-title">Identification</p>
  
      <small>
        <span class="color-danger">(*) </span>
        Champ obligatoire
      </small>
    </div>
  
    <p-messages [(value)]="msgs" key="alertMessage"></p-messages>
  
    <form [formGroup]="editForm">
      <p-toast key="studentInfoEdited"></p-toast>
  
      <div class="form-section">
        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <!-- <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_matricule"
                >Matricule
                <span class="color-danger">{{
                  fieldIsRequired(editForm.controls["app_matricule"])
                }}</span>
              </label>
              <input
                type="text"
                pInputText
                formControlName="app_matricule"
                id="field_app_matricule"
                placeholder="Matricule"
                [readonly]="true"
              />
              <small class="p-field-help" id="field_app_matricule-help"
                >&nbsp;</small
              >
            </div> -->
          </div>
        </div>
  
        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_lastname"
              >Nom
              <span class="color-danger">{{
                fieldIsRequired((editForm?.controls)!["app_lastname"])
              }}</span>
            </label>
            <input
              type="text"
              pInputText
              formControlName="app_lastname"
              id="field_lastname"
              placeholder="Votre nom"
              [ngModel]="studentLastName"
              (ngModelChange)="studentLastName = $event?.toUpperCase()"
              
            />
            <small
              *ngIf="
                editForm.get('app_lastname')?.errors &&
                (editForm.get('app_lastname')?.errors)!['required'] &&
                (editForm.controls['app_lastname'].dirty ||
                  editForm.controls['app_lastname'].touched)
              "
              class="p-field-help"
              id="field_app_lastname-help"
              >Ce champ est obligatore.</small
            >
            <small class="p-field-help" id="field_app_lastname-help"
              >&nbsp;</small
            >
          </div>
  
          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_firstname"
                >Prénom(s)
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_firstname"])
                }}</span>
              </label>
              <input
                type="text"
                pInputText
                formControlName="app_firstname"
                id="field_app_firstname"
                placeholder="Votre prénom"
                [ngModel]="studentFirstName"
                (ngModelChange)="studentFirstName = $event?.toUpperCase()"
              />
              <small
                *ngIf="
                  editForm.get('app_firstname')?.errors &&
                  (editForm.get('app_firstname')?.errors)!['required'] &&
                  (editForm.controls['app_firstname'].dirty ||
                    editForm.controls['app_firstname'].touched)
                "
                class="p-field-help"
                id="field_app_firstname-help"
                >Ce champ est obligatore.</small
              >
              <small class="p-field-help" id="field_app_firstname-help"
                >&nbsp;</small
              >
            </div>
          </div>
        </div>
  
        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_app_birthdate"
              >Date de naissance
              <span class="color-danger">{{
                fieldIsRequired((editForm?.controls)!["app_birthdate"])
              }}</span>
            </label>
            <!-- <input
              type="date"
              pInputText
              formControlName="app_birthdate"
              id="field_app_birthdate"
              placeholder="Votre date de naissance"
            /> -->
            <p-calendar
              formControlName="app_birthdate"
              [showIcon]="true"
              inputId="field_app_birthdate"
              placeholder="Date de naissance Ex: 21-12-2010"
              dateFormat="dd-mm-yy"
              [showButtonBar]="true"
              (onSelect)="handleDateChange()"
              (onInput)="handleDateChange()"
            >
            </p-calendar>
            <small
              *ngIf="
                editForm.get('app_birthdate')?.errors &&
                (editForm.get('app_birthdate')?.errors)!['required'] &&
                (editForm.controls['app_birthdate'].dirty ||
                  editForm.controls['app_birthdate'].touched)
              "
              class="p-field-help"
              id="field_app_birthdate-help"
              >Ce champ est obligatore.</small
            >
  
            <small
              *ngIf="maxBirthDateAllowed"
              class="p-field-help"
              id="field_app_birthdate-help"
              style="line-height: 1.4"
            >
              Vous ne remplissez pas les critères d'age pour participer au concours dans les
              filières de l'ETFP (l'age maximum est de 22 ans)
            </small>
  
            <small class="p-field-help" id="field_app_birthdate-help"
              >&nbsp;</small
            >
          </div>
  
          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_birthplace"
                >Lieu de naissance
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_birthplace"])
                }}</span>
              </label>
              <input
                type="text"
                pInputText
                formControlName="app_birthplace"
                id="field_app_birthplace"
                placeholder="Votre lieu de naissance"
                [ngModel]="studentBirthPlace"
                (ngModelChange)="studentBirthPlace = $event?.toUpperCase()"
              />
              <small
                *ngIf="
                  editForm.get('app_birthplace')?.errors &&
                  (editForm.get('app_birthplace')?.errors)!['required'] &&
                  (editForm.controls['app_birthplace'].dirty ||
                    editForm.controls['app_birthplace'].touched)
                "
                class="p-field-help"
                id="field_app_birthplace-help"
                >Ce champ est obligatore.</small
              >
              <small class="p-field-help" id="field_app_birthplace-help"
                >&nbsp;</small
              >
            </div>
          </div>
        </div>
  
        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <label class="p-field-label" for="field_app_gender"
              >Genre
              <span class="color-danger">{{
                fieldIsRequired((editForm?.controls)!["app_gender"])
              }}</span>
            </label>
            <p-dropdown
              id="field_app_gender"
              [options]="genders"
              [(ngModel)]="selectedGender"
              optionLabel="name"
              optionValue="code"
              formControlName="app_gender"
              placeholder="Selectionner votre genre"
            >
            </p-dropdown>
            <small
              *ngIf="
                editForm.get('app_gender')?.errors &&
                (editForm.get('app_gender')?.errors)!['required'] &&
                (editForm.controls['app_gender'].dirty ||
                  editForm.controls['app_gender'].touched)
              "
              class="p-field-help"
              id="field_app_gender-help"
              >Ce champ est obligatore.</small
            >
            <small class="p-field-help" id="field_app_gender-help">&nbsp;</small>
          </div>
  
          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_nationality"
                >Nationalité
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_nationality"])
                }}</span>
              </label>
              <p-dropdown
                id="field_app_nationality"
                [options]="nationalities"
                [(ngModel)]="selectedNationality"
                optionLabel="nat_label"
                formControlName="app_nationality"
                [filter]="true"
                filterPlaceholder="Nationalité"
                emptyFilterMessage="Aucune nationalité"
                placeholder="Selectionner votre nationalité"
                (onChange)="validateNationality()"
              >
              </p-dropdown>
              <small
                *ngIf="
                  editForm.get('app_nationality')?.errors &&
                  (editForm.get('app_nationality')?.errors)!['required'] &&
                  (editForm.controls['app_nationality'].dirty ||
                    editForm.controls['app_nationality'].touched)
                "
                class="p-field-help"
                id="field_app_nationality-help"
                >Ce champ est obligatore.</small
              >
              <small
                *ngIf="isNationalityInvalid"
                class="p-field-help"
                id="field_app_nationality-help"
                >Seule les candidats de nationalité IVOIRIENNE sont
                autorisée</small
              >
              <small
                *ngIf="!editForm.get('app_nationality')?.errors"
                class="p-field-help"
                id="field_app_nationality-help"
                >&nbsp;</small
              >
            </div>
          </div>
        </div>
  
        <div class="p-flex-between">
          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_email"
                >Email
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_email"])
                }}</span>
              </label>
              <input
                type="email"
                pInputText
                formControlName="app_email"
                id="field_app_email"
                placeholder="Votre email"
              />
              <small
                *ngIf="
                  editForm.get('app_email')?.errors &&
                  (editForm.get('app_email')?.errors)!['required'] &&
                  (editForm.controls['app_email'].dirty ||
                    editForm.controls['app_email'].touched)
                "
                class="p-field-help"
                id="field_app_email-help"
                >Ce champ est obligatore.</small
              >
              <small
                *ngIf="
                  editForm.get('app_email')?.errors &&
                  (editForm.get('app_email')?.errors)!['email'] &&
                  (editForm.controls['app_email'].dirty ||
                    editForm.controls['app_email'].touched)
                "
                class="p-field-help"
                id="field_app_email-help"
                >Ce champ est doit être un email valide</small
              >
              <small class="p-field-help" id="field_app_email-help">&nbsp;</small>
            </div>
          </div>
  
          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_phone"
                >Téléphone
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_phone"])
                }}</span>
              </label>
              <!-- <input
                  type="text"
                  pInputText
                  formControlName="app_phone1"
                  id="field_app_phone1"
                  placeholder="Téléphone 1"
                /> -->
              <p-inputMask
                mask="9999999999"
                formControlName="app_phone"
                id="field_app_phone1"
                placeholder="__________"
                [autoClear]="false"
              >
              </p-inputMask>
              <small
                *ngIf="
                  editForm.get('app_phone')?.errors &&
                  (editForm.get('app_phone')?.errors)!['required'] &&
                  (editForm.controls['app_phone'].dirty ||
                    editForm.controls['app_phone'].touched)
                "
                class="p-field-help"
                id="field_app_phone1-help"
                >Ce champ est obligatore.</small
              >
              <small
                *ngIf="
                  editForm.get('app_phone')?.errors &&
                  (editForm.get('app_phone')?.errors)!['pattern'] &&
                  (editForm.controls['app_phone'].dirty ||
                    editForm.controls['app_phone'].touched)
                "
                class="p-field-help"
                id="field_app_phone-help"
                >Le numéro de téléphone incorrecte.</small
              >
              <small class="p-field-help" id="field_app_phone-help">&nbsp;</small>
            </div>
          </div>
        </div>
  
        <!-- Confidential code -->
        <div class="p-flex-between" [hidden]="!isFirstTime">
          <div class="p-field p-field-column">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_authcode"
                >Code confidentiel (4 chiffres)
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_authcode"])
                }}</span>
              </label>
              <input
                type="password"
                pInputText
                formControlName="app_authcode"
                id="field_app_authcode"
                size="4"
                inputmode="numeric"
                pattern="[0-9]{4}"
                maxlength="4"
              />
              <small
                *ngIf="
                  editForm.get('app_authcode')?.errors &&
                  (editForm.get('app_authcode')?.errors)!['required'] &&
                  (editForm.controls['app_authcode'].dirty ||
                    editForm.controls['app_authcode'].touched)
                "
                class="p-field-help"
                id="field_app_authcode-help"
                >Ce champ est obligatore.</small
              >
              <small
                *ngIf="
                  editForm.get('app_authcode')?.errors &&
                  (editForm.get('app_authcode')?.errors)!['pattern'] &&
                  (editForm.controls['app_authcode'].dirty ||
                    editForm.controls['app_authcode'].touched)
                "
                class="p-field-help"
                id="field_app_authcode-help"
                >Code confidentiel doit être que des chiffres.</small
              >
              <small class="p-field-help" id="field_app_authcode-help"
                >&nbsp;</small
              >
            </div>
          </div>
  
          <div class="p-field p-field-column" [hidden]="!isFirstTime">
            <div class="p-field p-field-column">
              <label class="p-field-label" for="field_app_authcode_confirm"
                >Confirmer le code
                <span class="color-danger">{{
                  fieldIsRequired((editForm?.controls)!["app_authcode_confirm"])
                }}</span>
              </label>
              <input
                type="password"
                pInputText
                formControlName="app_authcode_confirm"
                id="field_app_authcode_confirm"
                size="4"
                inputmode="numeric"
                pattern="[0-9]{4}"
                maxlength="4"
              />
              <small
                *ngIf="
                  editForm.get('app_authcode_confirm')?.errors &&
                  (editForm.get('app_authcode_confirm')?.errors)!['required'] &&
                  (editForm.controls['app_authcode_confirm'].dirty ||
                    editForm.controls['app_authcode_confirm'].touched)
                "
                class="p-field-help"
                id="field_app_authcode_confirm-help"
                >Ce champ est obligatore.</small
              >
              <small
                *ngIf="
                  editForm.get('app_authcode_confirm')?.errors &&
                  (editForm.get('app_authcode_confirm')?.errors)!['pattern'] &&
                  (editForm.controls['app_authcode_confirm'].dirty ||
                    editForm.controls['app_authcode_confirm'].touched)
                "
                class="p-field-help"
                id="field_app_authcode_confirm1-help"
                >Code confidentiel doit être que des chiffres.</small
              >
              <small
                *ngIf="
                  editForm.get('app_authcode_confirm')?.errors &&
                  (editForm.get('app_authcode_confirm')?.errors)!['mustMatch'] &&
                  (editForm.controls['app_authcode_confirm'].dirty ||
                    editForm.controls['app_authcode_confirm'].touched)
                "
                class="p-field-help"
                id="field_app_authcode_confirm-help"
                >Code confidentiel non identique</small
              >
              <small class="p-field-help" id="field_app_authcode_confirm-help"
                >&nbsp;</small
              >
            </div>
          </div>
        </div>
      </div>
  
      <div class="p-field p-field-actions loader">
        <!-- <div class="search-loader">
          <img src="/assets/loader.gif" alt="Loader" *ngIf="isSaving" />
        </div> -->
  
        <div></div>
        <div>
          <button
            pButton
            pRipple
            type="button"
            label="Précédent"
            icon="pi pi-arrow-left"
            class="p-button-secondary p-button-text"
            (click)="goPrev()"
            [disabled]="isSaving"
          ></button>
          
          <!-- [disabled]="editForm?.invalid || isLoading || isSaving" -->
          <button
            
            pButton
            type="button"
            (click)="openConfirmDialog()"
            [style]="{ width: '130px' }"
          >
            <span *ngIf="isSaving"
              ><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i
            ></span>
            <span *ngIf="!isSaving">Suivant</span>
          </button>
        </div>
      </div>
    </form>
  
    <!-- confirm dialog -->
    <p-dialog
      header="Confirmation"
      [(visible)]="showDialog"
      [style]="{ width: '500px' }"
      [resizable]="false"
      appendTo="body"
      [modal]="true"
    >
      <p style="display: flex; align-items: center">
        <i
          style="font-size: 2rem; color: red; margin-right: 1rem"
          class="pi pi-question-circle"
        ></i>
        Êtes-vous sûr des informations renseignées ?
      </p>
  
      <ng-template pTemplate="footer">
        <p-button
          (click)="showDialog = false"
          icon="pi pi-times"
          label="Annuler"
          styleClass="p-button-secondary p-button-text"
        ></p-button>
        <p-button
          (click)="goToNext()"
          label="Confirmer"
          styleClass="p-button"
        ></p-button>
      </ng-template>
    </p-dialog>
  </div>
  
  <!-- <div class="dob-loader" *ngIf="isSaving">
    <p-progressSpinner></p-progressSpinner>
  </div> -->
  