<div class="examen-section">
  <div class="mb-8 examen-section__header">
    <app-examen-breadcrumb [items]="items"></app-examen-breadcrumb>
  </div>

  <div class="examen-section__content full" [hidden]="isRegistrationFinished">
    <h3>Fiche de préinscription</h3>
    <p>Veuillez télécharger votre fiche de préinscription</p>

    <div class="examen-section__document">
      <!-- <embed
        [src]="documentURL"
        type="application/pdf"
        width="100%"
        height="100%"
      /> -->
      <object
        width="100%"
        height="100%"
        type="application/pdf"
        [data]="documentURL"
      >
        <div style="text-align: center">
          <i class="pi pi-exclamation-circle" style="font-size: 2rem"></i>
        </div>

        <p style="margin-bottom: 4rem">Inpossible de prévisualiser la fiche.</p>

        <div style="text-align: center">
          <button
            pButton
            type="button"
            label="Télécharger le fichier"
            icon="pi pi-file-pdf"
            class="p-button-outlined"
            (click)="download()"
            style="margin-right: 2rem"
          ></button>

          <button
            pButton
            type="button"
            label="Terminer"
            (click)="finish()"
          ></button>
        </div>
      </object>
    </div>

    <form>
      <div class="p-field p-field-actions">
        <!-- <button
          pButton
          pRipple
          type="button"
          label="Annuler"
          icon="pi pi-arrow-left"
          class="p-button-secondary p-button-text"
          (click)="goPrev()"
        ></button> -->

        <button
          pButton
          type="button"
          label="Terminer"
          (click)="finish()"
        ></button>
      </div>
    </form>
  </div>

  <div class="examen-section__content full" [hidden]="!isRegistrationFinished">
    <h3 style="text-align: center">Préinscription enregistrée</h3>
    <p style="text-align: center">
      Votre préinscription à été enregistrée et envoyé pour validation, vous
      serez notifié par sms et par email une fois votre dossier de candidature
      validé pour le paiement de vos droits d’examen
    </p>

    <div style="text-align: center; margin-top: 4rem">
      <a class="link link-main" [routerLink]="['/']">
        <i class="pi pi-arrow-left"></i>
        Retour à l'accueil
      </a>
    </div>
  </div>
</div>
