import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DobService } from 'src/app/dob/dob.service';
import { InscriptionService } from 'src/app/inscription/inscription.service';
import { INationality } from 'src/app/shared/model/nationality.model';

@Component({
  selector: 'app-dashboard-regional-orientation-edit-nationality',
  templateUrl:
    './dashboard-regional-orientation-edit-nationality.component.html',
  styleUrls: [
    './dashboard-regional-orientation-edit-nationality.component.scss',
  ],
})
export class DashboardRegionalOrientationEditNationalityComponent
  implements OnInit
{
  editForm = this.fb.group({
    nationality: [null, [Validators.required]],
  });
  nationalities: INationality[] = [];
  selectedNationality?: INationality;
  isSaving = false;

  constructor(
    private fb: FormBuilder,
    private inscriptionService: InscriptionService,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dobService: DobService
  ) {}

  ngOnInit(): void {
    this.getNationalities();
  }

  getNationalities(): void {
    this.inscriptionService.getNationalities().subscribe((res) => {
      this.nationalities = res.body || [];
    });
  }

  closeDialog(): void {
    this.ref.close();
  }

  save(): void {
    this.isSaving = true;
    const body = {
      nationality: this.editForm.controls['nationality'].value.nat_id,
    };
    const applicantRef = this.config.data.applicant.app_matricule;

    this.dobService
      .validationEditApplicantNationality(applicantRef, body)
      .subscribe((res) => {
        this.isSaving = false;
        this.ref.close(res);
      });
  }
}
