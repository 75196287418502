import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { IUser } from '../account/model/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  usersResourceURL = environment.apiURL + 'api/v1/users';

  constructor(private http: HttpClient) {}

  getAllUsers(): Observable<HttpResponse<IUser[]>> {
    return this.http.get<IUser[]>(`${this.usersResourceURL}/`, {
      observe: 'response',
    });
  }
}
