<div class="code-container">
  <div
    style="
      max-width: 576px;
      margin: 0 auto;
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;
    "
  >
    <p style="font-size: 1.7rem">Recherche de code</p>
    <span class="link link-main" (click)="Clear()">
      <i class="pi pi-times"></i>
      Effacer
    </span>
  </div>
  <div class="code-wrapper">
    <div class="code-search">
      <p-inputMask
        mask="99999999a"
        [ngModel]="search"
        id="field_matricule"
        placeholder="_________"
        [maxlength]="9"
        (ngModelChange)="search = $event?.toUpperCase()"
        [autoClear]="false"
        [disabled]="isApplicantSelected"
        styleClass="rounded"
      >
      </p-inputMask>
      <button
        [disabled]="isApplicantSelected"
        (click)="searchStudent()"
        pButton
        class="p-button rounded"
        [disabled]="search == '' && search.length < 9"
      >
        <span *ngIf="!isLoading">Rechercher</span>
        <span *ngIf="isLoading">
          <i class="pi pi-spin pi-spinner"></i>
        </span>
      </button>
    </div>

    <div class="code-result">
      <div class="code-result-value">
        <span *ngIf="isApplicantSelected">{{ code }}</span>
        <span *ngIf="!isApplicantSelected && code.length < 0">----</span>
        <p-messages
          [style]="{ width: '550px' }"
          [(value)]="msgs"
          [closable]="false"
        ></p-messages>
      </div>
    </div>
  </div>
</div>
