import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ee-finish',
  templateUrl: './ee-finish.component.html',
  styleUrls: ['./ee-finish.component.scss']
})
export class EeFinishComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goHome(): void {
    this.router.navigate(['/']);
  }

}
