import { Component, Input, OnInit } from '@angular/core';
import { IExamenBreadcrumb } from 'src/app/shared/model/examen-breadcrumb.model';

@Component({
  selector: 'app-examen-breadcrumb',
  templateUrl: './examen-breadcrumb.component.html',
  styleUrls: ['./examen-breadcrumb.component.scss']
})
export class ExamenBreadcrumbComponent implements OnInit {

  @Input()
  items: IExamenBreadcrumb[] | null = []
  itemsLength = 0;

  constructor() { }

  ngOnInit(): void {
    this.itemsLength = this.items?.length!;
  }

}
