import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { IExamenBreadcrumb } from '../shared/model/examen-breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class StepService {
  private items$ = new BehaviorSubject<IExamenBreadcrumb[]>([]);
  private processStatus$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  init(storage: Storage): void {
    const data = JSON.parse(storage.getItem('registrationStepItems')!)?.data;
    this.items$.next(data);
  }

  getSidebarItems(storage: Storage): Observable<IExamenBreadcrumb[]> {
    this.init(storage);
    return this.items$;
  }

  setProcessStatus(status: boolean): void {
    this.processStatus$.next(status);
  }

  getProcessStatus(): Observable<boolean> {
    return this.processStatus$.asObservable();
  }
}
