import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationLayoutComponent } from './registration-layout/registration-layout.component';
import { RegistrationSearchComponent } from './registration-search/registration-search.component';
import { RegistrationInfosStudentComponent } from './registration-infos-student/registration-infos-student.component';
import { RegistrationInfosClassComponent } from './registration-infos-class/registration-infos-class.component';
import { RegistrationValidationComponent } from './registration-validation/registration-validation.component';
import { RegistrationFinishComponent } from './registration-finish/registration-finish.component';
import { RegistrationStatusComponent } from './registration-status/registration-status.component';

const routes: Routes = [
//   {
//     path: 'registration',
//     component: RegistrationLayoutComponent,
//     children: [
//       {
//         path: 'search',
//         component: RegistrationSearchComponent,
//       },
//       {
//         path: 'infos-student',
//         component: RegistrationInfosStudentComponent,
//       },
//       {
//         path: 'infos-class',
//         component: RegistrationInfosClassComponent,
//       },
//       {
//         path: 'validation',
//         component: RegistrationValidationComponent,
//       }
//     ],
//   },
//   {
//     path: 'registration/finish',
//     component: RegistrationFinishComponent,
//   },
//   {
//     path: 'registration/status',
//     component: RegistrationStatusComponent,
//   },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegistrationRoutingModule {}
