<p-toast></p-toast>
<div class="steps-wrapper full">
  <div class="steps-title">
    <h2>Information suplementaire</h2>
    <small>Veuillez choisir votre filiere</small>
  </div>
  <form [formGroup]="studentFiliereForm" (ngSubmit)="showDialog()">
    <div class="p-field p-field-column">
      <label class="p-field-label" for="field_fcr_session">Session</label>
      <input
        type="text"
        formControlName="fcr_session"
        id="field_fcr_session"
        readonly
        pInputText
      />
    </div>

    <div class="p-flex-between">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="diplome">Diplôme</label>
        <input type="text" formControlName="fcr_diploma" readonly pInputText />
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label" for="filiere">Filiere</label>
        <input
          type="text"
          id="valueOtption"
          formControlName="fcr_studyfield"
          readonly
          pInputText
        />
      </div>
    </div>

    <div class="p-flex-between">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="unite-obtenu"
          >Unité(s) obtenue(s)</label
        >
        <input
          type="text"
          id="unite-obtenu"
          formControlName="fcr_examunit_valid"
          readonly
          pInputText
        />
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label" for="unite-presente"
          >Unité(s) à présenter</label
        >
        <input
          type="text"
          id="unite-presente"
          formControlName="fcr_examtotake"
          readonly
          pInputText
        />
      </div>
    </div>

    <div class="p-flex-between">
      <div class="p-field p-field-column">
        <label class="p-field-label" for="localite"
          >Localité de l'etablissement d'affiliation
          <span class="color-danger">{{
            fieldIsRequired(studentFiliereForm.controls["fcr_locality"])
          }}</span>
        </label>
        <p-dropdown
          id="field_std_nationality"
          [options]="localities"
          optionLabel="loc_label"
          formControlName="fcr_locality"
          [(ngModel)]="selectedLocality"
          [filter]="true"
          [showClear]="true"
          filterPlaceholder="Rechercher une localité"
          placeholder="Selectionner la localité"
          (onChange)="onLocalityChange(selectedLocality)"
        >
        </p-dropdown>
        <!-- <small
              *ngIf="
                studentFiliereForm.get('std_nationality')?.errors &&
                (studentFiliereForm.get('std_nationality')?.errors)!['required'] &&
                (studentFiliereForm.controls['std_nationality'].dirty ||
                  studentFiliereForm.controls['std_nationality'].touched)
              "
              class="p-field-help"
              id="field_std_nationality-help"
              >Ce champ est obligatore.</small
            > -->
      </div>

      <div class="p-field p-field-column">
        <label class="p-field-label" for="school"
          >Etablissement d'affiliation
          <span class="color-danger">{{
            fieldIsRequired(studentFiliereForm.controls["fcr_school"])
          }}</span>
        </label>

        <p-dropdown
          id="field_std_nationality"
          [options]="schools"
          formControlName="fcr_school"
          [(ngModel)]="selectedSchool"
          optionLabel="sch_longlabel"
          placeholder="Selectionner l'établisseement"
          [disabled]="!selectedLocality"
          [showClear]="true"
          [filter]="true"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="p-field p-field-actions loader">
      <div></div>

      <div>
        <button
          pButton
          pRipple
          type="button"
          label="Précédent"
          icon="pi pi-arrow-left"
          class="p-button-secondary p-button-text"
          (click)="onPrevStep()"
          [disabled]="isLoader"
        ></button>

        <button
          [disabled]="isLoader || studentFiliereForm.invalid"
          pButton
          type="submit"
        >
          <span *ngIf="isLoader" class="pi pi-spin pi-spinner"></span>
          <span *ngIf="!isLoader">
            Suivant
            <i class="pi pi-arrow-right"></i>
          </span>
        </button>
      </div>
    </div>
  </form>
</div>

<!-- confirm dialog -->
<p-dialog
  header="Comfirmation"
  [(visible)]="displayDialog"
  [style]="{ width: '500px' }"
  [resizable]="false"
  appendTo="body"
  [modal]="true"
>
  <p style="display: flex; align-items: center">
    <i
      style="font-size: 2rem; color: red; margin-right: 1rem"
      class="pi pi-question-circle"
    ></i>
    Êtes-vous sûr des informations renseignées ?
  </p>

  <ng-template pTemplate="footer">
    <p-button
      (click)="displayDialog = false"
      icon="pi pi-times"
      label="Annuler"
      styleClass="p-button-secondary p-button-text"
    ></p-button>
    <p-button
      (click)="onHandleFreeCandidateExam()"
      label="Confirmer"
      styleClass="p-button"
    ></p-button>
  </ng-template>
</p-dialog>
