<!-- <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb> -->

<p class="dashboard-title">Validation des apprenants</p>
<div style="margin-bottom: 4rem" class="alert alert-message">
  <p>
    Afin de procéder à la validation des apprenants vous devez sélectionner
    <strong class="bold">le diplome</strong>, <strong>la filière</strong> et
    <strong>le niveau</strong> des apprenants concernés puis effectuer la
    validation
  </p>
</div>
<p-toast></p-toast>

<div class="dashboard-section">
  <!-- Search section -->
  <div class="dashboard-section-filter">
    <!-- <div class="dashboard-section-filter-actions"> -->
    <div style="flex: 1" class="dashboard-section-filter-input">
      <p-dropdown
        [options]="schoolDiplomas"
        [(ngModel)]="selectedDiploma"
        optionLabel="dip_longlabel"
        placeholder="Selectionner le diplôme"
        (onChange)="handleDiplomaChange()"
        [style]="{ width: '100%' }"
        styleClass="rounded"
        [disabled]="
          searchFounded === 1 || (searchFounded === 1 && searchStudent !== '')
        "
      >
      </p-dropdown>
    </div>

    <div style="flex: 1" class="dashboard-section-filter-input">
      <p-dropdown
        [options]="studyfields"
        [(ngModel)]="selectedStudyfield"
        optionLabel="sfd_longlabel"
        placeholder="Selectionner la filière"
        styleClass="rounded"
        [style]="{ width: '100%' }"
        [disabled]="
          !selectedDiploma ||
          searchFounded === 1 ||
          (searchFounded === 1 && searchStudent !== '')
        "
        (onChange)="handleLevelStudyFieldChange()"
      >
      </p-dropdown>
    </div>

    <div
      style="flex: 1; max-width: 200px"
      class="dashboard-section-filter-input"
    >
      <p-dropdown
        [options]="levels"
        [(ngModel)]="selectedLevel"
        optionLabel="lvl_label"
        optionValue="lvl_code"
        placeholder="Selectionner le niveau"
        styleClass="rounded"
        [style]="{ width: '100%' }"
        [disabled]="
          !selectedStudyfield ||
          searchFounded === 1 ||
          (searchFounded === 1 && searchStudent !== '')
        "
      >
      </p-dropdown>
    </div>

    <div class="dashboard-section-filter-button">
      <button
        class="p-button rounded p-button-main"
        pButton
        [disabled]="
          selectedLevel === '' ||
          selectedLevel == null ||
          selectedDiploma === {} ||
          selectedDiploma == null ||
          selectedStudyfield === {} ||
          selectedStudyfield == null ||
          searchFounded === 1 ||
          (searchFounded === 1 && searchStudent !== '')
        "
        (click)="handleSearch()"
      >
        <i class="pi pi-search pi-button"></i>
        Rechercher
      </button>
    </div>
    <!-- </div> -->
  </div>

  <!-- Filter section -->
  <div
    style="border-top: 1px solid #e5e5e5; padding-top: 2rem"
    class="dashboard-section__header"
  >
    <div class="dashboard-section__group">
      <div class="dashboard-section-input">
        <label for="">Afficher par status</label>
        <p-multiSelect
          [options]="statusList"
          [(ngModel)]="selectedStatus"
          optionLabel="name"
          optionValue="code"
          display="chip"
          placeholder="Selectionner le statut"
          (onChange)="handleFilterByStatus()"
          styleClass="rounded"
          [disabled]="searchFounded === 2 || searchFounded === 0"
        >
        </p-multiSelect>
      </div>

      <div class="dashboard-section-input">
        <label for="">Afficher par le nom ou le matricule</label>
        <input
          type="text"
          pInputText
          placeholder="Rechercher"
          [(ngModel)]="searchStudent"
          (ngModelChange)="handleFilterByStudentNameOrRef()"
          class="rounded"
          [disabled]="searchFounded === 2 || searchFounded === 0"
        />
      </div>
    </div>

    <div class="dashboard-section-action">
      <button
        class="p-button rounded p-button-outlined p-button-secondary"
        style="margin-left: 4rem"
        (click)="refreshRegistrations()"
      >
        <i class="pi pi-refresh pi-button"></i>
        Réinitialiser
      </button>
    </div>
  </div>
  <!-- <p-card> -->
  <p-table
    #registrationtable
    [value]="filterRegistrations!"
    [paginator]="true"
    [rows]="5"
    [showCurrentPageReport]="true"
    responsiveLayout="scroll"
    [alwaysShowPaginator]="false"
    currentPageReportTemplate="Affichage de {first} à {last} des {totalRecords} enregistrements"
  >
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th>Matricule MENA</th>
        <th>Matricule ETFP</th>
        <th>Date d'enregistrement</th>
        <th>Niveau</th>
        <th>Statut</th>
        <th style="text-align: right">Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-registration>
      <tr *ngIf="!isLoading">
        <td style="display: flex; align-items: center">
          <div style="width: 40px; height: 50px; margin-right: 1rem">
            <img
              style="width: 100%; height: 100%"
              [src]="getPicture(registration?.reg_student?.std_picture!)"
              alt=""
            />
          </div>
          <div style="display: flex; flex-direction: column">
            <span>{{
              registration?.reg_student?.std_lastname | uppercase
            }}</span>
            <span style="opacity: 0.5">{{
              registration?.reg_student?.std_firstname | lowercase
            }}</span>
          </div>
        </td>

        <td>{{ registration?.reg_student?.std_menaref }}</td>

        <td>
          {{
            registration?.reg_student?.std_etfparef
              ? registration?.reg_student?.std_etfparef
              : "-"
          }}
        </td>

        <td>
          {{ registration.created_at | date: "longDate":"utc":"fr-fr" }}
        </td>
        <td>
          <span>{{ studentLevelLabel(registration?.reg_level!) }}</span>
        </td>
        <td>
          <span
            class="chip chip-warning"
            *ngIf="registration.reg_validationstatus == '0'"
          >
            En attente
          </span>
          <span
            class="chip chip-success"
            *ngIf="registration.reg_validationstatus == '1'"
          >
            Validé
          </span>

          <span
            class="chip chip-danger"
            *ngIf="registration.reg_validationstatus == '2'"
          >
            Rejeté
          </span>
        </td>
        <td style="text-align: right">
          <!-- <button
            *ngIf="registration.reg_validationstatus == '1'"
            class="p-button p-button-main"
            (click)="showDetail(registration)"
            style="margin-right: 6px"
          >
            Validation examen
          </button> -->

          <button
            class="p-button rounded p-button-outlined p-button-secondary"
            (click)="showDetail(registration)"
          >
            <i class="pi pi-eye pi-button"></i>
            Details
          </button>
        </td>
      </tr>
    </ng-template>
    <!-- <ng-template pTemplate="emptymessage"> -->

    <!-- </ng-template> -->
  </p-table>
  <div
    class="empty-table-message-block"
    *ngIf="
      (searchFounded === 1 &&
        selectedStatus.length !== 0 &&
        filterRegistrations?.length === 0) ||
      (searchFounded === 1 &&
        searchStudent !== '' &&
        filterRegistrations?.length === 0) ||
      (searchFounded === 2 && !isLoading)
    "
  >
    <p>Aucun apprenant trouvé</p>
  </div>
  <div
    *ngIf="isLoading"
    class="loader"
    style="display: flex; justify-content: center"
  >
    <img src="/assets/loader.gif" />
  </div>
  <!-- </p-card> -->
</div>

<p-sidebar [(visible)]="displayDetail" [fullScreen]="true" [baseZIndex]="10000">
  <div class="validation-detail">
    <div class="validation-detail-item picture">
      <div class="validation-detail-header-wrapper">
        <div class="validation-detail-item-photo">
          <img
            [src]="getPicture(selectedRegistration?.reg_student?.std_picture!)"
            alt="Photo etudiant"
          />
        </div>

        <div class="validation-detail-header">
          <div class="validation-detail-header-group">
            <div class="validation-detail-header-item">
              <span>Matricule MENA</span>
              <span>{{ selectedRegistration?.reg_student?.std_menaref }}</span>
            </div>

            <div>/</div>

            <div class="validation-detail-header-item">
              <span>Matricule ETFP</span>
              <span>{{
                selectedRegistration?.reg_student?.std_etfparef
                  ? selectedRegistration?.reg_student?.std_etfparef
                  : "-"
              }}</span>
            </div>
          </div>

          <div class="validation-detail-header-group">
            <div
              class="validation-detail-header-item"
              style="margin-bottom: 1rem"
            >
              <span>Nom</span>
              <span>{{
                selectedRegistration?.reg_student?.std_lastname | uppercase
              }}</span>
            </div>

            <div>&nbsp;</div>

            <div class="validation-detail-header-item">
              <span>Prénoms</span>
              <span>{{
                selectedRegistration?.reg_student?.std_firstname | lowercase
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Actions page -->
      <div class="validation-detail-item validation-detail-item-action">
        <p-confirmDialog
          key="validationDialog"
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          acceptButtonStyleClass="p-button-reject"
          rejectButtonStyleClass="p-button-accept"
          acceptLabel="confirmer"
          rejectLabel="Annuler"
        ></p-confirmDialog>

        <button
          *ngIf="selectedRegistration?.reg_validationstatus == '0'"
          class="p-button"
          pButton
          type="button"
          icon="pi pi-check pi-button"
          label="Valider l'enregistrement"
          (click)="confirm()"
          style="margin-right: 1rem"
        ></button>
        <button
          *ngIf="selectedRegistration?.reg_validationstatus == '0'"
          class="p-button-danger"
          pButton
          type="button"
          icon="pi pi-times pi-button"
          label="Annuler"
          (click)="closeValidationDialog()"
        ></button>

        <!-- <p-confirmDialog
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
        ></p-confirmDialog> -->
      </div>
    </div>

    <div class="validation-detail-item-main-content">
      <div class="validation-detail-item">
        <p class="validation-detail-item-title">
          <i class="pi pi-user"></i>
          Information etudiant
        </p>

        <div class="validation-detail-content">
          <!-- <div class="validation-detail-content-item">
          <span>Mode d'accès ETFP</span>
          <span>{{
            studentAccessMode(
              selectedRegistration?.reg_student?.std_etfapaccessmode!
            )
          }}</span>
        </div> -->

          <div class="validation-detail-content-item">
            <span>Genre</span>
            <span>{{
              studentGender(selectedRegistration?.reg_student?.std_gender!)
            }}</span>
          </div>

          <div class="validation-detail-content-item">
            <span>Nationalité</span>
            <span>{{
              selectedRegistration?.reg_student?.std_nationality?.nat_label
            }}</span>
          </div>

          <div class="validation-detail-content-item">
            <span>Date de naissance</span>
            <span>{{ selectedRegistration?.reg_student?.std_birthdate }}</span>
          </div>

          <div class="validation-detail-content-item">
            <span>Lieu de naissance</span>
            <span>{{ selectedRegistration?.reg_student?.std_birthplace }}</span>
          </div>

          <div class="validation-detail-content-item">
            <span>Extrait de naissance</span>
            <span>{{
              selectedRegistration?.reg_student?.std_birthdocnumber
            }}</span>
          </div>

          <div class="validation-detail-content-item">
            <span>Lieu d'établissement</span>
            <span>{{
              selectedRegistration?.reg_student?.std_birthdoclocation
            }}</span>
          </div>

          <div class="validation-detail-content-item">
            <span>Date d'établissement</span>
            <span>{{
              selectedRegistration?.reg_student?.std_birthdocdate
            }}</span>
          </div>

          <div class="validation-detail-content-item">
            <span>Email</span>
            <span>{{ selectedRegistration?.reg_student?.std_email }}</span>
          </div>

          <div class="validation-detail-content-item">
            <span>Téléphone 1</span>
            <span>{{ selectedRegistration?.reg_student?.std_phone1 }}</span>
          </div>

          <div class="validation-detail-content-item">
            <span>Téléphone 2</span>
            <span>{{ selectedRegistration?.reg_student?.std_phone2 }}</span>
          </div>
        </div>
      </div>

      <div class="validation-detail-item-inner">
        <div class="validation-detail-item">
          <p class="validation-detail-item-title">
            <i class="pi pi-users"></i>
            Information parent
          </p>

          <div class="validation-detail-content">
            <div class="validation-detail-content-item">
              <span>Nom du père</span>
              <span>{{
                selectedRegistration?.reg_student?.std_fatherfullname
              }}</span>
            </div>

            <div class="validation-detail-content-item">
              <span>Nom de la mère</span>
              <span>{{
                selectedRegistration?.reg_student?.std_motherfullname
              }}</span>
            </div>
          </div>
        </div>

        <div class="validation-detail-item">
          <p class="validation-detail-item-title">
            <i class="pi pi-list"></i>
            Infos classe
          </p>

          <div class="validation-detail-content">
            <div class="validation-detail-content-item">
              <span>Mode d'accès à l'ETFP</span>
              <span>{{
                studentAccessMode(selectedRegistration?.reg_etfpaccessmode!)
              }}</span>
            </div>

            <div class="validation-detail-content-item">
              <span>Etablissement</span>
              <span>{{ selectedRegistration?.reg_school?.sch_longlabel }}</span>
            </div>

            <div class="validation-detail-content-item">
              <span>Diplôme</span>
              <span>{{
                selectedRegistration?.reg_diploma?.dip_longlabel
              }}</span>
            </div>

            <div class="validation-detail-content-item">
              <span>Filière</span>
              <span>{{
                selectedRegistration?.reg_studyfield?.sfd_longlabel
              }}</span>
            </div>

            <div class="validation-detail-content-item">
              <span>Niveau</span>
              <span>{{
                studentLevelLabel(selectedRegistration?.reg_level!)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>

<!-- <p-dialog
  header=""
  [(visible)]=""
>
  <label for="field_reject_message">Message du rejet</label>
  <textarea
    pInputTextarea
    [(ngModel)]="rejectedMessage"
    id="field_reject_message"
  ></textarea>

  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayRejectMessageForm = false"
      label="Annuler"
      styleClass="p-button-text"
    ></p-button>
    <p-button
      icon="pi pi-check"
      (click)="handleRegistrationReject()"
      label="Envoyer"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog> -->

<!-- Validation dialog -->
<p-dialog
  header="Validation de l'enregistrement "
  [(visible)]="displayValidationDialog"
  [style]="{ width: '30vw' }"
  [modal]="true"
>
  <p style="margin-bottom: 10px">
    Confirmez-vous la validation de l'étudiant
    {{ selectedRegistration?.reg_student?.std_lastname?.toUpperCase() }}
    {{ selectedRegistration?.reg_student?.std_firstname }}?
  </p>

  <!-- <div
    class="p-field-checkbox"
    style="margin: 2rem 0"
    *ngIf="selectedRegistration?.reg_level! == '3'"
  >
    <p-checkbox
      [(ngModel)]="candidatureChecked"
      [binary]="true"
      inputId="candidature"
    ></p-checkbox>
    <label for="candidature"
      >Voulez-vous sa candidature pour l'examen du
      <span style="font-weight: 700">{{
        selectedRegistration?.reg_diploma?.dip_longlabel
      }}</span></label
    >
  </div> -->

  <div class="p-field p-field-actions loader">
    <div class="search-loader">
      <img src="/assets/loader.gif" alt="Loader" *ngIf="isLoading" />
    </div>
    <div></div>
    <div style="float: right">
      <button
        pButton
        pRipple
        label="Annuler"
        type="button"
        icon="pi pi-times"
        class="p-button-text p-button-sm p-button-secondary"
        data-dismiss="modal"
        (click)="displayValidationDialog = false"
        [disabled]="isSaving"
      ></button>

      <button
        (click)="validate()"
        type="submit"
        pButton
        class="p-button p-button-sm rounded"
      >
        VALIDER
      </button>
    </div>
  </div>
</p-dialog>

<!-- Rejection Dialog -->
<p-dialog
  header="Rejet d'enregistrement"
  [(visible)]="displayRejectMessageForm"
  [style]="{ width: '30vw' }"
  [modal]="true"
>
  <form [formGroup]="rejectForm">
    <div class="p-field p-field-column">
      <label class="p-field-label" for="field_reject_message"
        >Message du rejet
        <span class="color-danger">{{
          fieldIsRequired(rejectForm.controls["message"]!)
        }}</span></label
      >
      <textarea
        pInputTextarea
        id="field_reject_message"
        formControlName="message"
        [style]="{ height: '150px' }"
        [(ngModel)]="rejectedMessage"
        placeholder="Message du rejet"
      ></textarea>
      <small
        *ngIf="
          rejectForm.get('message')?.errors &&
          (rejectForm.get('message')?.errors)!['required']
        "
        class="p-field-help"
        id="field_reject_message-help"
        >Ce champ est obligatore.</small
      >
    </div>

    <div class="p-field p-field-actions loader">
      <div class="search-loader">
        <img src="/assets/loader.gif" alt="Loader" *ngIf="isLoading" />
      </div>
      <div></div>
      <div style="float: right">
        <button
          pButton
          pRipple
          label="Annuler"
          type="button"
          icon="pi pi-times"
          class="p-button-text p-button-secondary"
          data-dismiss="modal"
          (click)="displayRejectMessageForm = false"
          [disabled]="isSaving"
        ></button>

        <button
          (click)="handleRegistrationReject()"
          type="submit"
          pButton
          [disabled]="rejectForm?.invalid || isSaving"
          class="p-button p-button-sm rounded"
        >
          <i
            *ngIf="isSaving"
            class="pi pi-spin pi-spinner"
            style="font-size: 1.7rem"
          ></i>
          <span *ngIf="!isSaving">Rejeter</span>
        </button>
      </div>
    </div>
  </form>
</p-dialog>
