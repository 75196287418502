import { IExamenBreadcrumb } from '../model/examen-breadcrumb.model';

export const steps = [
  {
    icon: 'pi pi-id-card',
    title: 'Recherche',
    step: 1,
    active: true,
    validated: false,
  },
  {
    icon: 'pi pi-info-circle',
    title: 'infos candidat',
    step: 2,
    active: false,
    validated: false,
  },
  {
    icon: 'pi pi-info-circle',
    title: 'infos examen',
    step: 3,
    active: false,
    validated: false,
  },
  {
    icon: 'pi pi-check-square',
    title: 'Validation',
    step: 4,
    active: false,
    validated: false,
  },
].sort((a: IExamenBreadcrumb, b: IExamenBreadcrumb) => {
  return a.step! < b.step! ? -1 : 1;
});

export const freeCandidateSteps = [
  {
    icon: 'pi pi-id-card',
    title: 'Recherche',
    step: 1,
    active: true,
    validated: false,
  },
  {
    icon: 'pi pi-user',
    title: 'Identification',
    step: 2,
    active: false,
    validated: false,
  },
  {
    icon: 'pi pi-book',
    title: 'Détail Examen',
    step: 3,
    active: false,
    validated: false,
  },
  {
    icon: 'pi pi-list',
    title: 'Récapitulatif',
    step: 4,
    active: false,
    validated: false,
  },
].sort((a: IExamenBreadcrumb, b: IExamenBreadcrumb) => {
  return a.step! < b.step! ? -1 : 1;
});
