import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { values } from 'ramda';
import { Observable } from 'rxjs/internal/Observable';
import { Subscribable } from 'rxjs/internal/types';
import { InscriptionService } from 'src/app/inscription/inscription.service';
import { DiplomaService } from 'src/app/services/diploma.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { SchoolService } from 'src/app/services/school.service';
import { StudyfieldService } from 'src/app/services/studyfield.service';
import { YearService } from 'src/app/services/year.service';
import { SessionStorageService } from 'src/app/session-storage.service';
import { IDiploma } from 'src/app/shared/model/diploma.model';
import { INationality } from 'src/app/shared/model/nationality.model';
import { SchoolType } from 'src/app/shared/model/schooltype.model';
import { IStudent } from 'src/app/shared/model/student.model';
import { IStudyField, StudyField } from 'src/app/shared/model/studyfield.model';
import { environment } from 'src/environments/environment';
import { genders } from 'src/app/shared/constant/gender.contanst';
import { FreeCandidateService } from 'src/app/services/free-candidate.service';


declare var $:any;
@Component({
  selector: 'app-concours',
  templateUrl: './concours.component.html',
  styleUrls: ['./concours.component.scss']
})
export class ConcoursComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private schoolService: SchoolService,
    private diplomaService: DiplomaService,
    private messageService: MessageService,
    private studyFieldService: StudyfieldService,
    private inscriptionService: InscriptionService,
    private registrationService: RegistrationService,
    private yearService: YearService,
    private freeCandidateService: FreeCandidateService,
    private sessionStorage: SessionStorageService
  ) {}

  editForm = this.fb.group({
    std_menaref: [null, [Validators.required]],
    std_lastname: [null, [Validators.required]],
    std_firstname: [null, [Validators.required]],
    std_birthdate: [null, [Validators.required]],
    std_birthplace: [null, [Validators.required]],
    std_gender: [null, [Validators.required]],
    std_nationality: [null, [Validators.required]],
    std_email: [null, [Validators.email, Validators.nullValidator]],
    std_motherfullname: [null, [Validators.required]],
    std_fatherfullname: [null, [Validators.required]],
    std_birthdocnumber: [null, [Validators.required]],
    std_birthdocdate: [null, [Validators.required]],
    std_birthdoclocation: [null, [Validators.required]],
    std_phone1: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    std_phone2: ['', []],
    std_picture: [null, [Validators.required]],
  });

  
  diplomas$ = this.diplomaService.getDiplomas();
  studyfields$ = this.studyFieldService.getStudyField();
  schools$ = this.schoolService.loadSchools()

  diplomas: IDiploma[] = [];
  studyfield: any = [];
  studyfield2: any = [];
  studyfield3: any = [];
  school: any = [];

  isSaving = false;
  showDialog = false;
  loaded = false;
  student?: IStudent;

  studentData?: any;

  studentLastName = '';
  studentFirstName = '';
  studentFatherName = '';
  studentMotherName = '';
  studentBirthPlace = '';
  studentBirthDocPlace = '';
  minBirthDateAllowed = false;

  subscription?: Subscribable<any>;

  sectors$?: Observable<IStudyField[]>;
  selectedSector?: IStudyField;

  nationalities: INationality[] = [];
  selectedNationality?: INationality;

  genders: any[] = [];
  selectedGender = '';

  msgs: Message[] = [];
  studentPictureURL = '';


  InitModule(){
    $(document).ready(function() {
      var base_color = "rgb(230,230,230)";
      var active_color = "rgb(36,54,117)";
      
      var child = 1;
      var length = $("section").length - 1;
      $("#prev").addClass("disabled");
      $("#submit").addClass("disabled");
      
      $("section").not("section:nth-of-type(1)").hide();
      $("section").not("section:nth-of-type(1)").css('transform','translateX(100px)');
      
      var svgWidth = length * 200 + 24;
      $("#svg_wrap").html(
        '<svg version="1.1" id="svg_form_time" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 ' +
          svgWidth +
          ' 24" xml:space="preserve"></svg>'
      );
      
      function makeSVG(tag:any, attrs:any) {
        var el = document.createElementNS("http://www.w3.org/2000/svg", tag);
        for (var k in attrs) el.setAttribute(k, attrs[k]);
        return el;
      }
      var i;
      for (i = 0; i < length; i++) {
        var positionX = 12 + i * 200;
        var rect = makeSVG("rect", { x: positionX, y: 9, width: 200, height: 6 });
        //@ts-ignore
        document.getElementById("svg_form_time").appendChild(rect);
        // <g><rect x="12" y="9" width="200" height="6"></rect></g>'
        var circle = makeSVG("circle", {
          cx: positionX,
          cy: 12,
          r: 12,
          width: positionX,
          height: 6
        });
        //@ts-ignore
        document.getElementById("svg_form_time").appendChild(circle);
      }
      
      var circle = makeSVG("circle", {
        //@ts-ignore
        cx: positionX + 200,
        cy: 12,
        r: 12,
        //@ts-ignore
        width: positionX,
        height: 6
      });
      //@ts-ignore
      document.getElementById("svg_form_time").appendChild(circle);
      
      $('#svg_form_time rect').css('fill',base_color);
      $('#svg_form_time circle').css('fill',base_color);
      $("circle:nth-of-type(1)").css("fill", active_color);
      
       
      $(".button").click(function () {
        $("#svg_form_time rect").css("fill", active_color);
        $("#svg_form_time circle").css("fill", active_color);
        //@ts-ignore
        var id = $(this).attr("id");
        if (id == "next") {
          $("#prev").removeClass("disabled");
          if (child >= length) {
            //@ts-ignore
            $(this).addClass("disabled");
            $('#submit').removeClass("disabled");
          }
          if (child <= length) {
            child++;
          }
        } else if (id == "prev") {
          $("#next").removeClass("disabled");
          $('#submit').addClass("disabled");
          if (child <= 2) {
            //@ts-ignore
            $(this).addClass("disabled");
          }
          if (child > 1) {
            child--;
          }
        }
        var circle_child = child + 1;
        $("#svg_form_time rect:nth-of-type(n + " + child + ")").css(
          "fill",
          base_color
        );
        $("#svg_form_time circle:nth-of-type(n + " + circle_child + ")").css(
          "fill",
          base_color
        );
        var currentSection = $("section:nth-of-type(" + child + ")");
        currentSection.fadeIn();
        currentSection.css('transform','translateX(0)');
       currentSection.prevAll('section').css('transform','translateX(-100px)');
        currentSection.nextAll('section').css('transform','translateX(100px)');
        $('section').not(currentSection).hide();
      });
      
    });
  }

  loaders(){
    var pointer = this
    this.diplomas$.subscribe({
      next(value) {
        // console.log(value)
        pointer.diplomas = value;
      },
      error(msg) {
        console.log('Error Getting Diplomas: ', msg);
      }
    })

    this.studyfields$.subscribe({
      next(value) {
        console.log('studyField',value)
        pointer.studyfield = value;
      },
      error(msg) {
        console.log('Error Getting studyfields: ', msg);
      }
    })

    this.schools$.subscribe({
      next(value) {
        console.log('Schools',value.body)
        pointer.school = value.body;
      },
      error(msg) {
        console.log('Error Getting schools: ', msg);
      }
    })

  }

  DelayInputKeys(){
    // console.log(event.target.value);
    
    let timeout1:any = null;
    var pointer = this
    $('#meana_id').keyup(function(){
      clearTimeout(timeout1);
      timeout1 = setTimeout(function () {
        pointer.loadStudentDataFromLS($('#meana_id').val())
      }, 1000);
    })
  }

  loadStudentDataFromLS(studentId:any): void {
    this.messageService.clear('alertMessage');
    this.messageService.clear();

    this.studentData = JSON.parse(
      this.sessionStorage.getItem('foundedStudent')!
    );
    const meanaRef = studentId;

    this.genders = genders;

    if (this.studentData?.message && !this.studentData?.founded) {
      this.msgs = [
        {
          key: 'alertMessage',
          severity: 'warn',
          detail: this.studentData?.message,
        },
      ];
    } else if (this.studentData?.message && this.studentData?.founded) {
      this.msgs = [
        {
          key: 'alertMessage',
          severity: 'success',
          detail: this.studentData?.message,
        },
      ];
    }

    // this.inscriptionService
    //   .getNationalities()
    //   .subscribe((res) => (this.nationalities = res.body || []));

    this.inscriptionService.getStudent(meanaRef).subscribe({
      next: (res) => {
        this.student = res.body || {};
        if (this.student) {
          this.selectedGender = this.student?.std_gender!;
          this.selectedNationality = this.student?.std_nationality!;
          this.studentPictureURL = `${environment.mediaURL}${this.student?.std_picture}`;
          this.studentLastName = this.student?.std_lastname!;
          this.studentFirstName = this.student?.std_firstname!;
          this.studentFatherName = this.student?.std_fatherfullname!;
          this.studentMotherName = this.student?.std_motherfullname!;
          this.studentBirthPlace = this.student?.std_birthplace!;
          this.studentBirthDocPlace = this.student?.std_birthdoclocation!;

          this.editForm.patchValue({
            std_menaref: this.student?.std_menaref,
            std_lastname: this.student?.std_lastname,
            std_firstname: this.student?.std_firstname,
            std_birthdate: this.student?.std_birthdate,
            std_birthplace: this.student?.std_birthplace,
            std_gender: this.student?.std_gender,
            std_nationality: this.student?.std_nationality,
            std_email: this.student?.std_email,
            std_phone1: this.student?.std_phone1,
            std_phone2: this.student?.std_phone2,
            std_picture: this.student?.std_picture,
            std_motherfullname: this.student?.std_motherfullname,
            std_fatherfullname: this.student?.std_fatherfullname,
            std_birthdocnumber: this.student?.std_birthdocnumber,
            std_birthdoclocation: this.student?.std_birthdoclocation,
            std_birthdocdate: this.student?.std_birthdocdate,
          });

          if (this.student?.std_picture) {
            // this.isImageDimOK = true;
            // this.isImageSizeOK = true;
          }
          // if (this.student?.std_birthdate) this.handleDateChange();
        }
      },
      error: () => {
        this.editForm.patchValue({
          std_menaref: meanaRef,
        });
      },
    });
  }

  choice1(event:any){
    this.freeCandidateService.findDiplomaStudyFields(event.target.value).subscribe({
      next: (value)=> {
        console.log(value)
        this.studyfield = value
      }
    })
  }
  choice2(event:any){
    this.freeCandidateService.findDiplomaStudyFields(event.target.value).subscribe({
      next: (value)=> {
        console.log(value)
        this.studyfield2 = value
      }
    })
  }
  choice3(event:any){
    this.freeCandidateService.findDiplomaStudyFields(event.target.value).subscribe({
      next: (value)=> {
        console.log(value)
        this.studyfield3 = value
      }
    })
  }
  

  ngOnInit(): void {
    this.InitModule();
    this.loaders();
    this.DelayInputKeys();
  }

}
