import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/account/auth.service';
import { IUser } from 'src/app/account/model/user.model';
// import { isPlatformBrowser } from '@angular/common';
// import { ChartComponent, ApexOptions, ApexChart } from 'ng-apexcharts';
import {
  ExamregistrationService,
  IStatistic,
} from 'src/app/services/examregistration.service';
import {
  IGeneralStatistic,
  StatisticService,
} from 'src/app/services/statistic.service';

@Component({
  selector: 'app-dashboard-supervisor',
  templateUrl: './dashboard-supervisor.component.html',
  styleUrls: ['./dashboard-supervisor.component.scss'],
})
export class DashboardSupervisorComponent implements OnInit {
  // @ViewChild('chart') chart?: ChartComponent;
  // @ViewChild('validatedExamStudentChart')
  // validatedExamStudentChart?: ChartComponent;
  // @ViewChild('initiatedPaymentChart') initiatedPaymentChart?: ChartComponent;
  // @ViewChild('pendingPaymentChart') pendingPaymentChart?: ChartComponent;
  // @ViewChild('validatedPaymentChart') validatedPaymentChart?: ChartComponent;

  // chartOptions?: Partial<ApexOptions>;
  // validatedExamStudentChartOptions?: Partial<ApexOptions>;
  // initiatedPaymentChartOptions?: Partial<ApexOptions>;
  // pendingPaymentChartOptions?: Partial<ApexOptions>;
  // validatedPaymentChartOptions?: Partial<ApexOptions>;

  paymentStatusValues: any[] = [];
  totalRegional = 0;
  totalSchool = 0;
  totalRegistrationForExam = 0;
  totalExamRegistration = 0;

  isBrowser: boolean = false;
  dataSource: any;
  registrationsStatisticData: any;
  basicOptions: any;
  isLoading = false;
  authUser?: IUser;

  constructor(
    private examRegistrationService: ExamregistrationService,
    private statisticService: StatisticService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // this.loadGeneralStatisticData();
    // this.loadRegisteredStatisticsByDiplomas();
    // this.loadExamRegistrationPaymentStatus();
    this.authService.getAuthUser().subscribe((user) => (this.authUser = user));
  }

  renderChart(): void {
    // this.chart = new ApexCharts(
    //   document.querySelector('#d'),
    //   this.chartOptions
    // );
    // this.chart.render();
  }

  drawRegistrationStatisticChart(labels: string[], values: number[]): void {
    this.registrationsStatisticData = {
      labels: labels,
      datasets: [
        {
          label: 'My first dataset',
          backgroundColor: '#1c2e82',
          data: values,
        },
      ],
    };

    this.basicOptions = {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  }

  // loadRegisteredStatisticChart(labels: string[], values: any[]): void {
  //   this.chartOptions = {
  //     series: [
  //       {
  //         name: 'Enregistrement examen',
  //         data: values,
  //       },
  //     ],

  //     chart: {
  //       height: 350,
  //       type: 'bar',
  //       toolbar: {
  //         show: false,
  //       },
  //     },

  //     plotOptions: {},
  //     title: {},
  //     fill: {
  //       colors: ['#1c2e82'],
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     colors: ['#1c2e82'],
  //     xaxis: {
  //       categories: labels,
  //     },
  //   };
  // }

  loadRegisteredStatisticsByDiplomas(): void {
    this.examRegistrationService
      .getExamRegistrationStatisticsByDiplomas()
      .subscribe((res) => {
        const statistics = res.body;

        if (statistics) {
          const { labels, values } = statistics;
          // this.loadRegisteredStatisticChart(labels, values);
        }
      });
  }

  loadExamRegistrationPaymentStatus(): void {
    this.examRegistrationService
      .getExamRegistrationPaymentStatusStatistics()
      .subscribe((res) => {
        const data = res.body;
        const { labels, percentages, values } = data as IStatistic;
        this.paymentStatusValues = values;
        // this.loadValidatedExamStudent(labels[0], percentages[0]);
        // this.loadInitiatedPaymentStatusData(labels[1], percentages[1]);
        // this.loadPendingPaymentStatusData(labels[2], percentages[2]);
        // this.loadValidatedPaymentStatusData(labels[3], percentages[3]);
      });
  }

  // loadValidatedExamStudent(label: string, value: number): void {
  //   this.validatedExamStudentChartOptions = {
  //     series: [value],
  //     chart: {
  //       height: 280,
  //       type: 'radialBar',
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     plotOptions: {
  //       radialBar: {
  //         hollow: {
  //           size: `${value}%`,
  //         },
  //       },
  //     },
  //     colors: ['#3bc7fa'],

  //     labels: [label],
  //   };
  // }

  // loadInitiatedPaymentStatusData(label: string, value: number): void {
  //   this.initiatedPaymentChartOptions = {
  //     series: [value],
  //     chart: {
  //       height: 280,
  //       type: 'radialBar',
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     plotOptions: {
  //       radialBar: {
  //         hollow: {
  //           size: `${value}%`,
  //         },
  //       },
  //     },
  //     colors: ['#1c2e82'],

  //     labels: [label],
  //   };
  // }

  // loadPendingPaymentStatusData(label: string, value: number): void {
  //   this.pendingPaymentChartOptions = {
  //     series: [value],
  //     chart: {
  //       height: 280,
  //       type: 'radialBar',
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     plotOptions: {
  //       radialBar: {
  //         hollow: {
  //           size: `${value}%`,
  //         },
  //       },
  //     },
  //     colors: ['#fb8500'],

  //     labels: [label],
  //   };
  // }

  // loadValidatedPaymentStatusData(label: string, value: number): void {
  //   this.validatedPaymentChartOptions = {
  //     series: [value],
  //     chart: {
  //       height: 280,
  //       type: 'radialBar',
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     plotOptions: {
  //       radialBar: {
  //         hollow: {
  //           size: `${value}%`,
  //         },
  //       },
  //     },
  //     colors: ['#519259'],

  //     labels: [label],
  //   };
  // }

  loadGeneralStatisticData(): void {
    this.isLoading = true;
    this.statisticService.getGeneralStatistics().subscribe((res) => {
      const { regionals, schools, registrationsExam, examregistrations } =
        res.body as IGeneralStatistic;
      this.totalRegional = regionals.length;
      this.totalSchool = schools.length;
      this.totalRegistrationForExam = registrationsExam.length;
      this.totalExamRegistration = examregistrations.length;
      this.isLoading = false;
    });
  }
}
