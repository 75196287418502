import { Component, OnInit } from '@angular/core';
import { SchoolService } from '../../services/school.service';
import { ISchool } from 'src/app/shared/model/school.model';
import { map, combineLatestWith, tap, toArray } from 'rxjs/operators';
import { IUser } from 'src/app/account/model/user.model';
import { AuthService } from 'src/app/account/auth.service';
import { RegistrationService } from '../../services/registration.service';
import { IRegistration } from '../../shared/model/registration.model';
import { DobService } from 'src/app/dob/dob.service';

@Component({
  selector: 'app-dashboard-regional',
  templateUrl: './dashboard-regional.component.html',
  styleUrls: ['./dashboard-regional.component.scss'],
})
export class DashboardRegionalComponent implements OnInit {
  schools: ISchool[] = [];
  authUser?: IUser;
  schoolsLength = 0;
  registrationTotalRegistred = 0;
  registrationTotalRejected = 0;
  registrationTotalValidated = 0;
  registrationTotalPending = 0;

  totalValidation = 0;
  totalValidationOK = 0;
  totalValidationNoOK = 0;

  schoolsIdList: string[] = [];
  registrations: IRegistration[] = [];
  regionalSchoolRegistrationsList: IRegistration[] = [];

  basicOptions: any;
  basicData: any;
  isLoading = false;

  constructor(
    private schoolService: SchoolService,
    private authService: AuthService,
    private registrationService: RegistrationService,
    private dobService: DobService
  ) {}

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(): void {
    this.isLoading = true;
    // this.loadChart();
    this.authService.getAuthUser().subscribe((user) => {
      this.isLoading = false;
      this.authUser = user;
      this.getValidationStatistics();

      // if (this.authUser) {
      // this.schoolService
      //   .getSchools()
      //   .pipe(
      //     map((schools) =>
      //       schools.filter(
      //         (school) =>
      //           school.sch_regionalbranch?.bch_id ==
      //           this.authUser?.usr_regionalbranch?.bch_id
      //       )
      //     )
      //   )
      //   .subscribe((schools) => {
      //     this.schools = schools || [];
      //     this.schoolsLength = this.schools.length;
      //     this.schoolsIdList =
      //       this.schools.map((school) => school?.sch_id!) || [];
      //   });

      // this.registrationService
      //   .findAll(this.authUser!)
      //   .subscribe((registrations) => {
      //     this.registrations = registrations;
      //     if (this.registrations) {
      //       this.regionalSchoolRegistrationsList = this.registrations.filter(
      //         (registration) =>
      //           this.schoolsIdList.includes(registration?.reg_school?.sch_id!)
      //       );

      //       if (this.regionalSchoolRegistrationsList)
      //         this.getSchoolsStatistics(this.regionalSchoolRegistrationsList);
      //     }
      //   });
      // }
    });
  }

  getSchoolsStatistics(registraions: IRegistration[]): void {
    this.isLoading = false;
    this.registrationTotalRegistred = registraions.length;
    this.registrationTotalValidated = registraions.filter(
      (registration) => registration?.reg_isvalidated
    ).length;
    this.registrationTotalPending = registraions.filter(
      (registration) => registration?.reg_validationstatus == '0'
    ).length;
    this.registrationTotalRejected = registraions.filter(
      (registration) => registration?.reg_validationstatus == '2'
    ).length;
    this.registrationTotalValidated = registraions.filter(
      (registration) => registration?.reg_validationstatus == '1'
    ).length;
  }

  getValidationStatistics(): void {
    this.dobService.getValidationStatistics().subscribe((statistics) => {
      this.totalValidation = statistics.total_validation!;
      this.totalValidationOK = statistics.total_validation_ok!;
      this.totalValidationNoOK = statistics.total_validation_no_ok!;
    });
  }

  loadChart(): void {
    this.basicData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: '#42A5F5',
          data: [65, 59, 80, 67, 56, 55, 40],
        },
        {
          label: 'My Second dataset',
          backgroundColor: '#FFA726',
          data: [28, 48, 40, 19, 75, 27, 45],
        },
      ],
    };
  }
}
